import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { filterIcon } from '../../../assets/images/filter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import CustomMenu from '../../../components/menu/Menu';
import FilterModal from '../../../components/filterModal/FilterModal';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import {
  getBillinghistoryList,
  exportBillingOverviewExcelsheet,
  exportBillingOverviewPdf,
} from '../../../store/actions/accountSettingsActions/billingHistoryActions';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';

const BillingHistory = () => {
  const dispatch = useDispatch();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const { billingHistoryList } = useSelector(
    ({ billingHistoryReducer }: Record<string, any>) =>
      billingHistoryReducer ?? {}
  );

  const { billingHistory } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => billingHistoryList ?? {},
    [billingHistoryList]
  );

  const mobileHeaders = ['billingNumber', 'planType'];

  const filters = {
    startDate: billingHistory?.startDate,
    endDate: billingHistory?.endDate,
  };

  const filterFields = [
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select date',
      type: 'date',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];
  const exportToExcel = () => {
    dispatch(
      exportBillingOverviewExcelsheet({
        ...filters,
      })
    );
  };

  const exportToPdf = () => {
    dispatch(
      exportBillingOverviewPdf({
        ...filters,
      })
    );
  };
  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getBillinghistoryList({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (limit: any) => {
    dispatch(getBillinghistoryList({ page: 1, limit: limit, ...filters }));
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getBillinghistoryList,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'billingHistory',
      () => setOpenFilterModal(false)
    );
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: billingHistory?.limit ?? limit ?? paramLimit ?? 15,
    startDate: billingHistory?.startDate
      ? new Date(billingHistory?.startDate)?.toISOString()
      : undefined,
    endDate: billingHistory?.endDate
      ? new Date(billingHistory?.endDate)?.toISOString()
      : undefined,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: billingHistory?.limit ?? limit ?? paramLimit ?? 15,
      ...filters,
    };
    dispatch(getBillinghistoryList(params));
    dispatch(resetfilterOnModuleChange('billingHistory'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Billing History</div>
        <div className="button-container">
          {!_.isEmpty(billingHistory) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(billingHistory) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(billingHistory)}
            filterData={billingHistory}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="invoice-action"
          />
        </div>
      </div>
      <CustomTable
        headers={headers}
        mobileHeaders={mobileHeaders}
        data={docs}
        onPageChange={onPageChange}
        onChangeLimit={onChangeLimit}
        limit={limit}
        page={page}
        pages={pages}
        total={total}
      />
      {openFilterModal && (
        <FilterModal
          requestFrom="billingHistory"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getBillinghistoryList}
          isDateFilters
        />
      )}
    </div>
  );
};
export default BillingHistory;
