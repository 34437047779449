import { Badge } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeIcon } from '../../../../assets/images/closeIcon';
import { getAuthTokenFromLocalStorage } from '../../../../helpers/localstoragehelper';
import { useOnClickOutside } from '../../../../hooks/userClickOutsideHook';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import { logOutUser } from '../../../../store/actions/loginAction';
import {
  clearNotificationAction,
  getAllNotificationAction,
} from '../../../../store/actions/notificationActions';

interface ProfileMenuProps {
  className?: string;
}

const NotificationDrawer: FC<ProfileMenuProps> = (props) => {
  const { className } = props;
  const dispatch = useDispatch<any>();
  const notificationRef = useRef<HTMLDivElement>(null);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  useOnClickOutside(notificationRef, () => setOpenNotificationModal(false));

  const { notificationList } = useSelector(
    ({ notificationReducer }: Record<string, any>) => notificationReducer ?? {}
  );

  const { role, flow } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );

  const cleareNotification = async (notificationId?: string) => {
    const success = await dispatch(clearNotificationAction(notificationId));
    if (success) {
      dispatch(getAllNotificationAction());
      if (!notificationId) {
        setOpenNotificationModal(false);
      }
    }
  };

  const onNotificationClick = () => {
    setOpenNotificationModal((prev) => !prev);
    if (role !== 'Super Admin' && flow !== 1 && flow !== 2)
      !openNotificationModal && dispatch(getAllNotificationAction());
  };

  useEffect(() => {
    if (role !== 'Super Admin' && flow !== 1 && flow !== 2)
      dispatch(getAllNotificationAction());
  }, []);

  return (
    <div className={`${className} `} ref={notificationRef}>
      {notificationList?.length > 0 ? (
        <Badge
          color="primary"
          badgeContent={notificationList?.length}
          showZero
          variant="standard"
          overlap="circular"
        >
          <span
            className="material-icons notification-bell"
            onClick={onNotificationClick}
          >
            notifications
          </span>
        </Badge>
      ) : (
        <span
          className="material-icons notification-bell"
          onClick={onNotificationClick}
        >
          notifications
        </span>
      )}

      {openNotificationModal && (
        <div className="notification-drawer">
          <div>
            <div className="notification-modal-header">
              <span>Notifications</span>
              <div>
                {notificationList.length > 0 && (
                  <span
                    className="clear-notification"
                    onClick={() => cleareNotification()}
                  >
                    Clear All
                  </span>
                )}
                <div
                  className="notification-close-icon"
                  onClick={() => setOpenNotificationModal(false)}
                >
                  {closeIcon}
                </div>
              </div>
            </div>
            <div className="notification-messages-wrapper">
              {notificationList?.map((notification: Record<string, any>) => (
                <div>
                  {notification.notification}
                  <span
                    className="clear-notification"
                    onClick={() => {
                      cleareNotification(notification._id);
                    }}
                  >
                    {closeIcon}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDrawer;
