import {
  ALPHA_NUMERIC_REGEX,
  FLOAT_NUMBER_REGEX,
} from '../../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  addProduct,
  onChangeProductData,
  updateSelectedProductDetails,
} from '../../../../store/actions/supplierActions/productActions';

export const formInputValidation: any = async (
  dispatch: any,
  productDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    supplierId: 'select supplier',
    categoryId: 'select category',
    name: 'enter valid product name',
    unitId: 'select Unit',
    weight: 'enter valid weight',
  };
  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'supplierId':
      case 'categoryId':
      case 'unitId':
      case 'name':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'weight':
        if (
          value?.toString()?.trim()?.length &&
          !FLOAT_NUMBER_REGEX.test(value?.toString()?.trim())
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in productDetail) {
        const value = productDetail[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, productDetail[fieldName]);
    }
    dispatch(onChangeProductData('errors', errors));
  }
  if (validated && requestFrom === 'onSubmit') {
    const finalData = productDetail;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'supplierId':
          case 'categoryId':
          case 'unitId':
            finalData[key] = finalData[key]._id;
            break;

          case 'weight':
            finalData['weight'] = parseFloat(finalData['weight']);
            break;

          case 'name':
            finalData['name'] = finalData['name']
              .replace(/_/g, ' ')
              .replace(/\w\S*/g, function (txt: string) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });
            break;

          default:
            finalData[key] = finalData[key].toString().trim();
            break;
        }
      }
    }
    delete productDetail?.errors;
    if (action === 'add') {
      const isAddedSuccessFully = await dispatch(addProduct(finalData));

      if (isAddedSuccessFully) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const updateUrl = () => history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT);
      dispatch(
        updateSelectedProductDetails(finalData, productDetail._id, updateUrl)
      );
    }
  }
};
