import { Delete } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import Button from '../../../components/button/Button';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import Input from '../../../components/input/Input';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  deleteCategoryAction,
  getRecipeCategoryList,
  onAddCategoryAction,
  updateRecipeCategoryAction,
} from '../../../store/actions/recipeActions';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const RecipeCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<Record<string, string>>({
    food: '',
    beverages: '',
  });
  const [error, setError] = useState({ food: '', beverages: '' });

  const { categories } = useSelector(
    ({ recipeReducer }: Record<string, any>) => recipeReducer ?? {}
  );

  const { updateRecipeCategoryLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { food, beverages } = useMemo(() => categories ?? {}, [categories]);

  const onAddCategory = (mainCategory: string) => {
    if (
      inputValue?.[mainCategory] &&
      inputValue?.[mainCategory].toString().trim().length
    ) {
      const duplicateRecord = categories?.[mainCategory]?.filter(
        (category: Record<string, string>) =>
          category.name.toString().trim().toLowerCase() ===
          inputValue?.[mainCategory]?.toString().trim().toLowerCase()
      );
      if (duplicateRecord?.length) {
        setInputValue((prev) => ({
          ...prev,
          [mainCategory]: '',
        }));
        setError((prev) => ({
          ...prev,
          [mainCategory]: `${inputValue?.[mainCategory]} subcategory already exists, please add  another one`,
        }));
      } else {
        const data = {
          mainCategory,
          subCategory: {
            name: inputValue[mainCategory],
            mainCategory: mainCategory === 'food' ? 'Food' : 'Beverages',
          },
        };
        dispatch(onAddCategoryAction(data));

        setInputValue((prev) => ({ ...prev, [mainCategory]: '' }));
        setError((prev) => ({ ...prev, [mainCategory]: '' }));
      }
    } else {
      setError((prev) => ({
        ...prev,
        [mainCategory]: 'Please add valid subcategory name',
      }));
    }
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setError((prev) => ({ ...prev, [name]: '' }));
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const onclickSave = () => {
    const finalData = { subcategories: [...food, ...beverages] };
    dispatch(updateRecipeCategoryAction(finalData, history));
  };

  useEffect(() => {
    dispatch(getRecipeCategoryList());
  }, []);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE)}
        />
        <span className="form-title">Add/Edit Categories</span>
      </div>
      <div className="form-container">
        <>
          <div className="category-title">Food</div>
          <div className="form-field-name">Add Subcategory</div>
          <div className="category-input-button-container">
            <Input
              name="food"
              variant="filled"
              value={inputValue?.food}
              placeholder="Enter A New Subcategory"
              onChange={(e) => onInputChange(e)}
              onKeyUp={(event) =>
                enterKeyHelper(event, () => onAddCategory('food'))
              }
              helperText={error.food}
            />
            <Button
              color="primary"
              variant="contained"
              className="modal-button"
              onClick={() => onAddCategory('food')}
            >
              Add
            </Button>
          </div>

          <div className="category-chips-container credit-note-button-container">
            {food?.length > 0 &&
              food?.map((foodCategory: Record<string, string>) => (
                <div className="category-chip">
                  <pre>{foodCategory.name}</pre>
                  <Delete
                    onClick={() =>
                      dispatch(
                        deleteCategoryAction({
                          category: 'food',
                          subcategory: foodCategory.name,
                        })
                      )
                    }
                  />
                </div>
              ))}
          </div>
        </>
        <>
          <div className="category-title">Beverages</div>
          <div className="form-field-name">Add Subcategory</div>
          <div className="category-input-button-container">
            <Input
              name="beverages"
              variant="filled"
              value={inputValue?.beverages}
              placeholder="Enter A New Subcategory"
              onChange={(e) => onInputChange(e)}
              onKeyUp={(event) =>
                enterKeyHelper(event, () => onAddCategory('beverages'))
              }
              helperText={error?.beverages}
            />
            <Button
              color="primary"
              variant="contained"
              className="modal-button"
              onClick={() => onAddCategory('beverages')}
            >
              Add
            </Button>
          </div>
          <div className="category-chips-container credit-note-button-container">
            {beverages?.length > 0 &&
              beverages?.map((drinkCategory: Record<string, string>) => (
                <div className="category-chip">
                  <pre>{drinkCategory?.name}</pre>
                  <Delete
                    onClick={() =>
                      dispatch(
                        deleteCategoryAction({
                          category: 'beverages',
                          subcategory: drinkCategory.name,
                        })
                      )
                    }
                  />
                </div>
              ))}
          </div>
        </>
        <div className="form-button-row">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            loader={updateRecipeCategoryLoaderAction}
            variant="contained"
            onClick={onclickSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecipeCategory;
