import { Collapse, SwipeableDrawer } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import burgerMenu from '../../../assets/images/burger-menu.svg';
import companyLogo from '../../../assets/images/company-logo.svg';
import { SIDEBAR_CONSTANTS } from '../../../constants/sidebarConstants';
import { strockIconConstants } from '../../../constants/strokeIconConstants';
import sidebarHelper from '../../../helpers/sidebarHelper';
import HeaderDateTime from './headerDate&Time/HeaderDate&Time';
import HeaderSubscribePart from './headerSubscribePart/HeaderSubscribepart';
import NotificationDrawer from './notificationDrawer/NotificationDrawer';
import ProfileMenu from './profileMenu/ProfileMenu';

const Header: FC = () => {
  const [isProfileDrawerOpened, setIsProfileDrawerOpened] = useState(false);
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);
  const location = useLocation();
  const { accessModuleList, flow } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const planCancellationFlow = [1, 2];

  const data = sidebarHelper(accessModuleList, profileDetail.role);

  return (
    <div className="header-container">
      <img
        src={companyLogo}
        alt="Foodygent"
        className="header-logo"
        onClick={() => {
          setIsSideMenuOpened(true);
        }}
      />

      <SwipeableDrawer
        className="side-bar-mobile-drawer"
        anchor="left"
        open={isSideMenuOpened}
        onClose={() => setIsSideMenuOpened(false)}
        onOpen={() => setIsSideMenuOpened(true)}
      >
        <div className="mobile-side-menu">
          <img
            src={companyLogo}
            alt="Foodygent"
            className="mobile-logo"
            onClick={() => setIsSideMenuOpened(false)}
          />
          {data.map((menu) => (
            <>
              <NavLink
                key={menu.name}
                to={menu?.url ? menu?.url : menu?.subMenu[0]?.url}
                className={`menu ${
                  location?.pathname?.includes(menu.name) &&
                  flow !== 1 &&
                  flow !== 2
                    ? 'active'
                    : ''
                }`}
                style={{
                  pointerEvents: flow === 1 || flow === 2 ? 'none' : 'auto',
                }}
                onClick={() => !menu?.subMenu && setIsSideMenuOpened(false)}
              >
                <span
                  className={`menu-icon ${
                    strockIconConstants.includes(menu.name)
                      ? 'menu-stroke-icon'
                      : 'menu-fill-icon'
                  }`}
                >
                  {menu.icon}
                </span>
                {menu.label}
              </NavLink>
              <Collapse
                unmountOnExit
                in={
                  menu?.subMenu &&
                  location.pathname?.indexOf(menu.name) > -1 &&
                  flow !== 1 &&
                  flow !== 2
                }
              >
                {menu?.subMenu &&
                  menu.subMenu.map((subMenu: any) => (
                    <NavLink
                      key={subMenu.name}
                      to={subMenu.url}
                      className="sub-menu"
                      onClick={() => setIsSideMenuOpened(false)}
                      style={{
                        pointerEvents:
                          flow === 1 || flow === 2 ? 'none' : 'auto',
                      }}
                    >
                      {subMenu.label}
                    </NavLink>
                  ))}
              </Collapse>
            </>
          ))}
          <div className="side-bar-menu-bottom-blank-container" />
        </div>
      </SwipeableDrawer>
      <div className="header-right-container">
        <HeaderSubscribePart
          className={`desktop-subscribe-part   ${
            (profileDetail.role === 'Super Admin' ||
              planCancellationFlow.includes(flow)) &&
            'header-visibility'
          }`}
          setIsProfileDrawerOpened={setIsProfileDrawerOpened}
        />

        <HeaderDateTime className="desktop-cell-time-container" />

        <div className="header-right-part">
          {flow !== 1 && flow !== 2 && (
            <NotificationDrawer
              className={`desktop-notification-menu-dropdown  ${
                profileDetail.role === 'Super Admin' && 'header-visibility'
              }`}
            />
          )}
          <ProfileMenu
            className="desktop-profile-menu-dropdown"
            setIsProfileDrawerOpened={setIsProfileDrawerOpened}
          />

          <img
            className="burger-menu"
            src={burgerMenu}
            alt="Menu"
            onClick={() => setIsProfileDrawerOpened(true)}
          />
          <SwipeableDrawer
            anchor="right"
            open={isProfileDrawerOpened}
            onClose={() => setIsProfileDrawerOpened(false)}
            onOpen={() => setIsProfileDrawerOpened(true)}
          >
            <div className="profile-drawer-container">
              <div className="close-profile-drawer">
                <span
                  className="material-icons"
                  onClick={() => setIsProfileDrawerOpened(false)}
                >
                  close
                </span>
              </div>

              <ProfileMenu
                setIsProfileDrawerOpened={setIsProfileDrawerOpened}
              />
              <HeaderDateTime className="mobile-date-time" />

              <HeaderSubscribePart
                className={`mobile-subscribe-part ${
                  (profileDetail.role === 'Super Admin' ||
                    planCancellationFlow.includes(flow)) &&
                  'header-visibility'
                }`}
                setIsProfileDrawerOpened={setIsProfileDrawerOpened}
              />
            </div>
          </SwipeableDrawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
