import Button from '../../../components/button/Button';
import myjob from '../../../assets/images/myjob.png';
import xero from '../../../assets/images/xero.png';
import fresho from '../../../assets/images/fresho.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import {
  accountingSoftwareintegration,
  chooseOrganizationAction,
  getCurrentSoftwareaction,
  removeIntegrationAction,
  resetInegrationAction,
  validateSoftwareIntegrationAction,
} from '../../../store/actions/accountSettingsActions/softwareIntegrationAction';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';

const AccountingSoftware = () => {
  const dispatch = useDispatch<any>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState<string | undefined>(
    undefined
  );
  const [switcchingConfirmationModal, setSwitchingConfirmationModal] =
    useState(false);

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<
    Record<string, string>
  >({});

  const { softwareDetails, accountDetail, organizations } = useSelector(
    ({ softwareIntegrationReducer }: Record<string, any>) =>
      softwareIntegrationReducer ?? {}
  );

  const { updateDetailsLoaderAction, loginOrganizationValidationLoader } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? {}
    );

  const onConnect = (software: string) => {
    if (!softwareDetails.allIntegrationsDisabled) {
      setSwitchingConfirmationModal(true);
    } else {
      setSelectedSoftware(software);
      const data = {
        softwareName: software,
      };
      const cb = () => {
        setSelectedSoftware(undefined);
      };
      dispatch(accountingSoftwareintegration(data, cb));
    }
  };

  const onRemove = () => {
    setOpenModal(true);
  };

  const onClickApply = async () => {
    if (!loginOrganizationValidationLoader) {
      const data = selectedOrganization && {
        ...selectedOrganization,
        softwareName: selectedSoftware,
        accountingSoftwareId: accountDetail?.accountingSoftwareId,
      };
      const success = await dispatch(chooseOrganizationAction(data));
      if (success) {
        setOpenOrganizationModal(false);
        setSelectedOrganization({});
        setSelectedSoftware(undefined);
        dispatch(getCurrentSoftwareaction());
      }
    }
  };

  const organizationSelectionButton: ButtonType[] = [
    {
      title: 'Reset',
      onClick: () => {
        setSelectedOrganization({});
      },
      color: 'secondary',
    },
    {
      title: 'Apply',
      onClick: () => {
        onClickApply();
      },
      color: 'primary',
      loader: loginOrganizationValidationLoader,
    },
  ];

  const onProceed = async () => {
    const data = {
      softwareName: selectedSoftware,
      accountingSoftwareId: accountDetail.accountingSoftwareId,
    };
    const reset = () => {
      setSelectedSoftware(undefined);
    };
    const success = await dispatch(
      validateSoftwareIntegrationAction(data, reset)
    );
    if (success) {
      setOpenOrganizationModal(true);
      !organizations?.length && dispatch(getCurrentSoftwareaction());
      selectedSoftware !== 'Myob' && setSelectedSoftware(undefined);
    }
  };

  const onCancel = () => {
    setSelectedSoftware(undefined);
    const success = dispatch(resetInegrationAction());
    if (success) {
      dispatch(getCurrentSoftwareaction());
    }
  };

  const onRemoveSoftware = async () => {
    const success = await dispatch(removeIntegrationAction());
    if (success) {
      setOpenModal(false);
      setSelectedSoftware(undefined);
      !organizations?.length && dispatch(getCurrentSoftwareaction());
    }
  };

  const onSwichAccountingSoftware = async () => {
    const success = await dispatch(removeIntegrationAction());
    if (success) {
      setSelectedSoftware(
        softwareDetails.connectedAccountingSoftwareName === 'Myob'
          ? 'Xero'
          : 'Myob'
      );
      const data = {
        softwareName:
          softwareDetails.connectedAccountingSoftwareName === 'Myob'
            ? 'Xero'
            : 'Myob',
      };
      const cb = () => {
        setSelectedSoftware(undefined);
      };
      const connected = await dispatch(accountingSoftwareintegration(data, cb));
      connected && dispatch(getCurrentSoftwareaction());
      setSwitchingConfirmationModal(false);
    }
  };

  const confirmationModalButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onRemoveSoftware,
      color: 'primary',
    },
  ];
  const switchingConfirmationModalButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setSwitchingConfirmationModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onSwichAccountingSoftware,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };

  const switchHandleClose = () => {
    setSwitchingConfirmationModal(false);
  };

  useEffect(() => {
    dispatch(getCurrentSoftwareaction());
    dispatch(resetfilterOnModuleChange('softwareIntegration'));
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="page-title account-page-title">Accounting Software</div>
      </div>
      <div className="main-details-container">
        <div className="view-details-white-container accounting-detail-block">
          <div className="software-image">
            <img src={myjob} alt="Foodygent" />
          </div>
          <div>
            <Button
              color="primary"
              variant={
                selectedSoftware === 'Myob' ||
                softwareDetails.connectedAccountingSoftwareName === 'Myob'
                  ? 'outlined'
                  : 'contained'
              }
              onClick={() =>
                selectedSoftware === 'Myob' ||
                softwareDetails.connectedAccountingSoftwareName === 'Myob'
                  ? onRemove()
                  : onConnect('Myob')
              }
            >
              {selectedSoftware === 'Myob' ||
              softwareDetails.connectedAccountingSoftwareName === 'Myob'
                ? 'Remove'
                : 'Connect'}
            </Button>
          </div>
        </div>
        <div className="view-details-white-container accounting-detail-block">
          <div className="software-image">
            <img src={xero} alt="Foodygent" />
          </div>
          <div>
            <Button
              color="primary"
              variant={
                selectedSoftware === 'Xero' ||
                softwareDetails.connectedAccountingSoftwareName === 'Xero'
                  ? 'outlined'
                  : 'contained'
              }
              onClick={() =>
                selectedSoftware === 'Xero' ||
                softwareDetails.connectedAccountingSoftwareName === 'Xero'
                  ? onRemove()
                  : onConnect('Xero')
              }
            >
              {selectedSoftware === 'Xero' ||
              softwareDetails.connectedAccountingSoftwareName === 'Xero'
                ? 'Remove'
                : 'Connect'}
            </Button>
          </div>
        </div>
        {/* <div className="view-details-white-container accounting-detail-block">
          <div className="software-image">
            <img src={fresho} alt="Foodygent" />
          </div>
          <div>
            <Button color="primary" variant="contained" onClick={() => {}}>
              Connect
            </Button>
          </div>
        </div> */}
      </div>
      <div className="form-button-row">
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Skip
        </Button>
        {selectedSoftware && (
          <Button
            variant="contained"
            loader={updateDetailsLoaderAction}
            color="primary"
            onClick={onProceed}
          >
            Save Changes
          </Button>
        )}
      </div>

      {organizations?.length > 0 && openOrganizationModal && (
        <CustomModal
          header="Select Organization"
          className="confirmation-modal organization-modal"
          bodyClassName="credential-modal-body"
          buttons={organizationSelectionButton}
          hideModal={() => {
            setOpenOrganizationModal(false);
          }}
        >
          {organizations?.map((organization: Record<string, string>) => {
            return (
              <div className="organization-container">
                <span>{organization?.name}</span>
                <Button
                  color="primary"
                  variant={
                    selectedOrganization.id === organization?.id
                      ? 'outlined'
                      : 'contained'
                  }
                  onClick={() => {
                    setSelectedOrganization(organization);
                  }}
                >
                  {selectedOrganization.id === organization?.id
                    ? 'Remove'
                    : 'Connect'}
                </Button>
              </div>
            );
          })}
        </CustomModal>
      )}

      {openModal && (
        <CustomModal
          header="Remove Accounting Software"
          buttons={confirmationModalButtons}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to Remove Accounting software?
          </span>
        </CustomModal>
      )}

      {switcchingConfirmationModal && (
        <CustomModal
          header="Switch Accounting Software"
          buttons={switchingConfirmationModalButtons}
          hideModal={switchHandleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to Change Accounting software?
          </span>
        </CustomModal>
      )}
    </div>
  );
};
export default AccountingSoftware;
