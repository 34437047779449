import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { downArrowIcon } from '../../../assets/images/downArrow';
import { downGraphIcon } from '../../../assets/images/downGraphIcon';
import { graphIcon } from '../../../assets/images/graph';
import { upArrowIcon } from '../../../assets/images/upArrow';
import Button from '../../../components/button/Button';
import { audConverter } from '../../../constants/regexConstants';
import { useWindowResizeGetElementWidth } from '../../../hooks/OnWindowResizeGetElementWidthHook';

const RecentPriceChange = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const container = containerRef.current;
  const [containerContentWidth, containerTotalWidth] =
    useWindowResizeGetElementWidth(container);
  const [containerScrollLeft, setContainerScrollLeft] = useState<number>(0);

  const { purchasePriceChangeDetails } = useSelector(
    ({ dashboardReducer }: Record<string, any>) => dashboardReducer ?? {}
  );

  const isContentOverflow = useMemo(
    () => containerContentWidth > containerTotalWidth,
    [containerContentWidth, containerTotalWidth]
  );
  const containerInvisiblePart = useMemo(
    () => containerContentWidth - containerTotalWidth,
    [containerContentWidth, containerTotalWidth]
  );
  const isContainerScrolledToEnd = useMemo(
    () => containerTotalWidth - containerScrollLeft <= 20,
    [containerTotalWidth, containerScrollLeft]
  );

  const scrollContainer = (speed: number, direction: string, step: number) => {
    let scrollAmount = 0;
    const scroll = setInterval(() => {
      scrollAmount += step;
      if (container)
        if (direction === 'left') {
          container.scrollLeft -= step;
          if (container) setContainerScrollLeft(container.scrollLeft);
        } else {
          container.scrollLeft += step;
          if (container) setContainerScrollLeft(container.scrollLeft);
        }
      if (scrollAmount >= containerTotalWidth * 0.6) {
        window.clearInterval(scroll);
      }
    }, speed);
  };

  const onPrevClicked = useCallback(() => {
    scrollContainer(10, 'left', 10);
  }, [
    container,
    isContentOverflow,
    containerInvisiblePart,
    containerTotalWidth,
  ]);

  const onNextClicked = useCallback(() => {
    scrollContainer(10, 'right', 10);
  }, [
    container,
    isContentOverflow,
    containerInvisiblePart,
    containerTotalWidth,
  ]);

  return (
    <>
      <div className="dashboard-sub-heading">
        Recent Price Change
        <span className="recent-price-change-date">
          {dayjs(purchasePriceChangeDetails?.[0]?.priceChangeDate).format(
            'MMMM D YYYY'
          )}
        </span>
      </div>
      <div className="recent-price-change-container-wrapper">
        <div ref={containerRef} className="recent-price-change-container">
          {purchasePriceChangeDetails?.map((product: Record<string, any>) => (
            <div className="price-change-block white-block">
              <div className="price-change-block-left-container">
                <div>{audConverter(product.price)}</div>
                <div>{product.name}</div>
              </div>
              <div>
                <div>
                  {product.priceChangeType === 'Down'
                    ? downGraphIcon
                    : graphIcon}
                </div>
                <div
                  className={`price-fluctuation ${
                    product?.priceChangeType
                      ? product?.priceChangeType?.toLowerCase()
                      : 'up'
                  }`}
                >
                  {`${product.priceChangeType === 'Down' ? '-' : '+'}${
                    product.priceChange
                  }% `}
                  {product.priceChangeType === 'Down'
                    ? downArrowIcon
                    : upArrowIcon}
                </div>
              </div>
            </div>
          ))}
        </div>
        {containerScrollLeft !== 0 && (
          <div className="slide-left-button-container">
            <Button
              color="primary"
              variant="contained"
              className="back-button"
              onClick={onPrevClicked}
            >
              <span className="material-icons-outlined">chevron_left</span>
            </Button>
          </div>
        )}
        {isContentOverflow && (
          <div className="slide-right-button-container">
            <Button
              color="primary"
              variant="contained"
              className="back-button"
              onClick={onNextClicked}
            >
              <span className="material-icons-outlined">chevron_right</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default RecentPriceChange;
