import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { reportsApiServices } from '../../../services/pageServices/reportServices/reportsApiServices';
import { REPORTS_REDUX_CONSTANT } from '../../reduxConstants/reportsReduxConstant';
import { REPORT_URL } from '../../../constants/urlConstants';

export const supplierListDropdownInReportsAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getSupplierListInReports();
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_SUPPLIER_LIST_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const categoryDropdownListInReportsAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getCategoryListInReports();
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_CATEGORY_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const supplierPurchaseChartAction = (params?: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getSupplierPurchaseChart(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_SUPPLIER_PURCHASE_CHART,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchasefoodAndBeveragesDetailsAction = (
  params: Record<string, any>
) => {
  return async (dispatch: any) => {
    try {
      const response =
        await reportsApiServices.getPurchaseFoodAndBeveragesDetail(params);
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_PURCHASE_FOOD_AND_BEVERAGES_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const exportPurchasesExcelsheet = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadPurchasesExcelSheet(
        data
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportPurchasesPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadPurchasesPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getSupplierSalesChartAction = (
  params?: Record<string, any> | string
) => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getSupplierSalesChart(params);
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_SUPPLIER_SALES_CHART,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getSalesFoodAndBeveragesDetailsAction = (
  params: Record<string, any>
) => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getSalesFoodAndBeveragesDetails(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_SALES_FOOD_AND_BEVERAGES_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const exportSalesExcelSheet = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadSalesExcelSheet(data);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSalesPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadSalesPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getSupplierCostSalesChartAction = (
  params?: Record<string, any> | string
) => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getSupplierCostSalesChart(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_SUPPLIER_COST_SALE_CHART,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getCostSalesDetailsAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      const response = await reportsApiServices.getCostSaleDetails(params);
      if (response.status === 200) {
        dispatch({
          type: REPORTS_REDUX_CONSTANT.GET_COST_SALE_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const exportCostSalesExcelSheet = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadCostSalesExcelSheet(
        data
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportCostSalesPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await reportsApiServices.downloadCostSalesPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
