import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { RECIPE_REDUX_CONSTANTS } from '../reduxConstants/recipeReduxConstants';
import { ProductType } from './supplierReducer/productReducer';

export interface RecipeType {
  _id?: string;
  name: string;
  recipeCost: string;
  portionCost: string;
  portionCostPercentage: string;
  profitGrossMargin: string;
  salePrice: string;
  numberOfPortions: string;
  foodCostPercentage: string;
  recipeCategoryId: null;
  products: any;
  instructions: string;
  coverPhotoURL: string;
  additionalPhotoURLs: string[];
  errors: Record<string, string>;
  selectedRecipeIds: Record<string, any>[];
}
interface RecipeProps {
  recipeList: Record<string, any>;
  recipeDetail: RecipeType;
  products: Record<string, any> | null;
  categories: Record<string, Record<string, string>[]>;
  categoryDropdown: Record<string, string>[] | null;
  productDropdown: Record<string, string>[] | null;
  previousRecipe: Record<string, string>[] | null;
}

const initialRecipeReducer: RecipeProps = {
  recipeList: {},
  recipeDetail: {
    name: '',
    recipeCost: '0',
    portionCost: '0',
    portionCostPercentage: '0',
    profitGrossMargin: '0',
    salePrice: '0',
    numberOfPortions: '',
    foodCostPercentage: '22',
    recipeCategoryId: null,
    products: null,
    instructions: '',
    coverPhotoURL: '',
    additionalPhotoURLs: [],
    errors: {},
    selectedRecipeIds: [],
  },
  products: null,
  categories: { food: [], beverages: [] },
  categoryDropdown: null,
  productDropdown: null,
  previousRecipe: null,
};

export const recipeReducer = (
  state = initialRecipeReducer,
  action: { type: string; data: Record<string, any> | any; status: boolean }
) => {
  switch (action?.type) {
    case RECIPE_REDUX_CONSTANTS.RECIPE_LIST:
      return {
        ...state,
        recipeList: action?.data,
      };
    case RECIPE_REDUX_CONSTANTS.GET_SELECTED_RECIPE_DETAIL:
      return {
        ...state,
        recipeDetail: action?.data,
      };
    case RECIPE_REDUX_CONSTANTS.ON_CHANGE_RECIPE_DATA:
      const { name, value } = action.data;
      let finalData = value;

      if (name === 'products') {
        finalData = value.reduce((acc: any[], product: any) => {
          const duplicate = acc?.find(
            (selectedProduct: Record<string, any>) =>
              selectedProduct._id === product._id
          );
          if (!duplicate) {
            acc.push(
              !product?.quantity ? { ...product, quantity: 1 } : product
            );
          }
          return acc;
        }, []);
      }

      return {
        ...state,
        recipeDetail: {
          ...state.recipeDetail,
          [name]: finalData,
        },
      };

    case RECIPE_REDUX_CONSTANTS.DELETE_PRODUCT_FOR_RECIPE:
      const products = [...state.recipeDetail.products];
      const updatedProductList =
        products &&
        products.filter(
          (product: ProductType) => product._id !== action.data.productId
        );
      return {
        ...state,
        recipeDetail: {
          ...state.recipeDetail,
          products: updatedProductList,
        },
      };

    case RECIPE_REDUX_CONSTANTS.ON_CHANGE_RECIPE_PRODUCT_QUANTITY:
      const { action: incrementAction, id } = action.data;
      const productList = [...state.recipeDetail.products];
      const updatedValue =
        productList?.length &&
        productList.map((product: Record<string, any>) => {
          if (product._id === id) {
            let qty = product?.quantity ?? 1;
            const quantityChanger: Record<string, number> = {
              add: qty + 1,
              remove: qty > 1 ? qty - 1 : qty,
            };
            const totalPrice =
              quantityChanger[incrementAction] *
              product?.weight *
              product.averageprice;

            return {
              ...product,
              quantity: quantityChanger[incrementAction],
              totalPrice,
            };
          } else return product;
        });

      return {
        ...state,
        recipeDetail: {
          ...state.recipeDetail,
          ['products']: updatedValue,
        },
      };

    case RECIPE_REDUX_CONSTANTS.ON_CHANGE_TABLE_DATA:
      const { inputValue, productId } = action.data;
      const selectedProducts = [...state.recipeDetail.products];
      const data = selectedProducts.map((product) => {
        if (product._id === productId) {
          const totalPrice =
            (product.quantity ?? 1) *
            parseFloat(inputValue) *
            product.averageprice;
          return { ...product, weight: inputValue, totalPrice };
        } else return product;
      });

      return {
        ...state,
        recipeDetail: {
          ...state.recipeDetail,
          ['products']: data,
        },
      };

    case RECIPE_REDUX_CONSTANTS.UPLOAD_RECIPE_PHOTO:
      return {
        ...state,
        recipeDetail: { ...state?.recipeDetail, coverPhotoURL: action.data },
      };

    case RECIPE_REDUX_CONSTANTS.OPTIONAL_RECIPE_PHOTOS:
      return {
        ...state,
        recipeDetail: {
          ...state?.recipeDetail,
          additionalPhotoURLs: [
            ...state?.recipeDetail?.additionalPhotoURLs,
            action.data,
          ],
        },
      };

    case RECIPE_REDUX_CONSTANTS.RESET_RECIPE_DATA:
      return {
        ...state,
        recipeDetail: { ...initialRecipeReducer.recipeDetail, errors: {} },
      };

    case RECIPE_REDUX_CONSTANTS.PRODUCT_DETAILS:
      return {
        ...state,
        products: action.data,
      };

    case RECIPE_REDUX_CONSTANTS.GET_RECIPE_CATEGORY_LIST:
      let food: Record<string, string>[] = [];
      let beverages: Record<string, string>[] = [];
      action.data.map((category: Record<string, string>) => {
        category.mainCategory.toLowerCase() === 'food'
          ? food.push(category)
          : beverages.push(category);
      });

      return { ...state, categories: { food, beverages } };

    case RECIPE_REDUX_CONSTANTS.ON_ADD_RECIPE_CATEGORY:
      const { mainCategory, subCategory } = action.data;

      return {
        ...state,
        categories: {
          ...state.categories,
          [mainCategory]: [...state.categories?.[mainCategory], subCategory],
        },
      };

    case RECIPE_REDUX_CONSTANTS.ON_DELETE_CATEGORY:
      const { category, subcategory } = action.data;
      const stateCategory = { ...state.categories };

      const finalCategory = stateCategory?.[category].filter(
        (item) => item.name !== subcategory
      );

      return {
        ...state,
        categories: { ...state.categories, [category]: finalCategory },
      };

    case RECIPE_REDUX_CONSTANTS.RECIPE_CATEGORY_DROPDOWN:
      return { ...state, categoryDropdown: action.data };

    case RECIPE_REDUX_CONSTANTS.RECIPE_PRODUCT_LIST:
      return { ...state, productDropdown: action.data };

    case RECIPE_REDUX_CONSTANTS.PREVIOUS_RECIPE_LIST:
      return { ...state, previousRecipe: action.data };

    case RECIPE_REDUX_CONSTANTS.RECIPE_CALCULATION:
      return {
        ...state,
        recipeDetail: { ...state.recipeDetail, ...action.data },
      };

    case RECIPE_REDUX_CONSTANTS.DELETE_PREVIOUS_RECIPE:
      const selectedPreviouserecipe = [...state.recipeDetail.selectedRecipeIds];
      const selectedProduct = [...state.recipeDetail.products];

      const finalRecipe = selectedPreviouserecipe.filter(
        (item) => item._id !== action.data
      );

      const finalProduct = selectedProduct.filter(
        (item) => item.recipeId !== action.data
      );
      return {
        ...state,
        recipeDetail: {
          ...state.recipeDetail,
          selectedRecipeIds: finalRecipe,
          products: finalProduct,
        },
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialRecipeReducer,
      };

    default:
      return state;
  }
};
