import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import uploadTermsAndPolicyApiServices from '../../../services/pageServices/admin/uploadTermsAndPlocyApiServices';
import { UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS } from '../../reduxConstants/admin/uploadTermsAndPolicyReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getTermsListAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('termsAndConditionsListLoaderAction');
      const response = await uploadTermsAndPolicyApiServices.gstTermsList();
      if (response.status === 200) {
        dispatch({
          type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.TERMS_AND_CONDITIONS_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('termsAndConditionsListLoaderAction');
    }
  };
};

export const getPolicyListAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('privacyPolicyListLoaderAction');
      const response = await uploadTermsAndPolicyApiServices.gstPolicyList();
      if (response.status === 200) {
        dispatch({
          type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.PRIVACY_POLICY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('privacyPolicyListLoaderAction');
    }
  };
};

export const uploadOrganizationTermsAndCondition = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response =
        await uploadTermsAndPolicyApiServices.uploadOrganizationTermsAndCondition(
          data,
          config
        );
      if (response?.status === 201) {
        dispatch({
          type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.UPLOAD_ORGANIZATION_TERMS_AND_CONDITION,
          data: response.data.data,
        });
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const uploadOrganizationPrivacyPolicy = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response =
        await uploadTermsAndPolicyApiServices.uploadOrganizationPrivacyPolicy(
          data,
          config
        );
      if (response?.status === 201) {
        dispatch({
          type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.UPLOAD_ORGANIZATION_PRIVACY_POLICY,
          data: response.data.data,
        });
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const saveTermsAndPolicyDocuments = (data: Record<string, any>) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('saveDocumentLoaderAction');
      const response = await uploadTermsAndPolicyApiServices.saveDocuments(
        data
      );
      if (response?.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('saveDocumentLoaderAction');
    }
  };
};

export const onChangeActive = (type: string, rowId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.ON_CHANGE_ACTIVE,
      data: { type, rowId },
    });
  };
};

export const onRemoveDocument = (isFor: string, id: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.ON_REMOVE_DOCUMENT,
      data: { isFor, id },
    });
  };
};
