import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import { Edit } from '@mui/icons-material';
import { featureIcon } from '../../../../assets/images/feature';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedPlanDetails,
  resetPlanData,
} from '../../../../store/actions/admin/adminPlanActions';
import Loader from '../../../../components/loader/Loader';

const ViewPlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { planId } = useParams<Record<string, string>>();

  const planDetailConstants = [
    {
      name: 'name',
      label: 'Plan Name',
    },
    {
      name: 'price',
      label: 'Price',
    },
    {
      name: 'noOfFreeInvoices',
      label: 'Number of Free Invoices',
    },
    {
      name: 'tag',
      label: 'Tag',
    },
    {
      name: 'isMonthlyPlan',
      label: 'Plan Type',
    },
  ];

  const onClickBackButton = () => {
    history.push(ADMIN_ROUTE_CONST.PLAN);
  };

  const { getSelectedAdminPlanDetailsLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { planDetail } = useSelector(
    ({ adminPlanReducer }: Record<string, any>) => adminPlanReducer ?? {}
  );

  const planDetails = (field: string) => {
    switch (field) {
      case 'isMonthlyPlan':
        return planDetail?.isMonthlyPlan ? 'Monthly' : 'Yearly';

      case 'price':
        return planDetail?.price
          ? planDetail?.facilities?.includes('GST')
            ? `$${planDetail?.price} + GST`
            : `$${planDetail?.price}`
          : '-';
      case 'isPopular':
        return planDetail?.isPopularPlan ? 'Popular' : '-';

      default:
        return planDetail?.[field] ? planDetail?.[field] : '-';
    }
  };

  useEffect((): any => {
    dispatch(getSelectedPlanDetails(planId));
    return () => dispatch(resetPlanData());
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton onClick={onClickBackButton} />
          <div className="form-title">Plan Details</div>
        </div>
        <div className="button-container">
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(`/plan/edit/${planId}`)}
          >
            Edit Plan
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/plan/edit/${planId}`)}
            >
              <Edit />
            </Button>
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>
      {getSelectedAdminPlanDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div>
          <div className="main-details-container">
            <div className="details-block-title">Plan Details</div>
            <div className="view-details-white-container details-grid details-grid-two-columns">
              {planDetailConstants.map((field) => (
                <div>
                  <div className="detail-label">{field.label}</div>
                  <div className="detail-value">{planDetails(field.name)}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="main-details-container">
            <div className="details-block-title">Plan Facilities</div>
            <div className="view-details-white-container details-grid details-grid-two-columns">
              {/* added filter to remove GST from facilities because we are not showing GST in facilities */}
              {planDetail?.['facilities']
                .filter((field: string) => field !== 'GST')
                .map((field: string) => (
                  <div>
                    <div className="detail-label facilities-label-with-icon">
                      <span>{featureIcon}</span>
                      {field}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewPlan;
