export const currencyConversion = (AUDPrice: number, rate: number) => {
  let price: number = AUDPrice;

  /** This is temporary fix for client demo
   *  TODO: Fix this
   */
  // const timeZone = Intl.DateTimeFormat()
  //   .resolvedOptions()
  //   .timeZone.split('/')
  //   .shift();

  // if (timeZone !== 'Australia') {
  //   price = AUDPrice / rate;
  // }

  price = price * 0.1 + price;

  return price.toFixed(2);
};
