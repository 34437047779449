import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import ProductApiServices from '../../../services/pageServices/supplierServices/productApiServices';
import { ProductType } from '../../reducers/supplierReducer/productReducer';
import { PRODUCT_REDUX_CONSTANTS } from '../../reduxConstants/supplierReduxConstants/productReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getProductList = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('productListLoaderAction');
      const response = await ProductApiServices.productList(params);

      if (response?.status === 200) {
        dispatch({
          type: PRODUCT_REDUX_CONSTANTS.PRODUCT_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('productListLoaderAction');
    }
  };
};

export const getSupplierListInProduct = () => {
  return async (dispatch: any) => {
    try {
      const response = await ProductApiServices.supplierList();
      if (response?.status === 200) {
        dispatch({
          type: PRODUCT_REDUX_CONSTANTS.GET_DROPDOWN_SUPPLIER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const addProduct = (data: ProductType) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('addProductLoaderAction');
      const response = await ProductApiServices.addProduct(data);
      if (response?.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addProductLoaderAction');
    }
  };
};

export const onChangeProductData = (
  name: string,
  value: string | Record<string, string | undefined> | undefined | null
) => {
  return (dispatch: any) => {
    dispatch({
      type: PRODUCT_REDUX_CONSTANTS.ON_CHANGE_PRODUCT_DATA,
      data: {
        name,
        value,
      },
    });
  };
};

export const resetProductData = () => {
  return (dispatch: any) => {
    dispatch({
      type: PRODUCT_REDUX_CONSTANTS.RESET_PRODUCT_DATA,
    });
  };
};

export const getSelectedProductDetails = (productId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedProductDetailLoaderAction');
      const response = await ProductApiServices.getProductDetailsById(
        productId
      );
      if (response?.status === 200) {
        dispatch({
          type: PRODUCT_REDUX_CONSTANTS.GET_SELECTED_PRODUCT_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getSelectedProductDetailLoaderAction');
    }
  };
};

export const updateSelectedProductDetails = (
  data: Record<string, string | number | undefined>,
  productId: string,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('updateProductDetailsLoaderAction');
      const response = await ProductApiServices.updateProductDetailsById(
        data,
        productId
      );
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_REDUX_CONSTANTS.RESET_PRODUCT_DATA,
          data,
        });
        successNotification(`${data?.name}'s details updated successfully`);
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateProductDetailsLoaderAction');
    }
  };
};

export const deleteProduct = (ids: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        productIds: ids,
      };

      const response = await ProductApiServices.deleteProduct(params);

      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportProductExcelsheet = (params: Record<string, string>) => {
  return async () => {
    try {
      const response = await ProductApiServices.downloadProductExcelSheet(
        params
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportProductPdf = (params: Record<string, any>) => {
  return async () => {
    try {
      const response = await ProductApiServices.downloadProductPdf(params);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
