import { displayErrors } from './../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from './../../routes/RouteConstants';
import {
  errorNotification,
  successNotification,
} from './../../components/notifyHelper';
import { registerApiServices } from './../../services/pageServices/registerApiServices';
import { REGISTER_REDUX_CONSTANTS } from './../reduxConstants/registerReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { SET_PASSWORD_REDUX_CONSTANTS } from '../reduxConstants/setPasswordReduxConstant';
import { PROFILE_REDUX_CONSTANTS } from '../reduxConstants/profileReduxConstant';

export const onChangeRegisterData = (
  name: string,
  value: string | number | Record<string, string | undefined> | undefined | null
) => {
  return async (dispatch: any) => {
    dispatch({
      type: REGISTER_REDUX_CONSTANTS.ON_CHANGE_VALUE,
      data: { name, value },
    });
  };
};
export const onRegister = (
  data: Record<string, string | number> | undefined,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await registerApiServices.onRegister(data);
      if (response?.status === 201) {
        successNotification(response.data.message);
        dispatch({
          // type: REGISTER_REDUX_CONSTANTS.RESET_REGISTER_DATA, //remove
          type: SET_PASSWORD_REDUX_CONSTANTS.SET_PASSWORD, //add
          data: response.data.data,
        });
        dispatch({
          type: PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL,
          data: response?.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getDetailsByName = (name: string) => {
  const param = {
    searchString: name,
  };
  return async (dispatch: any) => {
    try {
      const response = await registerApiServices.getDetailbyName(param);

      if (response?.status === 200) {
        response.data.data.length > 0
          ? dispatch({
              type: REGISTER_REDUX_CONSTANTS.GET_COMPANY_DETAILS_BY_NAME,
              data: response.data.data,
            })
          : errorNotification('No data found with this legal business name');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getDetailsByAbn = (abn: string | number) => {
  const param = {
    searchString: abn,
  };
  return async (dispatch: any) => {
    try {
      const response = await registerApiServices.getDetailbyAbn(param);
      if (response.status === 200) {
        dispatch({
          type: REGISTER_REDUX_CONSTANTS.UPDATE_DETAILS_BY_SEARCH,
          data: response.data.data,
        });
        successNotification('Company details filled successfully');
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetCompanyDetail = () => {
  return async (dispatch: any) => {
    dispatch({ type: REGISTER_REDUX_CONSTANTS.RESET_COMPANY_DETAILS });
  };
};

export const validateAbnAndVenueAction = (abn: string | number) => {
  const param = {
    searchString: abn,
  };
  return async () => {
    try {
      const response = await registerApiServices.getDetailbyAbn(param);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
