import React from 'react';
import { closeIcon } from '../../assets/images/closeIcon';
import Button from '../button/Button';
import { CUSTOM_MODAL_TEST_CONSTANT } from './CustomModalConst';
import ButtonLoader from '../buttonLoader/ButtonLoader';

export type ButtonType = {
  title: string | JSX.Element;
  onClick: () => void;
  color: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  loader?: boolean | undefined;
};

type CustomModalProps = {
  header?: string;
  headerIcon?: string;
  buttons?: ButtonType[];
  children: React.ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  className?: string;
  hideModal: (val: boolean) => void;
  successModal?: boolean;
  isHideModal?: boolean;
};

const CustomModal = (props: CustomModalProps) => {
  const {
    header,
    headerIcon,
    buttons,
    children,
    headerClassName,
    bodyClassName,
    footerClassName,
    className,
    hideModal,
    successModal,
    isHideModal = true,
    ...restProps
  } = props;
  const dialogContentClass = `modal-content ${className}`;
  const dialogHeaderClass = `modal-header ${
    successModal ? 'center-title' : ''
  } ${headerClassName}`;
  const dialogBodyClass = `modal-body ${bodyClassName}`;
  const dialogFooterClass = `modal-footer ${
    successModal ? 'success-footer' : ''
  } ${footerClassName}`;

  return (
    <div data-testid={CUSTOM_MODAL_TEST_CONSTANT.customModal} className="modal">
      <div className={dialogContentClass} {...restProps}>
        <div className={dialogHeaderClass}>
          <div>
            <div>
              {headerIcon && (
                <div className="d-flex just-center align-center">
                  <span className="material-icons-round mr-5">
                    {headerIcon}
                  </span>
                  {header}
                </div>
              )}
              {!headerIcon && header}
            </div>
            {successModal ||
              (isHideModal && (
                <div
                  data-testid={CUSTOM_MODAL_TEST_CONSTANT.closeModal}
                  onClick={() => hideModal(false)}
                  className="close-icon-container"
                >
                  {closeIcon}
                </div>
              ))}
          </div>
        </div>
        <div className={dialogBodyClass}>{children}</div>
        {buttons?.length && (
          <div className={dialogFooterClass}>
            {buttons?.map((button) => (
              <Button
                key={button?.title?.toString()}
                data-testid={`${CUSTOM_MODAL_TEST_CONSTANT.submitBtnModal}`}
                variant="contained"
                loader={button.loader}
                color={button.color}
                autoFocus
                className={
                  buttons.length === 1 ? 'big-modal-button' : 'modal-button'
                }
                onClick={() => button.onClick()}
              >
                {button.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
