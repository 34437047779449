import { AUTH_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

const cardApiServices = {
  cardList: (params: Record<string, string>) =>
    ApiService.getData(AUTH_URLS.CARD.GET_CARD_LIST, { params }),
  addCard: (data: Record<string, string>) =>
    ApiService.postData(AUTH_URLS.CARD.ADD_CARD, { ...data }),
  selectCard: (params: Record<string, string>) =>
    ApiService.putData(AUTH_URLS.CARD.SELECT_CARD, { ...params }),
};

export default cardApiServices;
