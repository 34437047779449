import { SALES_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

type SaleIdsType = {
  saleIds: string;
};

export const SalesApiServices = {
  addSale: (data: Record<string, any>) =>
    ApiService.postData(SALES_URLS.ADD_SALE, data),
  salesList: (params: Record<string, any>) =>
    ApiService.getData(SALES_URLS.SALES_LIST, {
      params,
    }),
  getSaleDetailsById: (saleId: string) =>
    ApiService.getData(`${SALES_URLS.GET_SALE_DETAILS_BY_ID}${saleId}`),
  updateSaleDetailsById: (
    data: Record<string, string | number | undefined | null>,
    saleId: string
  ) =>
    ApiService.putData(
      `${SALES_URLS.UPDATE_SALE_DETAILS_BY_ID}${saleId}`,
      data
    ),
  deleteSale: (params: SaleIdsType) =>
    ApiService.deleteData(SALES_URLS.DELETE_SALE, { params }),
  downloadSalesExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: SALES_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadSalesPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: SALES_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...data,
        userDate: new Date(),
      },
    }),
};
