export const statementStatusDropdown = [
  'New',
  'Pending',
  'Partially Reconciled',
  'Reconciled',
];

export const statementDetailsInvoiceHeaders = [
  {
    name: 'invoiceNumber',
    label: 'Invoice#',
    type: 'string',
  },
  {
    name: 'invoiceDate',
    label: 'Date of invoice',
    type: 'date',
  },
  {
    name: 'debitAmount',
    label: 'Debit Amount',
    type: 'amount',
  },
  {
    name: 'paidAmount',
    label: 'Paid',
    type: 'amount',
  },
  {
    name: 'outstandingAmount',
    label: 'Outstanding',
    type: 'amount',
  },
];

export const statementDetailsInvoiceMobileHeaders = [
  'invoiceNumber',
  'invoiceDate',
];
