import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { filterIcon } from '../../../assets/images/filter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import FilterField from '../../../components/filterField/FilterField';
import FilterModal from '../../../components/filterModal/FilterModal';
import Loader from '../../../components/loader/Loader';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import {
  getSubscribersFilterDropdown,
  getSubscribersListAction,
} from '../../../store/actions/admin/subscribersAction';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';

const Subscriber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const { subscribersList, subscribers, plans } = useSelector(
    ({ subscribersReducer }: Record<string, any>) => subscribersReducer ?? {}
  );

  const { subscriber } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { subscribersListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const mobileHeaders = ['tradingName', 'planName'];

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => subscribersList ?? {},
    [subscribersList]
  );

  const filters = {
    subscriberId: subscriber?.subscriberId?._id,
    packageId: subscriber?.packageId?._id,
    status: subscriber?.status,
  };

  const onPageChange = (page: any) => {
    dispatch(
      getSubscribersListAction({
        page: page,
        limit,
        ...filters,
      })
    );
  };

  const onChangeLimit = (value: any) => {
    dispatch(
      getSubscribersListAction({
        page: 1,
        limit: value,
        ...filters,
      })
    );
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: subscribersList?.limit ?? limit ?? paramLimit ?? 15,
    subscriberId: subscriber?.subscriberId?._id,
    packageId: subscriber?.packageId?._id,
    status: subscriber?.status,
  });

  const filterFields = [
    {
      name: 'subscriberId',
      label: 'Subscribers',
      placeholder: 'Select subscriber',
      type: 'autocomplete',
      options: subscribers,
    },
    {
      name: 'packageId',
      label: 'Package',
      placeholder: 'Select package',
      type: 'autocomplete',
      options: plans,
      className: 'filter-field-gap',
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select status',
      type: 'autocomplete',
      options: ['Active', 'Inactive'],
      className: 'filter-field-gap',
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getSubscribersListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'subscriber',
      () => setOpenFilterModal(false),
      [getSubscribersFilterDropdown]
    );
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: subscribersList?.limit ?? paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getSubscribersListAction({ ...params }));
    dispatch(getSubscribersFilterDropdown());
    dispatch(resetfilterOnModuleChange('subscriber'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Subscribers</div>
        <div className="button-container">
          {!_.isEmpty(subscriber) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(subscriber) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(subscriber)}
            filterData={subscriber}
          >
            {filterIcon}
          </Button>
        </div>
      </div>
      {subscribersListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isAction
          isDeleteAction={false}
          isEditable={false}
          viewRecord={(subscriber: Record<string, any>) => {
            history.push(`/subscriber/view/${subscriber?._id}`);
          }}
        />
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="subscriber"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getSubscribersListAction}
          filterAction={[getSubscribersFilterDropdown]}
        />
      )}
    </div>
  );
};

export default Subscriber;
