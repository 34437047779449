export const SUPPLIER_REDUX_CONSTANTS = {
  SUPPLIER_LIST: 'SUPPLIER_LIST',
  GET_PAYMENT_DROPDOWN_DATA: 'GET_PAYMENT_DROPDOWN_DATA',
  ON_ADD_SUPPLIER_VALUE_CHANGE: 'ON_ADD_SUPPLIER_VALUE_CHANGE',
  GET_SELECTED_SUPPLIER_DETAILS: 'GET_SELECTED_SUPPLIER_DETAILS',
  RESET_SUPPLIER_DETAILS: 'RESET_SUPPLIER_DETAILS',
  UPLOAD_TERMS_AND_CONDITION: 'UPLOAD_TERMS_AND_CONDITION',
  DELETE_TERMS_AND_CONDITIONS: 'DELETE_TERMS_AND_CONDITIONS',
  ON_CHANGE_CATEGORY: 'ON_CHANGE_CATEGORY',
  ON_CHANGE_SUBCATEGORY: 'ON_CHANGE_SUBCATEGORY',
  ON_DELETE_CATEGORY: 'ON_DELETE_SUPPLIER_CATEGORY',
  GET_COMPANY_DETAILS_BY_NAME_IN_SUPPLIER:
    'GET_COMPANY_DETAILS_BY_NAME_IN_SUPPLIER',
  UPDATE_DETAILS_BY_SEARCH_IN_SUPPLIER: 'UPDATE_DETAILS_BY_SEARCH_IN_SUPPLIER',
  RESET_COMPANY_DETAILS_IN_SUPPLIER: 'RESET_COMPANY_DETAILS_IN_SUPPLIER',
};
