import {
  EditRounded,
  UploadRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { desktopModificationIcon } from '../../../../assets/images/desktopModificationIcon';
import { MobileModyficationIcon } from '../../../../assets/images/mobileModificationIcon';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import CustomTable from '../../../../components/customTable/CustomTable';
import {
  statementDetailsInvoiceHeaders,
  statementDetailsInvoiceMobileHeaders,
} from '../../../../constants/invoiceconstants/statementConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  getModifieddataAction,
  getStatemetDetailsByIdAction,
  modifyStatementAction,
  syncStatementAction,
  updateStatusToPendingAction,
  updateStatusToReconciledAction,
} from '../../../../store/actions/invoiceActions/statementAction';

const ViewStatement = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { statementId } = useParams<Record<string, string>>();

  const { statementDetails } = useSelector(
    ({ statementReducer }: Record<string, any>) => statementReducer ?? {}
  );

  const { getModifiedStatementLoaderAction, manualSyncStatementLoaderAction } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );

  const modifyInvoice = () => {
    const data = {
      documentId: statementDetails.documentId,
      modifiedDocumentCount: statementDetails.modifiedDocumentCount,
    };
    dispatch(modifyStatementAction(statementId, data));
  };

  const getModifiedData = () => {
    dispatch(getModifieddataAction(statementId, statementDetails.documentId));
  };

  const onSync = async () => {
    const success = await dispatch(syncStatementAction(statementId));
    if (success) {
      dispatch(getStatemetDetailsByIdAction(statementId));
    }
  };

  useEffect(() => {
    dispatch(getStatemetDetailsByIdAction(statementId));
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.STATEMENT)}
          ></BackButton>
          <div className="form-title">Statement Details</div>
        </div>
        <div className="button-container">
          {!statementDetails.isAutoSync &&
            statementDetails.isAccountConnected &&
            !statementDetails?.isUploaded &&
            statementDetails?.status === 'Reconciled' &&
            !statementDetails?.isFreezed && (
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                loader={manualSyncStatementLoaderAction}
                onClick={onSync}
              >
                <Tooltip arrow title="Click to sync statement">
                  <UploadRounded />
                </Tooltip>
              </Button>
            )}
          {statementDetails?.status !== 'Reconciled' && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              loader={getModifiedStatementLoaderAction}
              onClick={() => getModifiedData()}
            >
              {desktopModificationIcon}
            </Button>
          )}
          {statementDetails.fileURL && (
            <Button
              variant="contained"
              color="primary"
              className="desktop-button"
              onClick={() => window.open(statementDetails?.fileURL, '_blank')}
            >
              View Actual statement
            </Button>
          )}
          {statementDetails.status !== 'Reconciled' && (
            <Button
              variant="outlined"
              color="primary"
              className="desktop-button"
              onClick={() => modifyInvoice()}
            >
              Modify
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {statementDetails.status !== 'Reconciled' && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => getModifiedData()}
              >
                <MobileModyficationIcon></MobileModyficationIcon>
              </Button>
            </div>
          )}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => window.open(statementDetails?.fileURL, '_blank')}
            >
              <VisibilityRounded />
            </Button>
          </div>
          {statementDetails.status !== 'Reconciled' && (
            <div className="mobile-button-container">
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => modifyInvoice()}
              >
                <EditRounded />
              </Button>
            </div>
          )}
          {/* mobile button end here */}
        </div>
      </div>

      <div className="list-container">
        <div>
          <div className="details-block-title">Statement Details</div>
          <div className="view-details-white-container details-grid details-grid-three-columns">
            <div>
              <div className="detail-label">Supplier</div>
              <div className="detail-value">
                {statementDetails?.supplierId?.supplierName ?? '-'}
              </div>
            </div>
            <div>
              <div className="detail-label">Email</div>
              <div className="detail-value">
                {statementDetails?.supplierId?.supplierEmail ?? '-'}
              </div>
            </div>
            <div>
              <div className="detail-label">Statement Status</div>
              <div className="detail-value">
                <div
                  className={`status-tag ${statementDetails?.status?.toLowerCase()}`}
                >
                  {statementDetails?.status ?? '-'}
                </div>
              </div>
            </div>
            <div>
              <div className="detail-label">Statement Reconciled By</div>
              <div className="detail-value">
                {statementDetails?.reconciledBy ?? '-'}
              </div>
            </div>
            <div>
              <div className="detail-label">Date</div>
              <div className="detail-value">
                {statementDetails?.statementDate?.toString()?.trim()?.length
                  ? format(
                      parseISO(statementDetails?.statementDate),
                      'dd MMM yyyy'
                    )
                  : '-'}
              </div>
            </div>
            <div>
              <div className="detail-label">Statement ID</div>
              <div className="detail-value">
                {statementDetails?.statementNumber ?? '-'}
              </div>
            </div>
          </div>

          <div className="main-details-container">
            <div className="details-block-title">Line items</div>

            <CustomTable
              headers={statementDetailsInvoiceHeaders}
              mobileHeaders={statementDetailsInvoiceMobileHeaders}
              data={statementDetails?.invoices ?? []}
              isPagination={false}
              primaryTextField="invoiceNumber"
            />
          </div>
          <div className="invoices-total-container statement-total-container">
            <div className="total-container">
              <div className="sub-total-container">
                <div>
                  <span>Current Amount</span>
                  <span className="invoice-total-value">{`$${statementDetails?.currentAmount}`}</span>
                </div>
              </div>
              <div>
                <span>Total</span>
                <span className="invoice-total-value">{`$${statementDetails?.statementBalance}`}</span>
              </div>
            </div>
          </div>
          {statementDetails?.status !== 'Reconciled' && (
            <div className="form-button-row bottom-padding">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.STATEMENT)}
              >
                Cancel
              </Button>
              {statementDetails?.status === 'New' && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    dispatch(updateStatusToPendingAction(statementId, history))
                  }
                >
                  Add to Pending
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  dispatch(updateStatusToReconciledAction(statementId, history))
                }
              >
                Reconciled
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewStatement;
