export const downArrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
  >
    <path
      d="M8.69931 17.8325L15.1999 11.4797C15.3855 11.2983 15.4873 11.0566 15.4873 10.7989C15.4873 10.5409 15.3853 10.2994 15.1999 10.118L14.6096 9.54122C14.4243 9.36002 14.1768 9.26019 13.913 9.26019C13.6493 9.26019 13.3935 9.36002 13.2082 9.54122L9.4076 13.2473L9.4076 1.4728C9.4076 0.941928 8.98233 0.522461 8.43897 0.522461L7.60439 0.522461C7.06103 0.522461 6.59289 0.941928 6.59289 1.4728L6.59289 13.2894L2.77107 9.54136C2.58551 9.36016 2.34478 9.26034 2.08093 9.26034C1.81737 9.26034 1.57313 9.36016 1.38771 9.54136L0.799279 10.1181C0.61372 10.2995 0.512599 10.541 0.512599 10.7991C0.512599 11.0568 0.615036 11.2985 0.800595 11.4798L7.30103 17.8326C7.48718 18.0144 7.73566 18.1143 7.9998 18.1136C8.26483 18.1142 8.51346 18.0144 8.69931 17.8325Z"
      fill="#FF6161"
    />
  </svg>
);
