import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { initialReducerProps } from '../store/reducers/authReducer';
import { ADMIN_ROUTE_CONST, ROUTE_CONSTANTS_VARIABLE } from './RouteConstants';
import '../stringExtension.ts';

interface NonauthenticatedRoutesProps {
  isEscapeRedirect?: boolean;
  component?: React.ComponentType<any>;
  exact?: boolean;
  path: string;
  options: any;
}

export const AuthenticatedRoute = ({
  component,
  path,
  options,
}: NonauthenticatedRoutesProps) => {
  const authStatus: boolean = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer?.authStatus ?? false
  );

  const { accessModuleList, flow } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );

  let key;
  const planFlow = [1, 2, 3];
  if (!planFlow.includes(flow)) {
    key = accessModuleList?.[0]?.hasSubModule
      ? accessModuleList[0]?.subModules?.[0]
          .kebabToSnakeCaseConvertor()
          .toUpperCase()
      : accessModuleList?.[0]?.name.kebabToSnakeCaseConvertor().toUpperCase();
  } else if (flow === 1) {
    key = 'CHANGE_PLAN';
  } else if (flow === 2) {
    key = 'CARD_LIST';
  } else {
    key = 'PAYMENT_AWAITING';
  }

  if (!authStatus) {
    return (
      <Route {...options} path={path}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.LOGIN} />
      </Route>
    );
  }

  if (!component) {
    return (
      <Route {...options} path={path}>
        <Redirect
          to={
            ROUTE_CONSTANTS_VARIABLE[key]
              ? ROUTE_CONSTANTS_VARIABLE[key]
              : ADMIN_ROUTE_CONST[key]
          }
        />
      </Route>
    );
  }

  return <Route {...options} component={component} path={path} />;
};
