import AuthTemplate from '../authTemplate/AuthTemplate';
import authCode from '../../../assets/images/auth-code.svg';
import OtpInput from '../../../components/otpInput/OtpInput';
import BackToLogin from '../authTemplate/BackToLogin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getQRCodeAction,
  verifyOptAction,
} from '../../../store/actions/SetPasswordAction';
import { useHistory, useParams } from 'react-router';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import Loader from '../../../components/loader/Loader';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { errorNotification } from '../../../components/notifyHelper';

export const ResetAuthentication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useQueryParams();
  const { userId, route } = useParams<Record<string, string>>();
  const [finalOtp, setFinalOtp] = useState('');

  const { userDetail, authDetail } = useSelector(
    ({ setPasswordReducer }: Record<string, any>) => setPasswordReducer ?? {}
  );
  const otpLength = 6;

  const onSubmitOtp = async (otpData: any) => {
    if (otpData && otpData.length === otpLength) {
      setFinalOtp(otpData.join(''));
    }
  };

  const onProceedClick = () => {
    if (finalOtp.split('_').join('').length !== 6) {
      errorNotification('OTP must be six digit number');
      return;
    }
    const params: Record<string, number | string> = userId
      ? {
          userId,
          OTP: finalOtp,
          route: route || 'sign-up',
        }
      : {
          userId: authDetail.userId,
          token: authDetail.token,
          OTP: finalOtp,
          route: 'reset-2FA',
        };
    dispatch(verifyOptAction(params, history));
  };

  useEffect(() => {
    if (userId) {
      const params = { route: route || 'sign-up', userId };
      dispatch(
        getQRCodeAction(params, () => {
          history.push(ROUTE_CONSTANTS_VARIABLE.LINK_EXPIRED);
        })
      );
    } else {
      const params = { route: 'reset-2FA', token };
      dispatch(
        getQRCodeAction(params, () => {
          history.push(ROUTE_CONSTANTS_VARIABLE.LINK_EXPIRED);
        })
      );
    }
  }, []);

  return (
    <div className="reset-auth-screen">
      <AuthTemplate
        title={'Scan QR With Authentication App'}
        buttonTitle="Proceed"
        buttonEvent={onProceedClick}
        titleClass="code-auth-title"
        imageContext={authCode}
      >
        <>
          <div className="auth-form">
            <div className="img-container authentication-code-image-container">
              <img src={authDetail.qrCodeData} alt="QR Code"></img>
            </div>
            <OtpInput
              otpInputs={otpLength}
              onOtpFill={onSubmitOtp}
              otpBoxWithValueClass="border-primary"
              onFocusOtpBoxClass="border-secondary"
              onEnter={onProceedClick}
            />
          </div>
          <BackToLogin />
        </>
      </AuthTemplate>
    </div>
  );
};
