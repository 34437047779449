import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import Button from '../../../button/Button';
import { useSelector } from 'react-redux';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import { useHistory } from 'react-router';

interface HeaderSubscribeProps {
  className?: string;
  setIsProfileDrawerOpened: (val: boolean) => void;
}

const HeaderSubscribePart: FC<HeaderSubscribeProps> = (props) => {
  const { className, setIsProfileDrawerOpened } = props;
  const history = useHistory();
  const [daysLeft, setDaysLeft] = useState<number>();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { userPlanDetails, accessModuleList } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );
  const hasAccessOfAccountSetting = accessModuleList?.find((object: any) => {
    if (object.name === 'account-settings') {
      if (object.subModules.includes('billing-overview')) return true;
    }
  });

  const daysLeftCounter = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const endDate = userPlanDetails?.endDate;
    const trialEndDate = dayjs.tz(endDate, profileDetail?.timeZoneString);
    const AUSCurrentDate = dayjs.tz(new Date(), profileDetail?.timeZoneString);
    const dayLeft = trialEndDate.diff(AUSCurrentDate, 'day');
    const millisecondLeftForChange = trialEndDate.diff(
      AUSCurrentDate.add(dayLeft, 'day'),
      'millisecond'
    );

    setTimeout(daysLeftCounter, millisecondLeftForChange + 100);

    setDaysLeft(dayLeft);
  };

  useEffect(() => {
    const endDate = userPlanDetails?.endDate;
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const trialEndDate = dayjs.tz(endDate, profileDetail?.timeZoneString);
    const AUSCurrentDate = dayjs.tz(new Date(), profileDetail?.timeZoneString);
    const dayLeft = trialEndDate?.diff(AUSCurrentDate, 'day');
    setDaysLeft(dayLeft);
  }, [userPlanDetails]);

  return (
    <div>
      {userPlanDetails?.isTrial && (
        <div className={`header-left-part ${className}`}>
          {daysLeft !== undefined && daysLeft >= 0 && (
            <div>{daysLeft} days left on your free trial</div>
          )}
          {hasAccessOfAccountSetting && (
            <Button
              className="regular-button subscribe-button"
              variant="contained"
              onClick={() => {
                history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN);
                setIsProfileDrawerOpened(false);
              }}
            >
              Subscribe Now
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderSubscribePart;
