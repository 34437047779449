export const gstIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11 2L18.298 4.28C18.5015 4.34354 18.6794 4.47048 18.8057 4.64229C18.932 4.81409 19.0001 5.02177 19 5.235V7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V10H9V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7H17V5.97L11 4.094L5 5.97V13.374C4.99986 13.9862 5.14025 14.5903 5.41036 15.1397C5.68048 15.6892 6.07311 16.1692 6.558 16.543L6.747 16.679L11 19.58L14.782 17H10C9.73478 17 9.48043 16.8946 9.29289 16.7071C9.10536 16.5196 9 16.2652 9 16V12H22V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17L17.78 17.001C17.393 17.511 16.923 17.961 16.38 18.331L11 22L5.62 18.332C4.81252 17.7815 4.15175 17.042 3.69514 16.1779C3.23853 15.3138 2.9999 14.3513 3 13.374V5.235C3.00012 5.02194 3.06829 4.81449 3.19456 4.64289C3.32084 4.47128 3.49862 4.34449 3.702 4.281L11 2Z"
      fill="#666666"
    />
  </svg>
);
