import { OptionsType } from './interfaces';

export const australiaStates: OptionsType<string, string>[] = [
  { _id: 'NSW', name: 'New South Wales' },
  { _id: 'VIC', name: 'Victoria' },
  { _id: 'QLD', name: 'Queensland' },
  { _id: 'SA', name: 'South Australia' },
  { _id: 'WA', name: 'Western Australia' },
  { _id: 'TAS', name: 'Tasmania' },
  { _id: 'NT', name: 'Northern Territory' },
  { _id: 'ACT', name: 'Australian Capital Territory' },
];
