import Visa from '../assets/images/card-type-icons/Visa.svg';
import MasterCard from '../assets/images/card-type-icons/MasterCard.svg';
import AmericanExpress from '../assets/images/card-type-icons/AmericanExpress.svg';
import Discover from '../assets/images/card-type-icons/Discover.svg';
import UnionPay from '../assets/images/card-type-icons/UnionPay.svg';
import JCB from '../assets/images/card-type-icons/JCB.svg';

export const cardTypeIconHelper = (type: string) => {
  switch (type) {
    case 'Visa':
      return <img src={Visa} />;

    case 'MasterCard':
      return <img src={MasterCard} />;

    case 'American Express':
      return <img src={AmericanExpress} />;

    case 'Discover':
      return <img src={Discover} />;

    case 'UnionPay':
      return <img src={UnionPay} />;

    case 'JCB':
      return <img src={JCB} />;

    default:
      return;
  }
};
