import { GST_REDUX_CONSTANTS } from '../../reduxConstants/admin/gstReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface gstProps {
  gstList: Record<string, any>;
  gstDetail: Record<string, any>;
  defaultGst: Record<string, any>;
}

const initialGstReducer: gstProps = {
  gstList: {},
  gstDetail: {
    country: null,
    gst: '',
    errors: {},
  },
  defaultGst: {
    gst: '',
    error: {},
  },
};

export const gstReducer = (
  state = initialGstReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case GST_REDUX_CONSTANTS.GST_LIST:
      return {
        ...state,
        gstList: action?.data,
      };

    case GST_REDUX_CONSTANTS.ADD_GST:
    case GST_REDUX_CONSTANTS.GET_SELECTED_GST_DETAIL:
      return {
        ...state,
        gstDetail: action.data,
      };

    case GST_REDUX_CONSTANTS.ON_CHANGE_GST_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        gstDetail: {
          ...state.gstDetail,
          [name]: value,
        },
      };

    case GST_REDUX_CONSTANTS.ADD_DEFAULT_GST:
      return {
        ...state,
        defaultGst: action.data,
      };

    case GST_REDUX_CONSTANTS.ON_CHANGE_DEFAULT_GST:
      return {
        ...state,
        defaultGst: {
          ...state.defaultGst,
          [action.data.name]: action.data.value,
        },
      };

    case GST_REDUX_CONSTANTS.GET_DEFAULT_GST:
      return {
        ...state,
        defaultGst: action?.data,
      };

    case GST_REDUX_CONSTANTS.RESET_DATA:
      return {
        ...state,
        gstDetail: { ...initialGstReducer.gstDetail, errors: {} },
      };
    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialGstReducer,
      };

    default:
      return { ...state };
  }
};
