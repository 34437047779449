import { MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { useState } from 'react';
import Button from '../button/Button';
import ButtonLoader from '../buttonLoader/ButtonLoader';

interface CustomMenuProps {
  id: string;
  className?: string;
  menuTitle?: string;
  menuIcon?: string;
  menuItems: Record<string, any>[];
  disabled?: Boolean;
  restProps?: MenuProps;
  loader?: any;
}

const CustomMenu = (props: CustomMenuProps) => {
  const {
    id,
    className,
    menuTitle,
    menuItems,
    menuIcon,
    disabled,
    loader,
    ...restProps
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClickMenuItem = (onMenuClick: () => void) => {
    setAnchorEl(null);
    onMenuClick();
  };

  return (
    <div className={className}>
      <Button
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={<ArrowDropDown />}
        color="primary"
        variant="outlined"
        disabled={disabled}
      >
        {loader ? (
          <ButtonLoader />
        ) : (
          <>
            <span className="menu-title">{menuTitle}</span>
            {menuIcon && (
              <span className="material-icons-round">{menuIcon}</span>
            )}
          </>
        )}
      </Button>
      <Menu
        id={`${id}-menu`}
        className="menu-container"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        {...restProps}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={id}
            className="action-item-container"
            onClick={() => onClickMenuItem(menuItem.onClick)}
          >
            {menuItem?.icon} {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
