export const BILLING_OVERVIEW_REDUX_CONSTANTS = {
  GET_BILLING_OVERVIEW_DETAIL: 'GET_BILLING_OVERVIEW_DETAIL',
  ON_CHANGE_ACCOUNT_INFO: 'ON_CHANGE_ACCOUNT_INFO',
  UPDATE_PAYMENT_INFO: 'UPDATE_PAYMENT_INFO',
  GET_PLAN_LIST: 'GET_PLAN_LIST',
  GET_CARD_LIST_UPGRADE_PALN: 'GET_CARD_LIST_UPGRADE_PLAN',
  GET_PAYMENT_DETAIL_UPGRADE_PLAN: 'GET_PAYMENT_DETAIL_UPGRADE_PLAN',
  PLAN_UPGRADE_DETAILS: 'PLAN_UPGRADE_DETAILS',
  RESET_PAYMENT_DETAILS: 'RESET_PAYMENT_DETAILS',
};
