import { ACCOUNT_SETTINGS_URLS } from '../../../constants/urlConstants';
import {
  accountInfoType,
  cardType,
} from '../../../store/reducers/accountSettingsReducer/billingOverviewReducer';
import ApiService from '../../apiService/apiService';

const billingOverviewApiServises = {
  billingOverviewDetails: () =>
    ApiService.getData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.GET_BILLING_OVERVIEW_DETAILS
    ),
  updateAccountInfo: (data: accountInfoType) =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.UPDATE_ACCOUNT_INFO,
      data
    ),
  cancelcurrentPlan: () =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.CANCEL_CURRENT_PLAN
    ),
  deleteCard: (cardId: string) =>
    ApiService.deleteData(
      `${ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.DELETE_CARD}${cardId}`
    ),
  changeDefalutCard: (cardId: string) =>
    ApiService.putData(
      `${ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.CHANGE_DEFAULT_CARD}${cardId}`
    ),
  getPlanListInBilling: (params?: Record<string, string>) =>
    ApiService.getData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.GET_PLAN_LIST,
      { params }
    ),
  getCardListInBilling: () =>
    ApiService.getData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.GET_CARD_LIST
    ),
  addCardInBilling: (data: Record<string, string>) =>
    ApiService.postData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.ADD_CARD_DETAIL,
      { ...data }
    ),
  getPaymentDetailInBilling: (params: Record<string, any>) =>
    ApiService.getData(
      `${ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS.GET_PAYMENT_DETAIL_UPGRADE_PLAN}/plan-details`,
      { params }
    ),
  getPaymentStatusInBilling: (data: Record<string, any>) =>
    ApiService.postData(
      ACCOUNT_SETTINGS_URLS.BILLING_OVERVIEW_URLS
        .GET_PAYMENT_STATUS_UPGRADE_PLAN,
      data
    ),
};

export default billingOverviewApiServises;
