export const planIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.842 9.64935L10.342 2.14935C10.042 1.84935 9.62533 1.66602 9.16699 1.66602H3.33366C2.41699 1.66602 1.66699 2.41602 1.66699 3.33268V9.16602C1.66699 9.62435 1.85033 10.041 2.15866 10.3493L9.65866 17.8493C9.95866 18.1493 10.3753 18.3327 10.8337 18.3327C11.292 18.3327 11.7087 18.1493 12.0087 17.841L17.842 12.0077C18.1503 11.7077 18.3337 11.291 18.3337 10.8327C18.3337 10.3743 18.142 9.94935 17.842 9.64935ZM10.8337 16.6743L3.33366 9.16602V3.33268H9.16699V3.32435L16.667 10.8243L10.8337 16.6743V16.6743Z"
      fill="#666666"
    />
    <path
      d="M5.41699 6.66602C6.10735 6.66602 6.66699 6.10637 6.66699 5.41602C6.66699 4.72566 6.10735 4.16602 5.41699 4.16602C4.72664 4.16602 4.16699 4.72566 4.16699 5.41602C4.16699 6.10637 4.72664 6.66602 5.41699 6.66602Z"
      fill="#666666"
    />
  </svg>
);
