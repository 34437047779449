import { AvTimerOutlined } from '@mui/icons-material';
import { STATEMENTS_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/statementsReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

export type statementDetailType = {
  currentAmount: number | null;
  documentId: number | null;
  fileURL: string;
  invoices:
    | []
    | [
        {
          debitAmount: number;
          invoiceDate: string;
          invoiceNumber: string;
          outstandingAmount: number;
          paidAmount: number;
          _id: string;
        }
      ];

  statementBalance: number | null;
  statementDate: string;
  status: string;
  supplierId: {
    _id: string;
    supplierName: String;
    supplierEmail: string;
  } | null;
  _id: string;
};
interface invoiceProps {
  statementList: Record<string, any>;
  statementDetails: statementDetailType;
  supplierList: Record<string, any> | null;
  uploadedStatementResponse: Record<string, any>;
}

const initialStatementReducer: invoiceProps = {
  statementList: {},
  statementDetails: {
    currentAmount: null,
    documentId: null,
    fileURL: '',
    invoices: [],
    statementBalance: null,
    statementDate: '',
    status: '',
    supplierId: null,
    _id: '',
  },
  supplierList: null,
  uploadedStatementResponse: {},
};

export const statementReducer = (
  state = initialStatementReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action.type) {
    case STATEMENTS_REDUX_CONSTANTS.STATEMENT_LIST:
      return { ...state, statementList: action.data };

    case STATEMENTS_REDUX_CONSTANTS.GET_STATEMENT_DETAILS_BY_ID:
      return { ...state, statementDetails: action.data };

    case STATEMENTS_REDUX_CONSTANTS.GET_SUPPLIER_LIST:
      return { ...state, supplierList: action.data };

    case STATEMENTS_REDUX_CONSTANTS.GET_UPLOADED_STATEMENT_RESPONSE:
      return { ...state, uploadedStatementResponse: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialStatementReducer,
      };

    default:
      return { ...state };
  }
};
