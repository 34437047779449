import { REPORT_URL } from '../../../constants/urlConstants';
import { REPORTS_REDUX_CONSTANT } from '../../../store/reduxConstants/reportsReduxConstant';
import ApiService from '../../apiService/apiService';

export const reportsApiServices = {
  getSupplierListInReports: () =>
    ApiService.getData(REPORT_URL.REPORTS_URL.GET_SUPPLIER_LIST_DROPDOWN),
  getCategoryListInReports: () =>
    ApiService.getData(REPORT_URL.REPORTS_URL.CATEGORY_DROPDOWN_LIST),
  getSupplierPurchaseChart: (params?: Record<string, any>) =>
    ApiService.getData(REPORT_URL.REPORTS_URL.GET_SUPPLIER_PURCHASE_CHART, {
      params,
    }),
  getPurchaseFoodAndBeveragesDetail: (params: Record<string, any>) =>
    ApiService.getData(
      REPORT_URL.REPORTS_URL.GET_PURCHASE_FOOD_AND_BEVERAGES_DETAIL,
      { params }
    ),
  downloadPurchasesExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_PURCHASE_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadPurchasesPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_PURCHASE_TO_PDF,
      responseType: 'blob',
      params: data,
    }),
  getSalesFoodAndBeveragesDetails: (params: Record<string, any>) =>
    ApiService.getData(
      REPORT_URL.REPORTS_URL.GET_SALE_FOOD_AND_BEVERAGES_DETAIL,
      { params }
    ),
  getSupplierSalesChart: (params?: Record<string, any> | string) =>
    ApiService.getData(REPORT_URL.REPORTS_URL.GET_SUPPLIER_SALE_CHART, {
      params,
    }),
  downloadSalesExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_SALE_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadSalesPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_SALE_TO_PDF,
      responseType: 'blob',
      params: data,
    }),
  getSupplierCostSalesChart: (params?: Record<string, any> | string) =>
    ApiService.getData(REPORT_URL.REPORTS_URL.GET_SUPPLIER_COST_SALE_CHART, {
      params,
    }),
  getCostSaleDetails: (params: Record<string, any>) =>
    ApiService.getData(REPORT_URL.REPORTS_URL.GET_COST_SALE_DETAILS, {
      params,
    }),
  downloadCostSalesExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_COST_SALE_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadCostSalesPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: REPORT_URL.REPORTS_URL.DOWNLOAD_COST_SALE_PDF,
      responseType: 'blob',
      params: data,
    }),
};
