import { SUBSCRIBERS_REDUX_CONSTANTS } from '../../reduxConstants/admin/subscribersReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface subscribersProps {
  subscribersList: Record<string, any>;
  subscribers: Record<string, string>[] | null;
  plans: Record<string, string>[] | null;
}

const initialSubscribersReducer: subscribersProps = {
  subscribersList: {},
  subscribers: null,
  plans: null,
};

export const subscribersReducer = (
  state = initialSubscribersReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case SUBSCRIBERS_REDUX_CONSTANTS.SUBSCRIBERS_LIST:
      return {
        ...state,
        subscribersList: action?.data,
      };

    case SUBSCRIBERS_REDUX_CONSTANTS.GET_SELECTED_SUBSCRIBER_DETAILS:
      return {
        ...state,
        planList: action?.data,
      };

    case SUBSCRIBERS_REDUX_CONSTANTS.PLAN_SUBSCRIBERS_DROPDOWN:
      const { subscribers, plans } = action.data;
      return { ...state, subscribers, plans };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialSubscribersReducer,
      };

    default:
      return state;
  }
};
