export const supplierDetailsFirstBlock = [
  {
    name: 'abn',
    label: 'ABN/Company Number',
  },
  {
    name: 'companyName',
    label: 'Company Name',
  },
  {
    name: 'ordersEmail',
    label: 'Orders Email',
  },
  {
    name: 'name',
    label: 'Rep Name',
  },
  {
    name: 'tradingName',
    label: 'Trading Name',
  },
];

export const supplierDetailsSecondBlock = [
  {
    name: 'categoryIds',
    label: 'Category/SubCategory',
  },
  {
    name: 'repNumber',
    label: 'Rep Number',
  },
  {
    name: 'contactEmail',
    label: 'Accounts Contact Email',
  },
  {
    name: 'contactNumber',
    label: 'Accounts Contact Number',
  },
];

export const supplierAddressDetailsBlock = [
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'postalCode',
    label: 'Zip Code / Postal Code',
  },
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'address',
    label: 'Street',
  },
];

export const supplierPaymenteDetailsBlock = [
  {
    name: 'paymentTerms',
    label: 'Payment Terms',
  },
  {
    name: 'paymentMethod',
    label: 'Payment Method',
  },
  {
    name: 'otherPaymentTerm',
    label: 'Other Payment Term',
  },
];

export const supplierInvoiceHeaders = [
  {
    name: 'invoiceNumber',
    label: 'Invoice #',
    type: 'string',
  },
  {
    name: 'invoiceDate',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'invoiceAmount',
    label: 'Amount',
    type: 'amount',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'tag',
  },
];

export const supplierInvoicMobileeHeaders = ['invoiceNumber', 'invoiceDate'];
