import { FC } from 'react';
import { TextField, FilledTextFieldProps } from '@mui/material';
import { INPUT_TEST_CONSTANT } from './InputConst';

const Input: FC<FilledTextFieldProps> = ({
  className,
  size = 'small',
  type = 'text',
  variant = 'filled',
  value,
  id,
  ...restProps
}) => {
  return (
    <TextField
      inputProps={{
        'data-testid': INPUT_TEST_CONSTANT.customInput,
        autocomplete: 'new-password',
        form: {
          autocomplete: 'off',
        },
      }}
      variant="filled"
      hiddenLabel
      id={id}
      autoComplete="off"
      type={type}
      size={size}
      value={value}
      className={`input ${className}`}
      {...restProps}
    />
  );
};

export default Input;
