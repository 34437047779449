import { LIST_FILTERS_REDUX_CONSTANTS } from './../reduxConstants/listFiltersReduxConstants';

export const saveAppliedFilters = (
  filterFor: string,
  filters: Record<string, any> | undefined
) => {
  return (dispatch: any) => {
    dispatch({
      type: LIST_FILTERS_REDUX_CONSTANTS.SAVE_APPLIED_FILTERS,
      filterFor,
      filters,
    });
  };
};

export const resetfilterOnModuleChange = (module: string) => {
  return (dispatch: any) => {
    dispatch({
      type: LIST_FILTERS_REDUX_CONSTANTS.RESET_ON_MODULE_CHANGE,
      data: module,
    });
  };
};
