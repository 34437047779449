import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getQRCodeAction,
  verifyOptAction,
} from '../../store/actions/SetPasswordAction';
import Button from '../button/Button';
import OtpInput from '../otpInput/OtpInput';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import { useQueryParams } from '../../hooks/getQueryParamHook';
import { SET_PASSWORD_REDUX_CONSTANTS } from '../../store/reduxConstants/setPasswordReduxConstant';
import { errorNotification } from '../notifyHelper';

const AuthenticationModal = (props: Record<string, string>) => {
  const { route } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [finalOtp, setFinalOtp] = useState('');
  const { token } = useQueryParams();

  const { userDetail, authDetail } = useSelector(
    ({ setPasswordReducer }: Record<string, any>) => setPasswordReducer ?? {}
  );

  const otpLength = 6;

  const onSubmitOtp = async (otpData: any) => {
    if (otpData && otpData.length === otpLength) {
      setFinalOtp(otpData.join(''));
    }
  };

  const onProceedClick = () => {
    if (finalOtp.split('_').join('').length !== 6) {
      errorNotification('OTP must be six digit number');
      return;
    }
    const params: Record<string, number | string> =
      route === 'sign-up'
        ? {
            userId: userDetail?.userId,
            OTP: finalOtp,
            route,
          }
        : {
            token: userDetail?.token,
            userId: userDetail?.userId,
            OTP: finalOtp,
            route,
          };
    dispatch(verifyOptAction(params, history));
  };

  useEffect(() => {
    const params: Record<string, number | string> =
      route === 'sign-up'
        ? {
            userId: userDetail?.userId,
            route,
          }
        : {
            userId: userDetail?.userId,
            route,
          };
    dispatch(getQRCodeAction(params, () => {}));
    return () => {
      dispatch({ type: SET_PASSWORD_REDUX_CONSTANTS.RESET_USER_DETAIL });
    };
  }, []);
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="auth-modal-body">
          <div className="title">Scan QR with the authenticator app</div>
          <div className="img-container">
            <img src={authDetail.qrCodeData} alt="QR Code"></img>
          </div>
          <OtpInput
            otpInputs={otpLength}
            onOtpFill={onSubmitOtp}
            otpBoxWithValueClass="border-primary"
            onFocusOtpBoxClass="border-secondary"
            onEnter={onProceedClick}
          />
        </div>
        <div className="auth-modal-footer">
          <Button
            variant="contained"
            color="primary"
            className="auth-modal-button"
            onClick={onProceedClick}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationModal;
