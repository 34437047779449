import companyLogo from '../../../../assets/images/company-logo.svg';
import feature from '../../../../assets/images/feature.png';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaymentDetailsAction,
  proceedPaymentAction,
} from '../../../../store/actions/paymnetAction';
import Loader from '../../../../components/loader/Loader';
import { cardTypeIconHelper } from '../../../../helpers/cardTypeIconHelper';
import { calculation } from '../../../../helpers/calculationHelper';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import { getCurrencyRateAction } from '../../../../store/actions/commonAction';
import { currencyConversion } from '../../../../helpers/currencyConversionHelper';
import { generalLoaderReducer } from '../../../../store/reducers/generalLoaderReducer';
import { startGeneralLoaderOnRequest } from '../../../../store/actions/generalLoaderAction';

const PaymentDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<Record<string, string>>();

  const { paymentDetails } = useSelector(
    ({ paymentReducer }: Record<string, any>) => paymentReducer ?? {}
  );

  const { getPaymentDetailsLoaderAction, payNowLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { currencyRate } = useSelector(
    ({ commonReducer }: any) => commonReducer ?? {}
  );

  const { AUD } = useMemo(() => currencyRate ?? {}, [currencyRate]);

  const totalCalculation = () => {
    const price = parseFloat(
      currencyConversion(paymentDetails?.planDetails?.price, AUD)
    );
    const gstAmount = parseFloat(
      currencyConversion(paymentDetails?.planDetails?.gstAmount, AUD)
    );
    const sum = price + gstAmount;
    return sum.toFixed(2);
  };

  const onPayNow = () => {
    if (!payNowLoaderAction) {
      const params = { userId };
      dispatch(proceedPaymentAction(params, history));
    }
  };

  useEffect(() => {
    dispatch(getCurrencyRateAction());
    const params = { userId };
    dispatch(getPaymentDetailsAction(params));
  }, [userId]);

  return (
    <div className="plans-container payment-container">
      <div className="auth-company-logo plan-header-container">
        <img src={companyLogo} alt="Foodygent" />
        <div className="plan-header">
          <div>
            <BackButton
              className="plan-back-button"
              onClick={() => history.push(`/card-details/${userId}`)}
            />
            Payment Details
          </div>
        </div>
      </div>

      {getPaymentDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div className="payment-details">
          <div className="payment-plan">
            <div className="payment-plan-title">
              {paymentDetails?.planDetails?.name}
            </div>
            <div className="payment-plan-price">{`$${totalCalculation()}/ ${
              paymentDetails?.planDetails?.planType
            }`}</div>
          </div>

          <div className="plan-feature">
            <div>
              <div className="payment-features">
                {paymentDetails?.planDetails?.facilities?.map(
                  (name: string) => (
                    <div className="payment-features-list">
                      <img src={feature} alt="feature-icon" />
                      <div className="features-list-name">{name}</div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="payment-card">
              <div>
                {cardTypeIconHelper(paymentDetails?.cardDetails?.cardType)}
              </div>
              <div>{paymentDetails?.cardDetails?.cardName}</div>
              <div>{`**** **** **** ${paymentDetails?.cardDetails?.cardNumber}`}</div>
              <div>{`Validity : ${paymentDetails?.cardDetails?.expiryDate.month}/${paymentDetails?.cardDetails?.expiryDate.year}`}</div>
            </div>
          </div>

          <div className="invoices-total-container payment-total-container">
            <div className="total-container">
              <div className="sub-total-container">
                <div>
                  <span>Plan Amount</span>
                  <span className="invoice-total-value">{`$${
                    AUD &&
                    currencyConversion(paymentDetails?.planDetails?.price, AUD)
                  }`}</span>
                </div>
                <div>
                  <span>{`GST`}</span>
                  <span className="invoice-total-value">{`$${
                    AUD &&
                    currencyConversion(
                      paymentDetails?.planDetails?.gstAmount,
                      AUD
                    )
                  }`}</span>
                </div>
              </div>
              <div>
                <span>Total</span>
                <span className="invoice-total-value">{`$${totalCalculation()}/ ${
                  paymentDetails?.planDetails?.planType
                }`}</span>
              </div>
            </div>
          </div>

          <div className="pay-now-button-container">
            <Button
              className="auth-button plan-confirm-button"
              variant="contained"
              color="primary"
              loader={payNowLoaderAction}
              onClick={onPayNow}
            >
              Pay now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default PaymentDetails;
