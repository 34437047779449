import { FormControl, FormHelperText } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getModuleListAction,
  onChangeModuleAction,
  onChangeRoleAction,
  onChangeUserData,
  resetUserDetails,
} from '../../../../../src/store/actions/accountSettingsActions/accountSettingsUsersActions';
import rightIcon from '../../../../assets/images/right-icon.svg';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import Loader from '../../../../components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  editUserData,
  getUserDataById,
} from '../../../../store/actions/accountSettingsActions/accountSettingsUsersActions';
import { getUserRoles } from '../../../../store/actions/commonAction';
import { inviteUserValidations } from './inviteUserValidations';
import ActiveSwitch from '../../../../components/activeSwitch/ActiveSwitch';
import { Switch } from '@mui/material';
import { moduleAccess } from '../../../../helpers/moduleAccessHelper';
import { focusHelper } from '../../../../helpers/focusHelper';
import _ from 'lodash';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const InviteUser = () => {
  const [sendInvitationModal, setSendInvitationModal] = useState(false);
  const { action, userId } = useParams<Record<string, string>>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { userDetail, list } = useSelector(
    ({ accountSettingsUsersReducer }: Record<string, any>) =>
      accountSettingsUsersReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const dispatch = useDispatch();

  const { userRoles } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const userData = useSelector(
    ({ profileReducer }: Record<string, any>) =>
      profileReducer?.profileDetail ?? {}
  );

  const { getUserDataByIdLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const isUserDataLoaded = () => {
    if (getUserDataByIdLoaderAction && action === 'edit') {
      return false;
    }
    return true;
  };
  const { errors } = userDetail || {};
  const history = useHistory();

  const sendInviteButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.USER);
      },
      color: 'primary',
    },
  ];

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeUserData(name, value));
  };

  const onClickAddUser = async (action: string) => {
    setIsSubmitted(true);
    inviteUserValidations(
      dispatch,
      { ...userDetail },
      errors,
      true,
      'onSubmit',
      undefined,
      setSendInvitationModal,
      action,
      history,
      profileDetail
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };
  const onInputBlur = (name: string) => {
    inviteUserValidations(
      dispatch,
      userDetail,
      errors,
      isSubmitted,
      'onBlur',
      name,
      setSendInvitationModal,
      action,
      history
    );
  };

  const onSelectChange = async (name: string, _e: any, value: any) => {
    dispatch(onChangeUserData(name, value));
    if (name === 'role') {
      await dispatch(getModuleListAction());
      const modules = moduleAccess(value);
      dispatch(onChangeRoleAction(modules));
    }

    if (isSubmitted) {
      inviteUserValidations(
        dispatch,
        { ...userDetail, role: value },
        errors,
        isSubmitted,
        'onBlur',
        'role',
        setSendInvitationModal,
        action,
        history
      );
    }
  };

  const onChangeModule = (index: number, submoduleIndex?: number) => {
    dispatch(onChangeModuleAction(index, submoduleIndex));
  };

  useEffect((): any => {
    const getdata = async () => {
      await dispatch(getModuleListAction());
      if (action !== 'add') {
        dispatch(getUserDataById(userId));
      }
    };
    getdata();
    dispatch(getUserRoles());
    return () => dispatch(resetUserDetails());
  }, []);

  return (
    <div className="form">
      {sendInvitationModal && (
        <CustomModal
          header="User Invitation Confirmation"
          buttons={sendInviteButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setSendInvitationModal(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.USER);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Invitation is successfully sent to the User</span>
          </>
        </CustomModal>
      )}
      <div className="form-title-switch-container">
        <div className="form-title-row">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.USER)}
          />
          <span className="form-title">
            {action === 'add' ? 'Add User' : 'Edit User'}
          </span>
        </div>

        <ActiveSwitch
          isActive={userDetail?.isActive}
          onChange={(value: boolean) =>
            dispatch(onChangeUserData('isActive', value))
          }
        />
      </div>

      {isUserDataLoaded() ? (
        <div className="form-container">
          <div className="invite-user-form">
            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">First Name</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter First Name"
                  className={`input-field ${
                    userDetail?.errors?.firstName ? 'input-with-error' : ''
                  }`}
                  name="firstName"
                  value={userDetail?.firstName}
                  type="text"
                  id="firstName"
                  helperText={userDetail?.errors?.firstName}
                  onChange={onChangeInput}
                  onBlur={() => onInputBlur('firstName')}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddUser(action))
                  }
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Last Name</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Last Name"
                  className={`input-field ${
                    userDetail?.errors?.lastName ? 'input-with-error' : ''
                  }`}
                  name="lastName"
                  id="lastName"
                  value={userDetail?.lastName}
                  type="text"
                  helperText={userDetail?.errors?.lastName}
                  onChange={onChangeInput}
                  onBlur={() => onInputBlur('lastName')}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddUser(action))
                  }
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Email</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Email"
                  className={`input-field ${
                    userDetail?.errors?.email ? 'input-with-error' : ''
                  }`}
                  disabled={action === 'edit'}
                  name="email"
                  id="email"
                  value={userDetail?.email}
                  type="text"
                  helperText={userDetail?.errors?.email}
                  onChange={onChangeInput}
                  onBlur={() => onInputBlur('email')}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddUser(action))
                  }
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Contact Number</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Contact Number"
                  className={`input-field ${
                    userDetail?.errors?.contactNumber ? 'input-with-error' : ''
                  }`}
                  name="contactNumber"
                  id="contactNumber"
                  value={userDetail?.contactNumber}
                  type="text"
                  helperText={userDetail?.errors?.contactNumber}
                  onChange={onChangeInput}
                  onBlur={() => onInputBlur('contactNumber')}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddUser(action))
                  }
                />
              </FormControl>
            </div>
            <div className="form-field-name">
              <div className="form-field-name">User Role</div>
              <div className="required-sign">*</div>
            </div>
            <FormControl
              className={`input-field ${
                userDetail?.errors?.role ? 'input-with-error' : ''
              }`}
            >
              <Autocomplete
                key={Math.random()}
                placeholder="Select User Role"
                onChange={(event, value) =>
                  onSelectChange('role', event, value)
                }
                options={userRoles?.allRoles}
                value={userDetail?.role}
                className="autocomplete user-role-select"
                id="role"
                onKeyUp={(event) =>
                  enterKeyHelper(event, () => onClickAddUser(action))
                }
                renderInput={(params) => (
                  <Input
                    variant="filled"
                    {...params}
                    placeholder="Select User Role"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <FormHelperText className="input-with-error">
                {userDetail?.errors?.role}
              </FormHelperText>
            </FormControl>
            <div className="filter-field-gap">
              <div className="form-field-name">Module Access</div>
            </div>
            <div className="module-container bottom-gap">
              {userDetail?.moduleAccess?.map(
                (module: Record<string, any>, index: number) =>
                  !module?.hasSubModule && (
                    <div className="module-card">
                      <div
                        className="module"
                        style={{
                          borderRadius: !module?.hasSubModule ? '8px' : '',
                        }}
                      >
                        <span>{module?.label}</span>
                        <Switch
                          size="small"
                          edge="start"
                          checked={module?.hasAccess}
                          onChange={() => onChangeModule(index)}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="module-container">
              {userDetail?.moduleAccess?.length > 0 &&
                userDetail?.moduleAccess?.map(
                  (module: Record<string, any>, index: number) =>
                    module?.hasSubModule && (
                      <div className="module-card">
                        <div className="module">
                          <span>{module.label}</span>
                          <Switch
                            size="small"
                            edge="start"
                            checked={module?.hasAccess}
                            onChange={() => onChangeModule(index)}
                          />
                        </div>
                        <div
                          className={`${module.hasSubModule && 'submodule'}`}
                        >
                          {module?.subModules?.map(
                            (submodule: Record<string, any>, i: number) => (
                              <div>
                                <span>{submodule.label}</span>
                                <Switch
                                  size="small"
                                  edge="start"
                                  checked={submodule.hasAccess}
                                  onChange={() => onChangeModule(index, i)}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>

          <div className="form-button-row">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.USER)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onClickAddUser(action === 'add' ? 'add' : 'edit')}
            >
              {action === 'add' ? 'Invite User' : 'Save'}
            </Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default InviteUser;
