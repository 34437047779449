import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { COMMON_REDUX_CONSTANTS } from './../reduxConstants/commonReduxConstant';
import { Options } from '../../constants/interfaces';

interface CommonProps {
  userRoles: Options[];
  suppliersDropdown: Options[];
  categories: Options[];
  units: Options[];
  productDropdown: Options[];
  categorySubcategoryDropdown: Options[];
  productDropdownList: string[];
  currencyRate: Record<string, string>;
}

const initialCommonReducer: CommonProps = {
  userRoles: [],
  suppliersDropdown: [],
  categories: [],
  units: [],
  productDropdown: [],
  categorySubcategoryDropdown: [],
  productDropdownList: [],
  currencyRate: {},
};

export const commonReducer = (
  state = initialCommonReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case COMMON_REDUX_CONSTANTS.GET_USER_ROLE:
      return {
        ...state,
        userRoles: action?.data,
      };
    case COMMON_REDUX_CONSTANTS.CATEGORY.GET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.data,
      };
    case COMMON_REDUX_CONSTANTS.SUPPLIER_DROPDOWN_DATA:
      return {
        ...state,
        suppliersDropdown: action?.data,
      };
    case COMMON_REDUX_CONSTANTS.UNIT.GET_UNIT_DROPDOWN_DATA:
      return {
        ...state,
        units: action?.data,
      };

    case COMMON_REDUX_CONSTANTS.UNIT.ON_CHANGE_UNIT:
      return {
        ...state,
        units: action.data?.units,
      };

    case COMMON_REDUX_CONSTANTS.CATEGORY.ON_CHANGE_CATEGORY_LIST:
      return {
        ...state,
        categories: action.data?.categories,
      };

    case COMMON_REDUX_CONSTANTS.PRODUCT_DROPDOWN_DATA:
      return {
        ...state,
        productDropdown: action?.data,
      };

    case COMMON_REDUX_CONSTANTS.CATEGORY.CATEGORY_SUBCATEGORY_DROPDOWN:
      return {
        ...state,
        categorySubcategoryDropdown: action?.data,
      };

    case COMMON_REDUX_CONSTANTS.PRODUCT_DROPDOWN_LIST:
      return {
        ...state,
        productDropdownList: action?.data,
      };

    case COMMON_REDUX_CONSTANTS.GET_CURRENCY_RATE:
      return { ...state, currencyRate: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialCommonReducer,
      };

    default:
      return state;
  }
};
