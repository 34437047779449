import { AxiosRequestConfig } from 'axios';
import { AUTH_URLS, SUPPLIERS_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

type SupplierIdsType = {
  supplierIds: string;
};
const supplierApiServices = {
  supplierList: (params: Record<string, any>) =>
    ApiService.getData(SUPPLIERS_URLS.SUPPLIERS_URLS.GET_SUPPLIERS_LIST, {
      params,
    }),
  deleteSuppliers: (params: SupplierIdsType) =>
    ApiService.deleteData(`${SUPPLIERS_URLS.SUPPLIERS_URLS.DELETE_SUPPLIERS}`, {
      params,
    }),

  importSupplierCsv: (
    data: FormData,
    config: AxiosRequestConfig<any> | undefined
  ) =>
    ApiService.postData(
      SUPPLIERS_URLS.SUPPLIERS_URLS.IMPORT_SUPPLIERS,
      data,
      config
    ),
  getPaymentMethodDropdownData: () =>
    ApiService.getData(SUPPLIERS_URLS.SUPPLIERS_URLS.GET_PAYMENT_METHOD),
  addSupplier: (data: Record<string, any>) =>
    ApiService.postData(SUPPLIERS_URLS.SUPPLIERS_URLS.ADD_SUPPLIER, data),
  getSupplierDataById: (supplierId: string) =>
    ApiService.getData(
      `${SUPPLIERS_URLS.SUPPLIERS_URLS.GET_SUPPLIER_DETAILS_BY_ID}${supplierId}`
    ),
  editSupplierDetails: (supplierId: string, data: Record<string, any>) =>
    ApiService.putData(
      `${SUPPLIERS_URLS.SUPPLIERS_URLS.EDIT_SUPPLIER_URL}${supplierId}`,
      data
    ),
  downloadSupplierExcelSheet: (params?: Record<string, any>) =>
    ApiService.request({
      method: 'GET',
      url: SUPPLIERS_URLS.SUPPLIERS_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: params,
    }),
  uploadTermsAndCondition: (
    data: FormData,
    config: AxiosRequestConfig<any> | undefined
  ) =>
    ApiService.putData(
      SUPPLIERS_URLS.SUPPLIERS_URLS.UPLOAD_TERMS_AND_CONDITIONS,
      data,
      config
    ),
  downloadSupplierListPDF: (params?: Record<string, any>) =>
    ApiService.request({
      method: 'GET',
      url: SUPPLIERS_URLS.SUPPLIERS_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...params,
        userDate: new Date(),
      },
    }),
  downloadSingleSupplierExcelSheet: (supplierId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${SUPPLIERS_URLS.SUPPLIERS_URLS.VIEW_EXPORT}${supplierId}/excel-download`,
      responseType: 'blob',
      params: {
        supplierId,
      },
    }),
  downloadSingleSupplierPdf: (supplierId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${SUPPLIERS_URLS.SUPPLIERS_URLS.VIEW_EXPORT}${supplierId}/pdf-download`,
      responseType: 'blob',
      params: {
        supplierId,
        userDate: new Date(),
      },
    }),
  getDetailbyName: (params: Record<string, any>) =>
    ApiService.getData(AUTH_URLS.GET_DETAIL_BY_NAME, { params }),
  getDetailbyAbn: (params: Record<string, any>) =>
    ApiService.getData(AUTH_URLS.GET_DETAIL_BY_ABN, { params }),
};
export default supplierApiServices;
