export const desktopModificationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.94 21.1701C7.09 21.9401 8.4 22.4901 9.8 22.7801C10.42 22.9001 11 22.4301 11 21.7901C11 21.3301 10.69 20.9101 10.24 20.8201C9.07 20.5901 7.98 20.1201 7.03 19.4801C6.64 19.2201 6.13 19.2901 5.8 19.6201C5.34 20.0701 5.41 20.8201 5.94 21.1701Z"
      fill="white"
    />
    <path
      d="M13 21.7901C13 22.4301 13.58 22.9001 14.2 22.7801C15.6 22.4901 16.91 21.9401 18.06 21.1701C18.58 20.8201 18.65 20.0701 18.21 19.6301C17.88 19.3001 17.37 19.2301 16.98 19.4901C16.02 20.1301 14.94 20.5901 13.77 20.8301C13.31 20.9101 13 21.3301 13 21.7901Z"
      fill="white"
    />
    <path
      d="M19.62 18.2101C20.07 18.6601 20.82 18.5801 21.16 18.0601C21.93 16.9101 22.49 15.6101 22.77 14.2001C22.9 13.5801 22.42 13.0001 21.79 13.0001C21.33 13.0001 20.91 13.3101 20.82 13.7601C20.59 14.9301 20.12 16.0201 19.48 16.9801C19.23 17.3601 19.3 17.8801 19.62 18.2101Z"
      fill="white"
    />
    <path
      d="M2.21001 13.0001C1.58001 13.0001 1.10001 13.5801 1.23001 14.2001C1.52001 15.6001 2.07001 16.9001 2.84001 18.0601C3.19001 18.5801 3.94001 18.6601 4.38001 18.2101C4.71001 17.8801 4.78001 17.3701 4.52001 16.9801C3.88001 16.0201 3.42001 14.9301 3.18001 13.7601C3.09001 13.3101 2.67001 13.0001 2.21001 13.0001Z"
      fill="white"
    />
    <path
      d="M16 11.5405L13.56 10.4305L12.46 8.00047C12.28 7.61047 11.73 7.61047 11.55 8.00047L10.44 10.4405L8.00001 11.5405C7.61001 11.7205 7.61001 12.2705 8.00001 12.4505L10.44 13.5605L11.54 16.0005C11.72 16.3905 12.27 16.3905 12.45 16.0005L13.56 13.5605L16 12.4605C16.39 12.2805 16.39 11.7205 16 11.5405Z"
      fill="white"
    />
    <path
      d="M12 3C15.11 3 17.85 4.59 19.46 7H18C17.45 7 17 7.45 17 8C17 8.55 17.45 9 18 9H22C22.55 9 23 8.55 23 8V4C23 3.45 22.55 3 22 3C21.45 3 21 3.45 21 4V5.7C19.01 2.86 15.73 1 12 1C7.55 1 3.73 3.64 2 7.43C1.74 8 2.08 8.68 2.69 8.82C3.14 8.92 3.62 8.71 3.81 8.28C5.23 5.17 8.36 3 12 3Z"
      fill="white"
    />
  </svg>
);
