import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import Loader from '../../../components/loader/Loader';
import { productInventoryDetailsConstants } from '../../../constants/productInventoryConstants';
import { processTableDataByType } from '../../../helpers/TableDataProcessHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { getProductInventoryDetailsById } from '../../../store/actions/productInventoryActions';

const ViewProductInventory = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { productId } = useParams<Record<string, string>>();

  const { productInventoryDetails } = useSelector(
    ({ productInventoryReducer }: Record<string, any>) =>
      productInventoryReducer ?? {}
  );

  const { productInventoryDetailsLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  useEffect(() => {
    dispatch(getProductInventoryDetailsById(productId));
  }, []);
  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() =>
              history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT_INVENTORY)
            }
          />
          <div className="form-title">Product Inventory Details</div>
        </div>
      </div>
      {productInventoryDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div className="main-details-container view-details-white-container details-grid details-grid-two-columns supplier-details-container">
          {productInventoryDetailsConstants?.map((field) => (
            <div>
              <div className="detail-label">{field.label}</div>
              <div className="detail-value">
                {processTableDataByType({
                  header: field,
                  row: productInventoryDetails,
                })}
              </div>
            </div>
          ))}
          <div>
            <div className="detail-label">Suppliers</div>
            <div className="detail-value">
              <ul className="inventory-sypplier-list">
                {productInventoryDetails?.suppliers?.map(
                  (supplier: Record<string, string>) => (
                    <li>{supplier.name}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProductInventory;
