import { FormControlLabel } from '@mui/material';
import { styled, Switch } from '@mui/material';
import { FC, useState } from 'react';

interface ActiveSwitchProps {
  isActive: boolean;
  onChange: (val: any) => void;
}

const ActiveSwitch: FC<ActiveSwitchProps> = (props) => {
  const { isActive, onChange } = props;

  const onClickSwitch = () => {
    onChange(isActive ? false : true);
  };

  return (
    <div className="switch-container">
      <div
        className={`switch-label ${
          isActive ? 'active-switch-label' : 'inactive-switch-label'
        }`}
      >
        {!isActive ? 'Inactive' : 'Active'}
      </div>
      <div className="toggle-switch-container">
        <div
          className={`toggle-switch ripple ${
            !isActive ? 'inactive-switch' : 'active-switch'
          }`}
          style={{
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="${encodeURIComponent(
              '#fff'
            )}">${
              isActive
                ? '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>'
                : '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>'
            }</svg>')`,
          }}
          onClick={onClickSwitch}
        />
      </div>
    </div>
  );
};

export default ActiveSwitch;
