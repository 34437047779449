export const productDetailConstants = [
  {
    name: 'productCode',
    label: 'Product Code',
    type: 'string',
  },
  {
    name: 'name',
    label: 'Product Name',
    type: 'string',
  },
  {
    name: 'supplierId',
    label: 'Supplier',
    type: 'object',
  },
  {
    name: 'categoryId',
    label: 'Category',
    type: 'object',
  },
  {
    name: 'unitId',
    label: 'UOM',
    type: 'object',
  },
  {
    name: 'weight',
    label: 'Weight (Kg)',
    type: 'string',
  },
  {
    name: 'price',
    label: 'Price',
    type: 'amount',
  },
  {
    name: 'priceFluctuation',
    label: 'Price Changes',
    type: 'priceChange',
  },
];
