import { FC } from 'react';
import { useSelector } from 'react-redux';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

interface LayoutProps {
  children: any;
}

const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  const authStatus: boolean = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer?.authStatus ?? false
  );

  if (!authStatus) {
    return children;
  } else {
    return (
      <div className="panel-layout">
        <Header />

        <div className="layout-bottom-container">
          <Sidebar />
          <div className="layout-page-container">{children}</div>
        </div>
      </div>
    );
  }
};

export default Layout;
