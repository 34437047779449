import { INVOICE_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const expenseApiServices = {
  expenseList: (params: Record<string, string | number>) =>
    ApiService.getData(INVOICE_URLS.EXPENSE_URL.EXPENSE_LIST, { params }),
  updatestatus: (expenseId: string, status: string) =>
    ApiService.putData(
      `${INVOICE_URLS.EXPENSE_URL.UPDATE_STATUS}${expenseId}`,
      { status }
    ),
  deleteExpense: (params: Record<string, string>) =>
    ApiService.deleteData(INVOICE_URLS.EXPENSE_URL.DELETE_EXPENSE, { params }),
  downloadExpenseExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.EXPENSE_URL.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadExpensePdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.EXPENSE_URL.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...data,
        userDate: new Date(),
      },
    }),
  expenseSupplerDropdown: () =>
    ApiService.getData(INVOICE_URLS.EXPENSE_URL.EXPENSE_SUPPLIER_DROPDOWN),
  modifyExpense: (expenseId: string, data: Record<string, string | number>) =>
    ApiService.postData(
      `${INVOICE_URLS.EXPENSE_URL.MODIFY_EXPENSE}${expenseId}/modify`,
      data
    ),
  getModifiedData: (expenseId: string, queryParam: Record<string, string>) =>
    ApiService.putData(
      `${INVOICE_URLS.EXPENSE_URL.GET_MODIFIED_DATA}${expenseId}/modified/document/`,
      null,
      { params: queryParam }
    ),
  expenseManualSync: (expenseId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.EXPENSE_URL.EXPENSE_MANUAL_SYNC}${expenseId}/manual-sync`
    ),
  expenseAutoSync: (data: Record<string, boolean>) =>
    ApiService.putData(INVOICE_URLS.EXPENSE_URL.EXPENSE_AUTO_SYNC, data),
  getModyfiedUploadedExpenseData: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.EXPENSE_URL.GET_MODIFIED_UPLOADED_DATA,
      null,
      { params }
    ),
  modifyUploadedExpense: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.EXPENSE_URL.MODIFY_UPLOADED_EXPENSE,
      null,
      { params }
    ),
};
