import { cloneDeep } from 'lodash';
import {
  ADMIN_PANEL_CONSTANTS,
  SIDEBAR_CONSTANTS,
} from '../constants/sidebarConstants';

const sidebarHelper = (accessModuleList: any, role: string) => {
  const copyAccessModule = cloneDeep(accessModuleList);
  const indexOfReport = copyAccessModule?.findIndex(
    (e: any) => e.name === 'report'
  );
  if (indexOfReport > -1) {
    const hasAccessofReport = copyAccessModule[indexOfReport].subModules.some(
      (x: any) => ['purchase', 'sales', 'cost-sales'].includes(x)
    );
    if (hasAccessofReport) {
      copyAccessModule[indexOfReport].subModules.push('reports');
    }
  }

  let data = [];
  if (role === 'Super Admin') {
    data = ADMIN_PANEL_CONSTANTS;
  } else {
    data = SIDEBAR_CONSTANTS.reduce((acc: any[], module: any) => {
      const foundModule = copyAccessModule?.find(
        (copyAccessModule: any) => module.name === copyAccessModule.name
      );
      if (foundModule) {
        const module2: any = { ...module };
        delete module2.subMenu;
        const subModules = module.subMenu?.filter((x: any) =>
          foundModule.subModules.includes(x.name)
        );
        module2.subMenu = subModules;
        acc.push(module2);
      }
      return acc;
    }, []);
  }
  return data;
};
export default sidebarHelper;
