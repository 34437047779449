import { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router';
import { FLOAT_NUMBER_REGEX } from '../../../../constants/regexConstants';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import {
  addGstAction,
  onChangeGstDataAction,
  updateGstDetailAction,
} from '../../../../store/actions/admin/gstActions';

export const addGstValidation = async (
  dispatch: Dispatch<any>,
  gstDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: RouteComponentProps['history']
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    country: 'Select Country',
    gst: 'enter valid GST',
  };
  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'country':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'gst':
        if (!value) {
          validated = false;
          if (errors) {
            errors[field] = 'Please Enter Gst';
          }
        } else {
          if (
            value?.length &&
            (parseFloat(value) > 100 || parseFloat(value) < 0)
          ) {
            validated = false;
            if (errors) {
              errors[field] = `Please ${errorMessages[field]}`;
            }
          } else {
            delete errors[field];
          }
        }
        break;
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in gstDetail) {
        const value = gstDetail[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, gstDetail[fieldName]);
    }
    dispatch(onChangeGstDataAction('errors', errors));
  }
  if (validated && requestFrom === 'onSubmit') {
    const finalData = gstDetail;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'country':
            finalData[key] = finalData[key].name ?? finalData[key];
            break;

          case 'gst':
            finalData[key] = parseFloat(finalData[key]);
            break;
        }
      }
    }
    delete gstDetail?.errors;
    if (action === 'add') {
      const isAddedSuccessFully: void = await dispatch(addGstAction(finalData));

      if (isAddedSuccessFully !== null) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const updateUrl = () => history.push(ADMIN_ROUTE_CONST.GST);
      dispatch(updateGstDetailAction(finalData, gstDetail._id, updateUrl));
    }
  }
};
