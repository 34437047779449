import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import { errorNotification } from '../../../../components/notifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  getPlansListAction,
  palnUpgradDetails,
} from '../../../../store/actions/accountSettingsActions/billingOverviewActions';
import { getCurrencyRateAction } from '../../../../store/actions/commonAction';
import { currencyConversion } from '../../../../helpers/currencyConversionHelper';

const ChangePlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [planType, setPlanType] = useState<string>('monthly');
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined
  );
  const { planList } = useSelector(
    ({ billingOverviewReducer }: any) => billingOverviewReducer ?? {}
  );

  const { flow } = useSelector(({ authReducer }: any) => authReducer);

  const { currencyRate } = useSelector(
    ({ commonReducer }: any) => commonReducer ?? {}
  );

  const { AUD } = useMemo(() => currencyRate ?? {}, [currencyRate]);

  const { getPlansLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  interface PlanType {
    _id?: string;
    price: string;
    name?: string;
    facilities?: string[];
    tag?: string;
    createdAt?: string;
    updatedAt?: string;
    _v?: number;
    isPopularPlan: boolean;
  }
  interface PlanListType {
    monthly: PlanType[];
    yearly: PlanType[];
  }
  const onPlanSelect = (plan: Record<string, any>) => {
    setSelectedPlan(plan?._id);
  };

  const onPlanConfirmClick = () => {
    if (selectedPlan) {
      dispatch(palnUpgradDetails('planId', selectedPlan));
      history.push(ROUTE_CONSTANTS_VARIABLE.CARD_LIST);
    } else {
      errorNotification('Please choose plan');
    }
  };

  useEffect(() => {
    const params = { planType: planType === 'monthly' ? 'Monthly' : 'Yearly' };
    dispatch(getPlansListAction(params));
  }, [planType]);

  useEffect(() => {
    dispatch(getCurrencyRateAction());
  }, []);

  return (
    <div className="change-plan-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          {flow !== 1 && flow !== 2 && (
            <BackButton
              onClick={() =>
                history.push(ROUTE_CONSTANTS_VARIABLE.BILLING_OVERVIEW)
              }
            ></BackButton>
          )}
          <div className="form-title">Upgrade Subscription Plan</div>
        </div>
        <div className="plan-type-menu">
          <span
            className={planType === 'monthly' ? 'selected-plan-type' : ''}
            onClick={() => setPlanType('monthly')}
          >
            Monthly
          </span>
          <span
            className={planType === 'yearly' ? 'selected-plan-type' : ''}
            onClick={() => setPlanType('yearly')}
          >
            Yearly
          </span>
          <div className="selected-plan" />
        </div>
      </div>
      {getPlansLoaderAction ? (
        <Loader />
      ) : (
        <div
          className={`${
            selectedPlan
              ? 'list-container mobile-list-container'
              : 'list-container'
          } ${planList?.length === 0 && 'message-container'}`}
        >
          {planList?.length > 0 ? (
            <>
              <div className="plans-grid">
                {planList?.map((plan: PlanType) => (
                  <div
                    key={plan._id}
                    className={`plan ${
                      selectedPlan === plan?._id ? 'selected-plan' : ''
                    }`}
                  >
                    {plan?.tag && (
                      <div className="ribbon-corner cr-top cr-right cr-sticky cr-blue">
                        Popular
                      </div>
                    )}
                    <div>
                      <div className="plan-name">
                        <div className="plan-name-break">{plan.name}</div>
                      </div>
                      <div>
                        $
                        {AUD && currencyConversion(parseFloat(plan.price), AUD)}
                        {plan.price.toString().includes('GST') ? ' + GST' : ''}
                        <span>{`/${
                          planType === 'monthly' ? 'Month' : 'Year'
                        }`}</span>
                      </div>
                      <div className="plan-facilities">
                        {plan?.facilities?.length &&
                          plan.facilities.map(
                            (facility: any, index: number) => (
                              <div
                                className="plan-facility"
                                key={`facility-${index}`}
                              >
                                <span className="material-icons">
                                  check_circle
                                </span>
                                {facility}
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <Button
                      className="choose-plan-button"
                      onClick={() => onPlanSelect(plan)}
                    >
                      Choose Plan
                    </Button>
                  </div>
                ))}
              </div>

              <div className="plan-confirm-button-container">
                <Button
                  className="auth-button plan-confirm-button"
                  variant="contained"
                  color="primary"
                  onClick={onPlanConfirmClick}
                >
                  Confirm
                </Button>
              </div>

              {/* Mobile button starts here  */}
              {selectedPlan && (
                <div className="mobile-plan-confirmation-container">
                  <Button
                    className="auth-button plan-confirm-button"
                    variant="contained"
                    color="primary"
                    onClick={onPlanConfirmClick}
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div>No {planType.toLowerCase()} plans available at the moment</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChangePlan;
