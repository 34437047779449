export const recipeTableProductsHeaderConstants: Record<string, any>[] = [
  {
    name: 'name',
    label: 'Product',
    type: 'recipeName',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'editQuantity',
  },
  {
    name: 'unit',
    label: 'UOM',
    type: 'string',
  },
  {
    name: 'weight',
    label: 'Measurement',
    type: 'input',
  },
  {
    name: 'totalPrice',
    label: 'Price',
    type: 'amount',
  },
];

export const recipeTableProductsMobileHeader: string[] = ['name', 'quantity'];

export const recipeDetailsHeader = [
  { label: 'Category', name: 'categoryName', type: 'string' },
  { label: 'Produced By', name: 'producedBy', type: 'string' },
  { label: 'Status', name: 'status', type: 'tag' },
];

export const calculationHeader = [
  { label: 'Recipe Cost', name: 'recipeCost', type: 'amount' },
  { label: 'Portion Cost', name: 'portionCost', type: 'amount' },
  {
    label: 'Portion Cost %',
    name: 'portionCostPercentage',
    type: 'percentage',
  },
  {
    label: 'Profit Gross Margin %',
    name: 'profitGrossMargin',
    type: 'percentage',
  },
  { label: 'Sale Price', name: 'salePrice', type: 'amount' },
];
