import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import rightIcon from '../../../../assets/images/right-icon.svg';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import Loader from '../../../../components/loader/Loader';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  getCategories,
  getUnits,
} from '../../../../store/actions/commonAction';
import {
  getSelectedProductDetails,
  onChangeProductData,
  resetProductData,
  getSupplierListInProduct,
} from '../../../../store/actions/supplierActions/productActions';
import { formInputValidation } from './addProductValidations';
import { LoadingButton } from '@mui/lab';
import { focusHelper } from '../../../../helpers/focusHelper';
import _ from 'lodash';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const AddProduct: FC = () => {
  const history = useHistory();
  const { action, productId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const { categories, units } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );

  const { productDetail, supplierList } = useSelector(
    ({ productReducer }: Record<string, any>) => productReducer ?? {}
  );

  const {
    getSelectedProductDetailLoaderAction,
    updateProductDetailsLoaderAction,
    addProductLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { errors } = productDetail || {};

  const onSelectChange = (name: string, _e: any, value: any) => {
    dispatch(onChangeProductData(name, value));
    if (name === 'supplierId' && value === null) {
      dispatch(onChangeProductData('categoryId', null));
    }
    if (isSubmitted) {
      formInputValidation(
        dispatch,
        { ...productDetail, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeProductData(name, value));
  };

  useEffect(() => {
    if (productDetail?.supplierId?._id) {
      dispatch(getCategories(productDetail.supplierId));
    }
  }, [productDetail?.supplierId]);

  const onClickAddProduct = async (action: string) => {
    setIsSubmitted(true);
    formInputValidation(
      dispatch,
      productDetail,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };

  const onBlur = (name: string) => {
    formInputValidation(
      dispatch,
      productDetail,
      errors,
      isSubmitted,
      'onBlur',
      name,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const productAddedConfirmationButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT);
      },
      color: 'primary',
    },
  ];

  useEffect((): any => {
    dispatch(getSupplierListInProduct());
    dispatch(getUnits());
    if (productId) {
      dispatch(getSelectedProductDetails(productId));
    }
    return () => {
      dispatch(resetProductData());
    };
  }, []);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => {
            history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT);
          }}
        />
        <span className="form-title">
          {`${action === 'add' ? 'Add' : 'Edit'} Product`}
        </span>
      </div>

      {action === 'edit' && getSelectedProductDetailLoaderAction ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="add-product-form">
            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Supplier</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl
                className={`input-field ${
                  productDetail?.errors?.supplierId ? 'input-with-error' : ''
                }`}
              >
                <Autocomplete
                  key={Math.random()}
                  placeholder="Select Supplier"
                  onChange={(event, value) =>
                    onSelectChange('supplierId', event, value)
                  }
                  options={supplierList ?? []}
                  getOptionLabel={(option) => option?.name ?? option ?? ''}
                  value={productDetail?.supplierId}
                  id="supplierId"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  className="autocomplete"
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder="Select Supplier"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {productDetail?.errors?.supplierId}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Category</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl
                className={`input-field ${
                  productDetail?.errors?.categoryId ? 'input-with-error' : ''
                }`}
              >
                <Autocomplete
                  key={Math.random()}
                  placeholder="Select Category"
                  onChange={(event, value) =>
                    onSelectChange('categoryId', event, value)
                  }
                  options={categories ?? []}
                  getOptionLabel={(option) => option?.name ?? option ?? ''}
                  value={productDetail?.categoryId}
                  className="autocomplete"
                  id="categoryId"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  disabled={!productDetail?.supplierId}
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder="Select Category"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {productDetail?.errors?.categoryId}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <InputLabel className="add-product-label">
                Product Code (Optional)
              </InputLabel>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Product Code"
                  className={`input-field`}
                  id="productCode"
                  name="productCode"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  value={productDetail?.productCode}
                  type="text"
                  onChange={onHandleChangeInput}
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name"> Product Name</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Product Name"
                  className={`input-field ${
                    productDetail?.errors?.name ? 'input-with-error' : ''
                  }`}
                  id="name"
                  name="name"
                  value={productDetail?.name}
                  type="text"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  helperText={productDetail?.errors?.name}
                  onChange={onHandleChangeInput}
                  onBlur={() => onBlur('name')}
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <InputLabel className="add-product-label">
                Weight (Kg) (Optional)
              </InputLabel>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Weight"
                  className={`input-field ${
                    productDetail?.errors?.weight ? 'input-with-error' : ''
                  }`}
                  name="weight"
                  id="weight"
                  value={productDetail?.weight}
                  type="text"
                  helperText={productDetail?.errors?.weight}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  onChange={onHandleChangeInput}
                  onBlur={() => onBlur('weight')}
                />
              </FormControl>
            </div>

            <div className="input-field-margin-bottom">
              <div className="form-field-name">
                <div className="form-field-name">Add UOM</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl
                className={`input-field ${
                  productDetail?.errors?.unitId ? 'input-with-error' : ''
                }`}
              >
                <Autocomplete
                  key={Math.random()}
                  onChange={(event, value) =>
                    onSelectChange('unitId', event, value)
                  }
                  options={units ?? []}
                  getOptionLabel={(option) => option?.name ?? option ?? ''}
                  value={productDetail?.unitId}
                  className="autocomplete"
                  id="unitId"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddProduct(action))
                  }
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder={
                        !productDetail?.unitId?.length ? 'Select Unit' : ''
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {productDetail?.errors?.unitId}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-button-row">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={
                  addProductLoaderAction || updateProductDetailsLoaderAction
                }
                onClick={() =>
                  onClickAddProduct(action === 'add' ? 'add' : 'edit')
                }
              >
                {action === 'add' ? 'Add product' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Product Added Confirmation"
          buttons={productAddedConfirmationButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Product added successfully</span>
          </>
        </CustomModal>
      )}
    </div>
  );
};
export default AddProduct;
