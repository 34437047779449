import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { statementsApiServices } from '../../../services/pageServices/invoiceServices/statementApiServices';
import { STATEMENTS_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/statementsReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getStatementListAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('statementsListLoaderAction');
      const response = await statementsApiServices.statementList(params);
      if (response.status === 200) {
        dispatch({
          type: STATEMENTS_REDUX_CONSTANTS.STATEMENT_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('statementsListLoaderAction');
    }
  };
};
export const getSupplierListInStatement = () => {
  return async (dispatch: any) => {
    try {
      const response = await statementsApiServices.getSupplierList();
      if (response?.status === 200) {
        dispatch({
          type: STATEMENTS_REDUX_CONSTANTS.GET_SUPPLIER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getStatemetDetailsByIdAction = (statementId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await statementsApiServices.statementDetailsById(
        statementId
      );
      if (response.status === 200) {
        dispatch({
          type: STATEMENTS_REDUX_CONSTANTS.GET_STATEMENT_DETAILS_BY_ID,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateStatusToPendingAction = (
  statementId: string,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await statementsApiServices.updateStatusToPending(
        statementId
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.STATEMENT);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateStatusToReconciledAction = (
  statemetId: string,
  history: any
) => {
  return async () => {
    try {
      const response = await statementsApiServices.updateStatusToReconciled(
        statemetId
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.STATEMENT);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteStatementAction = (ids: string, cb: () => void) => {
  return async () => {
    try {
      const params = {
        statementIds: ids,
      };
      const response = await statementsApiServices.deleteStatement(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const modifyStatementAction = (
  statementId: string,
  data: Record<string, string>
) => {
  return async () => {
    try {
      const response = await statementsApiServices.modifyStatement(
        statementId,
        data
      );
      if (response.status === 200) {
        successNotification(response.data.data.message);
        window.open(response.data.data.url, '_blank');
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifieddataAction = (
  statementId: string,
  documentId: string
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('getModifiedStatementLoaderAction');
      const params = { documentId };
      const response = await statementsApiServices.getModifiedData(
        statementId,
        params
      );
      if (response.status === 200) {
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getModifiedStatementLoaderAction');
    }
  };
};

export const statementExporttoExcel = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await statementsApiServices.downloadStatementEscelsheet(
        data
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const statementExportToPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await statementsApiServices.downloadStatementPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const syncStatementAction = (statementId: string) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest(`${statementId}Sync`);
      startGeneralLoaderOnRequest('manualSyncStatementLoaderAction');

      const response = await statementsApiServices.syncStatement(statementId);
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(`${statementId}Sync`);
      stopGeneralLoaderOnSuccessOrFail('manualSyncStatementLoaderAction');
    }
  };
};

export const statementAutoSyncAction = (autoSync: boolean) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('statementAutoSyncLoaderAction');
      const response = await statementsApiServices.statementAutoSync({
        autoSync,
      });
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('statementAutoSyncLoaderAction');
    }
  };
};

export const getUploadedStatementResponse = (data: Record<string, any>) => {
  return (dispatch: any) => {
    dispatch({
      type: STATEMENTS_REDUX_CONSTANTS.GET_UPLOADED_STATEMENT_RESPONSE,
      data,
    });
  };
};

export const modifyUploadedStatementAction = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await statementsApiServices.modifyUploadedstatement(
        data
      );
      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedUploadedStatementAction = (
  data: Record<string, string>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest(
        'getUploadedStatementModifiedDataLoaderAction'
      );
      const response =
        await statementsApiServices.getModyfiedUploadedStatementData(data);
      if (response.status === 200) {
        successNotification(response.data.data.message);
        dispatch({
          type: STATEMENTS_REDUX_CONSTANTS.GET_UPLOADED_STATEMENT_RESPONSE,
          data: { ...response.data.data },
        });
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getUploadedStatementModifiedDataLoaderAction'
      );
    }
  };
};
