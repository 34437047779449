import { InputLabel } from '@mui/material';
import logo from '../../../../src/assets/images/company-logo.svg';
import Button from '../../../components/button/Button';

type AuthTemplateProps = {
  title: string;
  subTitle?: string;
  buttonTitle?: string;
  imageContext: string;
  imageTitle?: string;
  children: JSX.Element;
  buttonEvent?: () => void;
  footerArea?: JSX.Element;
  isModalOpen?: boolean;
  titleClass?: string;
};
const AuthTemplate = (props: AuthTemplateProps) => {
  const {
    title,
    subTitle,
    buttonTitle,
    imageContext,
    children,
    imageTitle,
    buttonEvent,
    footerArea,
    isModalOpen,
    titleClass,
  } = props;

  return (
    <div
      className={`auth-container ${isModalOpen ? 'modal-cancel-overflow' : ''}`}
    >
      <div className="auth-left-part">
        <div className="auth-company-logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="auth-content-container">
          <div className="auth-user-info">
            <span className={`auth-title ${titleClass}`}>{title}</span>
            <span className="auth-subtitle">{subTitle}</span>
          </div>
          <div>{children}</div>
          {buttonTitle && (
            <Button
              color="primary"
              variant="contained"
              className="auth-button"
              onClick={buttonEvent}
            >
              {buttonTitle}
            </Button>
          )}
          {footerArea}
        </div>
      </div>

      <div className="auth-right-part">
        <div className="login-img-container">
          {imageTitle && (
            <span className="auth-right-part__title">{imageTitle}</span>
          )}
          <img src={imageContext} alt="img-context" />
        </div>
      </div>
    </div>
  );
};
export default AuthTemplate;
