import { Dispatch } from 'react';
import {
  errorNotification,
  successNotification,
} from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { SUPPLIER_REDUX_CONSTANTS } from '../../reduxConstants/supplierReduxConstants/supplierReduxConstants';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';
import supplierApiServices from '../../../services/pageServices/supplierServices/supplierApiServices';

export const getSupplierList = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('supplierListLoaderAction');
      const response = await supplierApiServices.supplierList(params);
      if (response?.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.SUPPLIER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('supplierListLoaderAction');
    }
  };
};

export const deleteSuppliers = (ids: string, cb: () => void) => {
  return async () => {
    try {
      const params = {
        supplierIds: ids,
      };
      const response = await supplierApiServices.deleteSuppliers(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getPaymentMethodDropdownData = () => {
  return async (dispatch: any) => {
    try {
      const response = await supplierApiServices.getPaymentMethodDropdownData();
      if (response?.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.GET_PAYMENT_DROPDOWN_DATA,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onAddSupplierValueChange = (
  name: string,
  value: string | number | boolean | Record<string, any> | undefined
) => {
  return (dispatch: any) => {
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.ON_ADD_SUPPLIER_VALUE_CHANGE,
      data: { name, value },
    });
  };
};

export const onAddSupplier = (data: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('addSupplierLoaderAction');
      const response = await supplierApiServices.addSupplier(data);
      if (response?.status === 201) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS,
        });
      }
      return true;
    } catch (e) {
      displayErrors(e);
      return false;
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addSupplierLoaderAction');
    }
  };
};

export const getSelectedSupplierDetails = (supplierId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedSupplierDetailsLoaderAction');
      const response = await supplierApiServices.getSupplierDataById(
        supplierId
      );
      if (response?.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.GET_SELECTED_SUPPLIER_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getSelectedSupplierDetailsLoaderAction'
      );
    }
  };
};

export const editSupplier = (
  supplierId: string,
  data: Record<string, any>,
  history: any
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('supplierDetailsUpdateLoaderAction');
      const response = await supplierApiServices.editSupplierDetails(
        supplierId,
        data
      );
      if (response?.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS,
        });
        history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
        successNotification(
          `${data?.companyName}'s details updated successfully`
        );
      }
      return true;
    } catch (e) {
      displayErrors(e);
      return false;
    } finally {
      stopGeneralLoaderOnSuccessOrFail('supplierDetailsUpdateLoaderAction');
    }
  };
};

export const exportSupplierExcelsheet = (params: Record<string, string>) => {
  return async () => {
    try {
      const response = await supplierApiServices.downloadSupplierExcelSheet(
        params
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetSupplierDetailsForm = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS,
    });
  };
};

export const uploadTermsAndCondition = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: any) => {
    try {
      const response = await supplierApiServices.uploadTermsAndCondition(
        data,
        config
      );
      if (response?.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.UPLOAD_TERMS_AND_CONDITION,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSupplierListPdf = (params: Record<string, any>) => {
  return async () => {
    try {
      const response = await supplierApiServices.downloadSupplierListPDF(
        params
      );
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteTermsAndConditionsAction = (fileName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SUPPLIER_REDUX_CONSTANTS.DELETE_TERMS_AND_CONDITIONS,
      });
      successNotification('Terms and condition deleted successfully');
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSingleSupplierExcelsheet = (supplierId: string) => {
  return async () => {
    try {
      const response =
        await supplierApiServices.downloadSingleSupplierExcelSheet(supplierId);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSingleSupplierPdf = (supplierId: string) => {
  return async () => {
    try {
      const response = await supplierApiServices.downloadSingleSupplierPdf(
        supplierId
      );
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetCompanyDetailList = () => {
  return async (dispatch: any) => {
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.RESET_COMPANY_DETAILS_IN_SUPPLIER,
    });
  };
};

export const getDetailsByCompanyName = (name: string) => {
  const param = {
    searchString: name,
  };
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest(
        'companyDetailsListByCompanyNameLoaderAction'
      );
      const response = await supplierApiServices.getDetailbyName(param);
      if (response?.status === 200) {
        response.data.data.length > 0
          ? dispatch({
              type: SUPPLIER_REDUX_CONSTANTS.GET_COMPANY_DETAILS_BY_NAME_IN_SUPPLIER,
              data: response.data.data,
            })
          : errorNotification('No data found with this company name');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'companyDetailsListByCompanyNameLoaderAction'
      );
    }
  };
};

export const getDetailsByCompanyNumber = (abn: string) => {
  const param = {
    searchString: abn,
  };
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('companyDetailsListByAbnLoaderAction');
      const response = await supplierApiServices.getDetailbyAbn(param);
      if (response.status === 200) {
        dispatch({
          type: SUPPLIER_REDUX_CONSTANTS.UPDATE_DETAILS_BY_SEARCH_IN_SUPPLIER,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('companyDetailsListByAbnLoaderAction');
    }
  };
};

export const validateSupplierAbnAndVenue = (abn: string | number) => {
  const param = {
    searchString: abn,
  };
  return async () => {
    try {
      const response = await supplierApiServices.getDetailbyAbn(param);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
