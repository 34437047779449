import { successNotification } from '../../components/notifyHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import signUpProcessHelper from '../../helpers/signUpProcessHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import cardApiServices from '../../services/pageServices/cardDetailApiServices';
import { CARD_DETAIL_REDUX_CONSTANTS } from '../reduxConstants/cardDetailReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const getCardListAction = (params: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      const response = await cardApiServices.cardList(params);
      if (response.status === 200) {
        response.data.data.isTrial &&
          successNotification(
            'After your free trial ends, the amount will be deducted from this account'
          );
        dispatch({
          type: CARD_DETAIL_REDUX_CONSTANTS.GET_CARD_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const addCardAction = (data: Record<string, any>, history: any) => {
  return async () => {
    try {
      const response = await cardApiServices.addCard(data);
      if (response.status === 201) {
        successNotification('Card added successfully');
        history.push(`/card-details/${data.userId}`);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addCardButtonLoaderAction');
    }
  };
};

export const selectCardAction = (params: Record<string, any>, history: any) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('selectCardButtonLoaderAction');
      const response = await cardApiServices.selectCard(params);
      if (response.status === 200) {
        const route = signUpProcessHelper(response.data.data);
        history.push(route);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('selectCardButtonLoaderAction');
    }
  };
};
