import { ADMIN_PLAN_REDUX_CONSTANTS } from '../../reduxConstants/admin/adminPlanReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface PlanProps {
  adminPlanList: Record<string, any>;
  planFacilitiesList: string[];
  planDetail: Record<string, any>;
}

const initialAdminPlanReducer: PlanProps = {
  adminPlanList: {},
  planFacilitiesList: [],
  planDetail: {
    name: '',
    price: null,
    tag: '',
    isPopularPlan: false,
    isMonthlyPlan: true,
    isYearlyPlan: false,
    noOfFreeInvoices: null,
    facilities: [],
    errors: {},
  },
};

export const adminPlanReducer = (
  state = initialAdminPlanReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case ADMIN_PLAN_REDUX_CONSTANTS.ADMIN_PLAN_LIST:
      return {
        ...state,
        adminPlanList: action?.data,
      };
    case ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_PLAN_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        planDetail: {
          ...state.planDetail,
          [name]: value,
        },
      };
    case ADMIN_PLAN_REDUX_CONSTANTS.ADMIN_PLAN_FACILITIES_LIST:
      return {
        ...state,
        planFacilitiesList: action?.data.facilities,
      };
    case ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_PLAN_TYPE:
      return {
        ...state,
        planDetail: {
          ...state.planDetail,
          isMonthlyPlan: !state?.planDetail?.isMonthlyPlan,
          isYearlyPlan: !state?.planDetail?.isYearlyPlan,
        },
      };

    case ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_FACILITIES:
      const { facility } = action.data;
      const { facilities } = state.planDetail;
      if (facilities?.includes(facility)) {
        const existingFacilityIndex = facilities.indexOf(facility);
        return {
          ...state,
          planDetail: {
            ...state.planDetail,
            facilities: [
              ...facilities.slice(0, existingFacilityIndex),
              ...facilities.slice(existingFacilityIndex + 1),
            ],
          },
        };
      } else {
        return {
          ...state,
          planDetail: {
            ...state.planDetail,
            facilities: [...facilities, facility],
          },
        };
      }

    case ADMIN_PLAN_REDUX_CONSTANTS.RESET_PLAN_DATA:
      return {
        ...state,
        planDetail: { ...initialAdminPlanReducer.planDetail, errors: {} },
      };

    case ADMIN_PLAN_REDUX_CONSTANTS.GET_SELECTED_PLAN_DETAILS:
      return {
        ...state,
        planDetail: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialAdminPlanReducer,
        planDetail: { ...initialAdminPlanReducer.planDetail, errors: {} },
      };

    default:
      return { ...state };
  }
};
