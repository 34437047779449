import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import Input from '../../../../components/input/Input';
import { Autocomplete, Box, FormControl, FormHelperText } from '@mui/material';
import CheckBox from '../../../../components/checkbox/CheckBox';
import {
  onChangePlanData,
  onChangePlanType,
  onChangeFacilities,
  resetPlanData,
  getSelectedPlanDetails,
} from '../../../../store/actions/admin/adminPlanActions';
import { useDispatch, useSelector } from 'react-redux';
import { addPlanFormValidation } from './addPlanValidations';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import rightIcon from '../../../../assets/images/right-icon.svg';
import { getPlanFacilitiesList } from '../../../../store/actions/admin/adminPlanActions';
import Loader from '../../../../components/loader/Loader';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import _ from 'lodash';
import { focusHelper } from '../../../../helpers/focusHelper';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const AddPlan = () => {
  const { action, planId } = useParams<Record<string, string>>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const {
    adminPlanFacilitiesListLoaderAction,
    updateAdminPlanDetailsLoaderAction,
    getSelectedAdminPlanDetailsLoaderAction,
    addPlanLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { planDetail } = useSelector(
    ({ adminPlanReducer }: Record<string, any>) => adminPlanReducer ?? {}
  );
  const { plan } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );
  const { errors } = planDetail || {};

  const { planFacilitiesList } = useSelector(
    ({ adminPlanReducer }: Record<string, any>) => adminPlanReducer ?? {}
  );
  const popularPlanOption = ['Yes', 'No'];

  useEffect((): any => {
    dispatch(getPlanFacilitiesList());
    if (planId) {
      dispatch(getSelectedPlanDetails(planId));
    }
    return () => dispatch(resetPlanData());
  }, []);

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangePlanData(name, value));
  };

  const onAddFacilities = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(onChangeFacilities(name));
  };

  const onClickAddPlan = (action: string) => {
    setIsSubmitted(true);
    addPlanFormValidation(
      dispatch,
      planDetail,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };
  const onBlur = (name: string) => {
    addPlanFormValidation(
      dispatch,
      planDetail,
      errors,
      isSubmitted,
      'onBlur',
      name,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const planAddedConfirmationButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ADMIN_ROUTE_CONST.PLAN);
      },
      color: 'primary',
    },
  ];
  useEffect(() => {
    if (plan?.planType === 'Yearly') dispatch(onChangePlanType());
  }, []);

  return (
    <div className="form">
      <div className="add-plan-form">
        <div className="form-title-switch-container">
          <div className="form-title-row">
            <BackButton onClick={() => history.push(ADMIN_ROUTE_CONST.PLAN)} />
            <span className="form-title">
              {`${action === 'add' ? 'Add' : 'Edit'} Plan`}
            </span>
          </div>

          <div className="plan-type-menu">
            <span
              className={planDetail?.isMonthlyPlan ? 'selected-plan-type' : ''}
              onClick={() => dispatch(onChangePlanType())}
            >
              Monthly
            </span>
            <span
              className={!planDetail?.isMonthlyPlan ? 'selected-plan-type' : ''}
              onClick={() => dispatch(onChangePlanType())}
            >
              Yearly
            </span>
            <div className="selected-plan" />
          </div>
        </div>

        {adminPlanFacilitiesListLoaderAction ||
        (action === 'edit' && getSelectedAdminPlanDetailsLoaderAction) ? (
          <Loader />
        ) : (
          <div className="form-container plan-form-container">
            <div className="form-fields-grid">
              <div>
                <div className="form-field-name">
                  <div className="form-field-name">Plan Name</div>
                  <div className="required-sign">*</div>
                </div>
                <FormControl className="input-field">
                  <Input
                    variant="filled"
                    placeholder="Enter Plan Name"
                    className={`input-field ${
                      planDetail?.errors?.name ? 'input-with-error' : ''
                    }`}
                    name="name"
                    id="name"
                    value={planDetail?.name}
                    type="text"
                    helperText={planDetail?.errors?.name}
                    onChange={onHandleChangeInput}
                    onKeyUp={(event) =>
                      enterKeyHelper(event, () => onClickAddPlan(action))
                    }
                    onBlur={() => onBlur('name')}
                  />
                </FormControl>
              </div>

              <div>
                <div className="form-field-name">
                  <div className="form-field-name">Price</div>
                  <div className="required-sign">*</div>
                </div>
                <FormControl className="input-field">
                  <Input
                    variant="filled"
                    placeholder="Enter Price"
                    className={`input-field ${
                      planDetail?.errors?.price ? 'input-with-error' : ''
                    }`}
                    name="price"
                    id="price"
                    value={planDetail?.price}
                    type="text"
                    helperText={planDetail?.errors?.price}
                    onChange={onHandleChangeInput}
                    onKeyUp={(event) =>
                      enterKeyHelper(event, () => onClickAddPlan(action))
                    }
                    onBlur={() => onBlur('price')}
                  />
                </FormControl>
              </div>

              <div>
                <div className="form-field-name">
                  <div className="form-field-name">
                    Enter the Number Hands Free Invoice Process
                  </div>
                  <div className="required-sign">*</div>
                </div>
                <FormControl className="input-field">
                  <Input
                    variant="filled"
                    placeholder="Enter the Number Hands Free Invoice Process"
                    className={`input-field ${
                      planDetail?.errors?.noOfFreeInvoices
                        ? 'input-with-error'
                        : ''
                    }`}
                    name="noOfFreeInvoices"
                    id="noOfFreeInvoices"
                    value={planDetail?.noOfFreeInvoices}
                    type="text"
                    helperText={planDetail?.errors?.noOfFreeInvoices}
                    onChange={onHandleChangeInput}
                    onKeyUp={(event) =>
                      enterKeyHelper(event, () => onClickAddPlan(action))
                    }
                    onBlur={() => onBlur('noOfFreeInvoices')}
                  />
                </FormControl>
              </div>

              <div>
                <div className="form-field-name popular-plan-dropdown">
                  Do you want to make this plan popular?
                </div>
                <Autocomplete
                  key={'popularPlan'}
                  className="autocomplete"
                  options={popularPlanOption}
                  value={planDetail?.isPopularPlan === true ? 'Yes' : 'No'}
                  onChange={(event, value) =>
                    dispatch(
                      onChangePlanData(
                        'isPopularPlan',
                        value === 'Yes' ? true : false
                      )
                    )
                  }
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      name="isPopularPlan"
                      {...params}
                      placeholder="Choose option"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div></div>
              <div
                className="form-full-width-field form-field-name"
                style={{ margin: 0, display: 'flex', flexDirection: 'column' }}
              >
                Facilities
                <FormHelperText className="input-with-error">
                  {planDetail?.errors?.facilities}
                </FormHelperText>
              </div>

              {planFacilitiesList?.map((labelName: string) => {
                return (
                  <div key={labelName}>
                    <CheckBox
                      label={labelName}
                      name={labelName}
                      onChange={onAddFacilities}
                      onBlur={() => onBlur('facilities')}
                      defaultChecked={planDetail?.facilities?.includes(
                        labelName
                      )}
                    ></CheckBox>
                  </div>
                );
              })}
            </div>

            <div className="form-button-row">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(ADMIN_ROUTE_CONST.PLAN);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={
                  addPlanLoaderAction || updateAdminPlanDetailsLoaderAction
                }
                onClick={() =>
                  onClickAddPlan(action === 'add' ? 'add' : 'edit')
                }
              >
                {action === 'add' ? 'Create Plan & Save' : 'Save'}
              </Button>
            </div>
          </div>
        )}
      </div>
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Plan Added Confirmation"
          buttons={planAddedConfirmationButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ADMIN_ROUTE_CONST.PLAN);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Plan added successfully</span>
          </>
        </CustomModal>
      )}
    </div>
  );
};

export default AddPlan;
