export const ordersIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.0625 11.835H15.3516V13.124H14.0625V11.835Z" fill="#666666" />
    <path d="M11.0957 11.835H12.3848V13.124H11.0957V11.835Z" fill="#666666" />
    <path d="M8.12793 11.835H9.41699V13.124H8.12793V11.835Z" fill="#666666" />
    <path
      d="M22 5.0826C22 2.28005 19.72 0 16.9174 0C14.8726 0 13.1063 1.21387 12.3005 2.9587H4.48241L4.18653 0H0V1.28906H3.01993L3.20495 3.13904L4.3823 14.9127C3.55429 15.2028 2.9587 15.9916 2.9587 16.9174C2.9587 18.0885 3.91149 19.0413 5.0826 19.0413H6.07982C5.96978 19.2977 5.90863 19.5798 5.90863 19.8761C5.90863 21.0472 6.86138 22 8.03249 22C9.20361 22 10.1564 21.0472 10.1564 19.8761C10.1564 19.5799 10.0953 19.2977 9.98521 19.0413H13.4943C13.3842 19.2977 13.3231 19.5798 13.3231 19.8761C13.3231 21.0472 14.2759 22 15.447 22C16.6181 22 17.5709 21.0472 17.5709 19.8761C17.5709 19.5799 17.5097 19.2977 17.3997 19.0413H18.9799V17.7522H5.0826C4.62228 17.7522 4.24776 17.3777 4.24776 16.9174C4.24776 16.4571 4.62228 16.0826 5.0826 16.0826H18.9801L19.6518 9.36525C21.0629 8.46119 22 6.8793 22 5.0826ZM8.03245 20.7109C7.57212 20.7109 7.19765 20.3364 7.19765 19.8761C7.19765 19.4158 7.57212 19.0413 8.03245 19.0413C8.49277 19.0413 8.86729 19.4158 8.86729 19.8761C8.86729 20.3364 8.49277 20.7109 8.03245 20.7109ZM15.4469 20.7109C14.9866 20.7109 14.612 20.3364 14.612 19.8761C14.612 19.4158 14.9866 19.0413 15.4469 19.0413C15.9072 19.0413 16.2817 19.4158 16.2817 19.8761C16.2817 20.3364 15.9072 20.7109 15.4469 20.7109ZM16.9174 1.28906C19.0092 1.28906 20.7109 2.99084 20.7109 5.0826C20.7109 7.17436 19.0092 8.87614 16.9174 8.87614C14.8256 8.87614 13.1239 7.17436 13.1239 5.0826C13.1239 2.99084 14.8256 1.28906 16.9174 1.28906ZM17.8134 14.7935H5.66586L4.61132 4.24776H11.9038C11.8587 4.5195 11.8348 4.79828 11.8348 5.0826C11.8348 7.88515 14.1149 10.1652 16.9174 10.1652C17.3948 10.1652 17.8569 10.0989 18.2953 9.97532L17.8134 14.7935Z"
      fill="#666666"
    />
    <path
      d="M19.384 4.31921L18.4725 3.40771L16.4898 5.39038L15.3617 4.26232L14.4502 5.17381L16.4898 7.21341L19.384 4.31921Z"
      fill="#666666"
    />
  </svg>
);
