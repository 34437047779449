export const filterIcon = (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2865 14.2139L18.9212 14.316V14.6954V17.1959C18.9212 17.2831 18.8877 17.3628 18.8344 17.4184C18.7818 17.4732 18.715 17.5 18.6499 17.5C18.5852 17.5 18.5188 17.4736 18.4663 17.4194C18.4135 17.3649 18.3799 17.2869 18.3786 17.201V14.7034V14.324L18.0132 14.2219C17.4988 14.0781 17.0402 13.7603 16.711 13.3129C16.3815 12.8652 16.2012 12.314 16.2012 11.7446C16.2012 11.1753 16.3815 10.624 16.711 10.1763C17.0402 9.72898 17.4988 9.41112 18.0132 9.26732L18.3786 9.16518V8.78578V0.825881C18.3786 0.738689 18.4121 0.659041 18.4654 0.60347L18.1045 0.257345L18.4654 0.60347C18.518 0.548606 18.5848 0.521851 18.6499 0.521851C18.715 0.521851 18.7818 0.548605 18.8344 0.60347C18.8877 0.659043 18.9212 0.738691 18.9212 0.825881V8.77774V9.15714L19.2865 9.25928C19.801 9.40308 20.2596 9.72094 20.5888 10.1683C20.9183 10.616 21.0986 11.1672 21.0986 11.7366C21.0986 12.3059 20.9183 12.8572 20.5888 13.3049C20.2596 13.7522 19.801 14.0701 19.2865 14.2139ZM17.0554 10.6493C16.848 10.9729 16.7385 11.3512 16.7385 11.7366C16.7385 12.2529 16.935 12.752 17.291 13.1231C17.6477 13.495 18.1361 13.708 18.6499 13.708C19.0327 13.708 19.4051 13.5895 19.7198 13.3703C20.0343 13.1512 20.2764 12.8421 20.4185 12.4843C20.5606 12.1266 20.5975 11.7342 20.5253 11.3558C20.4531 10.9773 20.2744 10.627 20.0087 10.35C19.7429 10.0729 19.4016 9.88161 19.0268 9.80389C18.6518 9.72613 18.2634 9.76625 17.9116 9.91817C17.56 10.07 17.2629 10.3256 17.0554 10.6493Z"
      fill="#666666"
      stroke="#F21D86"
    />
    <path
      d="M11.4352 5.57082L11.0709 5.67355V6.05206V17.1959C11.0709 17.2831 11.0375 17.3628 10.9842 17.4183C10.9316 17.4732 10.8647 17.5 10.7996 17.5C10.7346 17.5 10.6677 17.4732 10.6151 17.4183C10.5618 17.3628 10.5284 17.2831 10.5284 17.1959V6.05206V5.67355L10.1641 5.57082C9.59431 5.41017 9.09475 5.03773 8.76206 4.51667C8.42908 3.99516 8.28773 3.36249 8.36743 2.7371C8.44711 2.11182 8.74151 1.54216 9.19045 1.13222C9.63885 0.722762 10.2109 0.5 10.7996 0.5C11.3884 0.5 11.9604 0.722762 12.4088 1.13222C12.8578 1.54216 13.1522 2.11182 13.2319 2.7371C13.3116 3.36249 13.1702 3.99516 12.8372 4.51667C12.5045 5.03773 12.005 5.41017 11.4352 5.57082ZM9.20012 2.01381C8.99402 2.33873 8.88622 2.71806 8.88824 3.10389C8.89094 3.61834 9.0887 4.11475 9.44446 4.4836C9.8009 4.85315 10.2877 5.06461 10.7997 5.0646C11.1829 5.06459 11.5557 4.94587 11.8707 4.72613C12.1854 4.50659 12.4274 4.19683 12.5693 3.83843C12.711 3.48017 12.7473 3.08721 12.6743 2.70852C12.6013 2.32975 12.4215 1.97938 12.1548 1.70285C11.8879 1.42609 11.5455 1.23568 11.17 1.1592C10.7943 1.08269 10.4056 1.12439 10.054 1.27798C9.70268 1.43145 9.40636 1.68868 9.20012 2.01381Z"
      fill="#666666"
      stroke="#F21D86"
    />
    <path
      d="M2.67746 11.9697V11.5903L2.31207 11.4882C1.79765 11.3444 1.339 11.0265 1.00978 10.5792C0.680317 10.1315 0.5 9.58027 0.5 9.01091C0.5 8.44156 0.680317 7.89033 1.00978 7.44264C1.339 6.99528 1.79765 6.67742 2.31207 6.53362L2.67746 6.43148V6.05208V0.825881C2.67746 0.73869 2.71088 0.659042 2.76419 0.60347C2.81681 0.548605 2.88365 0.521851 2.94872 0.521851C3.01379 0.521851 3.08063 0.548605 3.13326 0.60347C3.18657 0.659042 3.21999 0.73869 3.21999 0.825881V6.05208V6.43148L3.58538 6.53362C4.09979 6.67742 4.55844 6.99528 4.88767 7.44264C5.21713 7.89033 5.39744 8.44156 5.39744 9.01091C5.39744 9.58027 5.21713 10.1315 4.88767 10.5792C4.55844 11.0266 4.09979 11.3444 3.58538 11.4882L3.21999 11.5903V11.9697V17.1959C3.21999 17.2831 3.18656 17.3628 3.13326 17.4184C3.08063 17.4732 3.01379 17.5 2.94872 17.5C2.88365 17.5 2.81682 17.4732 2.76419 17.4184C2.71088 17.3628 2.67746 17.2831 2.67746 17.1959V11.9697ZM4.01874 7.37193C3.70255 7.15396 3.32911 7.03736 2.94587 7.03955C2.43392 7.04246 1.94833 7.25668 1.59391 7.62819C1.24016 7.999 1.04502 8.49647 1.04502 9.01092C1.04503 9.39675 1.15482 9.7755 1.36267 10.0993C1.57064 10.4233 1.86836 10.6789 2.22054 10.8304C2.57299 10.982 2.96196 11.0214 3.3372 10.9428C3.71228 10.8642 4.05354 10.6718 4.31896 10.3936C4.58417 10.1156 4.76205 9.76425 4.83311 9.3851C4.90414 9.00602 4.8658 8.61325 4.72211 8.25577C4.57836 7.89814 4.33465 7.58971 4.01874 7.37193Z"
      fill="#666666"
      stroke="#F21D86"
    />
  </svg>
);
