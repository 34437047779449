export const importIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 7.3125V2H4.9375C4.41797 2 4 2.41797 4 2.9375V21.0625C4 21.582 4.41797 22 4.9375 22H18.0625C18.582 22 19 21.582 19 21.0625V8.25H13.6875C13.1719 8.25 12.75 7.82812 12.75 7.3125ZM15.2961 15.7504H12.75V18.8754C12.75 19.2207 12.4703 19.5004 12.125 19.5004H10.875C10.5297 19.5004 10.25 19.2207 10.25 18.8754V15.7504H7.70391C7.14609 15.7504 6.86758 15.075 7.26367 14.6816L11.0301 10.9434C11.2898 10.6852 11.7094 10.6852 11.9691 10.9434L15.7355 14.6816C16.132 15.075 15.8539 15.7504 15.2961 15.7504ZM18.7266 6.10156L14.9023 2.27344C14.7266 2.09766 14.4883 2 14.2383 2H14V7H19V6.76172C19 6.51563 18.9023 6.27734 18.7266 6.10156Z"
      fill="#666666"
    />
  </svg>
);
