import { PAYMENT_REDUX_CONSTANTS } from '../reduxConstants/paymentReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';

interface InitialPaymentProps {
  paymentDetails: Record<string, any>;
}

const initialPaymentdetails: InitialPaymentProps = {
  paymentDetails: {},
};

export const paymentReducer = (
  state = initialPaymentdetails,
  action: { type: string; data: Record<string, any> }
) => {
  switch (action?.type) {
    case PAYMENT_REDUX_CONSTANTS.GET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialPaymentdetails,
      };

    default:
      return state;
  }
};
