import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { deleteIcon } from '../../../assets/images/action-items/deleteIcon';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../../assets/images/action-items/printer';
import BackButton from '../../../components/backButton/BackButton';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import CustomMenu from '../../../components/menu/Menu';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  deleteRecipeAction,
  exportSingleRecipeExcelsheet,
  exportSingleRecipePdf,
  getSelectedRecipeDetails,
  resetRecipeData,
} from '../../../store/actions/recipeActions';
import companyLogo from '../../../assets/images/company-logo.svg';
import useMediaQuery from '../../../hooks/MediaQuery';
import {
  calculationHeader,
  recipeTableProductsHeaderConstants,
  recipeTableProductsMobileHeader,
} from '../../../constants/recipeCostConstants';
import { audConverter } from '../../../constants/regexConstants';
import Loader from '../../../components/loader/Loader';
import { Tooltip } from '@mui/material';

const RecipeCostOverview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { recipeId } = useParams<Record<string, string>>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const recipeRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const instructionPhotosRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 620px)');

  const { recipeDetail } = useSelector(
    ({ recipeReducer }: Record<string, any>) => recipeReducer ?? {}
  );

  const { getSelectedRecipeDetailLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const exportRecipeToExcel = () => {
    dispatch(exportSingleRecipeExcelsheet(recipeId));
  };

  const exportRecipeToPdf = () => {
    dispatch(exportSingleRecipePdf(recipeId));
  };

  const onDeleteRecipe = (val: string) => {
    setOpenDeleteModal(true);
  };

  const onClickPrint = useReactToPrint({
    content: () => {
      const recipe: any = recipeRef.current?.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle: any = `<div class='print-body-title'>Recipe Cost Overview</div>`;
      PrintElem.innerHTML += bodyTitle;
      PrintElem.appendChild(recipe);
      const table: any = tableRef.current?.cloneNode(true);
      PrintElem.appendChild(table);
      const instruction: any = instructionPhotosRef.current?.cloneNode(true);
      PrintElem.appendChild(instruction);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 5mm; } 
    @media print { 
      body { -webkit-print-color-adjust: exact; padding: 0 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportRecipeToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportRecipeToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        onClickPrint();
      },
    },
    {
      icon: deleteIcon,
      name: 'Delete',
      onClick: () => onDeleteRecipe(recipeId),
    },
  ];

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteRecipeAction(recipeId, afterSuccessfulDeletion));
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  const showAmountComponent = (value: string, type: string) => {
    let component = <></>;
    if (value.toString().length > 5) {
      component =
        type === 'amount' ? (
          <Tooltip enterDelay={1000} arrow title={`$${value}`}>
            <div>{`$${value}`}</div>
          </Tooltip>
        ) : (
          <Tooltip enterDelay={1000} arrow title={`${value}}%`}>
            <div>{`${value}}%`}</div>
          </Tooltip>
        );
    } else {
      component =
        type === 'amount' ? <div>{`$${value}`}</div> : <div>{`${value}%`}</div>;
    }
    return component;
  };

  useEffect(() => {
    dispatch(getSelectedRecipeDetails(recipeId));
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      dispatch(resetRecipeData());
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE)}
          ></BackButton>
          <div className="form-title">Recipe Cost Overview</div>
        </div>
        <div className="button-container">
          {recipeDetail.status !== 'Draft' && (
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="more_vert"
              menuItems={actionItems}
              id="supplier-action"
            />
          )}
        </div>
      </div>
      {getSelectedRecipeDetailLoaderAction ? (
        <Loader />
      ) : (
        <div ref={recipeRef}>
          <div className="overview-recipe-container view-details-white-container">
            <div className="recipe-detail details-grid details-grid-two-columns">
              <div>
                <div className="detail-label">Recipe Name</div>
                <div className="detail-value">{recipeDetail?.name ?? '-'}</div>
              </div>
              <div>
                <div className="detail-label">Recipe Cost ID</div>
                <div className="detail-value">
                  {recipeDetail?.recipeNumber ?? '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Food Category</div>
                <div className="detail-value">
                  {recipeDetail?.recipeCategoryId?.name ?? '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Number of Portions</div>
                <div className="detail-value">
                  {recipeDetail?.numberOfPortions ?? '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Food Cost Percentage</div>
                <div className="detail-value">
                  {recipeDetail?.foodCostPercentage ?? '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Previously Costed Recipe</div>
                <div className="detail-value">
                  {recipeDetail?.selectedRecipeIds.length > 0
                    ? recipeDetail?.selectedRecipeIds
                        ?.map((item: Record<string, string>) => item.name)
                        .join(', ')
                    : '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Status</div>
                <div className="detail-value">
                  {recipeDetail.status ? (
                    <span
                      className={`status-tag ${recipeDetail?.status?.toLowerCase()}`}
                    >
                      {recipeDetail.status ?? '-'}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
            </div>
            <div className="recipe-cover-photo">
              <img src={recipeDetail?.coverPhotoURL} alt="Recipe Image"></img>
            </div>
          </div>
          <div className="white-block-row">
            {calculationHeader?.map((item) => (
              <div className="white-block">
                {showAmountComponent(recipeDetail?.[item.name], item.type)}
                <div>{item.label}</div>
              </div>
            ))}
          </div>
          <div className="product-table-block">
            <div className="details-block-title">Products</div>
            <CustomTable
              headers={recipeTableProductsHeaderConstants}
              mobileHeaders={recipeTableProductsMobileHeader}
              data={recipeDetail?.products}
              isPagination
              addTableClassName="add-table-container"
            />
          </div>
          <div className="instruction-photos">
            <div ref={instructionPhotosRef}>
              {recipeDetail?.instructions && (
                <>
                  <div className="details-block-title">Recipe Instructions</div>
                  <p className="recipe-instructions">
                    {`${recipeDetail?.instructions}`}
                  </p>
                </>
              )}
              {recipeDetail?.additionalPhotoURLs?.length > 0 && (
                <div>
                  <div className="details-block-title">Photos</div>
                  <div className="recipe-photos-container">
                    {recipeDetail?.additionalPhotoURLs?.map((image: string) => (
                      <div className="recipe-photo">
                        <img src={image}></img>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Recipe"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {recipeDetail?.products?.length > 0 && (
          <>
            <div className="print-header-table statement-header">
              {recipeTableProductsHeaderConstants?.map(
                (header: Record<string, string>) => (
                  <span>{header.label}</span>
                )
              )}
            </div>
            <div className="print-table-body">
              {recipeDetail?.products?.map((data: Record<string, any>) => (
                <div className="print-table-row statememt-row">
                  <span>{data?.name}</span>
                  <span>{data.quantity}</span>
                  <span>{data.unit}</span>
                  <span>{data.weight}</span>
                  <span>{audConverter(data.totalPrice)}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default RecipeCostOverview;
