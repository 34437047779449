import { ACCOUNT_SETTINGS_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

const billingHistoryApiServices = {
  billingHistoryList: (params: Record<string, string | number>) =>
    ApiService.getData(
      ACCOUNT_SETTINGS_URLS.BILLING_HISTORY_URLS.GET_BILLING_HISTORY_LIST,
      { params }
    ),
  downloadBillingOverviewExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: ACCOUNT_SETTINGS_URLS.BILLING_HISTORY_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadBillingOverviewPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: ACCOUNT_SETTINGS_URLS.BILLING_HISTORY_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: data,
    }),
};

export default billingHistoryApiServices;
