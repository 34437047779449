import { displayErrors } from './../../../helpers/errorNotifyHelper';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './../generalLoaderAction';
import { successNotification } from '../../../components/notifyHelper';
import AccountSettingsUsersApiServices from '../../../services/pageServices/accountSettingsServices/accountSettingsUsersServices';
import { UserType } from '../../reducers/accountSettingsReducer/accountSettingsUsersReducer';
import { ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/accountSettingsUserReduxConstants';
import { Dispatch } from 'react';
import { AUTH_REDUX_CONSTANTS } from '../../reduxConstants/authReduxConstants';
import { accountingSoftwareApiServices } from '../../../services/pageServices/accountingSoftwareApiServices';

export const getUsersList = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('userListLoaderAction');
      const response = await AccountSettingsUsersApiServices.usersList(params);

      if (response?.status === 200) {
        dispatch({
          type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.USERS_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('userListLoaderAction');
    }
  };
};

export const addUser = (data: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      const response = await AccountSettingsUsersApiServices.addUser(data);
      if (response?.status === 201) {
        dispatch({
          type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.USER_DETAIL,
          data,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
      return false;
    }
    return false;
  };
};

export const getUserDataById = (id: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getUserDataByIdLoaderAction');
      const response = await AccountSettingsUsersApiServices.getUserDataById(
        id
      );
      if (response?.status === 200) {
        dispatch({
          type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.USER_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getUserDataByIdLoaderAction');
    }
  };
};

export const onChangeUserData = (
  name: string,
  value: string | boolean | Record<string, string | undefined> | undefined
) => {
  return (dispatch: any) => {
    dispatch({
      type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_USER_DETAIL,
      data: {
        name,
        value,
      },
    });
  };
};

export const editUserData = (
  data: any,
  id: string,
  cb: () => void,
  profileData: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await AccountSettingsUsersApiServices.editUserData(
        data,
        id
      );
      if (response?.status === 200) {
        if (cb) {
          cb();
        }
        successNotification(
          `${data?.firstName}'s details updated successfully`
        );

        dispatch({
          type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.RESET_USER_DETAIL,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteUsers = (ids: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        userIds: ids,
      };
      const response = await AccountSettingsUsersApiServices.deleteUsers(
        params
      );
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetUserDetails = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.RESET_USER_DETAIL,
    });
  };
};

export const resendEmailAction = (userId: string) => {
  return async () => {
    try {
      const response = await AccountSettingsUsersApiServices.resendEmail(
        userId
      );
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Invitation has been sent successfully'
        );
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeRoleAction = (modules: any) => {
  return (dispatch: any) => {
    dispatch({
      type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_ROLE,
      data: modules,
    });
  };
};

export const getModuleListAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await AccountSettingsUsersApiServices.moduleList();
      if (response.status === 200) {
        dispatch({
          type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.MODULE_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeModuleAction = (
  moduleIndex: number,
  subModuleIndex?: number
) => {
  return (dispatch: any) => {
    dispatch({
      type: ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_MODULE,
      data: { moduleIndex, subModuleIndex },
    });
  };
};

export const updateUserStatusAction = (
  data: Record<string, boolean>,
  userId: string
) => {
  return async () => {
    try {
      const response = await AccountSettingsUsersApiServices.updateStatus(
        data,
        userId
      );
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
