import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ADMIN_ROUTE_CONST, ROUTE_CONSTANTS_VARIABLE } from './RouteConstants';
import React from 'react';

interface NonauthenticatedRoutesProps {
  isEscapeRedirect?: boolean;
  component?: React.ComponentType<any>;
  options: any;
  exact?: boolean;
  path: string;
}

export const NonAuthenticatedRoute = ({
  isEscapeRedirect,
  component,
  path,
  options,
}: NonauthenticatedRoutesProps) => {
  const authStatus: boolean = useSelector(
    ({ authReducer }: any) => authReducer?.authStatus ?? false
  );

  const { accessModuleList } = useSelector(
    ({ authReducer }: any) => authReducer?.authStatus ?? false
  );

  let key = accessModuleList?.[0]?.name.toUpperCase();

  if (authStatus && !isEscapeRedirect) {
    return (
      <Route {...options}>
        <Redirect
          to={
            ROUTE_CONSTANTS_VARIABLE[key]
              ? ROUTE_CONSTANTS_VARIABLE[key]
              : ADMIN_ROUTE_CONST[key]
          }
        />
      </Route>
    );
  }
  if (!authStatus && path === '/') {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.LOGIN} />
      </Route>
    );
  }

  return <Route {...options} component={component} path={path} />;
};
