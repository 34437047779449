import { SUPPORT_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

const supportApiServices = {
  raiseQuery: (data: Record<string, string>) =>
    ApiService.postData(SUPPORT_URLS.REAISE_QUERY, data),
  queryList: () => ApiService.getData(SUPPORT_URLS.GET_QUERY_LIST),
};

export default supportApiServices;
