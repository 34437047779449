import { useDispatch, useSelector } from 'react-redux';
import {
  resetTwoFactorAction,
  verifyOptAction,
} from '../../../store/actions/SetPasswordAction';
import AuthTemplate from '../authTemplate/AuthTemplate';
import BackToLogin from '../authTemplate/BackToLogin';
import authCode from '../../../assets/images/auth-code.svg';
import { AUTH_REDUX_CONSTANTS } from '../../../store/reduxConstants/authReduxConstants';
import OtpInput from '../../../components/otpInput/OtpInput';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { errorNotification } from '../../../components/notifyHelper';

const Authenticatecode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [finalOtp, setFinalOtp] = useState('');
  const { userId } = useParams<Record<string, string>>();

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );
  const otpLength = 6;

  const onSubmitOtp = async (otpData: any) => {
    if (otpData && otpData.length === otpLength) {
      setFinalOtp(otpData.join(''));
    }
  };

  const onProceedClick = async () => {
    if (finalOtp.split('_').join('').length !== 6) {
      errorNotification('OTP must be six digit number');
      return;
    }
    const params = {
      userId,
      OTP: finalOtp,
      route: 'login',
      rememberMe: profileDetail?.rememberMe,
    };
    const response: any = await dispatch(verifyOptAction(params, history));
  };

  const onClickResetGoogleAuth = () => {
    dispatch(
      resetTwoFactorAction({ email: profileDetail?.email }, () =>
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
      )
    );
  };

  const getFooterArea = (): any => {
    return (
      <div className="redirect-container ">
        <div className="hr">
          <div></div>
          <div>OR</div>
          <div></div>
        </div>
        <span onClick={() => onClickResetGoogleAuth()}>
          <span className="reset-google-auth">Reset</span>{' '}
          <span className="sign-in-link">Google Auth</span>
        </span>
      </div>
    );
  };

  return (
    <AuthTemplate
      title={'Enter code from Authenticate App'}
      subTitle="Enter your code here"
      buttonTitle="Proceed"
      imageContext={authCode}
      buttonEvent={onProceedClick}
      titleClass="code-auth-title"
      footerArea={getFooterArea()}
    >
      <>
        <div className="auth-form">
          <OtpInput
            otpInputs={otpLength}
            onOtpFill={onSubmitOtp}
            otpBoxWithValueClass="border-primary"
            onFocusOtpBoxClass="border-secondary"
            onEnter={onProceedClick}
          />
        </div>
        <BackToLogin />
      </>
    </AuthTemplate>
  );
};

export default Authenticatecode;
