import { CARD_DETAIL_REDUX_CONSTANTS } from '../reduxConstants/cardDetailReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { REGISTER_REDUX_CONSTANTS } from '../reduxConstants/registerReduxConstants';

interface initialCardDetailProps {
  cardList: Record<string, Record<string, string | number | string[]>>[];
}

const initialCardDetail: initialCardDetailProps = {
  cardList: [],
};

export const cardDetailReducer = (
  state = initialCardDetail,
  action: { type: string; data: Record<string, any> }
) => {
  switch (action?.type) {
    case CARD_DETAIL_REDUX_CONSTANTS.GET_CARD_LIST:
      return {
        ...state,
        cardList: action.data,
      };

    default:
      return state;
  }
};
