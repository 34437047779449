import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import gstApiServices from '../../../services/pageServices/admin/gstApiServices';
import { GST_REDUX_CONSTANTS } from '../../reduxConstants/admin/gstReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getGstListAction = (params: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('gstListLoaderAction');
      const response = await gstApiServices.gstList(params);
      if (response.status === 200) {
        dispatch({
          type: GST_REDUX_CONSTANTS.GST_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('gstListLoaderAction');
    }
  };
};

export const addGstAction = (data: Record<string, any>) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('addGstLoaderAction');
      const response = await gstApiServices.addGst(data);
      if (response?.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addGstLoaderAction');
    }
  };
};

export const onChangeGstDataAction = (
  name: string,
  value: string | Record<string, string | undefined> | undefined
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GST_REDUX_CONSTANTS.ON_CHANGE_GST_DATA,
      data: {
        name,
        value,
      },
    });
  };
};

export const onChangeDefaultGstAction = (
  name: string,
  value: string | Record<string, string>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GST_REDUX_CONSTANTS.ON_CHANGE_DEFAULT_GST,
      data: {
        name,
        value,
      },
    });
  };
};

export const resetGstData = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GST_REDUX_CONSTANTS.RESET_DATA,
    });
  };
};

export const addDefaultGstAction = (data: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await gstApiServices.addDefaultGst(data);
      if (response?.status === 201) {
        dispatch({
          type: GST_REDUX_CONSTANTS.ADD_DEFAULT_GST,
          data: data,
        });
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getDefaultGstAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await gstApiServices.defaultGst();
      if (response.status === 200) {
        dispatch({
          type: GST_REDUX_CONSTANTS.GET_DEFAULT_GST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteGstAction = (gstId: string, cb: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await gstApiServices.deleteGst(gstId);
      if (response.status === 200) {
        successNotification(
          response.data?.message || 'Record deleted successfully'
        );
      }
      if (cb) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getSelectedGstDetailAction = (gstId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedGstDetailLoaderAction');
      const response = await gstApiServices.getGstDetailsById(gstId);
      if (response.status === 200) {
        dispatch({
          type: GST_REDUX_CONSTANTS.GET_SELECTED_GST_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getSelectedGstDetailLoaderAction');
    }
  };
};

export const updateGstDetailAction = (
  data: Record<string, string | number | undefined>,
  gstId: string,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('updateGstDetailsLoaderAction');
      const response = await gstApiServices.updateGstDetailsById(data, gstId);
      if (response.status === 200) {
        dispatch({
          type: GST_REDUX_CONSTANTS.RESET_DATA,
          data,
        });
        if (cb) {
          cb();
        }
        successNotification(`${data?.country}'s details updated successfully`);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateGstDetailsLoaderAction');
    }
  };
};
