import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { CHECKBOX_TEST_CONSTANT } from './CheckboxConst';

interface CheckBoxProps extends CheckboxProps {
  className?: string;
  label?: string;
}

const CheckBox: FC<CheckBoxProps> = ({ className, label, ...restProps }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          inputProps={{
            // @ts-ignore
            'data-testid': CHECKBOX_TEST_CONSTANT.commonCheckbox,
          }}
          icon={<span className="checkbox" />}
          checkedIcon={<span className="checked-checkbox" />}
          {...restProps}
        />
      }
      label={label ?? ''}
      className={`common-checkbox ${className}`}
    />
  );
};

export default CheckBox;
