import { displayErrors } from './../../helpers/errorNotifyHelper';
import { successNotification } from '../../components/notifyHelper';
import AuthApiServices from '../../services/apiService/authApiServices/AuthApiservices';

export const forgotPassword = async (email: string, callBack: () => void) => {
  try {
    const data = { email };
    const response = await AuthApiServices.forgotPassword(data);

    if (response?.status === 200) {
      successNotification(response?.data?.message);
      callBack();
    }
  } catch (e) {
    displayErrors(e);
  }
};
