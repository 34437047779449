import { AUTH_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

export const accountingSoftwareApiServices = {
  integrateSoftware: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      AUTH_URLS.ACCOUNTING_SOFTWARE.SOFTWARE_INTEGRATION,
      data
    ),
  validateIntegration: (params: Record<string, string | boolean>) =>
    ApiService.putData(AUTH_URLS.ACCOUNTING_SOFTWARE.VALIDATE_INTEGRATION, {
      ...params,
    }),
  chooseOrganization: (data: Record<string, string | boolean>) =>
    ApiService.putData(AUTH_URLS.ACCOUNTING_SOFTWARE.CHOOSE_ORGANIZATION, data),
  skipAccountingSoftwareIntegration: (data: Record<string, string>) =>
    ApiService.putData(
      AUTH_URLS.ACCOUNTING_SOFTWARE.SKIP_ACCOUNTING_SOFTWARE,
      data
    ),
  removeAccountingSoftware: (params: Record<string, string>) =>
    ApiService.deleteData(
      AUTH_URLS.ACCOUNTING_SOFTWARE.REMOVE_ACCOUNTING_SOFTWARE,
      { params }
    ),
};
