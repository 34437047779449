import { NOTIFICATION_URLS } from '../../../constants/urlConstants';
import ApiService from '../apiService';

const notificationApiServices = {
  connectFirebase: (token: Record<string, string>) =>
    ApiService.putData(NOTIFICATION_URLS.CONNECT_FIREBASE, token),
  getAllNotification: () =>
    ApiService.getData(NOTIFICATION_URLS.GET_ALL_NOTIFICATION),
  clearNotification: (notificationId?: string) =>
    ApiService.putData(
      NOTIFICATION_URLS.CLEAR_NOTIFICATION,
      notificationId && {
        notificationId,
      }
    ),
  disconnectFirebase: (token: Record<string, string>) =>
    ApiService.putData(NOTIFICATION_URLS.DISCONNECT_FIREBASE, token),
};

export default notificationApiServices;
