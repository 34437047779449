import Expenses from '../../../pages/invoices/expenses/Expenses';
import { EXPENSES_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/expenseReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface expenseProps {
  expenseList: Record<string, any>;
  supplier: Record<string, string>;
  uploadedExpensesResponse: Record<string, any>;
}

const initialExpenseReducer: expenseProps = {
  expenseList: {},
  supplier: {},
  uploadedExpensesResponse: {},
};

export const expenseReducer = (
  state = initialExpenseReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action?.type) {
    case EXPENSES_REDUX_CONSTANTS.EXPENSES_LIST:
      return { ...state, expenseList: action.data };

    case EXPENSES_REDUX_CONSTANTS.EXPENSE_FILTER_SUPPLIER:
      return { ...state, supplier: action.data };

    case EXPENSES_REDUX_CONSTANTS.GET_UPLOADED_EXPENSE_RESPONSE:
      return { ...state, uploadedExpensesResponse: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return { ...initialExpenseReducer };

    default:
      return { ...state };
  }
};
