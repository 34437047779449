import { SUBSCRIBERS_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

const subscribersApiServices = {
  subscribersList: (params: Record<string, any>) => {
    return ApiService.getData(SUBSCRIBERS_URLS.SUBSCRIBERS_LIST, {
      params,
    });
  },
  getSubscriberDetailById: (params: Record<string, any>) =>
    ApiService.getData(SUBSCRIBERS_URLS.VIEW_SUBSCRIBER, { params }),
  subscriberPlanDropdown: () =>
    ApiService.getData(SUBSCRIBERS_URLS.SUBSCRIBER_PLAN_DROPDOWN),
  cancelPlan: (subscriberId: string, params: Record<string, boolean>) =>
    ApiService.putData(
      `${SUBSCRIBERS_URLS.CANCEL_PLAN}${subscriberId}/cancel`,
      params
    ),
};

export default subscribersApiServices;
