import { Dispatch } from 'react';
import { successNotification } from '../../components/notifyHelper';
import { downloadAll } from '../../helpers/downloadHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import { SalesApiServices } from '../../services/pageServices/salesApiServices';
import { SALES_REDUX_CONSTANTS } from '../reduxConstants/salesReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const addSaleAction = (data: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getCategoriesInAddSaleLoaderAction');
      const response = await SalesApiServices.addSale(data);
      if (response.status === 200) {
        dispatch({
          type: SALES_REDUX_CONSTANTS.ADD_SALE,
          data: data,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getCategoriesInAddSaleLoaderAction');
    }
  };
};

export const onChangeSaleData = (
  name: string,
  value: string | Record<string, string | undefined> | undefined | null | any
) => {
  return (dispatch: any) => {
    dispatch({
      type: SALES_REDUX_CONSTANTS.ON_CHANGE_SALE_DATA,
      data: {
        name,
        value,
      },
    });
  };
};

export const resetSaleData = () => {
  return (dispatch: any) => {
    dispatch({
      type: SALES_REDUX_CONSTANTS.RESET_SALE_DATA,
    });
  };
};

export const getSalesListAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('saleListLoaderAction');
      const response = await SalesApiServices.salesList(params);
      if (response.status === 200) {
        dispatch({
          type: SALES_REDUX_CONSTANTS.SALES_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('saleListLoaderAction');
    }
  };
};

export const getSelectedSaleDetails = (saleId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedSaleDetailLoaderAction');
      const response = await SalesApiServices.getSaleDetailsById(saleId);
      if (response?.status === 200) {
        dispatch({
          type: SALES_REDUX_CONSTANTS.GET_SELECTED_SALE_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getSelectedSaleDetailLoaderAction');
    }
  };
};

export const updateSelectedSaleDetails = (
  data: Record<string, string | number | undefined>,
  saleId: string,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('updateSaleDetailsLoaderAction');
      const response = await SalesApiServices.updateSaleDetailsById(
        data,
        saleId
      );
      if (response.status === 200) {
        dispatch({
          type: SALES_REDUX_CONSTANTS.UPDATE_SELECTED_SALE_DETAIL,
          data,
        });
        successNotification(`details updated successfully`);
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateSaleDetailsLoaderAction');
    }
  };
};

export const deleteSalesAction = (ids: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        saleIds: ids,
      };

      const response = await SalesApiServices.deleteSale(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateSaleDetailsLoaderAction');
    }
  };
};

export const exportSalesExcelsheet = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await SalesApiServices.downloadSalesExcelSheet(data);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSalesPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await SalesApiServices.downloadSalesPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
