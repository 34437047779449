import { Collapse } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  onChangeActive,
  onRemoveDocument,
} from '../../store/actions/admin/uploadTermsAndPolicyAction';
import Button from '../button/Button';

type listRowProps = {
  row: Record<string, any>;
  index: number;
  openRow: Record<string, any>;
  setOpenRow: any;
  isActive: boolean | string;
  isFor: string;
};

const ListRow = (props: listRowProps) => {
  const { row, index, openRow, setOpenRow, isActive, isFor } = props;
  const dispatch = useDispatch();

  return (
    <>
      <div className="list-row">
        <div>
          <a href={row?.URL} target="_blank" className="file-list">
            {row?.name ?? '-'}
          </a>
        </div>
        <div>
          <Button
            color="success"
            variant="outlined"
            className="success-outlined-button"
            onClick={() => dispatch(onChangeActive(isFor, row?._id))}
          >
            {isActive ? 'Activated' : 'Active'}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className="outlined-primary-button "
            onClick={() => dispatch(onRemoveDocument(isFor, row?._id))}
          >
            Remove
          </Button>
        </div>
      </div>
      <div
        className={`mobile-table-row ${
          index === 0 ? 'first-mobile-table-row' : ''
        }`}
      >
        <div
          className={`table-row ${openRow === row?.id ? 'collapsed-row' : ''}`}
          onClick={(e) => {
            setOpenRow(openRow === row?._id ? -1 : row?._id);
          }}
        >
          <div>
            <a href={row?.URL} target="_blank" className="file-list">
              {row?.name ?? '-'}
            </a>
          </div>
        </div>
        <div className="table-collapse-row">
          <div style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={openRow === row?._id} timeout="auto" unmountOnExit>
              <div className="collapsed-table collapse-row list-row-button">
                {' '}
                <Button
                  color="success"
                  variant="outlined"
                  className="success-outlined-button "
                  onClick={() => dispatch(onChangeActive(isFor, row?._id))}
                >
                  {isActive ? 'Activated' : 'Active'}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  className="outlined-primary-button"
                  onClick={() => dispatch(onRemoveDocument(isFor, row?._id))}
                >
                  Remove
                </Button>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListRow;
