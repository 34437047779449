export const CREDIT_NOTES_REDUX_CONSTANTS = {
  UPLOAD_PHOTO_FOR_CREDIT: 'UPLOAD_PHOTO_FOR_CREDIT',
  ON_CHANGE_CREDIT_NOTE_DATA: 'ON_CHANGE_CREDIT_NOTE_DATA',
  GENERATE_CREDIT: 'GENERATE_CREDIT',
  RESET_CREDIT_NOTE_DATA: 'RESET_CREDIT_NOTE_DATA',
  CREDIT_NOTES_LIST: 'CREDIT_NOTES_LIST',
  INVOICE_DROPDOWN_LIST: 'INVOICE_DROPDOWN_LIST',
  GET_CREDIT_NOTE_DETAILS: 'GET_CREDIT_NOTE_DETAILS',
  GET_UPLOADED_CREDIT_NOTE_DETAILS: 'GET_UPLOADED_CREDIT_NOTE_DETAILS',
  GET_SUPPLIER_LIST: 'GET_SUPPLIER_LIST',
};
