export const moduleAccess = (role: string) => {
  switch (role) {
    case 'Admin':
    case 'Director':
    case 'Owner':
    case 'Co-owner':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { name: 'invoice-upload' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        {
          moduleName: 'invoice',
          subModules: [
            'invoice',
            'reconcile',
            'statement',
            'expense',
            'credit-note',
          ],
        },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe', 'drink-recipe'] },
        { moduleName: 'sale' },
        {
          moduleName: 'report',
          subModules: [
            'purchase',
            'sales',
            'cost-sales',
            'wages',
            'reconciled-statements',
          ],
        },
        {
          moduleName: 'account-settings',
          subModules: [
            'billing-history',
            'billing-overview',
            'software-integration',
            'user',
          ],
        },
      ];

    case 'General Manager':
    case 'Location Manager':
    case 'Supervisor':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['drink-recipe'] },
        { moduleName: 'sale' },
        {
          moduleName: 'report',
          subModules: ['wages', 'reconciled-statements'],
        },
        { moduleName: 'account-settings', subModules: ['user'] },
      ];

    case 'Assistant Manager':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['drink-recipe'] },
        { moduleName: 'sale' },
        { moduleName: 'account-settings', subModules: ['user'] },
      ];

    case 'Executive Chef':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe'] },
        { moduleName: 'report', subModules: ['purchase', 'wages'] },
        { moduleName: 'account-settings', subModules: ['user'] },
      ];

    case 'Head Chef':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe'] },
        { moduleName: 'report', subModules: ['purchase'] },
      ];

    case 'Sous Chef':
    case 'Chef':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe'] },
      ];

    case 'Store Manager':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe', 'drink-recipe'] },
        { moduleName: 'account-settings', subModules: ['user'] },
      ];

    case 'Catering Manager':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe', 'drink-recipe'] },
      ];

    case 'Beverage Manager':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['drink-recipe'] },
        { moduleName: 'report', subModules: ['purchase'] },
      ];

    case 'Food Production Manager':
    case 'Kitchen Manager':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe', 'drink-recipe'] },
        { moduleName: 'report', subModules: ['purchase'] },
      ];

    case 'Food and Beverage Director':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'invoice', subModules: ['invoice', 'statement'] },
        { moduleName: 'product-inventory' },
        { moduleName: 'recipe', subModules: ['food-recipe', 'drink-recipe'] },
        { moduleName: 'sale' },
        { moduleName: 'report', subModules: ['purchase'] },
      ];

    case 'Prep Hand':
      return [
        { moduleName: 'dashboard' },
        { moduleName: 'order' },
        { moduleName: 'supplier', subModules: ['supplier', 'product'] },
        { moduleName: 'product-inventory' },
      ];

    default:
      return [];
  }
};
