export const LOGIN_PAGE_TEST_CONSTANTS: any = {
  loginPage: 'loginPage',
  emailInput: 'emailInput',
  passwordInput: 'passwordInput',
  showHidePwd: 'showHidePwd',
  rememberMe: 'rememberMe',
  submitBtn: 'submitBtn',
  forgotPwd: 'forgotPwd',
  registerBtn: 'registerBtn',
};
