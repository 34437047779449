import { Delete } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { desktopResetFilter } from '../../assets/images/desktopResetFilter';
import { filterIcon } from '../../assets/images/filter';
import { mobileResetFilter } from '../../assets/images/mobileResetFilter';
import Button from '../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../components/customModal/CustomModal';
import CustomTable from '../../components/customTable/CustomTable';
import FilterModal from '../../components/filterModal/FilterModal';
import Loader from '../../components/loader/Loader';
import CustomMenu from '../../components/menu/Menu';
import { errorNotification } from '../../components/notifyHelper';
import { statusDropdown } from '../../constants/orderConstants';
import { resetFilterHelper } from '../../helpers/resetFilterHelper';
import { useQueryParams } from '../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../hooks/useUrlParamsUpdate';
import { getSupplierListInOrderPage } from '../../store/actions/orderAction';
import { resetfilterOnModuleChange } from '../../store/actions/listFiltersAction';
import {
  deleteOrders,
  getOrderDetailsById,
  getOrderList,
} from '../../store/actions/orderAction';

const Orders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderList, supplierList } = useSelector(
    ({ orderReducer }: Record<string, any>) => orderReducer ?? {}
  );

  const { orders } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );
  const { orderListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);

  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const { docs, headers, limit, page, pages, total } = useMemo(
    () => orderList ?? {},
    [orderList]
  );

  const updatedHeaders =
    headers?.length &&
    headers?.map((header: Record<string, string>) => {
      if (header.label === 'Order Date') {
        return { ...header, name: 'createdDate' };
      } else if (header.label === 'Time') {
        return { ...header, name: 'createdTime' };
      } else {
        return header;
      }
    });

  const filters = {
    supplierId: orders?.supplierId?._id,
    status: orders?.status,
    startDate: orders?.startDate,
    endDate: orders?.endDate,
  };

  const mobileHeaders = ['orderNumber', 'createdDate'];

  const onCreateOrderClick = () => {
    history.push('/orders/create');
  };

  const onPageChange = (page: any) => {
    dispatch(getOrderList({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getOrderList({ page: 1, limit: value, ...filters }));
  };

  const deleteTableRow = (id: string) => {
    setRowId(id);
    setOpenDeleteModal(true);
  };

  const selectAllCheckBox = () => {
    const allIds = docs.map((data: Record<string, string>) => data._id);
    if (docs?.length !== 0) {
      if (checkedIds.length === docs.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
    setRowId('');
  };

  const viewOrderDetails = (order: Record<string, any>) => {
    if (order?.status === 'Draft') {
      history.push(`/orders/draft/${order._id}`);
    } else if (order?.status === 'Completed' || order?.status === 'Received') {
      history.push(`/orders/completed/${order._id}`);
    } else {
      history.push(`/orders/placed/${order._id}`);
    }
  };

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: limit ?? paramLimit ?? 15,
      ...filters,
    };
    await dispatch(getOrderList({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteOrders(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds.length > 1 ? checkedIds.join(',') : checkedIds[0];

      if (checkedIds.length !== 0) {
        dispatch(deleteOrders(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const addMenuItems = [
    {
      name: 'Create Order',
      onClick: () => onCreateOrderClick(),
    },
  ];

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Search by Supplier',
      type: 'autocomplete',
      options: supplierList,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select Status',
      type: 'autocomplete',
      options: statusDropdown,
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: orders?.limit ?? limit ?? paramLimit ?? 15,
    supplierId: orders?.supplierId?._id,
    status: orders?.status,
    startDate: orders?.startDate
      ? new Date(orders?.startDate)?.toISOString()
      : undefined,
    endDate: orders?.endDate
      ? new Date(orders?.endDate)?.toISOString()
      : undefined,
  });

  const onDeleteAllClick = () => {
    setOpenDeleteModal(true);
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const onEditOrder = (order: Record<string, any>) => {
    dispatch(getOrderDetailsById(order?._id));
    history.push(`/orders/edit-order/edit/${order._id}`);
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getOrderList,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'orders',
      () => setOpenFilterModal(false),
      [getSupplierListInOrderPage]
    );
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: orders?.limit ?? paramLimit ?? limit ?? 15,
      ...filters,
    };
    dispatch(getOrderList({ ...params }));
    dispatch(getSupplierListInOrderPage());
    dispatch(resetfilterOnModuleChange('orders'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Orders Dashboard</div>
        <div className="button-container">
          {!_.isEmpty(orders) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(orders) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          <div className="mobile-button-container">
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="add"
              menuItems={addMenuItems}
              id="order-action"
            />
          </div>
          {/* Mobile Buttons end here */}
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={onCreateOrderClick}
          >
            Create Order
          </Button>
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(orders)}
            filterData={orders}
          >
            {filterIcon}
          </Button>
          {orderList?.docs?.length > 0 && (
            <Button
              className="list-icon-button"
              variant="outlined"
              color="primary"
              onClick={onDeleteAllClick}
            >
              <Delete />
            </Button>
          )}
        </div>
      </div>
      {orderListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={updatedHeaders}
          mobileHeaders={mobileHeaders}
          data={docs}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          isCheckbox
          onChangeCheckBox={onChangeCheckBox}
          checkedIds={checkedIds}
          selectAllCheckBox={selectAllCheckBox}
          isAction
          deleteTableRow={deleteTableRow}
          editTableRow={onEditOrder}
          listName="order"
          viewRecord={viewOrderDetails}
          primaryTextField="orderNumber"
        />
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="orders"
          closeModal={() => setOpenFilterModal(false)}
          updateListAction={getOrderList}
          filterFields={filterFields}
          defaultParams={{
            page: 1,
            limit: limit ?? paramLimit,
          }}
          filterAction={[getSupplierListInOrderPage]}
          isDateFilters
        />
      )}
      {openDeleteModal && (
        <CustomModal
          header="Delete Order"
          buttons={deleteModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
    </div>
  );
};
export default Orders;
