import { Edit } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import Button from '../../../components/button/Button';
import { audConverter } from '../../../constants/regexConstants';
import { calculation } from '../../../helpers/calculationHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  getSelectedSaleDetails,
  resetSaleData,
} from '../../../store/actions/salesAction';

export const ViewSale = () => {
  const history = useHistory();
  const { saleId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();

  const { sale } = useSelector(
    ({ salesReducer }: Record<string, any>) => salesReducer ?? {}
  );

  const salesDetailConstants = [
    {
      name: 'date',
      label: 'Sale Date',
    },
    {
      name: 'total',
      label: 'Sales Total',
    },
    {
      name: 'covers',
      label: 'Covers',
    },
    {
      name: 'averageSpend',
      label: 'Average Spend',
    },
    {
      name: 'foodSales',
      label: 'Food Sales',
    },
    {
      name: 'beveragesSales',
      label: 'Beverages Sales',
    },
  ];

  useEffect((): any => {
    dispatch(getSelectedSaleDetails(saleId));
    return () => dispatch(resetSaleData());
  }, []);

  const valueBasedOnField = (value: string) => {
    switch (value) {
      case 'date':
        return dayjs(sale?.['date']).format('DD MMMM YYYY') ?? '-';

      case 'foodSales':
      case 'beveragesSales':
        return audConverter(sale?.[value]) ?? '-';

      case 'averageSpend':
        return audConverter(
          sale?.['averageSpend'] ??
            calculation(
              'decimal',
              (sale?.['foodSales'] + sale?.['beveragesSales']) /
                sale?.['covers']
            )
        );

      case 'total':
        return audConverter(
          sale?.['averageSpend'] ??
            sale?.['foodSales'] + sale?.['beveragesSales']
        );
      default:
        return sale?.[value] ?? '-';
    }
  };
  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.SALE)}
          />
          <div className="form-title">Sales Details</div>
        </div>
        <div className="button-container">
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(`/sales/edit/${saleId}`)}
          >
            Edit Sale
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/sales/edit/${saleId}`)}
            >
              <Edit />
            </Button>
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>
      <div className="main-details-container">
        <div className="view-details-white-container details-grid details-grid-two-columns">
          {salesDetailConstants?.map((field) => (
            <div>
              <div className="detail-label">{field.label}</div>
              <div className="detail-value">
                {valueBasedOnField(field.name)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
