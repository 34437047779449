import { Icon, InputAdornment } from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../../assets/images/action-items/printer';
import CalenderIcon from '../../../assets/images/CalenderIcon';
import ClockIcon from '../../../assets/images/ClockIcon';
import BackButton from '../../../components/backButton/BackButton';
import Button from '../../../components/button/Button';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import Input from '../../../components/input/Input';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import {
  orderTableProductsHeaderConstants,
  orderTableProductsMobileHeader,
} from '../../../constants/orderTableProductsHeaderConstants';
import { isIsoFormat } from '../../../helpers/isISOFormat';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  deleteProductFromDraftOrder,
  exportOrderPdf,
  exportProductExcelsheet,
  getOrderDetailsById,
  onChangeOrderDetail,
  onClickDoneAction,
  orderReceivedValidationForCredit,
  orderReorderAction,
  resetOrderDetails,
} from '../../../store/actions/orderAction';
import { ORDER_REDUX_CONSTANTS } from '../../../store/reduxConstants/orderReduxConstants';
import { checkOrderValidation } from '../createNewOrder/checkOrderValidations';
import companyLogo from '../../../assets/images/company-logo.svg';
import useMediaQuery from '../../../hooks/MediaQuery';
import { dateCalculationHelper } from '../../../helpers/dateCalculationHelper';
import dayjs from 'dayjs';

const DraftOrderDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status, orderId } = useParams<Record<string, string>>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { order } = useSelector(
    ({ orderReducer }: Record<string, any>) => orderReducer ?? {}
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const detailRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 620px)');

  const { getOrderDetailsByIdLoaderAction, confirmOrderLoaderAction } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? true
  );

  const onClickBackButton = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
    dispatch({
      type: ORDER_REDUX_CONSTANTS.GET_ORDER_DETAILS_BY_ID,
    });
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteProductFromDraftOrder(rowId));
    setOpenDeleteModal(false);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
  };

  const onChangeDetail = (name: string, value: string) => {
    dispatch(onChangeOrderDetail({ name, value }));
    if (isSubmitted && name !== 'deliveryNotes') {
      checkOrderValidation(
        'draft',
        { name: value },
        dispatch,
        history,
        'onChange'
      );
    }
  };

  const onConfirmSend = () => {
    checkOrderValidation('confirm', order, dispatch, history, 'onSubmit');
  };

  const onClickPrint = useReactToPrint({
    content: () => {
      const cards: any = detailRef.current?.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle: any = `<div class='print-body-title'>${pageTitle[status]}</div>`;
      PrintElem.innerHTML += bodyTitle;
      PrintElem.appendChild(cards);
      const table: any = tableRef.current?.cloneNode(true);
      PrintElem.appendChild(table);
      const note: any = noteRef.current?.cloneNode(true);
      PrintElem.appendChild(note);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 0mm; } 
    @media print { 
      html, body { overflow: hidden;}
      body { -webkit-print-color-adjust: exact; padding: 0 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export Excel',
      onClick: () => dispatch(exportProductExcelsheet(orderId)),
    },
    {
      icon: exportPdfIcon,
      name: 'Export PDF',
      onClick: () => dispatch(exportOrderPdf(orderId)),
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => onClickPrint(),
    },
  ];

  const onClickReceivedOrder = () => {
    dispatch(orderReceivedValidationForCredit(orderId, history));
    // dispatch(receivedOredrAction(orderId, history));
  };

  const onClickDone = async () => {
    await dispatch(onClickDoneAction(orderId));
    await dispatch(getOrderDetailsById(orderId));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  useEffect(() => {
    dispatch(getOrderDetailsById(orderId));
  }, [orderId]);

  useEffect(() => {
    dispatch(getOrderDetailsById(orderId));
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      dispatch(resetOrderDetails());
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const pageTitle: Record<string, string> = {
    draft: 'Draft Order Details',
    placed: 'View Order Details',
    completed: 'Received Order Details',
  };

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton onClick={onClickBackButton} />
          <div className="form-title">{pageTitle[status]}</div>
        </div>
        {status !== 'draft' && (
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="supplier-action"
          />
        )}
      </div>
      {getOrderDetailsByIdLoaderAction ? (
        <Loader />
      ) : (
        <>
          <div ref={detailRef}>
            <div className="main-details-container">
              <div className="details-block-title">Order Details</div>
              <div className="view-details-white-container details-grid details-grid-three-columns">
                <div>
                  <div className="detail-label">Supplier</div>
                  <div className="detail-value">
                    {order?.supplierId?.name ?? '-'}
                  </div>
                </div>
                <div>
                  <div className="detail-label">Order Status</div>
                  <div className={`status-tag ${order?.status?.toLowerCase()}`}>
                    {order?.status ?? '-'}
                  </div>
                </div>
                <div>
                  <div className="detail-label">Order ID</div>
                  <div className="detail-value">
                    {order?.orderNumber ?? '-'}
                  </div>
                </div>
                <div>
                  <div className="detail-label">Order By</div>
                  <div className="detail-value">
                    {`${order?.orderedBy?.firstName} ${order?.orderedBy?.lastName}` ??
                      '-'}
                  </div>
                </div>
                <div>
                  <div className="detail-label">User Contact Number</div>
                  <div className="detail-value">
                    {order?.orderedBy?.contactNumber ?? '-'}
                  </div>
                </div>
                <div>
                  <div className="detail-label">Order Date</div>
                  <div className="detail-value">
                    {order?.orderDate
                      ? dayjs(
                          dateCalculationHelper(
                            order?.orderDate,
                            profileDetail?.timeZoneOffSet
                          )
                        ).format('DD MMM YYYY')
                      : '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="order-delivery-details">
              <div className="details-block-title">Delivery Details</div>

              <div className="main-details-container">
                <div className="view-details-white-container details-grid details-grid-two-columns">
                  <div>
                    <div className="detail-label">Delivery Date</div>
                    <div className="detail-value">
                      {order?.deliveryDate
                        ? dayjs(
                            dateCalculationHelper(
                              order?.deliveryDate,
                              profileDetail?.timeZoneOffSet
                            )
                          ).format('DD MMM YYYY')
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div className="detail-label">Delivery Time</div>
                    <div className="detail-value">
                      {isIsoFormat(order?.deliveryTime)
                        ? dayjs(
                            dateCalculationHelper(
                              order?.deliveryTime,
                              profileDetail?.timeZoneOffSet
                            )
                          ).format('hh:mm A')
                        : '-'}
                    </div>
                  </div>
                  {order?.deliveredBy && (
                    <div>
                      <div className="detail-label">Delivered By</div>
                      <div className="detail-value">{order?.deliveredBy}</div>
                    </div>
                  )}
                  {order?.checkedBy && (
                    <div>
                      <div className="detail-label">Checked By</div>
                      <div className="detail-value">{order.checkedBy}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-details-container draft-order-details-product-list-container">
            <div className="details-block-title products-table-title">
              Products
            </div>
            <CustomTable
              className="draft-order-details-table"
              headers={orderTableProductsHeaderConstants}
              mobileHeaders={orderTableProductsMobileHeader}
              data={order?.products}
              isPagination={false}
              isEditable={false}
              isDeleteAction={false}
            />
          </div>

          <div className="main-details-container" ref={noteRef}>
            {status === 'draft' ? (
              displayNote ? (
                <div className="creditnote-container">
                  <div className="details-block-title">Notes</div>
                  <Input
                    variant="filled"
                    multiline
                    placeholder="Notes"
                    rows={4}
                    value={order?.notes}
                    onChange={(e) => onChangeDetail('notes', e.target.value)}
                  />
                </div>
              ) : (
                <>
                  {order?.notes && (
                    <div className="view-details-white-container creditnote-container draft-note-container">
                      <div className="detail-label">Notes</div>
                      <div className="detail-value details-instruction-container">
                        {order?.notes ?? '-'}
                      </div>
                    </div>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setDisplayNote(true)}
                  >
                    {order?.notes ? 'Edit Note' : 'Add Note'}
                  </Button>
                </>
              )
            ) : (
              order?.notes && (
                <div className="view-details-white-container creditnote-container">
                  <div className="detail-label">Notes</div>
                  <div className="detail-value details-instruction-container">
                    {order?.notes ?? '-'}
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}

      {order?.status === 'Received' && (
        <div>
          <div className="title">Signature</div>
          <div className="signature-container">
            <img src={order?.signatureURL} />
          </div>
        </div>
      )}

      <div className="main-details-container">
        {status === 'draft' && (
          <div className="form-button-row">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.ORDER)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                history.push(`/orders/edit-order/edit/${order._id}`)
              }
            >
              Edit Order
            </Button>
            <Button
              color="primary"
              loader={confirmOrderLoaderAction}
              variant="contained"
              onClick={onConfirmSend}
            >
              Confirm & Send
            </Button>
          </div>
        )}

        {status === 'placed' && (
          <div className="form-button-row">
            {order?.allowedToAddOn && (
              <>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    history.push(`/orders/edit-order/addOn/${order._id}`)
                  }
                >
                  Order Add On
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={onClickDone}
                >
                  Done
                </Button>
              </>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(orderReorderAction(orderId, history))}
            >
              Reorder
            </Button>
          </div>
        )}

        {status === 'completed' && order.status === 'Completed' && (
          <div className="form-button-row">
            <Button
              color="primary"
              variant="contained"
              onClick={onClickReceivedOrder}
            >
              Order Received
            </Button>
          </div>
        )}
      </div>
      {openDeleteModal && (
        <CustomModal
          header="Delete Product"
          buttons={deleteModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete this item?
          </span>
        </CustomModal>
      )}
      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {order?.products?.length > 0 && (
          <>
            <div className="print-header-table statement-header">
              {orderTableProductsHeaderConstants?.map(
                (header: Record<string, string>) => (
                  <span>{header.label}</span>
                )
              )}
            </div>
            <div className="print-table-body">
              {order?.products?.map((product: Record<string, any>) => (
                <div className="print-table-row statememt-row">
                  <span>{product.productCode ?? '-'}</span>
                  <span>{product.name ?? '-'}</span>
                  <span>{product.quantity ?? '-'}</span>
                  <span>
                    {product.unitId?.name?.length > 0
                      ? product.unitId?.name
                      : '-'}
                  </span>
                  <span>{product.weight ?? '-'}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default DraftOrderDetails;
