import { BILLING_HISTORY_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/billinghistoryReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface billingHistoryInitialStateProps {
  billingHistoryList: Record<string, string | number | Record<string, string>>;
}

const initialReducer: billingHistoryInitialStateProps = {
  billingHistoryList: {},
};

export const billingHistoryReducer = (
  state = initialReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case BILLING_HISTORY_REDUX_CONSTANTS.GET_BILLING_HISTORY_LIST:
      return { ...state, billingHistoryList: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialReducer,
      };

    default:
      return state;
  }
};
