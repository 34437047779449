import { RouteConstantsProps } from '../routes/RouteConstants';

export interface AccessModuleProps {
  name: string;
  hasSubModule: boolean;
  subModules: string[];
}

export const urlManagementHelper = (
  accessModuleList: AccessModuleProps[],
  routeConstantsList: RouteConstantsProps[]
) => {
  const routeConstants = routeConstantsList.reduce(
    (acc: RouteConstantsProps[], route: RouteConstantsProps) => {
      if (!route.module) {
        acc.push(route);
        return acc;
      }
      const foundModule = accessModuleList?.find((module: AccessModuleProps) =>
        route.module?.includes(module.name)
      );

      if (foundModule) {
        if (route.submodule) {
          route.submodule.forEach(
            (submodule) =>
              foundModule.subModules.includes(submodule) && acc.push(route)
          );
        } else acc.push(route);
      }
      return acc;
    },
    []
  );

  return routeConstants;
};
