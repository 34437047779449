import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import persistedReducer from './RootReducer';

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export const persistStoreData = persistStore(store);
