export const requestCredit = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.90476 8.57129C0.857143 8.57129 0 9.42843 0 10.4761V17.1427C0 18.1903 0.857143 19.0475 1.90476 19.0475C2.95238 19.0475 3.80952 18.1903 3.80952 17.1427V10.4761C3.80952 9.42843 2.95238 8.57129 1.90476 8.57129Z"
      fill="#F21D86"
    />
    <path
      d="M14.2853 0C11.6568 0 9.52344 2.13333 9.52344 4.7619C9.52344 7.39048 11.6568 9.52381 14.2853 9.52381C16.9139 9.52381 19.0472 7.39048 19.0472 4.7619C19.0472 2.13333 16.9139 0 14.2853 0ZM14.9568 7.6619V7.9381C14.9568 8.28571 14.6711 8.57143 14.3234 8.57143H14.3187C13.9711 8.57143 13.6853 8.28571 13.6853 7.9381V7.65238C13.052 7.51905 12.4901 7.17143 12.252 6.58571C12.1425 6.32381 12.3472 6.03333 12.633 6.03333H12.7472C12.9234 6.03333 13.0663 6.15238 13.133 6.31905C13.2711 6.67619 13.633 6.92381 14.3282 6.92381C15.2615 6.92381 15.4711 6.45714 15.4711 6.16667C15.4711 5.77143 15.2615 5.4 14.1996 5.14762C13.0187 4.86191 12.2092 4.37619 12.2092 3.4C12.2092 2.58095 12.8711 2.04762 13.6901 1.87143V1.58571C13.6901 1.2381 13.9758 0.952381 14.3234 0.952381H14.3282C14.6758 0.952381 14.9615 1.2381 14.9615 1.58571V1.88095C15.6187 2.04286 16.033 2.45238 16.2139 2.95714C16.3092 3.21905 16.1092 3.49524 15.8282 3.49524H15.7044C15.5282 3.49524 15.3853 3.37143 15.3377 3.2C15.2282 2.8381 14.9282 2.60476 14.3282 2.60476C13.6139 2.60476 13.1853 2.92857 13.1853 3.38571C13.1853 3.78571 13.4949 4.04762 14.4568 4.29524C15.4187 4.54286 16.4472 4.95714 16.4472 6.15714C16.4377 7.02857 15.7853 7.50476 14.9568 7.6619Z"
      fill="#F21D86"
    />
    <path
      d="M18.0863 14.2856H11.5815C11.4767 14.2856 11.372 14.2665 11.2672 14.2284L9.86722 13.7427C9.61961 13.657 9.4958 13.3903 9.58151 13.1427C9.66723 12.8951 9.94342 12.7618 10.191 12.857L11.2577 13.2665C11.3625 13.3046 11.4863 13.3332 11.6006 13.3332H14.1053C14.7244 13.3332 15.2291 12.8284 15.2291 12.2094C15.2291 11.7427 14.9339 11.3237 14.4958 11.1522L7.90532 8.6951C7.6958 8.60938 7.46723 8.57129 7.23865 8.57129H5.71484V17.1618L11.7815 18.8856C12.172 18.9999 12.591 18.9808 12.972 18.838L20.0006 16.1903C20.0006 15.1332 19.1434 14.2856 18.0863 14.2856Z"
      fill="#F21D86"
    />
  </svg>
);
