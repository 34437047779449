import { Add, Delete } from '@mui/icons-material';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { importIcon } from '../../../assets/images/action-items/importIcon';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { filterIcon } from '../../../assets/images/filter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import Button from '../../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import FilterModal from '../../../components/filterModal/FilterModal';
import ImportCsv from '../../../components/importCsv/ImportCsv';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { errorNotification } from '../../../components/notifyHelper';
import { levelOneRole } from '../../../constants/levelOneRoleConstants';
import { ACCOUNT_SETTINGS_URLS } from '../../../constants/urlConstants';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import {
  deleteUsers,
  getUsersList,
  getModuleListAction,
  updateUserStatusAction,
} from '../../../store/actions/accountSettingsActions/accountSettingsUsersActions';
import { getUserRoles } from '../../../store/actions/commonAction';
import {
  resetfilterOnModuleChange,
  saveAppliedFilters,
} from '../../../store/actions/listFiltersAction';
import { UserType } from '../../../store/reducers/accountSettingsReducer/accountSettingsUsersReducer';

const Users: FC = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [rowId, setRowId] = useState<string>('');
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);
  const [importModal, setImportModal] = useState<boolean>(false);
  const { usersList } = useSelector(
    ({ accountSettingsUsersReducer }: Record<string, any>) =>
      accountSettingsUsersReducer ?? {}
  );
  const { userRoles } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const { users } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { userListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const { docs, headers, limit, page, pages, total } = useMemo(
    () => usersList ?? {},
    [usersList]
  );

  const afterFileUploadSuccess = () => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getUsersList({ ...params }));
  };

  const selectAllCheckBox = () => {
    const allIds = docs.map((data: UserType) => data?._id);
    if (docs?.length !== 0) {
      if (checkedIds.length === docs.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };
  /* add only those database headers, which you want to see in mobile row(not in collapsible part of the table) */
  const mobileHeaders = ['firstName', 'lastName'];

  const editTableRow = (val: Record<string, any>) => {
    history.push(`/account-settings/users/edit/${val?._id}`);
  };

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
    setRowId('');
  };

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: limit ?? paramLimit ?? 15,
      ...filters,
    };
    await dispatch(getUsersList({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteUsers(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds.length > 1 ? checkedIds.join(',') : checkedIds[0];

      if (checkedIds.length !== 0) {
        dispatch(deleteUsers(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const onDeleteAllClick = () => {
    setOpenDeleteModal(true);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const actionItems = [
    {
      icon: importIcon,
      name: 'Import Users',
      onClick: () => setImportModal(true),
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getUsersList({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getUsersList({ page: 1, limit: value, ...filters }));
  };

  const filterFields = [
    {
      name: 'searchString',
      label: 'User',
      placeholder: 'Search by User Name',
    },
    {
      name: 'role',
      label: 'User Role',
      placeholder: 'Select User Role',
      options: userRoles?.allRoles,
      type: 'autocomplete',
      className: 'filter-field-gap',
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getUsersList,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'users',
      () => setOpenFilterModal(false)
    );
  };

  const onChangeSwitch = async (data: Record<string, any>) => {
    const { _id, isActive } = data;
    const status = isActive === 'Active' ? false : true;
    const updated = await dispatch(
      updateUserStatusAction({ isActive: status }, _id)
    );
    if (updated) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };
      dispatch(getUsersList({ ...params }));
    }
  };

  const filters = {
    role: users?.role,
    searchString: users?.searchString,
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: users?.limit ?? limit ?? paramLimit ?? 15,
      ...filters,
    };
    dispatch(getModuleListAction());
    dispatch(getUsersList({ ...params }));
    dispatch(getUserRoles());
    dispatch(resetfilterOnModuleChange('users'));
  }, []);

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: users?.limit ?? limit ?? paramLimit ?? 15,
    role: users?.role,
    searchString: users?.searchString,
  });

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Users</div>
        <div className="button-container">
          {!_.isEmpty(users) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(users) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {levelOneRole.includes(profileDetail.role) && (
            <div className="mobile-button-container">
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => history.push('/account-settings/users/add')}
              >
                <Add />
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          {levelOneRole.includes(profileDetail.role) && (
            <Button
              variant="contained"
              className="desktop-button"
              color="primary"
              onClick={() => history.push('/account-settings/users/add')}
            >
              Invite User
            </Button>
          )}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(users)}
            filterData={users}
            isFor="User"
          >
            {filterIcon}
          </Button>
          {levelOneRole.includes(profileDetail.role) && (
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="more_vert"
              menuItems={actionItems}
              id="user-action"
            />
          )}
          {levelOneRole.includes(profileDetail.role) && (
            <Button
              className="list-icon-button"
              variant="outlined"
              color="primary"
              onClick={onDeleteAllClick}
            >
              <Delete />
            </Button>
          )}
        </div>
      </div>
      {userListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isCheckbox={levelOneRole.includes(profileDetail.role) ? true : false}
          onChangeCheckBox={onChangeCheckBox}
          checkedIds={checkedIds}
          selectAllCheckBox={selectAllCheckBox}
          isAction
          isDeleteAction={
            levelOneRole.includes(profileDetail.role) ? true : false
          }
          isEditable={levelOneRole.includes(profileDetail.role) ? true : false}
          deleteTableRow={deleteTableRow}
          editTableRow={editTableRow}
          viewRecord={(user: Record<string, any>) => {
            history.push(`/account-settings/users/view/${user?._id}`);
          }}
          listName="user"
          onChangeSwitch={onChangeSwitch}
        />
      )}
      {openDeleteModal && (
        <CustomModal
          header="Delete User"
          buttons={deleteModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="users"
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getUsersList}
          defaultParams={{
            page: 1,
            limit: limit ?? paramLimit,
          }}
        />
      )}

      {importModal && (
        <ImportCsv
          setImportModal={setImportModal}
          className="import-users-modal"
          fileNameKey="user-csv-file"
          afterFileUploadSuccess={afterFileUploadSuccess}
          actionUrl={ACCOUNT_SETTINGS_URLS.USERS_URLS.IMPORT_USERS}
          csvFor="user"
        />
      )}
    </div>
  );
};

export default Users;
