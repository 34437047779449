import { recipeCalculationAction } from '../store/actions/recipeActions';
import { calculation } from './calculationHelper';

export const recipeCalculationHelper = (
  recipeDetails: Record<string, any>,
  dispatch: any
) => {
  let recipeCost = 0;
  let portionCost = recipeDetails?.portionCost;
  let salePrice = recipeDetails?.salePrice;
  let portionCostPercentage = recipeDetails?.portionCostPercentage;
  let profitGrossMargin = recipeDetails?.profitGrossMargin;
  let foodCost = recipeDetails?.foodCostPercentage / 100;

  recipeDetails.products?.forEach(
    (item: Record<string, any>) => (recipeCost += item.totalPrice)
  );
  recipeCost = parseInt(recipeCost.toString());

  portionCost =
    recipeDetails.numberOfPortions > 0
      ? calculation('decimal', recipeCost / recipeDetails?.numberOfPortions)
      : 0;

  salePrice =
    portionCost > 0 ? calculation('decimal', portionCost / foodCost) : 0;

  portionCostPercentage =
    portionCost > 0 && salePrice > 0
      ? calculation('decimal', (portionCost / salePrice) * 100)
      : 0;

  const grossProfit = salePrice - portionCost;
  profitGrossMargin =
    grossProfit > 0 && salePrice > 0
      ? calculation('decimal', (grossProfit / salePrice) * 100)
      : 0;

  const finalData = {
    recipeCost,
    portionCost,
    salePrice,
    portionCostPercentage,
    profitGrossMargin,
  };

  dispatch(recipeCalculationAction(finalData));
};
