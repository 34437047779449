import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';

interface HeaderDateTimeProps {
  className?: string;
}

const HeaderDateTime: FC<HeaderDateTimeProps> = (props) => {
  const { className } = props;
  let [date, setDate] = useState(dayjs().format('D MMMM, YYYY'));
  let [time, setTime] = useState(dayjs().format('h:mm A'));

  function getDateTime() {
    setDate(dayjs().format('D MMMM, YYYY'));
    setTime(dayjs().format('h:mm A'));
  }

  useEffect(() => {
    setInterval(getDateTime, 1000);
  }, []);

  return (
    <div className={`header-time-container ${className}`}>
      <span className="day-name">Today</span>
      <span>{time}</span>
      <span>{date}</span>
    </div>
  );
};

export default HeaderDateTime;
