import { GST_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

const gstApiServices = {
  gstList: (params: Record<string, any>) =>
    ApiService.getData(GST_URLS.GST_LIST, { params }),
  addGst: (data: Record<string, any>) =>
    ApiService.postData(GST_URLS.ADD_GST, data),
  addDefaultGst: (data: Record<string, any>) =>
    ApiService.postData(GST_URLS.ADD_DEFAULT_GST, data),
  defaultGst: () => ApiService.getData(GST_URLS.DEFAULT_GST),
  deleteGst: (gstId: string) =>
    ApiService.deleteData(`${GST_URLS.DELETE_GST}${gstId}`),
  getGstDetailsById: (gstId: string) =>
    ApiService.getData(`${GST_URLS.GET_GST_DETAIL_BY_ID}${gstId}`),
  updateGstDetailsById: (
    data: Record<string, string | number | undefined | null>,
    gstId: string
  ) => ApiService.putData(`${GST_URLS.UPDATE_GST_DETAIL_BY_ID}${gstId}`, data),
};

export default gstApiServices;
