import loader from '../../assets/images/loader.gif';

const Loader = () => {
  return (
    <div className="loader-container">
      <img alt="loader.gif" src={loader} />
    </div>
  );
};

export default Loader;
