import { useHistory } from 'react-router';
import Button from '../../../components/button/Button';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import loader from '../../../assets/images/loader.gif';

const PaymentAwaiting = () => {
  const history = useHistory();
  return (
    <div className="payment-rocessin-page-container">
      <div className="loader">
        <img alt="loader.gif" src={loader} />
        <div className="processing-message">
          Your recurring payment is under process,
          <br /> Please login after some time
        </div>
        <div className="login-button-container">
          <Button
            color="primary"
            variant={'contained'}
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)}
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PaymentAwaiting;
