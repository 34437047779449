import { Add, Delete } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { exportExcelIcon } from '../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../assets/images/action-items/printer';
import { filterIcon } from '../../assets/images/filter';
import Button from '../../components/button/Button';
import CustomMenu from '../../components/menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import CustomTable from '../../components/customTable/CustomTable';
import {
  deleteSalesAction,
  exportSalesExcelsheet,
  exportSalesPdf,
  getSalesListAction,
} from '../../store/actions/salesAction';
import { useQueryParams } from '../../hooks/getQueryParamHook';
import FilterModal from '../../components/filterModal/FilterModal';
import Loader from '../../components/loader/Loader';
import { useUrlParamsUpdate } from '../../hooks/useUrlParamsUpdate';
import CustomModal, {
  ButtonType,
} from '../../components/customModal/CustomModal';
import { errorNotification } from '../../components/notifyHelper';
import {
  resetfilterOnModuleChange,
  saveAppliedFilters,
} from '../../store/actions/listFiltersAction';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import companyLogo from '../../assets/images/company-logo.svg';
import { format, parseISO } from 'date-fns';
import { audConverter } from '../../constants/regexConstants';
import useMediaQuery from '../../hooks/MediaQuery';
import { dateLableHelper } from '../../helpers/dateLabelHelper';
import { resetFilterHelper } from '../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../assets/images/mobileResetFilter';
import _ from 'lodash';

const Sales = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [figureDate, setFigureDate] = useState('');
  const [rowId, setRowId] = useState<string>('');
  const tableRef = useRef<HTMLDivElement>(null);
  const salesListRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 620px)');

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const { saleListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { sales } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { saleList } = useSelector(
    ({ salesReducer }: Record<string, any>) => salesReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => saleList ?? [],
    [saleList]
  );

  const mobileHeaders = ['date', 'total'];

  const filters = {
    startDate: sales?.startDate,
    endDate: sales?.endDate,
  };

  const exportToExcel = () => {
    dispatch(
      exportSalesExcelsheet({
        ...filters,
      })
    );
  };

  const exportToPdf = () => {
    dispatch(
      exportSalesPdf({
        ...filters,
      })
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  const onClickPrint = useReactToPrint({
    content: () => {
      const cards: any = salesListRef.current?.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle: any = `<div class='print-body-title'>Sales</div>`;
      PrintElem.innerHTML += bodyTitle;
      PrintElem.appendChild(cards);
      const table: any = tableRef.current?.cloneNode(true);
      PrintElem.appendChild(table);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 0mm; } 
    @media print { 
      body { -webkit-print-color-adjust: exact; padding: 0 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        if (docs?.length > 0) onClickPrint();
      },
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getSalesListAction({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (limit: any) => {
    dispatch(getSalesListAction({ page: 1, limit: limit, ...filters }));
  };

  const filterFields = [
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select date',
      type: 'date',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];
  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    await dispatch(getSalesListAction({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteSalesAction(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds.length > 1 ? checkedIds.join(',') : checkedIds[0];
      if (checkedIds.length !== 0) {
        dispatch(deleteSalesAction(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
  };

  const onDeleteAllClick = () => {
    setOpenDeleteModal(true);
  };

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const selectAllCheckBox = () => {
    const allIds = docs.map((data: Record<string, any>) => data._id);
    if (docs.length !== 0) {
      if (checkedIds.length === docs.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getSalesListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'sales',
      () => setOpenFilterModal(false)
    );
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: sales?.limit ?? limit ?? paramLimit ?? 15,
    startDate: sales?.startDate
      ? new Date(sales?.startDate)?.toISOString()
      : undefined,
    endDate: sales?.endDate
      ? new Date(sales?.endDate)?.toISOString()
      : undefined,
  });

  const onEditSale = (sale: Record<string, any>) => {
    history.push(`/sales/edit/${sale._id}`);
  };

  useEffect(() => {
    const date = dateLableHelper(
      {
        startDate: sales?.startDate,
        endDate: sales?.endDate,
      },
      'sales',
      profileDetail.timeZoneOffSet
    );
    setFigureDate(date);
  }, [sales?.startDate, sales?.endDate]);

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: sales?.limit ?? paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getSalesListAction({ ...params }));
    dispatch(resetfilterOnModuleChange('sales'));
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Sales</div>
        <div className="button-container">
          {!_.isEmpty(sales) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(sales) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={() => {
              history.push('/sales/add');
            }}
          >
            Add Sale
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => history.push('/sales/add')}
            >
              <Add />
            </Button>
          </div>
          {/* mobile button end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(sales)}
            filterData={sales}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="sales-action"
          ></CustomMenu>
          {saleList?.docs?.length > 0 && (
            <Button
              className="list-icon-button"
              variant="outlined"
              color="primary"
              onClick={onDeleteAllClick}
            >
              <Delete />
            </Button>
          )}
        </div>
      </div>
      <div className="sales-list-container">
        <div className="sales-white-block-row " ref={salesListRef}>
          <div className="white-block">
            <div className="sales-date-lable">{figureDate}</div>
            <div>{audConverter(saleList?.foodsFigure ?? 0)} </div>
            <div>Food Sales</div>
          </div>
          <div className="white-block">
            <div className="sales-date-lable">{figureDate}</div>
            <div>{audConverter(saleList?.beveragesFigure ?? 0)}</div>
            <div>Beverages Sales</div>
          </div>
          <div className="white-block">
            <div className="sales-date-lable">{figureDate}</div>
            <div>{audConverter(saleList?.totalSales ?? 0)}</div>
            <div>Total Sales</div>
          </div>
        </div>
        {saleListLoaderAction ? (
          <Loader />
        ) : (
          <CustomTable
            headers={headers}
            mobileHeaders={mobileHeaders}
            data={docs}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            limit={limit}
            page={page}
            pages={pages}
            total={total}
            isCheckbox
            selectAllCheckBox={selectAllCheckBox}
            checkedIds={checkedIds}
            onChangeCheckBox={onChangeCheckBox}
            isAction
            deleteTableRow={deleteTableRow}
            editTableRow={onEditSale}
            viewRecord={(sale: Record<string, any>) => {
              history.push(`/sales/view/${sale._id}`);
            }}
          />
        )}
      </div>
      {openFilterModal && (
        <FilterModal
          requestFrom="sales"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getSalesListAction}
          isDateFilters
        />
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Sale"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {docs?.length > 0 && (
          <>
            <div className="print-header-table">
              {headers?.map((header: Record<string, string>) => (
                <span>{header.label}</span>
              ))}
            </div>
            <div className="print-table-body">
              {docs?.map((data: Record<string, any>) => (
                <div className="print-table-row">
                  <span>{format(parseISO(data.date), 'dd MMM yyyy')}</span>
                  <span>{audConverter(data.total)}</span>
                  <span>{data.covers}</span>
                  <span>{audConverter(data.averageSpend)}</span>
                  <span>{audConverter(data.foodSales)}</span>
                  <span>{audConverter(data.beveragesSales)}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default Sales;
