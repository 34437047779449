import { saveAppliedFilters } from '../store/actions/listFiltersAction';

export const resetFilterHelper = (
  dispatch: any,
  updateListAction: (dispatch: any) => void,
  defaultParams: Record<string, any>,
  requestFrom: string,
  closeModal: () => void,
  filterAction?: any
) => {
  dispatch(updateListAction(defaultParams));
  filterAction?.forEach((action: (dispatch: any) => void) =>
    dispatch(action(defaultParams))
  );
  dispatch(saveAppliedFilters(requestFrom, {}));
  closeModal();
};
