import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import { useHistory } from 'react-router';
import {
  deletePlan,
  getAdminPlanList,
} from '../../../store/actions/admin/adminPlanActions';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import Loader from '../../../components/loader/Loader';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { Add } from '@mui/icons-material';
import {
  resetfilterOnModuleChange,
  saveAppliedFilters,
} from '../../../store/actions/listFiltersAction';

const Plan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    page: paramPage,
    limit: paramLimit,
    planType: paramPlanType,
  } = useQueryParams();
  const [planType, setPlanType] = useState<string>('Monthly');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [rowId, setRowId] = useState<string>('');

  const { adminPlanListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { plan } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { adminPlanList } = useSelector(
    ({ adminPlanReducer }: Record<string, any>) => adminPlanReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => adminPlanList ?? {},
    [adminPlanList]
  );

  const filter = {
    planType: plan?.planType ?? paramPlanType ?? 'Monthly',
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: adminPlanList?.limit ?? limit ?? paramLimit ?? 15,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: adminPlanList?.limit ?? limit ?? paramLimit ?? 15,
      ...filter,
    };
    setPlanType(plan?.planType ?? paramPlanType ?? 'Monthly');
    dispatch(getAdminPlanList(params));
    dispatch(resetfilterOnModuleChange('plan'));
  }, []);

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filter,
    };
    await dispatch(getAdminPlanList({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    if (rowId) {
      dispatch(deletePlan(rowId, afterSuccessfulDeletion));
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];
  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
  };

  const onChangeLimit = (value: any) => {
    dispatch(getAdminPlanList({ page: 1, limit: value, ...filter }));
  };

  const onPageChange = (page: any) => {
    dispatch(getAdminPlanList({ page: page, limit, ...filter }));
  };

  const mobileHeaders = ['name', 'price'];

  const onClickAddPlan = () => {
    history.push('/plan/add');
  };

  const onEditPlan = (plan: Record<string, any>) => {
    history.push(`plan/edit/${plan?._id}`);
  };

  const onDeletePlan = (id: string) => {
    setRowId(id);
    setOpenDeleteModal(true);
  };

  const onChangeSwitch = (type: string) => {
    setPlanType(type);
    const params = {
      page: paramPage ?? page ?? 1,
      limit: adminPlanList?.limit ?? limit ?? paramLimit ?? 15,
      planType: type,
    };
    dispatch(saveAppliedFilters('plan', { planType: type }));
    dispatch(getAdminPlanList(params));
  };

  const addMenuItems = [
    {
      name: 'Add Plan',
      onClick: () => onClickAddPlan(),
    },
  ];

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: limit ?? paramLimit ?? 15,
    planType: plan?.planType ?? paramPlanType ?? 'Monthly',
  });

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Plan List</div>
        <div className="button-container">
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => onClickAddPlan()}
          >
            Add Plan
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => onClickAddPlan()}
            >
              <Add />
            </Button>
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>
      <div className="plan-container">
        <div className="plan-type-menu">
          <span
            className={planType === 'Monthly' ? 'selected-plan-type' : ''}
            onClick={() => onChangeSwitch('Monthly')}
          >
            Monthly
          </span>
          <span
            className={planType === 'Yearly' ? 'selected-plan-type' : ''}
            onClick={() => onChangeSwitch('Yearly')}
          >
            Yearly
          </span>
          <div className="selected-plan" />
        </div>
        {adminPlanListLoaderAction ? (
          <Loader />
        ) : (
          <CustomTable
            headers={headers}
            mobileHeaders={mobileHeaders}
            data={docs}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            limit={limit}
            page={page}
            pages={pages}
            total={total}
            isAction
            isDeleteAction={true}
            isEditable={true}
            viewRecord={(plan: Record<string, Record<string, string>>) => {
              history.push(`/plan/view/${plan?._id}`);
            }}
            deleteTableRow={onDeletePlan}
            editTableRow={onEditPlan}
            message={`No ${planType.toLowerCase()} plans available at the moment`}
          />
        )}
      </div>
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Plan"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default Plan;
