export const EMAIL_ADDRESS_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MOBILE_NUMBER_REGEX = /^([+0]?)(?:\d\s?){6,15}$/;
export const NUMBER_REGEX = /^\d+$/;
export const SPECIAL_CHARACTER_REGEX = /[^A-Za-z 0-9]/;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const DECIMAL_REGEX = /^[0-9]*(\.\d{0,2})?$/;
export const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9 ]+$/;

export const audConverter = (number: number) => {
  const numberToUSD = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return numberToUSD.format(number);
};
export const ONLY_NUMBER_HYPHEN_REGEX = /^[0-9-\-]+$/;
export const ALPHA_REGEX = /^[a-zA-Z\s]*$/;
export const ABN_REGEX = /^(\d *?){11}$/;
export const FLOAT_NUMBER_REGEX =
  /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
export const NONZERO_NUMBER_REGEX = /^[0-9]*[1-9][0-9]*$/;
export const POSTAL_CODE_REGEX = /^[0-9]{1,6}$/;
export const ALPHA_WITH_MINIMUM_LENGTH = /^[a-zA-Z\s]{2,}$/;
export const ALPHA_WITH_MAXIMUM_LENGTH = /^[a-zA-Z\s]{2,50}$/;
export const ADDRESS_SIGN_VALIDATION_IN_EMAIL = /@/;
export const ALPHA_HYPHEN_REGEX = /^[a-zA-Z-\s]*$/;
export const ALPHA_HYPHEN_WITH_MINIMUM_LENGTH = /^[a-zA-Z-\s]{2,}$/;
export const ALPHA_HYPHEN_WITH_MAXIMUM_LENGTH = /^[a-zA-Z-\s]{2,50}$/;
export const INVOICE_NUMBER_REGEX = /^[a-zA-Z0-9*\/-]{1,13}$/g;
export const PLAN_PRICE_REGEX = /^(?=.*[1-9])\d{0,4}(\.\d+)?$/;
export const NUMBER_DOT_REGEX = /^[0-9.,]*$/;
