import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ADMIN_PLAN_REDUX_CONSTANTS } from '../../reduxConstants/admin/adminPlanReduxConstants';
import adminPlansApiServices from '../../../services/pageServices/admin/adminPlansApiServices';
import { successNotification } from '../../../components/notifyHelper';
import { Dispatch } from 'react';

export const getAdminPlanList = (params: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('adminPlanListLoaderAction');
      const response = await adminPlansApiServices.planList(params);
      if (response?.status === 200) {
        dispatch({
          type: ADMIN_PLAN_REDUX_CONSTANTS.ADMIN_PLAN_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('adminPlanListLoaderAction');
    }
  };
};

export const addPlan = (data: Record<string, any>) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('addPlanLoaderAction');
      const response = await adminPlansApiServices.addPlan(data);
      if (response?.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addPlanLoaderAction');
    }
  };
};

export const getPlanFacilitiesList = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('adminPlanFacilitiesListLoaderAction');
      const response = await adminPlansApiServices.planFacilitiesList();
      if (response?.status === 200) {
        dispatch({
          type: ADMIN_PLAN_REDUX_CONSTANTS.ADMIN_PLAN_FACILITIES_LIST,
          data: response.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('adminPlanFacilitiesListLoaderAction');
    }
  };
};

export const deletePlan = (id: string, cb: () => void) => {
  return async () => {
    try {
      const params = {
        planId: id,
      };
      const response = await adminPlansApiServices.deletePlan(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getSelectedPlanDetails = (planId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedAdminPlanDetailsLoaderAction');
      const response = await adminPlansApiServices.getPlanDetailById(planId);
      if (response?.status === 200) {
        dispatch({
          type: ADMIN_PLAN_REDUX_CONSTANTS.GET_SELECTED_PLAN_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getSelectedAdminPlanDetailsLoaderAction'
      );
    }
  };
};

export const updateSelectedAdminPlanDetails = (
  data: Record<string, string | number | undefined>,
  planId: string,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('updateAdminPlanDetailsLoaderAction');
      const response = await adminPlansApiServices.updatePlanDetailsById(
        data,
        planId
      );
      if (response.status === 200) {
        dispatch({
          type: ADMIN_PLAN_REDUX_CONSTANTS.RESET_PLAN_DATA,
          data,
        });
        successNotification(
          `${data?.name} Plan's details updated successfully`
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateAdminPlanDetailsLoaderAction');
    }
  };
};

export const onChangePlanData = (
  name: string,
  value:
    | string
    | Record<string, string | undefined>
    | undefined
    | null
    | boolean
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_PLAN_DATA,
      data: {
        name,
        value,
      },
    });
  };
};

export const onChangeFacilities = (facility: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_FACILITIES,
      data: {
        facility,
      },
    });
  };
};

export const onChangePlanType = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADMIN_PLAN_REDUX_CONSTANTS.ON_CHANGE_PLAN_TYPE,
    });
  };
};

export const resetPlanData = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADMIN_PLAN_REDUX_CONSTANTS.RESET_PLAN_DATA,
    });
  };
};
