import { FormField } from './interfaces';
export interface RegisterType {
  title: string;
  subTitle: string;
  name: string;
  buttonText: string;
  fields: FormField[];
}

export const registerVenueList: string[] = [
  'Restaurant',
  'Cafe',
  'Hotel',
  'Pub',
  'Deli',
  'Food Manufacture',
  'Catering',
  'Fast Food Outlet',
];

export const numberOfEmployeeDropdown = [
  { _id: '10', name: 'Around 10' },
  { _id: '20', name: 'Around 20' },
  { _id: '30', name: 'Around 30' },
  { _id: '40', name: 'Around 40' },
  { _id: '50', name: 'Around 50' },
  { _id: '60', name: 'Around 60' },
  { _id: '70', name: 'Around 70' },
  { _id: '80', name: 'Around 80' },
  { _id: '90', name: 'Around 90' },
  { _id: '100', name: 'Around 100' },
];

export const registerConstants: RegisterType[] = [
  {
    title: "Let's get started",
    subTitle:
      'Begin this journey for free with no set up costs or hidden charges. Start by filling out your details',
    name: 'Register Account',
    buttonText: 'Proceed',
    fields: [
      {
        name: 'firstName',
        label: 'First Name',
        placeholder: 'Enter First Name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Enter Last Name',
      },
      {
        name: 'email',
        label: 'Email',
        inputType: 'email',
        placeholder: 'Enter Email',
      },
      {
        name: 'contactNumber',
        label: 'Contact Number',
        placeholder: 'Enter Contact Number',
        inputType: 'tel',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        inputType: 'password',
        suffix: 'visibility',
      },
      {
        name: 'reTypePassword',
        label: 'Re type Password',
        placeholder: 'Re type Password',
        inputType: 'password',
        suffix: 'visibility',
      },
    ],
  },
  {
    title: "Let's get started",
    subTitle: "Let's begin by setting up your venue details",
    name: 'Complete Profile',
    buttonText: 'Proceed',
    fields: [
      {
        name: 'country',
        label: 'Country',
        placeholder: 'Select Country',
        type: 'autocomplete',
      },
      {
        name: 'state',
        label: 'State',
        placeholder: 'Enter State',
        type: 'state',
      },
      {
        name: 'city',
        label: 'City',
        placeholder: 'Enter City',
        type: 'input',
      },
      {
        name: 'postalCode',
        label: 'Zip Code / Postal Code',
        placeholder: 'Zip Code / Postal Code',
        type: 'input',
      },
      {
        name: 'address',
        label: 'Address',
        placeholder: 'Enter business address',
        type: 'input',
        isFull: true,
      },
      {
        name: 'tradingName',
        label: 'Legal business name',
        placeholder: ' Enter legal business name',
        suffix: 'search',
      },
      {
        name: 'abn',
        label: 'ABN / Company Number',
        placeholder: 'ABN / Company Number',
        type: 'input',
        suffix: 'search',
      },
    ],
  },
  {
    title: 'Almost done!',
    subTitle: 'A few more details to finalise your account',
    name: 'More Info',
    buttonText: 'Get Access',
    fields: [
      {
        name: 'role',
        label: '1. Who is opening this account today?',
        placeholder: 'Owner / Director',
        type: 'autocomplete',
      },
      {
        name: 'venue',
        label: '2. Select your venue',
        placeholder: 'Select Venue',
        options: registerVenueList,
        type: 'autocomplete',
      },
      {
        name: 'numberOfEmployee',
        label: '3. How many employees in the business?',
        placeholder: '1-5',
        type: 'autocomplete',
      },
      {
        name: 'teamMemberEmail',
        label: '4. Team member email',
        placeholder: 'Enter Team User Email',
        type: 'input',
        inputType: 'email',
        fieldType: 'teamMember',
      },
      {
        name: 'teamMemberFirstName',
        label: '5. Team member first name',
        placeholder: 'Enter Team Member First Name',
        type: 'input',
        fieldType: 'teamMember',
      },
      {
        name: 'teamMemberLastName',
        label: '6. Team member last name',
        placeholder: 'Enter Team Member Last Name',
        type: 'input',
        fieldType: 'teamMember',
      },
      {
        name: 'teamMemberRole',
        label: '7. Team member Role',
        placeholder: 'Select Team Member Role',
        type: 'autocomplete',
        fieldType: 'teamMember',
      },
      {
        name: 'teamMemberContactNumber',
        label: '8. Team member contact number',
        placeholder: 'Enter Team Member Contact Number',
        type: 'input',
        fieldType: 'teamMember',
      },
    ],
  },
];

export const errorMessages: Record<string, string> = {
  tradingName: 'Please enter valid legal business name',
  firstName: 'Please enter first name',
  lastName: 'Please enter last name',
  email: 'Please enter email address',
  contactNumber: 'Please enter contact number',
  password: 'Please enter password',
  reTypePassword: 'Please enter Re type Passsword',
  country: 'Please select country',
  state: 'Please enter valid state',
  city: 'Please enter a valid City Name',
  postalCode: 'Please enter valid zipcode / Postalcode',
  address: 'Please enter valid address',
  abn: 'Please enter valid ABN',
  role: 'Please select user role',
  teamMemberFirstName: 'Please enter team member first name',
  teamMemberLastName: 'Please enter team member last name',
  venue: 'Please select venue',
  teamMemberEmail: 'Please enter email address',
  numberOfEmployee: 'Please enter number of employee ',
  teamMemberRole: 'Please select team member role',
  teamMemberContactNumber: 'Please enter contact number',
  isReadPolicyAndTerms:
    'Please click the box to indicate you have read ourLegal Policy & Terms',
};
