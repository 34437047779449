import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { SALES_REDUX_CONSTANTS } from '../reduxConstants/salesReduxConstants';

export type SalesType = {
  date: string | null;
  covers: number | null;
};

interface SalesProps {
  saleList: Record<string, any>;
  sale: Record<string, any>;
}

const initialSaleReducer: SalesProps = {
  saleList: {},
  sale: {
    date: null,
    foodSales: '',
    beveragesSales: '',
    covers: null,
  },
};

export const salesReducer = (
  state = initialSaleReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case SALES_REDUX_CONSTANTS.ADD_SALE:
    case SALES_REDUX_CONSTANTS.GET_SELECTED_SALE_DETAIL:
      return { ...state, sale: action.data };

    case SALES_REDUX_CONSTANTS.ON_CHANGE_SALE_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        sale: {
          ...state.sale,
          [name]: value,
        },
      };

    case SALES_REDUX_CONSTANTS.RESET_SALE_DATA:
      return { ...state, sale: initialSaleReducer.sale };

    case SALES_REDUX_CONSTANTS.SALES_LIST:
      return { ...state, saleList: action?.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialSaleReducer,
      };

    default:
      return { ...state };
  }
};
