import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../button/Button';
import { errorNotification } from '../notifyHelper';
import CloseIcon from '@mui/icons-material/Close';
import ButtonLoader from '../buttonLoader/ButtonLoader';

interface BrowsFileProps {
  fileNameKey: string;
  uploadAction: (formData: any, config: any) => void;
  fileExtension: string[];
  mimeType: string[];
  errorMessage: string;
  fileName: string;
  fileUrl: string;
  removeFileAction: (dispatch: any) => void;
}

const BrowseFile: FC<BrowsFileProps> = (props) => {
  const {
    uploadAction,
    fileNameKey,
    fileExtension,
    mimeType,
    errorMessage,
    fileName,
    fileUrl,
    removeFileAction,
  } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const BrowseButtonClick = () => {
    hiddenFileInput?.current?.click();
  };

  const { browseFileLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const browseFileChange = async (event: any) => {
    event.persist();
    if (event.target.files) {
      const checkExtension =
        fileExtension.indexOf(
          event.target.files[0].name.split('.').splice(-1)[0]
        ) !== -1;
      const checkMimeTypes =
        mimeType.indexOf(event.target.files[0].type) !== -1;
      const checkFileSize = event.target.files[0].size > 4194304;
      if (!(checkExtension || checkMimeTypes)) {
        errorNotification(errorMessage);
      } else if (checkFileSize) {
        errorNotification('File size should be upto 4 MB');
      } else {
        if (event?.target?.files?.[0]) {
          const formData = new FormData();
          formData.append(fileNameKey, event.target.files[0]);
          const config: Record<string, Record<string, string>> = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          await dispatch(uploadAction(formData, config));
        }
      }
    }
  };

  const removeFile = () => {
    dispatch(removeFileAction(fileName));
  };

  return (
    <div className="file-upload-container">
      <input
        type="file"
        className="input-file-type"
        ref={hiddenFileInput}
        onChange={browseFileChange}
        onClick={(e: any) => e.target.files[0] && browseFileChange(e)}
      />
      <Button
        color="primary"
        variant="outlined"
        component="span"
        loader={browseFileLoaderAction}
        className="browse-file-button outlined-primary-button"
        onClick={BrowseButtonClick}
      >
        Browse File
      </Button>
      <span className="terms-condition-file-name">
        {fileUrl ? (
          <a href={fileUrl} target="_blank" className="file-value">
            {fileName}
          </a>
        ) : (
          'No file attached'
        )}
        {fileUrl && (
          <CloseIcon className="T-and-C-close-icon" onClick={removeFile} />
        )}
      </span>
    </div>
  );
};

export default BrowseFile;
