import {
  FormControl,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loginImage from '../../../assets/images/login-img.svg';
import CheckBox from '../../../components/checkbox/CheckBox';
import Input from '../../../components/input/Input';
import { isEmail, passwordValidate } from '../../../helpers/validationHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { loginUser } from '../../../store/actions/loginAction';
import AuthTemplate from '../authTemplate/AuthTemplate';
import { LOGIN_PAGE_TEST_CONSTANTS } from './LoginPageConst';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    errors: {
      email: '',
      password: '',
    },
    showPassword: false,
    rememberMe: false,
  });

  const CryptoJS = require('crypto-js');
  var hash = CryptoJS.SHA256(loginForm.password);

  /* Redirect to forgot screen starts here */
  const onClickForgotPass = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.FORGOT_PASSWORD);
  };
  /* Redirect to forgot screen ends here */
  const onCopyPaste = (e: any) => {
    e.preventDefault();
  };

  /* Login Action starts here */
  const onClickLogin = async () => {
    if (
      !isEmail('email', loginForm.email).status ||
      !passwordValidate('password', loginForm.password).status
    ) {
      setLoginForm((prevState) => ({
        ...prevState,
        errors: {
          email: isEmail('email', loginForm.email).message,
          password: passwordValidate('password', loginForm.password).message,
        },
      }));
      return false;
    } else {
      const data = {
        email: loginForm.email,
        password: hash.toString(),
        showPassword: loginForm.showPassword,
        rememberMe: loginForm.rememberMe,
      };

      const response = await dispatch(
        loginUser(data, history, loginForm.rememberMe)
      );
      if (!response) {
        setLoginForm({
          ...loginForm,
          password: '',
        });
      }
    }
  };
  /* Login Action ends here */

  /* Input OnChangeEvent starts here */
  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginForm({
      ...loginForm,
      [name]: value,
    });
  };
  /* Input OnChangeEvent ends here */

  /* Show and Hide Password starts here */
  const handleClickShowPassword = () => {
    setLoginForm({ ...loginForm, showPassword: !loginForm.showPassword });
  };
  /* Show and Hide Password ends here */

  /* Input Validation starts here */
  const formInputValidation: any = (fieldValues: any) => {
    let formValue: any = { ...loginForm.errors };
    if ('email' in fieldValues) {
      if (fieldValues.email)
        formValue.email = isEmail('email', fieldValues.email).message;
    } else {
      formValue.password = passwordValidate(
        'password',
        fieldValues.password
      ).message;
    }

    setLoginForm((prevState) => ({
      ...prevState,
      errors: {
        ...formValue,
      },
    }));
  };
  /* Input Validation ends here*/

  const getFooterArea = (): JSX.Element => {
    return (
      <div className="redirect-container">
        Don't have an Account?
        <span
          data-testid={LOGIN_PAGE_TEST_CONSTANTS.registerBtn}
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(ROUTE_CONSTANTS_VARIABLE.REGISTER);
          }}
        >
          Sign Up
        </span>
      </div>
    );
  };

  const onEnterKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      onClickLogin();
    }
  };

  return (
    <AuthTemplate
      title="Log in"
      buttonTitle="Log In"
      imageTitle="100's of features are waiting for you to create easy business!"
      imageContext={loginImage}
      buttonEvent={onClickLogin}
      footerArea={getFooterArea()}
    >
      {
        <div
          data-testid={LOGIN_PAGE_TEST_CONSTANTS.loginPage}
          className="auth-form"
        >
          <div className="input-field-margin-bottom">
            <div className="auth-field-name">Email</div>
            <FormControl>
              <Input
                variant="filled"
                inputProps={{
                  'data-testid': LOGIN_PAGE_TEST_CONSTANTS.emailInput,
                }}
                className={`input-field ${
                  loginForm.errors.email ? 'input-with-error' : ''
                }`}
                name="email"
                type="email"
                onKeyUp={onEnterKeyUp}
                placeholder="Email"
                helperText={loginForm.errors.email}
                onChange={onHandleChangeInput}
                value={loginForm.email}
              />
            </FormControl>
          </div>
          <div className="auth-field-name">
            Password
            <Tooltip
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title="Your password must contain at least eight characters, at least one number; both lower and uppercase letters and at least one special character"
            >
              <Info />
            </Tooltip>
          </div>
          <FormControl
            className={`input-field
              ${loginForm.errors.password ? 'input-with-error' : ''}
            `}
          >
            <Input
              variant="filled"
              inputProps={{
                'data-testid': LOGIN_PAGE_TEST_CONSTANTS.passwordInput,
              }}
              className={`input-field ${
                loginForm.errors.password ? 'input-with-error' : ''
              }`}
              placeholder="Password"
              name="password"
              value={loginForm.password}
              type={loginForm.showPassword ? 'text' : 'password'}
              helperText={loginForm.errors.password}
              onKeyUp={onEnterKeyUp}
              onChange={onHandleChangeInput}
              onCopy={onCopyPaste}
              onPaste={onCopyPaste}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} size="large">
                      {loginForm.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl className="forgot-area-container ">
            <CheckBox
              inputProps={{
                // @ts-ignore
                'data-testid': LOGIN_PAGE_TEST_CONSTANTS.rememberMe,
              }}
              className="checkbox-container"
              onChange={() => {
                setLoginForm((prev) => ({
                  ...prev,
                  rememberMe: !prev.rememberMe,
                }));
              }}
              label="Remember Me"
            />
            <span
              data-testid={LOGIN_PAGE_TEST_CONSTANTS.forgotPwd}
              onClick={onClickForgotPass}
              className="forgot-password-text"
            >
              Forgot Password?
            </span>
          </FormControl>
        </div>
      }
    </AuthTemplate>
  );
};
export default LoginPage;
