import { INVOICE_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const reconcileApiService = {
  reconcileList: (params: Record<string, string>) =>
    ApiService.getData(INVOICE_URLS.RECONCILE_URLS.RECONCILE_LIST, { params }),
  supplierLst: () =>
    ApiService.getData(INVOICE_URLS.RECONCILE_URLS.GET_SUPPLIER_LIST),
  statementList: (params: Record<string, string>) =>
    ApiService.getData(INVOICE_URLS.RECONCILE_URLS.GET_STATEMENT_LIST, {
      params,
    }),
  invoiceIdList: (params: Record<string, string>) =>
    ApiService.getData(INVOICE_URLS.RECONCILE_URLS.GET_INVOICE_ID_LIST, {
      params,
    }),
  updateStatus: (
    data: Record<string, Record<string, string | boolean | number>>,
    supplierId: string
  ) =>
    ApiService.putData(
      `${INVOICE_URLS.RECONCILE_URLS.UPDATE_STATUS}${supplierId}/updateStatus`,
      data
    ),
  addAdjustmentNote: (
    data: Record<string, string | number>,
    supplierId: string
  ) =>
    ApiService.putData(
      `${INVOICE_URLS.RECONCILE_URLS.ADD_ADJUSTMENT_NOTE}${supplierId}/adjustmentNote`,
      data
    ),
  exportToExcelReconcile: (params: Record<string, string | number>) =>
    ApiService.request({
      method: 'get',
      url: INVOICE_URLS.RECONCILE_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params,
    }),

  exportToPdfReconcile: (params: Record<string, string | number>) =>
    ApiService.request({
      method: 'get',
      url: INVOICE_URLS.RECONCILE_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params,
    }),
};
