import dayjs from 'dayjs';
import { dateCalculationHelper } from './dateCalculationHelper';

export const dateLableHelper = (
  data: Record<string, string>,
  isFor: string,
  timeZoneOffSet: number
) => {
  const { startDate, endDate } = data;

  let sDate = dateCalculationHelper(startDate, timeZoneOffSet);

  let eDate = dateCalculationHelper(endDate, timeZoneOffSet);

  let currentDate = dateCalculationHelper(new Date(), timeZoneOffSet);

  let figureDate = '';
  if (startDate) {
    if (endDate) {
      figureDate = `${dayjs(sDate).format('D MMMM')} - ${dayjs(eDate).format(
        'D MMMM'
      )}`;
    } else {
      figureDate = `${dayjs(sDate).format('D MMMM')}-${dayjs(
        currentDate
      ).format('D MMMM')}`;
    }
  } else if (isFor === 'sales') {
    if (dayjs().day() === 0) {
      figureDate = `${dayjs(currentDate)
        .subtract(6, 'day')
        .format('D MMMM')} - ${dayjs(currentDate).format('D MMMM')}`;
    } else {
      figureDate = dayjs(currentDate).format('D MMMM');
    }
  }
  return figureDate;
};
