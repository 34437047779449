import { PRODUCT_REDUX_CONSTANTS } from '../../reduxConstants/supplierReduxConstants/productReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from './../../reduxConstants/loginReduxConstants';

export type ProductType = {
  _id?: string;
  supplierId?: string | null;
  categoryId?: string | null;
  quantity?: number | null;
  name?: string;
  productcode?: number | null;
  weight?: number | null;
  unitId?: string[] | null;
  price?: string | null;
  error?: Record<string, any> | null;
};
interface ProductProps {
  productDetail: Record<string, string | null | Record<string, string>>;
  productList: Record<string, any>;
  supplierList: Record<string, any>[] | null;
}
const initialProductReducer: ProductProps = {
  productList: {},
  productDetail: {
    supplierId: null,
    categoryId: null,
    productCode: '',
    name: '',
    weight: '',
    unitId: null,
    errors: {},
  },
  supplierList: null,
};

export const productReducer = (
  state = initialProductReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case PRODUCT_REDUX_CONSTANTS.GET_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        productDetail: action.data,
      };

    case PRODUCT_REDUX_CONSTANTS.GET_DROPDOWN_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: action.data,
      };

    case PRODUCT_REDUX_CONSTANTS.ON_CHANGE_PRODUCT_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          [name]: value,
        },
      };
    case PRODUCT_REDUX_CONSTANTS.RESET_PRODUCT_DATA:
      return {
        ...state,
        productDetail: { ...initialProductReducer.productDetail, errors: {} },
      };
    case PRODUCT_REDUX_CONSTANTS.PRODUCT_LIST:
      return {
        ...state,
        productList: action?.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialProductReducer,
        productDetail: { ...initialProductReducer.productDetail, errors: {} },
      };

    default:
      return { ...state };
  }
};
