import { FormControl, InputLabel } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import forgetImg from '../../../assets/images/forget-image.svg';
import Input from '../../../components/input/Input';
import {
  checkForEmail,
  replaceHiddenCharacters,
} from '../../../helpers/validationHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { forgotPassword } from '../../../store/actions/forgotPasswordAction';
import AuthTemplate from '../authTemplate/AuthTemplate';
import BackToLogin from '../authTemplate/BackToLogin';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const history = useHistory();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailText = e.target.value;
    setEmail(emailText);
  };

  const onClickForgotPassword = async () => {
    let validated = true;
    if (email?.toString()?.trim()?.length === 0) {
      setEmailError('Please enter an email');
      validated = false;
    } else if (!checkForEmail(replaceHiddenCharacters(email))) {
      setEmailError('Please enter a valid email');
      validated = false;
    } else {
      setEmailError('');
      validated = true;
    }
    if (validated) {
      await forgotPassword(email?.trim(), () =>
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
      );
    }
  };

  return (
    <AuthTemplate
      title="Forgot your Password?"
      subTitle="No Problem! Please enter your registered email."
      buttonTitle="Proceed"
      imageContext={forgetImg}
      buttonEvent={onClickForgotPassword}
    >
      {
        <>
          <div className="auth-form">
            <div className="auth-field-name">Email</div>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Email"
                helperText={emailError}
                onChange={onChangeEmail}
                onKeyUp={(event) =>
                  enterKeyHelper(event, onClickForgotPassword)
                }
                className={`input-field ${
                  emailError ? 'input-with-error' : ''
                }`}
              />
            </FormControl>
          </div>
          <BackToLogin />
        </>
      }
    </AuthTemplate>
  );
};
export default ForgotPassword;
