import Button from '../../../components/button/Button';
import profileImage from '../../../../src/assets/images/user-profile.svg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileDetail } from '../../../store/actions/ProfileAction';
import dayjs from 'dayjs';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';

const ProfileDetailPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  useEffect(() => {
    dispatch(userProfileDetail());
  }, []);

  return (
    <div className="profile-details-container">
      <div className="profile-header">
        <span className="profile-header-title">Profile</span>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.EDIT_PROFILE)}
        >
          Edit
        </Button>
      </div>

      <div className="profile-detail-container">
        <div className="profile-person-detail">
          <div className="profile-image">
            <img
              alt="profile-image"
              src={
                profileDetail?.profileURL
                  ? profileDetail.profileURL
                  : profileImage
              }
            />
          </div>
          <div className="profile-personal-info">
            <div className="text-detail-field-margin-bottom">
              <div className="detail-label">Full Name</div>
              <div className="detail-value">
                {profileDetail?.firstName || profileDetail?.lastName
                  ? `${profileDetail?.firstName} ${profileDetail?.lastName}`
                  : '-'}
              </div>
            </div>
            <div className="text-detail-field-margin-bottom">
              <div className="detail-label">Email</div>
              <div className="detail-value">
                {profileDetail?.email ? profileDetail?.email : '-'}
              </div>
            </div>
            <div className="text-detail-field-margin-bottom">
              <div className="detail-label">Contact Number</div>
              <div className="detail-value">
                {profileDetail?.contactNumber
                  ? profileDetail?.contactNumber
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-detail">
          <div className="info-content">
            <span className="detail-label">First Name</span>
            <span className="detail-value">
              {profileDetail?.firstName ? profileDetail?.firstName : '-'}
            </span>
          </div>
          <div className="info-content">
            <span className="detail-label">Last Name</span>
            <span className="detail-value">
              {profileDetail?.lastName ? profileDetail?.lastName : '-'}
            </span>
          </div>
          <div className="info-content">
            <span className="detail-label">User Role</span>
            <span className="detail-value">
              {profileDetail?.role ? profileDetail?.role : '-'}
            </span>
          </div>
          <div className="info-content">
            <span className="detail-label">Profile Created</span>
            <span className="detail-value">
              {profileDetail?.accountCreatedAt
                ? dayjs(profileDetail?.accountCreatedAt).format('DD MMM YYYY')
                : '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileDetailPage;
