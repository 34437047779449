import { ADMIN_SOFTWARE_INTEGRAION_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

const adminSoftwareIntegrationApiServices = {
  integrateSoftware: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      ADMIN_SOFTWARE_INTEGRAION_URLS.SOFTWARE_INTEGRATION,
      data
    ),
  getCurrentsoftware: () =>
    ApiService.getData(ADMIN_SOFTWARE_INTEGRAION_URLS.GET_CURRENT_SOFTWARE),
  validateSoftwareIntegration: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      ADMIN_SOFTWARE_INTEGRAION_URLS.VALIDATE_INTEGRATION,
      data
    ),
  chooseOrganization: (data: Record<string, string | boolean>) =>
    ApiService.putData(ADMIN_SOFTWARE_INTEGRAION_URLS.CHOOSE_ORGANIZATION, {
      ...data,
    }),
  resetIntegration: () =>
    ApiService.putData(ADMIN_SOFTWARE_INTEGRAION_URLS.RESET_INTEGRATION),
  removeIntegration: () =>
    ApiService.deleteData(ADMIN_SOFTWARE_INTEGRAION_URLS.REMOVE_INTEGRATION),
};
export default adminSoftwareIntegrationApiServices;
