export const exportExcelIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.945 13.1628H16.0823V11.6163H18.945V13.1628ZM18.945 14.0465H16.0823V15.593H18.945V14.0465ZM18.945 6.75581H16.0823V8.30231H18.945V6.75581ZM18.945 9.18604H16.0823V10.7325H18.945V9.18604ZM18.945 16.4767H16.0823V18.0233H18.945V16.4767ZM20.9223 19.5256C20.8405 19.9851 20.3293 19.9962 19.9878 20.0117H13.6286V22H12.3588L3 20.2325V4.76966L12.4141 3H13.6286V4.76081H19.769C20.1146 4.77627 20.4949 4.74976 20.7955 4.97289C21.0061 5.29986 20.9857 5.71524 21 6.0908L20.9918 17.5924C20.9816 18.2353 21.047 18.8915 20.9223 19.5256ZM10.4981 16.0592C9.93376 14.822 9.3592 13.5936 8.79688 12.3564C9.35305 11.1523 9.90106 9.94385 10.447 8.73534C9.98284 8.75965 9.51867 8.79057 9.05658 8.82594C8.71099 9.73394 8.30817 10.6177 8.03828 11.5566C7.78677 10.6707 7.45349 9.81789 7.14882 8.95406C6.69896 8.98057 6.2491 9.00929 5.79928 9.03801C6.27364 10.1692 6.77873 11.2848 7.23879 12.4227C6.69692 13.5273 6.18983 14.6475 5.66432 15.7587C6.1121 15.7786 6.55992 15.7985 7.00771 15.8051C7.32671 14.9258 7.72338 14.0797 8.00147 13.1827C8.25094 14.1459 8.67418 15.0363 9.02181 15.9575C9.51459 15.9952 10.0053 16.0283 10.4981 16.0592ZM20.0248 5.81008H13.6286V6.75581H15.2645V8.30231H13.6286V9.18604H15.2645V10.7325H13.6286V11.6163H15.2645V13.1628H13.6286V14.0465H15.2645V15.593H13.6286V16.4767H15.2645V18.0233H13.6286V19.045H20.0248V5.81008Z"
      fill="#666666"
    />
  </svg>
);
