import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import BackButton from '../../../components/backButton/BackButton';
import CustomTable from '../../../components/customTable/CustomTable';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePreviouseRecipe,
  deleteProductFromRecipe,
  getPreviousRecipeList,
  getRecipeCategoryDropdown,
  getRecipeProductDropdown,
  getSelectedRecipeDetails,
  onChangeProductQuantity,
  onChangeRecipeData,
  onRecipeProductPriceChange,
  resetRecipeData,
  uploadOptionalPhotos,
  uploadRecipeCoverPicture,
} from '../../../store/actions/recipeActions';
import {
  calculationHeader,
  recipeTableProductsHeaderConstants,
  recipeTableProductsMobileHeader,
} from '../../../constants/recipeCostConstants';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { useHistory, useParams } from 'react-router';
import { errorNotification } from '../../../components/notifyHelper';
import { addRecipeValidations } from './addRecipeValidations';
import { RECIPE_REDUX_CONSTANTS } from '../../../store/reduxConstants/recipeReduxConstants';
import rightIcon from '../../../assets/images/right-icon.svg';
import { Delete } from '@mui/icons-material';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import { recipeCalculationHelper } from '../../../helpers/recipeCalculationHelper';
import Loader from '../../../components/loader/Loader';
import _ from 'lodash';
import { focusHelper } from '../../../helpers/focusHelper';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const AddRecipe = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const [rowId, setRowId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [statusConfirmationModal, setStatusConfirmationModal] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const optionalPhotosInput = useRef<HTMLInputElement>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const { action, recipeId } = useParams<Record<string, string>>();
  const [recipeOption, setRecipeOption] = useState<Record<string, any>[]>([]);

  const { recipeDetail, categoryDropdown, productDropdown, previousRecipe } =
    useSelector(
      ({ recipeReducer }: Record<string, any>) => recipeReducer ?? {}
    );

  const {
    updateRecipeDetailsLoaderAction,
    getSelectedRecipeDetailLoaderAction,
    addRecipeLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { errors } = recipeDetail || {};
  const centerTableFields = ['quantity', 'unitIds', 'weight'];

  const onSelectChange = (name: string, _e: any, value: any) => {
    if (name === 'selectedRecipeIds') {
      const data = value?.length
        ? recipeDetail?.products?.length > 0
          ? [...recipeDetail?.products, ...value?.[value.length - 1]?.products]
          : [...value?.[value.length - 1]?.products]
        : [];

      const products = data.map((product: Record<string, any>) => {
        const totalPrice =
          (product.quantity ?? 1) *
          (product?.weight ?? 0) *
          product.averageprice;
        return { ...product, totalPrice };
      });

      dispatch(onChangeRecipeData('products', products));
      dispatch(onChangeRecipeData('selectedRecipeIds', value));
    } else if (name === 'products') {
      const products = value.map((product: Record<string, any>) => {
        const totalPrice =
          (product.quantity ?? 1) *
          (product?.weight ?? 0) *
          product.averageprice;
        return { ...product, totalPrice };
      });
      dispatch(onChangeRecipeData(name, products));
    } else {
      dispatch(onChangeRecipeData(name, value));
    }
    if (isSubmitted) {
      addRecipeValidations(
        dispatch,
        { ...recipeDetail, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const saveAsDraft = () => {
    addRecipeValidations(
      dispatch,
      recipeDetail,
      errors,
      false,
      'onDraft',
      undefined,
      recipeId ? 'editDraft' : 'draft',
      setIsSubmittedSuccessfully,
      history
    );
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteProductFromRecipe(rowId));
    setOpenDeleteModal(false);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const statusConfirmationModalButtons: ButtonType[] = [
    {
      title: 'Discard',
      onClick: () => {
        setStatusConfirmationModal(false);
        history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      },
      color: 'secondary',
    },
    {
      title: 'Save as a Draft',
      onClick: saveAsDraft,
      color: 'primary',
    },
  ];

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const optionalPhotoUpload = () => {
    optionalPhotosInput?.current?.click();
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
  };

  const onDeleteProduct = (id: string) => {
    setRowId(id);
    setOpenDeleteModal(true);
  };

  const onIncrementalChange = (action: string, id: string) => {
    dispatch(onChangeProductQuantity({ action, id }));
  };

  const fileExtension = ['jpeg', 'jpg', 'png'];
  const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];

  const handlePhotoChange = async (event: any) => {
    event.persist();
    if (event?.target?.files && event.target.files.length > 0) {
      const checkExtension =
        fileExtension.indexOf(
          event.target.files[0].name.split('.').splice(-1)[0]
        ) !== -1;
      const checkMimeTypes =
        mimeType.indexOf(event.target.files[0].type) !== -1;
      const checkFileSize = event.target.files[0].size > 4194304;

      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('That file type is not accepted');
      } else if (checkFileSize) {
        errorNotification('File size should be less than 4 mb');
      } else {
        if (event?.target?.files?.[0]) {
          const formData = new FormData();
          formData.append('recipe-image', event.target.files[0]);
          const config: Record<string, Record<string, string>> = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };

          await dispatch(uploadRecipeCoverPicture(formData, config));
        }
      }
    }

    addRecipeValidations(
      dispatch,
      { ...recipeDetail, [event.target.name]: event.target.value },
      errors,
      isSubmitted,
      'onBlur',
      'coverPhotoURL',
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const OptionalPhotosChange = async (event: any) => {
    const formData = new FormData();
    event.persist();
    if (event?.target?.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const checkExtension =
          fileExtension.indexOf(
            event.target.files[i].name.split('.').splice(-1)[0]
          ) !== -1;
        const checkMimeTypes =
          mimeType.indexOf(event.target.files[i].type) !== -1;
        const checkFileSize = event.target.files[i].size > 4194304;

        if (!(checkExtension || checkMimeTypes)) {
          errorNotification('That file type is not accepted');
        } else if (checkFileSize) {
          errorNotification('File size should be less than 4 mb');
        } else {
          if (event?.target?.files?.[i]) {
            formData.append('recipe-image', event.target.files[i]);
            const config: any = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };

            await dispatch(uploadOptionalPhotos(formData, config));
          }
        }
      }
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeRecipeData(name, value));
  };

  const onInputBlur = (name: string) => {
    addRecipeValidations(
      dispatch,
      recipeDetail,
      errors,
      isSubmitted,
      'onBlur',
      name,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const onClickAddRecipe = async (action: string) => {
    setIsSubmitted(true);
    addRecipeValidations(
      dispatch,
      recipeDetail,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };

  const removeFile = () => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.UPLOAD_RECIPE_PHOTO,
      data: null,
    });
  };

  const clearFileData = () => {
    removeFile();
  };

  const deleteFromOptional = (id: any) => {
    const data = recipeDetail?.additionalPhotoURLs?.filter(
      (photo: any) => photo !== id
    );
    dispatch(onChangeRecipeData('additionalPhotoURLs', data));
  };

  const okButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      },
      color: 'primary',
    },
  ];

  const onInputCellChange = (
    inputValue: string | number,
    productId: string
  ) => {
    dispatch(onRecipeProductPriceChange({ inputValue, productId }));
  };

  const onDeletePreviousRecipe = (recipeId: string) => {
    dispatch(deletePreviouseRecipe(recipeId));
  };

  const onCancel = () => {
    if (action === 'add') {
      setStatusConfirmationModal(true);
    } else {
      if (recipeDetail.status === 'Draft') {
        setStatusConfirmationModal(true);
      } else {
        history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      }
    }
  };

  const showAmountComponent = (value: string, type: string) => {
    let component = <></>;
    if (value.toString().length > 5) {
      component =
        type === 'amount' ? (
          <Tooltip enterDelay={1000} arrow title={`$${value}`}>
            <div>{`$${value}`}</div>
          </Tooltip>
        ) : (
          <Tooltip enterDelay={1000} arrow title={`${value}}%`}>
            <div>{`${value}%`}</div>
          </Tooltip>
        );
    } else {
      component =
        type === 'amount' ? <div>{`$${value}`}</div> : <div>{`${value}%`}</div>;
    }
    return component;
  };

  useEffect(() => {
    recipeCalculationHelper(recipeDetail, dispatch);
  }, [
    recipeDetail.products,
    recipeDetail.numberOfPortions,
    recipeDetail.foodCostPercentage,
  ]);

  useEffect(() => {
    previousRecipe &&
      setRecipeOption((prev) =>
        prev.length > 0
          ? [...previousRecipe?.docs, ...prev]
          : previousRecipe?.docs
      );
  }, [previousRecipe]);

  useEffect((): any => {
    const recipeParams = { page: 1, limit: 10 };

    dispatch(getPreviousRecipeList(recipeParams));
    dispatch(getRecipeCategoryDropdown());
    dispatch(getRecipeProductDropdown());
    if (recipeId) {
      dispatch(getSelectedRecipeDetails(recipeId));
    }
    return () => {
      dispatch(resetRecipeData());
    };
  }, []);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => {
            history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
          }}
        ></BackButton>
        <div className="form-title">{`${
          action === 'add' ? 'Add' : 'Edit'
        } Recipe`}</div>
      </div>
      {getSelectedRecipeDetailLoaderAction ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="white-block-row">
            {calculationHeader?.map((item) => (
              <div className="white-block">
                {showAmountComponent(recipeDetail?.[item.name], item.type)}
                <div>{item.label}</div>
              </div>
            ))}
          </div>
          <div className="previously-recipe-container">
            <div className="form-field-name">
              Select previously costed recipe
            </div>
            <FormControl className={`input-field `}>
              <Autocomplete
                placeholder="Select previously costed recipe"
                onChange={(event, value) =>
                  onSelectChange('selectedRecipeIds', event, value)
                }
                options={recipeOption}
                onKeyUp={(event) =>
                  enterKeyHelper(event, () => onClickAddRecipe(action))
                }
                getOptionLabel={(option) => option?.name ?? option ?? ''}
                value={recipeDetail?.selectedRecipeIds ?? []}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?._id === value?._id
                }
                getOptionDisabled={(option) => option._id === recipeId}
                filterOptions={(options) => {
                  const uniq = _.uniqBy(options, function (recipe: any) {
                    return recipe?._id;
                  });
                  return uniq;
                }}
                ListboxProps={{
                  style: { maxHeight: 200, overflow: 'auto' },
                  onScroll: (event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;

                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight ===
                        listboxNode.scrollHeight &&
                      previousRecipe.page !== previousRecipe.pages
                    ) {
                      dispatch(
                        getPreviousRecipeList({
                          page: previousRecipe.page + 1,
                          limit: 10,
                        })
                      );
                    }
                  },
                }}
                multiple
                className="autocomplete recipe-chip"
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      color="primary"
                      label={option.name}
                      onDelete={(e) => onDeletePreviousRecipe(option._id)}
                    />
                  ))
                }
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    placeholder="Select previously costed recipe"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="main-details-container form-fields-grid">
            <div>
              <div className="form-field-name">
                <div className="form-field-name">Category</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl
                className={`input-field ${
                  recipeDetail?.errors?.recipeCategoryId
                    ? 'input-with-error'
                    : ''
                }`}
              >
                <Autocomplete
                  key={Math.random()}
                  placeholder="Select category"
                  onChange={(event, value) =>
                    onSelectChange('recipeCategoryId', event, value)
                  }
                  options={categoryDropdown ?? []}
                  getOptionLabel={(option) => option?.name ?? option ?? ''}
                  value={
                    recipeDetail?.recipeCategoryId?.name
                      ? recipeDetail?.recipeCategoryId
                      : null
                  }
                  className="autocomplete"
                  id="recipeCategoryId"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddRecipe(action))
                  }
                  renderInput={(params: any) => (
                    <Input
                      {...params}
                      placeholder="Select category"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {recipeDetail?.errors?.recipeCategoryId}
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <div className="form-field-name">
                <div className="form-field-name">Recipe Name</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl className="input-field">
                <Input
                  variant="filled"
                  placeholder="Recipe name"
                  className={`input-field ${
                    recipeDetail?.errors?.name ? 'input-with-error' : ''
                  }`}
                  name="name"
                  id="name"
                  value={recipeDetail?.name}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddRecipe(action))
                  }
                  type="text"
                  helperText={recipeDetail?.errors?.name}
                  onChange={onInputChange}
                  onBlur={() => onInputBlur('name')}
                />
              </FormControl>
            </div>
            <div>
              <div className="form-field-name">
                <div className="form-field-name">Products</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl
                className={`input-field ${
                  recipeDetail?.errors?.products ? 'input-with-error' : ''
                }`}
              >
                <Autocomplete
                  key={Math.random()}
                  placeholder="Select products"
                  onChange={(event, value) =>
                    onSelectChange('products', event, value)
                  }
                  multiple
                  options={productDropdown ?? []}
                  getOptionLabel={(option) => option?.name ?? option ?? ''}
                  value={recipeDetail?.products ?? []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option._id === value._id
                  }
                  className="autocomplete"
                  id="products"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddRecipe(action))
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        deleteIcon={<></>}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params: any) => (
                    <Input
                      {...params}
                      placeholder={
                        recipeDetail?.products?.length > 0
                          ? ''
                          : 'Select products'
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {recipeDetail?.errors?.products}
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <div className="form-field-name">
                <div className="form-field-name">Number Of Portions</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl className="input-field">
                <Input
                  variant="filled"
                  placeholder="Number of portions"
                  className={`input-field ${
                    recipeDetail?.errors?.numberOfPortions
                      ? 'input-with-error'
                      : ''
                  }`}
                  name="numberOfPortions"
                  id="numberOfPortions"
                  value={recipeDetail?.numberOfPortions}
                  type="text"
                  onChange={onInputChange}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddRecipe(action))
                  }
                  onBlur={() => onInputBlur('numberOfPortions')}
                  helperText={recipeDetail?.errors?.numberOfPortions}
                  disabled={
                    !recipeDetail?.products ||
                    recipeDetail?.products?.length === 0
                  }
                />
              </FormControl>
            </div>
            <div>
              <div className="form-field-name">
                <div className="form-field-name">Food Cost Percentage</div>
                <div className="required-sign">*</div>
              </div>
              <FormControl className="input-field">
                <Input
                  placeholder="Food cost percentage"
                  className={`input-field ${
                    recipeDetail?.errors?.foodCostPercentage
                      ? 'input-with-error'
                      : ''
                  }`}
                  name="foodCostPercentage"
                  id="foodCostPercentage"
                  value={recipeDetail?.foodCostPercentage}
                  variant="filled"
                  type="text"
                  onChange={onInputChange}
                  onBlur={() => onInputBlur('foodCostPercentage')}
                  helperText={recipeDetail?.errors?.foodCostPercentage}
                  disabled={!recipeDetail?.products}
                />
              </FormControl>
            </div>
            <div></div>
            <div className="upload-recipe-photo-block">
              <div>
                <div className="form-field-name">
                  <div className="form-field-name">Add Photo Cover</div>
                  <div className="required-sign">*</div>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={`outlined-primary-button ${
                      recipeDetail?.errors?.coverPhotoURL
                        ? 'input-with-error'
                        : ''
                    }`}
                    onClick={handleClick}
                  >
                    Upload Photo
                  </Button>
                  <input
                    type="file"
                    name="coverPhotoURL"
                    className="input-file-type"
                    onChange={handlePhotoChange}
                    ref={hiddenFileInput}
                    onBlur={() => onInputBlur('coverPhotoURL')}
                  />
                </div>
                {recipeDetail?.errors?.coverPhotoURL && (
                  <div className="error">
                    {recipeDetail?.errors?.coverPhotoURL}
                  </div>
                )}
              </div>
              <div className="recipe-photo-container ">
                {recipeDetail?.coverPhotoURL ? (
                  <div className="recipe-photo">
                    <img
                      alt="recipe-photo"
                      className="recipe-photo-container"
                      src={recipeDetail?.coverPhotoURL}
                    />
                    {recipeDetail?.coverPhotoURL && (
                      <Delete className="delete-icon" onClick={clearFileData} />
                    )}
                  </div>
                ) : (
                  <span className="material-icons-round">insert_photo</span>
                )}
              </div>
            </div>
            <div className="upload-recipe-photo-block">
              <div>
                <div className="form-field-name">Photos (Optional)</div>
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="outlined-primary-button"
                    onClick={optionalPhotoUpload}
                  >
                    Upload Photo
                  </Button>
                  <input
                    type="file"
                    multiple
                    className="input-file-type"
                    onChange={OptionalPhotosChange}
                    ref={optionalPhotosInput}
                  />
                </div>
              </div>
              <div className="optionalPhotosContainer">
                {recipeDetail?.additionalPhotoURLs?.length > 0 ? (
                  recipeDetail?.additionalPhotoURLs?.map((url: string) => (
                    <div className="recipe-photo">
                      <img
                        alt="profile-image"
                        className="recipe-photo-container"
                        src={url}
                      />
                      {recipeDetail?.additionalPhotoURLs && (
                        <Delete
                          className="delete-icon"
                          onClick={() => {
                            deleteFromOptional(url);
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="photo-container">
                    <span className="material-icons-round">insert_photo</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {recipeDetail?.products?.length ? (
            <div className="main-details-container">
              <div className="details-block-title">Products</div>

              <CustomTable
                headers={recipeTableProductsHeaderConstants}
                mobileHeaders={recipeTableProductsMobileHeader}
                centerTableFields={centerTableFields}
                data={recipeDetail?.products ?? []}
                isAction
                deleteTableRow={onDeleteProduct}
                isPagination
                isIncremental
                isEditable={false}
                onIncrementalChange={onIncrementalChange}
                onInputCellChange={onInputCellChange}
                addTableClassName="add-table-container"
                listName="addRecipe"
              />
            </div>
          ) : (
            ''
          )}
          <div>
            <div className="form-field-name instruction-title">
              <div className="form-field-name">Recipe Instructions</div>
              <div className="required-sign">*</div>
            </div>
            <FormControl className="input-field">
              <Input
                variant="filled"
                placeholder="Recipe Instructions"
                className={`input-field ${
                  recipeDetail?.errors?.instructions ? 'input-with-error' : ''
                }`}
                name="instructions"
                id="instructions"
                value={recipeDetail?.instructions}
                type="text"
                multiline
                rows={3}
                onChange={onInputChange}
                onKeyUp={(event) =>
                  enterKeyHelper(event, () => onClickAddRecipe(action))
                }
                helperText={recipeDetail?.errors?.instructions}
              />
            </FormControl>
          </div>
          <div className="form-button-row">
            <Button color="secondary" variant="contained" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              loader={addRecipeLoaderAction || updateRecipeDetailsLoaderAction}
              onClick={() => {
                onClickAddRecipe(action === 'add' ? 'add' : 'edit');
              }}
            >
              {action === 'add' ? 'Publish Recipe' : 'Save Changes'}
            </Button>
          </div>
        </div>
      )}
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Recipe Added Confirmation"
          buttons={okButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Recipe added successfully</span>
          </>
        </CustomModal>
      )}
      {openDeleteModal && (
        <CustomModal
          header="Delete Product"
          buttons={deleteModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete this item?
          </span>
        </CustomModal>
      )}
      {statusConfirmationModal && (
        <CustomModal
          className="delete-modal"
          header="Recipe Status Confirmation"
          buttons={statusConfirmationModalButtons}
          hideModal={() => setStatusConfirmationModal(false)}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Save recipe as a Draft or discard
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default AddRecipe;
