import { australiaStates } from '../../constants/australiaStates';
import { REGISTER_REDUX_CONSTANTS } from '../reduxConstants/registerReduxConstants';

const initialRegister: Record<string, any> = {
  registrationDetail: {
    tradingName: '',
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    password: '',
    reTypePassword: '',
    isReadPolicyAndTerms: false,
    country: {
      _id: 'AU',
      name: 'Australia',
    },
    state: null,
    city: '',
    postalCode: '',
    address: '',
    abn: '',
    role: null,
    venue: null,
    numberOfEmployee: null,
    teamMemberFirstName: '',
    teamMemberLastName: '',
    teamMemberEmail: '',
    teamMemberRole: null,
    teamMemberContactNumber: '',
    inviteMemberLater: true,
  },
  companyDetailsList: [],
};

export const registerReducer = (
  state = initialRegister,
  action: { type: string; data: Record<string, any> }
) => {
  switch (action.type) {
    case REGISTER_REDUX_CONSTANTS.ON_CHANGE_VALUE:
      const { name, value } = action.data;
      return {
        ...state,
        registrationDetail: { ...state.registrationDetail, [name]: value },
      };

    case REGISTER_REDUX_CONSTANTS.UPDATE_DETAILS_BY_SEARCH:
      const data = action.data;

      const stateData: Record<string, any> | undefined = australiaStates.find(
        (state) => state._id === data.state
      );

      return {
        ...state,
        registrationDetail: {
          ...state.registrationDetail,
          abn: data.abn,
          tradingName: data.entityName || data.tradingName,
          state: stateData?.name,
          postalCode: data.postalCode,
        },
      };

    case REGISTER_REDUX_CONSTANTS.GET_COMPANY_DETAILS_BY_NAME:
      return {
        ...state,
        companyDetailsList: action.data,
      };

    case REGISTER_REDUX_CONSTANTS.RESET_COMPANY_DETAILS:
      return { ...state, companyDetailsList: [] };

    case REGISTER_REDUX_CONSTANTS.RESET_REGISTER_DATA:
      return { ...initialRegister };

    default:
      return initialRegister;
  }
};
