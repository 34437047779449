import { australiaStates } from '../../../constants/australiaStates';
import { SUPPLIER_REDUX_CONSTANTS } from '../../reduxConstants/supplierReduxConstants/supplierReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from './../../reduxConstants/loginReduxConstants';

export type SupplierType = {
  _id?: string;
  organizationId: string;
  name: string;
  contactNumber?: string;
  ordersEmail?: string;
  companyName?: string;
  categoryName?: string;
};
interface SupplierProps {
  supplierList: Record<string, any>;
  supplier?: Record<string, any>;
  companyDetailList: string[];
}

const initialSupplierReducer: SupplierProps = {
  supplierList: {},
  supplier: {
    abn: '',
    companyName: '',
    ordersEmail: '',
    name: '',
    category: [],
    repNumber: '',
    contactEmail: '',
    contactNumber: '',
    city: '',
    postalCode: '',
    state: null,
    country: {
      _id: 'AU',
      name: 'Australia',
    },
    address: '',
    paymentTerms: null,
    paymentMethod: null,
    instructions: '',
    errors: {},
    termsandconditionsURL: '',
  },
  companyDetailList: [],
};

export const supplierReducer = (
  state = initialSupplierReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case SUPPLIER_REDUX_CONSTANTS.SUPPLIER_LIST:
      return {
        ...state,
        supplierList: action?.data,
      };

    case SUPPLIER_REDUX_CONSTANTS.GET_PAYMENT_DROPDOWN_DATA: {
      const { paymentMethods, paymentTerms } = action.data;
      return {
        ...state,
        paymentMethods,
        paymentTerms,
      };
    }

    case SUPPLIER_REDUX_CONSTANTS.ON_ADD_SUPPLIER_VALUE_CHANGE: {
      const { name, value } = action.data;

      return {
        ...state,
        supplier: {
          ...state.supplier,
          [name]: value,
        },
      };
    }

    case SUPPLIER_REDUX_CONSTANTS.GET_SELECTED_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: { ...action.data, category: action.data.categoryIds },
      };

    case SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: {
          ...initialSupplierReducer.supplier,
          errors: {},
          category: [],
        },
        companyDetailList: [],
      };

    case SUPPLIER_REDUX_CONSTANTS.UPDATE_DETAILS_BY_SEARCH_IN_SUPPLIER:
      const data = action.data;

      const stateData: Record<string, any> | undefined = australiaStates.find(
        (state) => state._id === data.state
      );

      return {
        ...state,
        supplier: {
          ...state.supplier,
          abn: data.abn,
          companyName: data.entityName,
          tradingName: data.tradingName,
          state: stateData?.name,
          postalCode: data.postalCode,
        },
      };

    case SUPPLIER_REDUX_CONSTANTS.RESET_COMPANY_DETAILS_IN_SUPPLIER:
      return { ...state, companyDetailList: [] };

    case SUPPLIER_REDUX_CONSTANTS.UPLOAD_TERMS_AND_CONDITION:
      return {
        ...state,
        supplier: {
          ...state?.supplier,
          termsandconditionsURL: action.data.URL,
          fileName: action.data.fileName,
        },
      };

    case SUPPLIER_REDUX_CONSTANTS.DELETE_TERMS_AND_CONDITIONS:
      return {
        ...state,
        supplier: {
          ...state.supplier,
          termsandconditionsURL: '',
          fileName: '',
        },
      };

    /**
     * OLD CODE
     */

    /*  case SUPPLIER_REDUX_CONSTANTS.ON_CHANGE_CATEGORY:
      const { selectedCategory } = action.data;
      console.log(state.supplier?.category);
      if (state.supplier?.category.length > 0) {
        const data = state.supplier?.category?.length ? [...state.supplier.category] : [];
        console.log(data);
        let repeted = [];
        for (const index in data) {
          if (data[index]._id === selectedCategory._id) {
            repeted = state.supplier?.category.splice(index, 1);
          }
        }
        if (repeted.length === 0) {
          state.supplier?.category.push({
            _id: selectedCategory._id,
            name: selectedCategory.name,
            subcategoryIds: [],
          });
        }
      } else {
        state.supplier?.category.push({
          _id: selectedCategory._id,
          name: selectedCategory.name,
          subcategoryIds: [],
        });
      }
      return { ...state };
      */

    /**
     * NEW CODE
     */

    /**
     * Redux reducer case for handling the ON_CHANGE_CATEGORY action.
     * This case updates or adds a category based on the selected category data.
     *
     * @param {Object} state - The current state.
     * @param {Object} action - The action object containing the selected category data.
     * @returns {Object} - A new state object with the updated category information.
     */

    case SUPPLIER_REDUX_CONSTANTS.ON_CHANGE_CATEGORY:
      // Destructure the selected category from the action data
      const { selectedCategory } = action.data;

      // Initialize a variable to store the current category array from the state
      const currentCategory = state.supplier?.category || [];

      // Create a shallow copy of the current category array to ensure immutability
      const updatedCategory = [...currentCategory];

      // Initialize a flag to track whether the category already exists
      let categoryExists = false;

      // Iterate over the updatedCategory array to find the selected category
      for (let index = 0; index < updatedCategory.length; index++) {
        if (updatedCategory[index]._id === selectedCategory._id) {
          // If the category already exists, update it with new data
          updatedCategory[index] = {
            _id: selectedCategory._id,
            name: selectedCategory.name,
            subcategoryIds: [],
          };
          categoryExists = true;
          break; // Exit the loop as the category has been found and updated
        }
      }

      // If the category doesn't exist, add it to the updatedCategory array
      if (!categoryExists) {
        updatedCategory.push({
          _id: selectedCategory._id,
          name: selectedCategory.name,
          subcategoryIds: [],
        });
      }

      // Return a new state object with the updated category array, ensuring immutability
      return {
        ...state,
        supplier: {
          ...state.supplier,
          category: updatedCategory,
        },
      };

    case SUPPLIER_REDUX_CONSTANTS.ON_CHANGE_SUBCATEGORY:
      const { category, selectedSubcategory } = action.data;
      if (state.supplier?.category?.length > 0) {
        const data = state.supplier?.category;
        for (const index in data) {
          if (
            state?.supplier?.category?.[index] &&
            !('subcategoryIds' in state.supplier?.category[index])
          ) {
            state.supplier.category[index].subcategoryIds = [];
          }
          if (
            data[index]._id === category._id &&
            data[index].subcategoryIds?.length > 0
          ) {
            const subcategory = data[index].subcategoryIds;
            let repetedSubcategory = [];
            for (const i in subcategory) {
              if (subcategory[i]._id === selectedSubcategory._id) {
                repetedSubcategory = state.supplier?.category[
                  index
                ].subcategoryIds.splice(i, 1);
              }
            }
            if (repetedSubcategory?.length === 0) {
              state.supplier?.category[index].subcategoryIds?.push(
                selectedSubcategory
              );
            }
          } else {
            data[index].name === category.name &&
              state.supplier?.category[index]?.subcategoryIds?.push(
                selectedSubcategory
              );
          }
        }
        const existingCategory = data.filter(
          (item: any) => item._id === category._id
        );
        existingCategory.length === 0 &&
          state.supplier?.category.push({
            _id: category._id,
            name: category.name,
            subcategoryIds: [selectedSubcategory],
          });
      } else {
        state.supplier?.category.push({
          _id: category._id,
          name: category.name,
          subcategoryIds: [selectedSubcategory],
        });
      }
      return { ...state };

    case SUPPLIER_REDUX_CONSTANTS.ON_DELETE_CATEGORY:
      const { categoryToDelete } = action.data;

      return {
        ...state,
        supplier: {
          ...state.supplier,
          category: state.supplier?.category?.filter(
            (c: Record<string, any>) => c.name != categoryToDelete
          ),
        },
      };

    case SUPPLIER_REDUX_CONSTANTS.GET_COMPANY_DETAILS_BY_NAME_IN_SUPPLIER:
      return {
        ...state,
        companyDetailList: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialSupplierReducer,
        supplier: {
          ...initialSupplierReducer.supplier,
          errors: {},
          companyDetailList: [],
        },
      };

    default:
      return state;
  }
};
