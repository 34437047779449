import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from '@mui/material';
import { FC } from 'react';
import CheckBox from '../../checkbox/CheckBox';

interface TableHeaderProps {
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (sortID: string, sortDesc: string) => void;
  headers: Record<string, any>[];
  mobileHeaders: string[];
  centerTableFields?: string[];
  rightTableFields?: string[];
  rowCount?: number;
  isCheckbox?: boolean;
  isAction?: boolean;
  isSortable?: boolean;
  sortID?: string;
  sortDesc?: string;
  selectAllCheckBox: () => void;
  isAllChecked: boolean;
}

const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    headers,
    mobileHeaders,
    centerTableFields,
    rightTableFields,
    isCheckbox,
    isAction,
    isSortable,
    sortID,
    sortDesc,
    selectAllCheckBox,
    onRequestSort,
    isAllChecked,
  } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      const sort = sortDesc === 'asc' ? 'desc' : 'asc';
      onRequestSort(property ?? '0', sort);
    };

  return (
    <>
      <TableHead className="desktop-header">
        <TableRow>
          {isCheckbox && (
            <TableCell
              padding="checkbox"
              className="table-checkbox desktop-checkbox"
            >
              <CheckBox checked={isAllChecked} onChange={selectAllCheckBox} />
            </TableCell>
          )}
          {headers?.length &&
            headers.map((header, index) => (
              <TableCell
                className={`desktop-cell ${
                  centerTableFields?.includes(header.name)
                    ? 'table-field-text-center'
                    : ''
                } ${
                  rightTableFields?.includes(header.name)
                    ? 'table-field-text-right'
                    : ''
                }`}
                key={`heading-${index}`}
              >
                {isSortable && (
                  <TableSortLabel
                    active={Number(sortID) === index}
                    direction={
                      Number(sortID) === index && sortDesc === 'asc'
                        ? 'asc'
                        : Number(sortID) === index && sortDesc === 'desc'
                        ? 'desc'
                        : 'asc'
                    }
                    onClick={createSortHandler(index)}
                  >
                    {header.label}
                  </TableSortLabel>
                )}
                {!isSortable && header.label}
              </TableCell>
            ))}
          {isAction && (
            <TableCell className="desktop-cell">
              <div className="action-cell">Action</div>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <div className="table-header mobile-table-header">
        {isCheckbox && (
          <div>
            <CheckBox checked={isAllChecked} onChange={selectAllCheckBox} />
          </div>
        )}

        {headers?.length &&
          headers.map(
            (header, index) =>
              mobileHeaders.includes(header?.name) && (
                <div key={`heading-${index}`}>{header.label}</div>
              )
          )}
      </div>
    </>
  );
};

export default TableHeader;
