export const saveAuthTokenToLocalStorage = (authToken: string) => {
  localStorage.setItem('authToken', authToken);
};

export const getAuthTokenFromLocalStorage = () => {
  return localStorage.getItem('authToken');
};

export const clearAuthTokenFromLocalStorage = () => {
  localStorage.removeItem('authToken');
};

export const saveFCMTokenToLocalStorage = (FCMToken: string) => {
  localStorage.setItem('FCMToken', FCMToken);
};

export const getFCMTokenFromLocalStorage = () => {
  return localStorage.getItem('FCMToken');
};

export const clearFCMTokenFromLocalStorage = () => {
  localStorage.removeItem('FCMToken');
};
