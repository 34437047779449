import { Dispatch } from 'react';
import {
  NONZERO_NUMBER_REGEX,
  PLAN_PRICE_REGEX,
} from '../../../../constants/regexConstants';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import {
  addPlan,
  onChangePlanData,
  updateSelectedAdminPlanDetails,
} from '../../../../store/actions/admin/adminPlanActions';

export const addPlanFormValidation = async (
  dispatch: Dispatch<any>,
  planDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    name: 'enter plan name',
    price: 'enter valid price',
    noOfFreeInvoices: 'enter valid number of free invoices',
    facilities: 'select any Two facilities',
  };
  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'name':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'noOfFreeInvoices':
        if (
          !value?.toString()?.trim()?.length ||
          !NONZERO_NUMBER_REGEX.test(value?.toString()?.trim())
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'price':
        if (!PLAN_PRICE_REGEX.test(value)) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'facilities':
        if (value?.length === 0 || value?.length === 1) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in planDetail) {
        const value = planDetail[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, planDetail[fieldName]);
    }
    dispatch(onChangePlanData('errors', errors));
  }

  if (validated && requestFrom === 'onSubmit') {
    delete planDetail?.errors;

    const finalData = planDetail;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'noOfFreeInvoices':
            finalData[key] = parseInt(finalData[key]);
            break;

          case 'price':
            finalData[key] = parseFloat(finalData[key])
              .toFixed(2)
              .toString()
              .trim();
            break;
          case 'name':
            finalData[key] = finalData[key].toString().trim();
            break;
          case 'isPopularPlan':
            finalData[key];
        }
      }
    }
    if (action === 'add') {
      const isAddedSuccessFully: void = await dispatch(addPlan(finalData));

      if (isAddedSuccessFully !== null) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const updateUrl = () => history.push(ADMIN_ROUTE_CONST.PLAN);
      dispatch(
        updateSelectedAdminPlanDetails(finalData, planDetail._id, updateUrl)
      );
    }
  }
};
