export const invoiceStatusDropdown: string[] = ['New', 'Pending', 'Accepted'];

export const invoiceTableProductsHeaderConstants: Record<string, string>[] = [
  {
    name: 'name',
    label: 'Products',
    type: 'string',
  },
  {
    name: 'priceFluctuation',
    label: 'Price Change',
    type: 'priceChange',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'string',
  },
  {
    name: 'unit',
    label: 'UOM',
    type: 'string',
  },
  {
    name: 'price',
    label: 'Unit Price',
    type: 'amount',
  },
  {
    name: 'weight',
    label: 'Weight',
    type: 'input',
  },
  {
    name: 'total',
    label: 'Amount',
    type: 'amount',
  },
];

export const invoiceTableProductsMobileHeader: string[] = [
  'name',
  'priceFluctuation',
];

export const addInvoiceProductTableHeader = [
  {
    name: 'name',
    label: 'Products',
    type: 'string',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'quantity',
  },
  {
    name: 'unitId',
    label: 'UOM',
    type: 'object',
  },
  {
    name: 'price',
    label: 'Unit Price',
    type: 'input',
  },
  {
    name: 'weight',
    label: 'Weight',
    type: 'input',
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'amount',
  },
];

export const addInvoiceMobileHeader = ['name', 'quantity'];
