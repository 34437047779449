export const reconcileIncoiceHeaders = [
  {
    name: 'statementNumber',
    label: 'Statement ID',
    type: 'string',
  },
  {
    name: 'statementDate',
    label: 'Statement Date',
    type: 'date',
  },
  {
    name: 'invoiceNumber',
    label: 'Invoice#',
    type: 'string',
  },
  {
    name: 'invoiceDate',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'debitAmount',
    label: 'Debit',
    type: 'amount',
  },
  {
    name: 'orderId',
    label: 'OrderId',
    type: 'string',
  },
  {
    name: 'paidAmount',
    label: 'Paid',
    type: 'amount',
  },
  {
    name: 'outstandingAmount',
    label: 'Outstanding',
    type: 'amount',
  },
];

export const mobileReconcileInvoiceHeaders = ['statementId', 'statementDate'];
