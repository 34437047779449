import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Tooltip,
} from '@mui/material';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import BackButton from '../../components/backButton/BackButton';
import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import { passwordValidate } from '../../helpers/validationHelper';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../store/actions/ProfileAction';
import { ADMIN_ROUTE_CONST } from '../../routes/RouteConstants';
import { enterKeyHelper } from '../../helpers/enterKeyHelper';

const ChangePassword = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [changePasswordForm, setChangePasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    errors: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );
  const redirectingPath = profileDetail?.moduleAccess[0]?.hasSubModule
    ? profileDetail.moduleAccess[0]?.subModules[0]
        .kebabToSnakeCaseConvertor()
        .toUpperCase()
    : profileDetail.moduleAccess[0]?.name
        .kebabToSnakeCaseConvertor()
        .toUpperCase();

  const handleShowCurrentPassword = () => {
    setChangePasswordForm({
      ...changePasswordForm,
      showCurrentPassword: !changePasswordForm.showCurrentPassword,
    });
  };
  const handleShowNewPassword = () => {
    setChangePasswordForm({
      ...changePasswordForm,
      showNewPassword: !changePasswordForm.showNewPassword,
    });
  };
  const handleShowConfirmPassword = () => {
    setChangePasswordForm({
      ...changePasswordForm,
      showConfirmPassword: !changePasswordForm.showConfirmPassword,
    });
  };

  const formInputValidation = (fieldValues: any) => {
    let formValue: any = { ...changePasswordForm.errors };
    if ('currentPassword' in fieldValues) {
      formValue.currentPassword = passwordValidate(
        'Current Password',
        fieldValues.currentPassword
      ).message;
    } else if ('newPassword' in fieldValues) {
      formValue.newPassword = passwordValidate(
        'New Password',
        fieldValues.newPassword,
        true
      ).message;
    } else if ('confirmPassword' in fieldValues) {
      formValue.confirmPassword = passwordValidate(
        'Confirm Password',
        changePasswordForm.newPassword,
        false,
        fieldValues.confirmPassword
      ).message;
    }

    setChangePasswordForm((prevState) => ({
      ...prevState,
      errors: {
        ...formValue,
      },
    }));
  };

  const onSaveChangePassword = async () => {
    if (
      !passwordValidate('currentPassword', changePasswordForm.currentPassword)
        .status ||
      !passwordValidate('newPassword', changePasswordForm.newPassword).status ||
      !passwordValidate(
        'Confirm Password',
        changePasswordForm.newPassword,
        false,
        changePasswordForm.confirmPassword
      ).status
    ) {
      setChangePasswordForm((prevState) => ({
        ...prevState,
        errors: {
          currentPassword: passwordValidate(
            'Current Password',
            changePasswordForm.currentPassword
          ).message,
          newPassword: passwordValidate(
            'New Password',
            changePasswordForm.newPassword,
            true
          ).message,
          confirmPassword: passwordValidate(
            'Confirm Password',
            changePasswordForm.newPassword,
            false,
            changePasswordForm.confirmPassword
          ).message,
        },
      }));
      return false;
    } else {
      const CryptoJS = require('crypto-js');
      var currentPasswordHash = CryptoJS.SHA256(
        changePasswordForm.currentPassword
      );
      var newPasswordHash = CryptoJS.SHA256(changePasswordForm.newPassword);
      const changePasswordData = {
        currentPassword: currentPasswordHash?.toString(),
        newPassword: newPasswordHash?.toString(),
      };
      await dispatch(changePassword(changePasswordData));
      history.push(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setChangePasswordForm({
      ...changePasswordForm,
      [name]: value,
    });
    formInputValidation({ [name]: value });
  };

  const cancelChangePasswordChanges = () => {
    if (profileDetail?.role === 'Super Admin') {
      history.push(ADMIN_ROUTE_CONST.SUBSCRIBERS);
    } else {
      history.push(ROUTE_CONSTANTS_VARIABLE[redirectingPath]);
    }
  };

  return (
    <div className="form">
      <div className="change-password-header">
        <div className="change-pass-backbtn-container">
          <BackButton onClick={cancelChangePasswordChanges} />
        </div>
        <span>Change Password</span>
      </div>

      <div className="change-password-form">
        <div className="change-password-detail">
          <div className="input-field-margin-bottom">
            <InputLabel className="field-name">Current Password</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Current password"
                className={`${
                  changePasswordForm.errors.currentPassword
                    ? 'input-with-error'
                    : ''
                }`}
                name="currentPassword"
                value={changePasswordForm.currentPassword}
                type={
                  changePasswordForm.showCurrentPassword ? 'text' : 'password'
                }
                helperText={changePasswordForm.errors.currentPassword}
                onChange={onHandleChangeInput}
                // onKeyUp={(event)=> enterKeyHelper(event,onSaveChangePassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowCurrentPassword}
                        size="large"
                      >
                        {changePasswordForm.showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>

          <div className="input-field-margin-bottom">
            <InputLabel className="auth-field-name">
              New Password
              <Tooltip
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title="Your password must contain at least eight characters, at least one number; both lower and uppercase letters and at least one special character"
              >
                <Info />
              </Tooltip>
            </InputLabel>

            <FormControl>
              <Input
                variant="filled"
                placeholder="New Password"
                className={`input-field ${
                  changePasswordForm.errors.newPassword
                    ? 'input-with-error'
                    : ''
                }`}
                name="newPassword"
                value={changePasswordForm.newPassword}
                type={changePasswordForm.showNewPassword ? 'text' : 'password'}
                helperText={changePasswordForm.errors.newPassword}
                onChange={onHandleChangeInput}
                onKeyUp={(event) => enterKeyHelper(event, onSaveChangePassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassword} size="large">
                        {changePasswordForm.showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>

          <div>
            <InputLabel className="field-name">Confirm New Password</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Confirm New Password"
                className={`input-field ${
                  changePasswordForm.errors.confirmPassword
                    ? 'input-with-error'
                    : ''
                }`}
                name="confirmPassword"
                value={changePasswordForm.confirmPassword}
                type={
                  changePasswordForm.showConfirmPassword ? 'text' : 'password'
                }
                helperText={changePasswordForm.errors.confirmPassword}
                onChange={onHandleChangeInput}
                onKeyUp={(event) => enterKeyHelper(event, onSaveChangePassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowConfirmPassword}
                        size="large"
                      >
                        {changePasswordForm.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="form-button-row">
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelChangePasswordChanges}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSaveChangePassword}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
