import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAuthTokenFromLocalStorage,
  getFCMTokenFromLocalStorage,
} from '../../../../helpers/localstoragehelper';
import { useOnClickOutside } from '../../../../hooks/userClickOutsideHook';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import { logOutUser } from '../../../../store/actions/loginAction';
import { getAllNotificationAction } from '../../../../store/actions/notificationActions';
import { userProfileDetail } from '../../../../store/actions/ProfileAction';

interface ProfileMenuProps {
  className?: string;
  setIsProfileDrawerOpened: (val: boolean) => void;
}

const ProfileMenu: FC<ProfileMenuProps> = (props) => {
  const { className, setIsProfileDrawerOpened } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userSettingsRef = useRef<HTMLDivElement>(null);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const toggleUserSettings = () => {
    setShowUserSettings((prev) => !prev);
  };
  useOnClickOutside(userSettingsRef, () => setShowUserSettings(false));

  const { profileMenuDetail, profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );
  const { flow } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );

  const onLogoutClick = () => {
    dispatch(logOutUser(history));
  };

  useEffect(() => {
    if (getAuthTokenFromLocalStorage() && flow !== 1 && flow !== 2) {
      dispatch(userProfileDetail());
    }
  }, []);

  return (
    <div className={`user-profile-dropdown ${className}`} ref={userSettingsRef}>
      <div className="profile-container" onClick={toggleUserSettings}>
        <div className="user-details">
          {profileDetail?.profileURL ? (
            <img
              src={profileDetail?.profileURL}
              className="header-profile-img"
            />
          ) : (
            <span className="user-dp material-icons">person</span>
          )}
          <div>
            <div className="user-name">{profileMenuDetail?.name}</div>
            <div className="user-role">{profileMenuDetail?.role}</div>
          </div>
        </div>
        <span className="material-icons-outlined profile-expand-more-arrow">
          expand_more
        </span>
      </div>
      {showUserSettings && (
        <div className="user-settings">
          <div>
            {flow !== 1 && flow !== 2 && (
              <div
                onClick={() => {
                  history.push(ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE_DETAILS);
                  setShowUserSettings(false);
                  setIsProfileDrawerOpened(false);
                }}
              >
                <span className="material-icons">person</span>
                Profile
              </div>
            )}
            {flow !== 1 && flow !== 2 && (
              <div
                onClick={() => {
                  history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PASSWORD);
                  setShowUserSettings(false);
                  setIsProfileDrawerOpened(false);
                }}
              >
                <span className="material-icons">lock</span> Change Password
              </div>
            )}
            <div onClick={onLogoutClick}>
              <span className="material-icons">logout</span> Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
