import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import resetPassImg from '../../../../src/assets/images/reset-password.svg';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import Input from '../../../components/input/Input';
import {
  PASSWORD_REGEX,
  replaceHiddenCharacters,
} from '../../../helpers/validationHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { resetPassword } from '../../../store/actions/ResetPasswordAction';
import { setPassWord } from '../../../store/actions/SetPasswordAction';
import AuthTemplate from '../authTemplate/AuthTemplate';
import BackToLogin from '../authTemplate/BackToLogin';
import { Info, Visibility, VisibilityOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import AuthenticationModal from '../../../components/authModal/authenticationModal';
import { useDispatch, useSelector } from 'react-redux';
import rightIcon from '../../../assets/images/right-icon.svg';

const SetOrResetPassword = () => {
  const [isNotVisible, setIsNotVisible] = useState(true);
  const [isRetypeNotVisible, setIsRetypeNotVisible] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordRegexMessage, setIsPasswordRegexMessage] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [reTypePasswordError, setReTypePasswordError] = useState('');
  const { token } = useQueryParams();
  const { action } = useParams<Record<string, string>>();
  const [openResetModal, setOpenResetModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch<any>();

  const { userDetail } = useSelector(
    ({ setPasswordReducer }: Record<string, any>) => setPasswordReducer ?? {}
  );

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPasswordRegexMessage(false);
    setPasswordError('');
    const changedPassword = e.target.value;
    setPassword(changedPassword);
  };

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedConfirmPassword = e.target.value;
    setConfirmPassword(changedConfirmPassword);
  };

  const onClickResetPassword = async () => {
    let validated = true;
    if (replaceHiddenCharacters(password.toString()).trim().length === 0) {
      setIsPasswordRegexMessage(false);
      validated = false;
      setPasswordError('Password is empty, please enter the password');
    } else if (!PASSWORD_REGEX.test(password)) {
      validated = false;
      setIsPasswordRegexMessage(true);
    } else if (
      replaceHiddenCharacters(confirmPassword.toString()).trim().length === 0
    ) {
      validated = false;
      setReTypePasswordError('Re type password is empty, please fill it');
    } else if (password !== confirmPassword) {
      validated = false;
      setIsPasswordRegexMessage(false);
      setReTypePasswordError('Confirm Password and Password should be same');
    } else {
      setPasswordError('');
      setReTypePasswordError('');
      validated = true;
    }
    if (validated) {
      setIsPasswordRegexMessage(false);
      const CryptoJS = require('crypto-js');
      var hash = CryptoJS.SHA256(password);
      const dataToSend = {
        token: token,
        password: hash.toString(),
      };

      const isSubmittedSuccefully =
        action === 'reset'
          ? await dispatch(resetPassword(dataToSend, history))
          : await dispatch(setPassWord(dataToSend, history));

      if (isSubmittedSuccefully) {
        setOpenResetModal(true);
      }
    }
  };
  // re-direct to login
  const resetPassBtns: ButtonType[] = [
    {
      title: 'Log In',
      color: 'primary',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
      },
    },
  ];

  const onCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const onEnterKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      onClickResetPassword();
    }
  };

  return (
    <>
      {/* {userDetail?.userId && <AuthenticationModal />} */}
      {openResetModal && (
        <CustomModal
          header={`Your password is ${
            action === 'reset' ? 'reset' : 'set'
          } successfully!`}
          buttons={resetPassBtns}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {}}
          successModal
        >
          <>
            <img src={rightIcon} />
          </>
        </CustomModal>
      )}
      {action === 'set' && userDetail?.userId && (
        <AuthenticationModal route="set-2FA" />
      )}

      <AuthTemplate
        title={
          action === 'reset' ? 'Now Add Your New Password' : 'Set your Password'
        }
        buttonTitle="Proceed"
        imageContext={resetPassImg}
        buttonEvent={onClickResetPassword}
      >
        <>
          <div className="auth-form">
            <div className="input-field-margin-bottom">
              <div className="auth-field-name">
                Password
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title="Your password must contain at least eight characters, at least one number; both lower and uppercase letters and at least one special character"
                >
                  <Info />
                </Tooltip>
              </div>
              <FormControl>
                <Input
                  variant="filled"
                  className={`input-field
              ${
                passwordError || isPasswordRegexMessage
                  ? 'input-with-error'
                  : ''
              }
            `}
                  onCopy={onCopyPaste}
                  onPaste={onCopyPaste}
                  onKeyUp={onEnterKeyUp}
                  placeholder="Password"
                  helperText={
                    isPasswordRegexMessage ? 'Invalid Password' : passwordError
                  }
                  type={isNotVisible ? 'password' : 'text'}
                  onChange={onChangePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsNotVisible(!isNotVisible)}
                        >
                          {isNotVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className="auth-field-name">Re type Password</div>
            <FormControl>
              <Input
                variant="filled"
                className={`input-field
              ${reTypePasswordError?.trim().length ? 'input-with-error' : ''}
            `}
                placeholder="Re type Password"
                type={isRetypeNotVisible ? 'password' : 'text'}
                onChange={onChangeConfirmPassword}
                onKeyUp={onEnterKeyUp}
                helperText={
                  isPasswordRegexMessage
                    ? 'Confirm Password and Password should be same'
                    : reTypePasswordError
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setIsRetypeNotVisible(!isRetypeNotVisible)
                        }
                      >
                        {isRetypeNotVisible ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>

          <BackToLogin />
        </>
      </AuthTemplate>
    </>
  );
};
export default SetOrResetPassword;
