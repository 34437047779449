import { downloadAll } from './../../helpers/downloadHelper';
import { displayErrors } from './../../helpers/errorNotifyHelper';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { successNotification } from './../../components/notifyHelper';
import { COMMON_REDUX_CONSTANTS } from './../reduxConstants/commonReduxConstant';
import { Dispatch } from 'react';
import { commonApiServices } from '../../services/pageServices/commonApiServices';
import { async } from '@firebase/util';

export const getUserRoles = () => {
  return async (dispatch: any) => {
    try {
      const response = await commonApiServices.getUserRoles();
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.GET_USER_ROLE,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getCategories = (supplierId?: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        supplierId: supplierId?._id,
      };
      startGeneralLoaderOnRequest('categoriesLoaderaction');
      const response = await commonApiServices.getCategoryList(params);
      if (response.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.CATEGORY.GET_CATEGORY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('categoriesLoaderaction');
    }
  };
};

export const getSuppliers = (categoryId?: any) => {
  return async (dispatch: any) => {
    try {
      const params = {
        categoryId: categoryId,
      };
      const response = await commonApiServices.getSuppliers(params);
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.SUPPLIER_DROPDOWN_DATA,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getUnits = () => {
  return async (dispatch: any) => {
    try {
      const response = await commonApiServices.getUnits();
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.UNIT.GET_UNIT_DROPDOWN_DATA,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateUnits = (
  data: Record<string, Record<string, string>[]>,
  callbackFunction: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('addUnitLoaderAction');
      const response = await commonApiServices.updateUnits(data);
      if (response.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.UNIT.ON_CHANGE_UNIT,
          data,
        });
        if (callbackFunction) {
          callbackFunction();
        }
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addUnitLoaderAction');
    }
  };
};

export const getProducts = (supplierId?: any, categoryId?: any) => {
  return async (dispatch: any) => {
    const params = { supplierId, categoryId };
    try {
      const response = await commonApiServices.getProducts(params);
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.PRODUCT_DROPDOWN_DATA,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateCategoryList = (
  data: Record<string, any>,
  callbackFunction: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('addCategoryLoaderAction');
      const response = await commonApiServices.updateCategoryList(data);
      if (response.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.CATEGORY.ON_CHANGE_CATEGORY_LIST,
          data,
        });
        if (callbackFunction) {
          callbackFunction();
        }
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addCategoryLoaderAction');
    }
  };
};

export const downloadSampleCsvFile = (csvFor: string) => {
  return async () => {
    try {
      const requestedFor = {
        csvFor,
      };
      startGeneralLoaderOnRequest('downloadSampleFileLoaderAction');
      const response = await commonApiServices.downloadSampleCsv(requestedFor);
      if (response?.status === 200) {
        downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const categorySubcategoryDropdownAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await commonApiServices.getCategorySubcategoryDropdown();
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.CATEGORY.CATEGORY_SUBCATEGORY_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const productDropdownListAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await commonApiServices.productDropdownList();
      if (response?.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.PRODUCT_DROPDOWN_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getCurrencyRateAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await commonApiServices.getCurrencyRate();
      if (response.status === 200) {
        dispatch({
          type: COMMON_REDUX_CONSTANTS.GET_CURRENCY_RATE,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
