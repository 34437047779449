export const printerIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 7V4C18 3.45 17.55 3 17 3H7C6.45 3 6 3.45 6 4V7H18ZM19 8H5C3.34 8 2 9.34 2 11V16C2 16.55 2.45 17 3 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H21C21.55 17 22 16.55 22 16V11C22 9.34 20.66 8 19 8ZM16 19H8V15H16V19ZM18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5C19 12.05 18.55 12.5 18 12.5Z"
      fill="#666666"
    />
  </svg>
);
