import { Delete } from '@mui/icons-material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import {
  getCategories,
  updateCategoryList,
} from '../../../../store/actions/commonAction';

interface CategoryModalProps {
  setCategoryModal: (val: boolean) => void;
}

const CategoryModal: FC<CategoryModalProps> = (props) => {
  const { setCategoryModal } = props;
  const dispatch = useDispatch();
  const { categories } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const [inputValue, setInputValue] = useState<
    Record<string, string | undefined>
  >({
    value: '',
    error: '',
  });
  const [updatedCategories, setUpdatedCategories] =
    useState<Record<string, string>[]>(categories);
  const { addCategoryLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const categoryButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setCategoryModal(false);
        setInputValue({ value: undefined, error: undefined });
      },
      color: 'secondary',
    },
    {
      title: addCategoryLoaderAction ? <ButtonLoader /> : 'Save',
      onClick: () => {
        dispatch(
          updateCategoryList({ categories: updatedCategories }, () => {
            setCategoryModal(false);
            dispatch(getCategories());
          })
        );
      },
      color: 'primary',
    },
  ];

  const onAddCategory = () => {
    if (inputValue.value && inputValue.value.toString().trim().length) {
      const duplicateRecord = updatedCategories.filter(
        (category) =>
          category.name.toString().trim().toLowerCase() ===
          inputValue?.value?.toString().trim().toLowerCase()
      );
      if (duplicateRecord?.length) {
        setInputValue({
          value: '',
          error: `${inputValue.value} category already exists, please add  another one`,
        });
      } else {
        setUpdatedCategories((prevValue) =>
          inputValue.value
            ? [
                ...prevValue,
                {
                  name:
                    inputValue.value.toString().trim() &&
                    inputValue.value.toString().trim(),
                },
              ]
            : [...prevValue]
        );
        setInputValue({ value: '', error: '' });
      }
    } else {
      setInputValue({
        value: '',
        error: 'Please add valid category name',
      });
    }
  };

  const deleteCategory = (index: number) => {
    const categoryList = updatedCategories;
    categoryList.splice(index, 1);
    setInputValue({ value: '', error: '' });
    setUpdatedCategories([...categoryList]);
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <CustomModal
      header="Add/ Edit Categories"
      buttons={categoryButtons}
      className="category-modal"
      bodyClassName="category-modal-body"
      hideModal={() => {
        setCategoryModal(false);
        setInputValue({ value: undefined, error: undefined });
      }}
    >
      <div className="form-field-name">Add Category</div>
      <div className="category-input-button-container">
        <Input
          variant="filled"
          placeholder="Enter a new Category"
          value={inputValue.value}
          onChange={(e) => setInputValue({ value: e.target.value })}
          helperText={inputValue?.error}
        />
        <Button
          color="primary"
          variant="contained"
          className="modal-button"
          onClick={onAddCategory}
        >
          Add
        </Button>
      </div>
      <div className="category-chips-container">
        {updatedCategories?.length > 0 &&
          updatedCategories.map(
            (category: Record<string, string>, index: number) => (
              <div className="category-chip">
                <pre>{category.name}</pre>
                {category?.isDefault || (
                  <Delete onClick={() => deleteCategory(index)} />
                )}
              </div>
            )
          )}
      </div>
    </CustomModal>
  );
};

export default CategoryModal;
