export const reportsIcon = (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6406 0.718872H3.1875C3.09375 0.718872 3.03125 0.765747 3 0.796997L0.171875 3.6095C0.125 3.65637 0.09375 3.7345 0.09375 3.797V19.0001C0.09375 19.1564 0.21875 19.2657 0.359375 19.2814H13.625C13.7813 19.2814 13.8906 19.1564 13.9062 19.0157V0.984497C13.9062 0.828247 13.7813 0.718872 13.6406 0.718872ZM2.92188 1.64075V3.53137H1.03125L2.92188 1.64075ZM13.3594 18.7345H0.640625V4.07825H3.1875C3.34375 4.07825 3.45313 3.95325 3.46875 3.81262V1.26575H13.3594V18.7345Z"
      fill="#666666"
    />
    <path
      d="M5 4.07812H9C9.15625 4.07812 9.28125 3.95312 9.28125 3.79687C9.28125 3.64062 9.15625 3.51562 9 3.51562H5C4.84375 3.51562 4.71875 3.64062 4.71875 3.79687C4.71875 3.95312 4.84375 4.07812 5 4.07812Z"
      fill="#666666"
    />
    <path
      d="M6.375 10.5469H5.21875C5.0625 10.5469 4.9375 10.6719 4.9375 10.8281V16.1875C4.9375 16.3438 5.0625 16.4531 5.20313 16.4688H6.375C6.53125 16.4688 6.64062 16.3437 6.65625 16.2031V10.8281C6.65625 10.6719 6.53125 10.5469 6.375 10.5469ZM6.10938 15.9219H5.48438V11.0938H6.09375L6.10938 15.9219Z"
      fill="#666666"
    />
    <path
      d="M8.78124 12.6096H7.625C7.46875 12.6096 7.34375 12.7346 7.34375 12.8909V16.1877C7.34375 16.344 7.46875 16.4534 7.60937 16.469H8.76561C8.92186 16.469 9.03124 16.344 9.04686 16.2034V12.8909C9.06248 12.7346 8.93749 12.6096 8.78124 12.6096ZM8.51561 15.9221H7.90625V13.1565H8.51561V15.9221Z"
      fill="#666666"
    />
    <path
      d="M3.95313 12.6096H2.79687C2.64062 12.6096 2.51562 12.7346 2.51562 12.8909V16.1877C2.51562 16.344 2.64063 16.4534 2.78125 16.469H3.9375C4.09375 16.469 4.20313 16.344 4.21875 16.2034V12.8909C4.23438 12.7346 4.10938 12.6096 3.95313 12.6096ZM3.6875 15.9221H3.07813V13.1565H3.6875V15.9221Z"
      fill="#666666"
    />
    <path
      d="M11.2031 10.5469H10.0469C9.89062 10.5469 9.76562 10.6719 9.76562 10.8281V16.1875C9.76562 16.3438 9.89062 16.4531 10.0312 16.4688H11.1875C11.3437 16.4688 11.4531 16.3437 11.4687 16.2031V10.8281C11.4844 10.6719 11.3594 10.5469 11.2031 10.5469ZM10.9375 15.9219H10.3281V11.0938H10.9375V15.9219Z"
      fill="#666666"
    />
    <path
      d="M11.2031 9.01587H2.79688C2.64063 9.01587 2.51562 9.14087 2.51562 9.29712C2.51562 9.45337 2.64063 9.57837 2.79688 9.57837H11.2031C11.3594 9.57837 11.4844 9.45337 11.4844 9.29712C11.4844 9.14087 11.3594 9.01587 11.2031 9.01587Z"
      fill="#666666"
    />
    <path
      d="M11.2031 7.1875H2.79688C2.64063 7.1875 2.51562 7.3125 2.51562 7.46875C2.51562 7.625 2.64063 7.75 2.79688 7.75H11.2031C11.3594 7.75 11.4844 7.625 11.4844 7.46875C11.4844 7.3125 11.3594 7.1875 11.2031 7.1875Z"
      fill="#666666"
    />
    <path
      d="M2.53125 5.64087C2.53125 5.79712 2.65625 5.90649 2.79687 5.92212H11.2031C11.3594 5.92212 11.4844 5.79712 11.4844 5.64087C11.4844 5.48462 11.3594 5.35962 11.2031 5.35962H2.79687C2.64062 5.35962 2.53125 5.48462 2.53125 5.64087Z"
      fill="#666666"
    />
  </svg>
);
