import {
  Box,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Info, Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Tooltip } from '@mui/material';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import companyLogo from '../../../assets/images/company-logo.svg';
import BackButton from '../../../components/backButton/BackButton';
import CheckBox from '../../../components/checkbox/CheckBox';
import Input from '../../../components/input/Input';
import { australiaStates } from '../../../constants/australiaStates';
import { preparedCountries } from '../../../constants/countryConstants';
import { FormField, OptionsType } from '../../../constants/interfaces';
import {
  numberOfEmployeeDropdown,
  registerConstants,
} from '../../../constants/registerConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { getUserRoles } from '../../../store/actions/commonAction';
import {
  getDetailsByAbn,
  getDetailsByName,
  onChangeRegisterData,
  resetCompanyDetail,
} from '../../../store/actions/registerActions';
import { REGISTER_REDUX_CONSTANTS } from '../../../store/reduxConstants/registerReduxConstants';
import RegisterButton, { onRegisterButtonClick } from './RegisterButton';
import AuthenticationModal from '../../../components/authModal/authenticationModal';
import { BASE_URL } from '../../../constants/urlConstants';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import { errorNotification } from '../../../components/notifyHelper';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';
const Register: FC = () => {
  const dispatch = useDispatch<Dispatch<Record<string, any>>>();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setRetypePassword] = useState(false);
  const [selectValues, setSelectValues] = useState<Record<string, any>>({});
  const [venueTradingModal, setVenueTradingModal] = useState(false);
  const { registrationDetail, companyDetailsList } = useSelector(
    ({ registerReducer }: Record<string, any>) => registerReducer ?? {}
  );
  const { userRoles } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const [isLoadingCompanyDetail, setIsLoadingCompanyDetail] =
    useState<boolean>(false);
  const [isLoadingCompanyDetailByAbn, setIsLoadingCompanyDetailByAbn] =
    useState<boolean>(false);
  const { userDetail } = useSelector(
    ({ setPasswordReducer }: Record<string, any>) => setPasswordReducer ?? {}
  );
  const { errors } = registrationDetail;
  const headers = [
    { name: 'entityName', label: 'Name' },
    { name: 'abn', label: 'ABN' },
    { name: 'code', label: 'State/Postal Code' },
  ];
  const mobileHeaders = ['entityName', 'abn'];
  const onInputSuffixClick = (name: string) => {
    if (name === 'Password') {
      setShowPassword((e) => !e);
    } else {
      setRetypePassword((e) => !e);
    }
  };
  const onInputValueChange = (e: Record<string, any>, input: FormField) => {
    dispatch(onChangeRegisterData(input.name, e.target.value));
  };
  const onAutocompleteChange = (
    _event: React.SyntheticEvent,
    value: any,
    input: Record<string, any>
  ) => {
    if (input.name === 'country') {
      dispatch(onChangeRegisterData('state', null));
      setSelectValues({
        ...selectValues,
        state: {},
      });
    }
    dispatch(onChangeRegisterData(input.name, value));
  };
  const onCopyPaste = (e: any) => {
    e.preventDefault();
  };
  const onSearchClick = async (label: string) => {
    try {
      if (label === 'tradingName') {
        setIsLoadingCompanyDetail(true);
        if (registrationDetail?.tradingName.length > 0) {
          const searchByName = await dispatch(
            getDetailsByName(registrationDetail?.tradingName)
          );

          if (searchByName !== null) {
            // window.scrollTo(0, 0);
            setVenueTradingModal(true);
          }
        } else errorNotification('Please enter legal business name');
      } else {
        setIsLoadingCompanyDetailByAbn(true);
        registrationDetail?.abn.length > 0
          ? dispatch(getDetailsByAbn(registrationDetail.abn))
          : errorNotification('Please enter ABN / Company number');
      }
    } catch (e) {
      /** */
    } finally {
      setIsLoadingCompanyDetail(false);
      setIsLoadingCompanyDetailByAbn(false);
    }
  };
  const getComponentFromType = (input: FormField) => {
    let component = null;
    let caseType = () => {
      if (
        input.type === 'autocomplete' ||
        (registrationDetail?.country?.name === 'Australia' &&
          input.type === 'state')
      ) {
        return 'autocomplete';
      } else {
        return 'input';
      }
    };
    switch (caseType()) {
      case 'autocomplete':
        let preparedOptions: OptionsType<string, string>[] | string[] =
          userRoles?.allRoles;
        if (input.name === 'country') {
          preparedOptions = preparedCountries;
        } else if (input.name === 'state') {
          preparedOptions = australiaStates;
        } else if (input.name === 'role') {
          preparedOptions = userRoles?.levelOneRoles;
        } else if (input.name === 'numberOfEmployee') {
          preparedOptions = numberOfEmployeeDropdown;
        } else if (input?.options) {
          preparedOptions = input?.options;
        }
        component = (
          <Autocomplete
            key={input.name}
            options={preparedOptions}
            className={`autocomplete ${
              errors?.[input.name] ? 'input-with-error' : ''
            }`}
            id={input.name}
            getOptionLabel={(option) => (option ? option.name ?? option : '')}
            onChange={(event, value) =>
              onAutocompleteChange(event, value, input)
            }
            onKeyUp={(event) =>
              enterKeyHelper(event, () => {
                onRegisterButtonClick(
                  {
                    currentStep,
                    setCurrentStep: () => setCurrentStep(currentStep + 1),
                    data: registrationDetail,
                  },
                  dispatch,
                  history
                );
              })
            }
            disabled={registrationDetail.inviteMemberLater && input?.fieldType}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {input.name === 'country' ? (
                  <>
                    <img
                      loading="lazy"
                      width="20"
                      className="select__country-flag"
                      src={`https://flagcdn.com/w20/${option?._id?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option?._id?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option?.name}
                  </>
                ) : (
                  option.name ?? option
                )}
              </Box>
            )}
            defaultValue={registrationDetail[input?.name]}
            value={registrationDetail[input?.name]}
            renderInput={(params) => (
              <Input
                variant="filled"
                {...params}
                placeholder={input.placeholder}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        );
        break;
      default:
        component = (
          <Input
            variant="filled"
            placeholder={input.placeholder}
            key={input.name}
            onCopy={input.inputType === 'password' ? onCopyPaste : () => {}}
            onPaste={input.inputType === 'password' ? onCopyPaste : () => {}}
            className={errors?.[input.name] ? 'input-with-error' : ''}
            id={input.name}
            type={
              input.inputType === 'password'
                ? (input.label === 'Password' && showPassword) ||
                  (input.label === 'Re type Password' && showRetypePassword)
                  ? 'text'
                  : input.inputType
                : input?.inputType
            }
            size="small"
            value={
              registrationDetail.inviteMemberLater && input?.fieldType
                ? ''
                : registrationDetail[input?.name]
            }
            onChange={(e) => onInputValueChange(e, input)}
            onKeyUp={(event) =>
              enterKeyHelper(event, () => {
                onRegisterButtonClick(
                  {
                    currentStep,
                    setCurrentStep: () => setCurrentStep(currentStep + 1),
                    data: registrationDetail,
                  },
                  dispatch,
                  history
                );
              })
            }
            disabled={registrationDetail.inviteMemberLater && input?.fieldType}
            InputProps={{
              endAdornment:
                input?.suffix === 'visibility' ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => onInputSuffixClick(input.label)}
                      size="large"
                    >
                      {input.label === 'Password' ? (
                        showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )
                      ) : showRetypePassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : input?.suffix === 'search' ? (
                  <InputAdornment position="start">
                    {(isLoadingCompanyDetail && input.name === 'tradingName') ||
                    (isLoadingCompanyDetailByAbn && input.name === 'abn') ? (
                      <ButtonLoader />
                    ) : (
                      <Search
                        onClick={() => onSearchClick(input.name)}
                        className="search-icon"
                      />
                    )}
                  </InputAdornment>
                ) : (
                  ''
                ),
            }}
          />
        );
    }
    return (
      <div className={input.isFull ? 'form-full-width-field' : ''}>
        <div className="auth-field-name">
          <div>
            <span
              className={`${
                registrationDetail.inviteMemberLater && input?.fieldType
                  ? 'disable-title'
                  : ''
              }`}
            >
              {input.label}
            </span>
            <span className="required-sign">
              {registrationDetail.inviteMemberLater && input?.fieldType
                ? ''
                : '*'}
            </span>
          </div>
          {input?.name === 'password' && (
            <Tooltip
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title="Your password must contain at least eight characters, at least one number; both lower and uppercase letters and at least one special character"
            >
              <Info />
            </Tooltip>
          )}
        </div>
        {component}
        {errors?.[input.name] && (
          <div className="error-message">{errors?.[input.name]}</div>
        )}
      </div>
    );
  };
  const onCheckMemberInviteLater = (name: string, e: any) => {
    const clearedErrors = {
      teamMemberRole: undefined,
      teamMemberFirstName: undefined,
      teamMemberLastName: undefined,
      teamMemberEmail: undefined,
      teamMemberContactNumber: undefined,
    };
    dispatch(onChangeRegisterData('teamMemberRole', null));
    dispatch(onChangeRegisterData('teamMemberFirstName', ''));
    dispatch(onChangeRegisterData('teamMemberLastName', ''));
    dispatch(onChangeRegisterData('teamMemberEmail', ''));
    dispatch(onChangeRegisterData('teamMemberContactNumber', ''));
    dispatch(onChangeRegisterData(name, e.target.checked));
    dispatch(onChangeRegisterData('errors', { ...errors, ...clearedErrors }));
  };
  const legalPolicy = `${BASE_URL}documents/privacy-and-policy`;
  const termsAndCondition = `${BASE_URL}documents/terms-and-conditions`;

  const onSelectRow = (row: Record<string, string>) => {
    dispatch(getDetailsByAbn(row.abn));
    setVenueTradingModal(false);
    dispatch(resetCompanyDetail());
  };

  useEffect(() => {
    dispatch(getUserRoles());
    return () => {
      dispatch({ type: REGISTER_REDUX_CONSTANTS.RESET_REGISTER_DATA });
      setVenueTradingModal(false);
      dispatch(resetCompanyDetail());
    };
  }, []);
  return (
    <div className="register-container ">
      <div className="auth-company-logo">
        <img src={companyLogo} alt="Foodygent" />
      </div>
      <div>
        <div className="register-button-title-row">
          <div className="register-back-button-container">
            <BackButton
              color="primary"
              variant="contained"
              onClick={() => {
                currentStep === 0
                  ? history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
                  : currentStep !== 0 && setCurrentStep(currentStep - 1);
              }}
            />
          </div>
          <div className="register-title">
            {registerConstants[currentStep]?.title}
          </div>
        </div>
        <div className="register-subtitle">
          {registerConstants[currentStep]?.subTitle}
        </div>
        <div key="register-steps" className="register-step-row">
          {registerConstants.map((step, index) => (
            <div
              key={Math.random()}
              className={`register-step ${
                currentStep >= index ? 'register-completed-step' : ''
              }`}
            >
              <div className="register-step-number">{index + 1}</div>
              <div className="register-step-name">{step.name}</div>
            </div>
          ))}
        </div>
        <div className="register-fields-grid">
          {registerConstants[currentStep]?.fields.map(getComponentFromType)}
        </div>
      </div>
      <div className="register-footer-container">
        {currentStep === 0 && (
          <div className="register-checkbox">
            <CheckBox
              onChange={(e) =>
                onCheckMemberInviteLater('isReadPolicyAndTerms', e)
              }
              checked={registrationDetail?.isReadPolicyAndTerms}
              label=""
            />
            <div>
              <div>
                Please click the box to indicate you have read our
                <span className="checkbox-legal-policies">
                  <a href={legalPolicy} target="_blank">
                    Legal Policy
                  </a>{' '}
                </span>
                and
                <span className="checkbox-legal-policies">
                  <a href={termsAndCondition} target="_blank">
                    Terms
                  </a>
                </span>
              </div>
              {!registrationDetail?.isReadPolicyAndTerms &&
                errors?.isReadPolicyAndTerms && (
                  <div className="error-message">
                    {errors?.isReadPolicyAndTerms}
                  </div>
                )}
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="register-checkbox">
            <CheckBox
              defaultChecked
              onChange={(e) => onCheckMemberInviteLater('inviteMemberLater', e)}
              checked={registrationDetail?.inviteMemberLater}
              label="Set up team member invite later"
            />
          </div>
        )}
        <RegisterButton
          currentStep={currentStep}
          setCurrentStep={() => setCurrentStep(currentStep + 1)}
          data={registrationDetail}
        />
        {(currentStep === 0 || currentStep === 1) && (
          <span className="redirect-container">
            Already have an account?
            <span
              className="sign-in-link"
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)}
            >
              Sign In
            </span>
          </span>
        )}
      </div>
      {userDetail?.userId && <AuthenticationModal route="sign-up" />}
      {venueTradingModal && companyDetailsList.length > 0 && (
        <CustomModal
          header="Search Company Name"
          className="confirmation-modal register-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="company-details-table"
          hideModal={() => {
            setVenueTradingModal(false);
            dispatch(resetCompanyDetail());
          }}
        >
          <CustomTable
            headers={headers}
            mobileHeaders={mobileHeaders}
            data={companyDetailsList}
            isPagination
            onClickRow={(row) => onSelectRow(row)}
            className="modal-table"
          />
        </CustomModal>
      )}
    </div>
  );
};
export default Register;
