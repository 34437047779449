import { Edit } from '@mui/icons-material';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { exportExcelIcon } from '../../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../../../assets/images/action-items/printer';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import CustomMenu from '../../../../components/menu/Menu';
import {
  supplierAddressDetailsBlock,
  supplierDetailsFirstBlock,
  supplierDetailsSecondBlock,
  supplierInvoiceHeaders,
  supplierInvoicMobileeHeaders,
  supplierPaymenteDetailsBlock,
} from '../../../../constants/supplierConstants/supplierDetailsConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  exportSingleSupplierExcelsheet,
  exportSingleSupplierPdf,
  exportSupplierExcelsheet,
  getSelectedSupplierDetails,
} from '../../../../store/actions/supplierActions/supplierActions';
import { SUPPLIER_REDUX_CONSTANTS } from '../../../../store/reduxConstants/supplierReduxConstants/supplierReduxConstants';
import companyLogo from '../../../../assets/images/company-logo.svg';
import useMediaQuery from '../../../../hooks/MediaQuery';
import CustomTable from '../../../../components/customTable/CustomTable';
import { format, parseISO } from 'date-fns';
import { audConverter } from '../../../../constants/regexConstants';

const ViewSupplier = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { supplierId } = useParams<Record<string, string>>();
  const supplierDetailsRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLDivElement>(null);

  const { supplier } = useSelector(
    ({ supplierReducer }: Record<string, any>) => supplierReducer ?? {}
  );
  const { getSelectedSupplierDetailsLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const isMobile = useMediaQuery('(max-width: 620px)');

  const onClickBackButton = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS,
    });
  };
  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    dispatch(getSelectedSupplierDetails(supplierId));
  }, []);

  const isValueObject = (value: string | object) => {
    return typeof value === 'object' || value === undefined;
  };

  const onClickPrint = useReactToPrint({
    content: () => {
      const supplier: any = supplierDetailsRef.current?.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle: any = `<div class='print-body-title'>Supplier Details</div>`;
      PrintElem.innerHTML += bodyTitle;
      PrintElem.appendChild(supplier);
      const table: any = tableRef.current?.cloneNode(true);
      PrintElem.appendChild(table);
      const file: any = fileRef.current?.cloneNode(true);
      PrintElem.appendChild(file);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 5mm; } 
    @media print { 
      body { -webkit-print-color-adjust: exact; padding: 28px 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const exportSupplierToExcel = () => {
    dispatch(exportSingleSupplierExcelsheet(supplierId));
  };

  const exportSupplierToPdf = () => {
    dispatch(exportSingleSupplierPdf(supplierId));
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportSupplierToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportSupplierToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        onClickPrint();
      },
    },
  ];

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton onClick={onClickBackButton} />
          <div className="form-title">Supplier Details</div>
        </div>
        <div className="button-container">
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(`/suppliers/list/edit/${supplierId}`)}
          >
            Edit Supplier
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/suppliers/list/edit/${supplierId}`)}
            >
              <Edit />
            </Button>
          </div>
          {/* Mobile Buttons end here */}
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="supplier-action"
          />
        </div>
      </div>

      {getSelectedSupplierDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div ref={supplierDetailsRef}>
          <div className="main-details-container">
            <div className="view-details-white-container details-grid details-grid-two-columns">
              {supplierDetailsFirstBlock.map((field) => (
                <div>
                  <div className="detail-label">{field.label}</div>
                  <div className="detail-value">
                    {supplier?.[field?.name] ?? '-'}
                  </div>
                </div>
              ))}
            </div>

            <div className="view-details-white-container details-grid details-grid-two-columns view-details-inner-block">
              {supplierDetailsSecondBlock.map((field) => (
                <div>
                  <div className="detail-label">{field.label}</div>
                  <div className="detail-value">
                    {field.name === 'categoryIds'
                      ? supplier?.['categoryIds']?.length
                        ? supplier?.['categoryIds']?.map(
                            (category: Record<string, any>) => (
                              <div>
                                <span>{category.name}</span>
                                {category?.subcategoryIds?.length && (
                                  <span className="subcategory-view">
                                    {' '}
                                    -{' '}
                                    {category.subcategoryIds
                                      ?.map(
                                        (subcategory: Record<string, string>) =>
                                          subcategory.name
                                      )
                                      .join(', ')}
                                  </span>
                                )}
                              </div>
                            )
                          )
                        : '-'
                      : supplier?.[field.name] ?? '-'}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="main-details-container">
            <div className="details-block-title">Address Details</div>
            <div className="view-details-white-container details-grid details-grid-two-columns">
              {supplierAddressDetailsBlock.map((field) => (
                <div>
                  <div className="detail-label">{field.label}</div>
                  <div className="detail-value details-instruction-container">
                    {isValueObject(supplier?.[field.name])
                      ? '-'
                      : supplier?.[field.name]}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="main-details-container">
            <div className="details-block-title">Payment Details</div>
            <div className="view-details-white-container details-grid details-grid-two-columns">
              {supplierPaymenteDetailsBlock.map((field) => (
                <div>
                  <div className="detail-label">{field.label}</div>
                  <div className="detail-value">
                    {supplier?.[field.name] ?? '-'}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="main-details-container view-detail-last-container">
            <div className="view-details-white-container">
              <div className="detail-label">Delivery Instructions</div>
              <div className="detail-value details-instruction-container">
                {supplier?.['instructions'] ?? '-'}
              </div>
            </div>
          </div>

          <div className="invoice-table">
            {supplier?.invoiceDetails?.length > 0 && (
              <CustomTable
                headers={supplierInvoiceHeaders}
                mobileHeaders={supplierInvoicMobileeHeaders}
                isPagination={false}
                isAction={false}
                data={supplier.invoiceDetails}
                rightTableFields={['status']}
              />
            )}
          </div>

          <div className="file-container">
            <div ref={fileRef}>
              {supplier.fileName && (
                <div className="main-details-container view-detail-last-container">
                  <div className="view-details-white-container">
                    <div className="detail-label">
                      Attach Credit Application
                    </div>
                    <div className="detail-value details-instruction-container">
                      <a
                        href={supplier.termsandconditionsURL}
                        target="_blank"
                        className="file-value"
                      >
                        {supplier?.['fileName'] ?? '-'}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {supplier?.invoiceDetails?.length > 0 && (
          <>
            <div className="print-header-table invoice-header">
              {supplierInvoiceHeaders?.map((header: Record<string, string>) => (
                <span>{header.label}</span>
              ))}
            </div>
            <div className="print-table-body">
              {supplier?.invoiceDetails?.map((data: Record<string, any>) => (
                <div className="print-table-row invoice-row">
                  <span>{data?.invoiceNumber}</span>
                  <span>
                    {format(parseISO(data.invoiceDate), 'dd MMM yyyy')}
                  </span>
                  <span>{audConverter(data.invoiceAmount)}</span>
                  <span>
                    <span className={`status-tag ${data.status.toLowerCase()}`}>
                      {data.status}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default ViewSupplier;
