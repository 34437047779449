import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

const BackButton: FC<ButtonProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <Button
      color="primary"
      variant="contained"
      className={`back-button ${className}`}
      {...restProps}
    >
      <span className="material-icons-outlined">chevron_left</span>
    </Button>
  );
};

export default BackButton;
