import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import { Edit } from '@mui/icons-material';
import { useEffect } from 'react';
import {
  getSelectedProductDetails,
  resetProductData,
} from '../../../../store/actions/supplierActions/productActions';
import { processTableDataByType } from '../../../../helpers/TableDataProcessHelper';
import { productDetailConstants } from '../../../../constants/supplierConstants/productConstants';
import Loader from '../../../../components/loader/Loader';

export const ViewProduct = () => {
  const history = useHistory();
  const { productId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();

  const { productDetail } = useSelector(
    ({ productReducer }: Record<string, any>) => productReducer ?? {}
  );

  const { getSelectedProductDetailLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  useEffect((): any => {
    dispatch(getSelectedProductDetails(productId));
    return () => dispatch(resetProductData());
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.PRODUCT)}
          />
          <div className="form-title">Product Details</div>
        </div>
        <div className="button-container">
          {!productDetail?.isFreezed && !getSelectedProductDetailLoaderAction && (
            <Button
              className="desktop-button"
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(`/suppliers/products/edit/${productId}`)
              }
            >
              Edit Product
            </Button>
          )}
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            {!productDetail?.isFreezed &&
              !getSelectedProductDetailLoaderAction && (
                <Button
                  className="form-icon-button"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    history.push(`/suppliers/products/edit/${productId}`)
                  }
                >
                  <Edit />
                </Button>
              )}
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>
      {getSelectedProductDetailLoaderAction ? (
        <Loader />
      ) : (
        <div className="main-details-container">
          <div className="view-details-white-container details-grid details-grid-two-columns">
            {productDetailConstants?.map((field) => (
              <div>
                <div className="detail-label">{field.label}</div>
                <div className="detail-value">
                  {processTableDataByType({
                    header: field,
                    row: productDetail,
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProduct;
