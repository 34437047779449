import { displayErrors } from './../../helpers/errorNotifyHelper';
import { successNotification } from '../../components/notifyHelper';
import AuthApiServices from '../../services/apiService/authApiServices/AuthApiservices';

type ResetPassProps = {
  token: string;
  password: string;
};

export const resetPassword = (
  { token, password }: ResetPassProps,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const data = { token, password };
      const response = await AuthApiServices.resetPassword(data);

      if (response?.status === 200) {
        successNotification(response?.data?.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
