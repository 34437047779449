import { RECIPE_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

type RecipeIdsType = {
  recipeIds: string;
};

export const recipeApiServices = {
  recipeList: (params: Record<string, any>) =>
    ApiService.getData(RECIPE_URLS.RECIPE_LIST, { params }),
  addRecipe: (
    data: Record<
      string,
      string | string[] | number | Record<string, string | number>[]
    >
  ) => ApiService.postData(RECIPE_URLS.ADD_RECIPE, data),
  uploadRecipePhoto: (
    data: FormData,
    config: Record<string, Record<string, string>>
  ) => ApiService.putData(RECIPE_URLS.UPLOAD_RECIPE_PHOTO, data, config),
  getRecipeDetailsById: (recipeId: string) =>
    ApiService.getData(`${RECIPE_URLS.GET_RECIPE_DETAILS_BY_ID}${recipeId}`),
  updateProductDetailsById: (
    data: Record<string, string | number | undefined | null>,
    recipeId: string
  ) =>
    ApiService.putData(
      `${RECIPE_URLS.UPDATE_RECIPE_DETAILS_BY_ID}${recipeId}`,
      data
    ),
  deleteRecipe: (params: Record<string, string>) =>
    ApiService.deleteData(RECIPE_URLS.DELETE_RECIPE, { params }),
  downloadRecipeExcelSheet: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: RECIPE_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: params,
    }),
  downloadRecipePdf: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: RECIPE_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...params,
        userDate: new Date(),
      },
    }),
  downloadSingleRecipeExcelSheet: (recipeId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${RECIPE_URLS.VIEW_EXPORT}${recipeId}/excel-download`,
      responseType: 'blob',
      params: {
        recipeId,
      },
    }),
  downloadSingleRecipePdf: (recipeId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${RECIPE_URLS.VIEW_EXPORT}${recipeId}/pdf-download`,
      responseType: 'blob',
      params: {
        recipeId,
        userDate: new Date(),
      },
    }),
  getRecipeCategoryList: () =>
    ApiService.getData(RECIPE_URLS.GET_RECIPE_CATEGORY_LIST),
  updateRecipeCategory: (data: Record<string, any>) =>
    ApiService.putData(RECIPE_URLS.UPDATE_RECIPE_CATEGORY, data),
  getRecipeCategoryDropdown: () =>
    ApiService.getData(RECIPE_URLS.RECIPE_CATEGORY_LIST_DROPDOWN),
  getProductRecipeList: () =>
    ApiService.getData(RECIPE_URLS.RECIPE_PRODUCT_LIST),
  getPreviousRecipeDropdown: (params: Record<string, any>) =>
    ApiService.getData(RECIPE_URLS.PREVIOUSE_RECIPE_DROPDOWN, { params }),
  saveAsDraft: (
    data: Record<
      string,
      string | string[] | number | Record<string, string | number>[]
    >
  ) => ApiService.postData(RECIPE_URLS.SAVE_AS_DRAFT, data),
  updateDraftRecipe: (data: Record<string, any>) =>
    ApiService.putData(
      `${RECIPE_URLS.UPDATE_DRAFT_RECIPE}${data._id}/update/draft`,
      data
    ),
};
