import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import Select from '../select/Select';

const noPerPage = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface PaginationProps {
  total?: number;
  limit?: number;
  pages?: number;
  page?: number;
  className?: string;
  pageActionClick?: any;
  onChangeLimit?: any;
}

const Pagination: FC<PaginationProps> = (props) => {
  const {
    total,
    limit,
    pages,
    page,
    className,
    pageActionClick,
    onChangeLimit,
  } = props;

  const paginationClass = `pagination-container ${className}`;

  const [recordLimit, setRecordLimit] = useState(15);

  const fromRecordCount = useMemo(
    () => page && limit && (page - 1) * limit + 1,
    [page, limit, total]
  );
  const toRecordCount = useMemo(
    () =>
      total && page && limit && (total < page * limit ? total : page * limit),
    [page, limit, total]
  );

  const onNextClick = () =>
    page && pages && (page < pages ? pageActionClick(page + 1) : null);
  const onPrevClick = () =>
    page && (page > 1 ? pageActionClick(page - 1) : null);
  const onFirstClick = () => page && (page > 1 ? pageActionClick(1) : null);
  const onLastClick = () =>
    page && pages && (page < pages ? pageActionClick(pages) : null);
  const onSelectLimit = (event: any) => {
    setRecordLimit(event?.target?.value);
    onChangeLimit(event?.target?.value);
  };

  useEffect(() => {
    const found = noPerPage.find((e) => e.value === limit);
    let value = 15;

    if (found) {
      value = found.value;
    }
    setRecordLimit(value);
  }, [limit]);

  if (total === 0) {
    return null;
  }

  return (
    <div className={paginationClass}>
      <div className="records-per-page-container">
        <span>Items per page</span>
        <Select
          options={noPerPage}
          onChange={onSelectLimit}
          value={recordLimit}
          className="no-per-page-select"
        />
      </div>
      <div className="pagination">
        <span className="ml-10">
          {fromRecordCount}-{toRecordCount} of {total}
        </span>
        <div className="page-handler">
          <div
            className={`first-page ${
              page === 1 && 'pagination-button-disabled'
            }`}
            onClick={onFirstClick}
          >
            <span className="material-icons-round">first_page</span>
          </div>
          <div
            className={`prev-page ${
              page === 1 && 'pagination-button-disabled'
            }`}
            onClick={onPrevClick}
          >
            <span className="material-icons-round">arrow_back_ios</span>
          </div>
          <div className="page-number">{page}</div>
          <div
            className={`next-page ${
              page === pages && 'pagination-button-disabled'
            }`}
            onClick={onNextClick}
          >
            <span className="material-icons-round">arrow_forward_ios</span>
          </div>
          <div
            className={`last-page ${
              page === pages && 'pagination-button-disabled'
            }`}
            onClick={onLastClick}
          >
            <span className="material-icons-round">last_page</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
