import { successNotification } from '../../components/notifyHelper';
import {
  getFCMTokenFromLocalStorage,
  saveAuthTokenToLocalStorage,
} from '../../helpers/localstoragehelper';
import signUpProcessHelper from '../../helpers/signUpProcessHelper';
import AuthApiServices from '../../services/apiService/authApiServices/AuthApiservices';
import notificationApiServices from '../../services/apiService/firebaseApiServices/notificationApiServices';
import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { PROFILE_REDUX_CONSTANTS } from '../reduxConstants/profileReduxConstant';
import { displayErrors } from './../../helpers/errorNotifyHelper';
import {
  ADMIN_ROUTE_CONST,
  ROUTE_CONSTANTS_VARIABLE,
} from './../../routes/RouteConstants';
import { AUTH_REDUX_CONSTANTS } from './../reduxConstants/authReduxConstants';

type LoginUserProps = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const loginUser = (
  { email, password }: LoginUserProps,
  history: any,
  rememberMe: boolean
) => {
  return async (dispatch: any) => {
    try {
      const data = {
        email: email.toLowerCase().trim(),
        password: password.trim(),
        rememberMe,
      };
      const response = await AuthApiServices.loginUser(data);

      if (response?.status === 200) {
        if (response.data.data.token) {
          saveAuthTokenToLocalStorage(response.data.data.token);
          successNotification(response?.data?.message ?? 'Login successfully.');
          dispatch({
            type: AUTH_REDUX_CONSTANTS.ADD_DATA,
            data: {
              accessModuleList: response.data.data.moduleAccess,
              role: response.data.data.role,
            },
            status: true,
          });
          let key = response.data.data.moduleAccess[0]?.name.toUpperCase();
          history.push(ADMIN_ROUTE_CONST[key]);
        } else {
          const route = signUpProcessHelper(response?.data?.data);
          history.push(route);

          dispatch({
            type: PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL,
            data: { ...response?.data.data, rememberMe },
          });
          return true;
        }
      } else return false;
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const logOutUser = (history: any) => {
  return async (dispatch: any) => {
    try {
      const FCMToken = getFCMTokenFromLocalStorage();
      FCMToken &&
        (await notificationApiServices.disconnectFirebase({
          firebaseToken: FCMToken,
        }));

      const response = await AuthApiServices.logoutUser();
      if (response?.status === 200) {
        dispatch({
          type: AUTH_REDUX_CONSTANTS.ADD_DATA,
          data: {
            accessModuleList: [],
            role: '',
          },
          status: false,
        });
        dispatch({
          type: LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION,
        });
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
        successNotification(
          response.data?.message || 'Logged out successfully.'
        );
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
