import { SOFTWARE_INTEGRATION_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/softwareIntegrationReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface softwareIntegrationIniitialProps {
  softwareDetails: Record<string, string | boolean>;
  accountDetail: Record<string, string>;
  organizations: Record<string, Record<string, string> | string>;
}

const initialReducer: softwareIntegrationIniitialProps = {
  softwareDetails: {},
  accountDetail: {},
  organizations: {},
};

export const softwareIntegrationReducer = (
  state = initialReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_CURRENT_SOFTWARE:
      return { ...state, softwareDetails: action.data };

    case SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ACCOUNT_DETAILS:
      return { ...state, accountDetail: action.data };

    case SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ORGANIZATION_DETAIL:
      return { ...state, organizations: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialReducer,
      };

    default:
      return state;
  }
};
