import { Collapse } from '@mui/material';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { SIDEBAR_CONSTANTS } from '../../../constants/sidebarConstants';
import { strockIconConstants } from '../../../constants/strokeIconConstants';
import sidebarHelper from '../../../helpers/sidebarHelper';

interface SideBarProps {
  onSideMenuOpen: () => void;
}

const Sidebar = () => {
  const location = useLocation();
  const { accessModuleList, flow } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const data = sidebarHelper(accessModuleList, profileDetail.role);

  return (
    <>
      <div className="side-menu">
        {data.map((menu) => (
          <>
            <NavLink
              key={menu.name}
              to={menu?.url ? menu?.url : menu?.subMenu[0]?.url}
              className={`menu ${
                location?.pathname?.includes(menu.name) &&
                flow !== 1 &&
                flow !== 2
                  ? 'active'
                  : ''
              }`}
              style={{
                pointerEvents: flow === 1 || flow === 2 ? 'none' : 'auto',
              }}
            >
              <span
                className={`menu-icon ${
                  strockIconConstants.includes(menu.name)
                    ? 'menu-stroke-icon'
                    : 'menu-fill-icon'
                }`}
              >
                {menu.icon}
              </span>
              {menu.label}
            </NavLink>

            <Collapse
              unmountOnExit
              in={
                menu?.subMenu &&
                location.pathname?.indexOf(menu.name) > -1 &&
                flow !== 1 &&
                flow !== 2
              }
            >
              {menu?.subMenu &&
                menu.subMenu.map((subMenu: any) => (
                  <NavLink
                    key={subMenu.name}
                    to={subMenu.url}
                    className="sub-menu"
                    style={{
                      pointerEvents: flow === 1 || flow === 2 ? 'none' : 'auto',
                    }}
                  >
                    {subMenu.label}
                  </NavLink>
                ))}
            </Collapse>
          </>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
