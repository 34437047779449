import {
  DeleteRounded,
  EditRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { exportExcelIcon } from '../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../assets/images/action-items/exportPdfIcon';
import CalculateIcon from '../../assets/images/CalculateIcon';
import { filterIcon } from '../../assets/images/filter';
import Button from '../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../components/customModal/CustomModal';
import FilterModal from '../../components/filterModal/FilterModal';
import Loader from '../../components/loader/Loader';
import CustomMenu from '../../components/menu/Menu';
import Pagination from '../../components/pagination/Pagination';
import { useQueryParams } from '../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../hooks/useUrlParamsUpdate';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import {
  deleteRecipeAction,
  exportRecipePdf,
  getRecipeCategoryDropdown,
  getRecipeProductDropdown,
} from '../../store/actions/recipeActions';
import { getRecipeListAction } from '../../store/actions/recipeActions';
import { exportRecipeExcelsheet } from '../../store/actions/recipeActions';
import { resetfilterOnModuleChange } from '../../store/actions/listFiltersAction';
import { resetFilterHelper } from '../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../assets/images/mobileResetFilter';
import _ from 'lodash';
import { addRecipeIcon } from '../../assets/images/action-items/addRecipeIcon';
import { recipeDetailsHeader } from '../../constants/recipeCostConstants';
import { Tooltip } from '@mui/material';
import { dateCalculationHelper } from '../../helpers/dateCalculationHelper';
import dayjs from 'dayjs';

const RecipeCost = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowId, setRowId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const { recipeList, categoryDropdown, productDropdown } = useSelector(
    ({ recipeReducer }: Record<string, any>) => recipeReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? true
  );

  const { recipe } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => recipeList ?? {},
    [recipeList]
  );

  const { recipeListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const onPageChange = (page: any) => {
    dispatch(getRecipeListAction({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getRecipeListAction({ page: 1, limit: value, ...filters }));
  };

  const onCalculateClick = (recipeId: string) => {
    history.push(`/recipe-cost/overview/${recipeId}`);
  };

  const onAddRecipeClick = () => {
    history.push('/recipe-cost/action/add');
  };

  const filters = {
    searchString: recipe?.searchString,
    recipeCategoryId: recipe?.recipeCategoryId?._id,
    productNames:
      recipe?.productNames?.length &&
      recipe.productNames
        .map((product: Record<string, any>) => product.name)
        ?.toString(),
    numberOfPortionsFrom: recipe?.numberOfPortionsFrom,
    numberOfPortionsTo: recipe?.numberOfPortionsTo,
    salePriceFrom: recipe?.salePriceFrom,
    salePriceTo: recipe?.salePriceTo,
    startDate: recipe?.startDate,
    endDate: recipe?.endDate,
  };

  const exportToExcel = () => {
    dispatch(exportRecipeExcelsheet(filters));
  };

  const exportToPdf = () => {
    dispatch(exportRecipePdf({ ...filters, userDate: new Date() }));
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];
  const filterFields = [
    {
      name: 'searchString',
      label: 'Recipe',
      placeholder: 'Search by Recipe',
    },
    {
      name: 'recipeCategoryId',
      label: 'Category',
      placeholder: 'Select category',
      type: 'autocomplete',
      options: categoryDropdown,
      className: 'filter-field-gap',
    },
    {
      name: 'productNames',
      label: 'Product',
      placeholder: 'Select Products',
      type: 'autocomplete',
      options: productDropdown,
      isMulti: true,
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'numberofPortion',
      label: '',
      placeholder: '',
      type: 'rang',
      fields: [
        {
          name: 'numberOfPortionsFrom',
          label: 'Serving Size From',
          placeholder: 'From',
          type: 'numberOfPortions',
        },
        {
          name: 'numberOfPortionsTo',
          label: 'Serving Size To',
          placeholder: 'To',
          type: 'numberOfPortions',
        },
      ],
    },
    {
      name: 'price',
      label: '',
      placeholder: '',
      type: 'rang',
      fields: [
        {
          name: 'salePriceFrom',
          label: 'Price From',
          placeholder: 'From',
          type: 'salePrice',
        },
        {
          name: 'salePriceTo',
          label: 'Price To',
          placeholder: 'To',
          type: 'salePrice',
        },
      ],
    },
  ];

  const addMenuItems = [
    {
      icon: addRecipeIcon,
      name: 'Add Recipe',
      onClick: () => history.push('/recipe-cost/action/add'),
    },
    {
      icon: <span className="material-icons">category</span>,
      name: 'Add/ Edit Category',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.ADD_EDIT_RECIPE_CATEGORY);
      },
    },
  ];

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: recipe?.limit ?? limit ?? paramLimit ?? 15,
    searchString: recipe?.searchString,
    recipeCategoryId: recipe?.recipeCategoryId?._id,
    productNames:
      recipe?.productNames?.length &&
      recipe.productNames
        .map((product: Record<string, any>) => product.name)
        ?.toString(),
    numberOfPortionsFrom: recipe?.numberOfPortionsFrom,
    numberOfPortionsTo: recipe?.numberOfPortionsTo,
    salePriceFrom: recipe?.salePriceFrom,
    salePriceTo: recipe?.salePriceTo,
    startDate: recipe?.startDate
      ? new Date(recipe?.startDate)?.toISOString()
      : undefined,
    endDate: recipe?.endDate
      ? new Date(recipe?.endDate)?.toISOString()
      : undefined,
  });

  const onDeleteRecipe = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    await dispatch(getRecipeListAction({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteRecipeAction(rowId, afterSuccessfulDeletion));
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const onEditRecipe = (recipeId: string) => {
    history.push(`/recipe-cost/action/edit/${recipeId}`);
  };
  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getRecipeListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'recipe',
      () => setOpenFilterModal(false),
      [getRecipeProductDropdown, getRecipeCategoryDropdown]
    );
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: limit ?? paramLimit ?? 15,
      ...filters,
    };

    dispatch(getRecipeListAction({ ...params }));
    dispatch(getRecipeProductDropdown());
    dispatch(getRecipeCategoryDropdown());
    dispatch(resetfilterOnModuleChange('recipe'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Recipe Cost</div>
        <div className="button-container">
          {!_.isEmpty(recipe) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(ROUTE_CONSTANTS_VARIABLE.ADD_EDIT_RECIPE_CATEGORY)
            }
          >
            Add/Edit Category
          </Button>
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={onAddRecipeClick}
          >
            Add Recipe
          </Button>
          {/* Mobile Buttons start here */}
          {!_.isEmpty(recipe) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          <div className="mobile-button-container">
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="add"
              menuItems={addMenuItems}
              id="user-action"
            />
          </div>
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(recipe)}
            filterData={recipe}
            isFor="Recipe"
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="recipe-cost-action"
          />
        </div>
      </div>

      {docs?.length ? (
        recipeListLoaderAction ? (
          <Loader />
        ) : (
          <div className="list-container">
            <div className="list-scroll">
              {docs.map((recipe: Record<string, any>) => (
                <div>
                  <div className="recipe-container">
                    <div className="recipe-image-container">
                      <img src={recipe?.coverPhotoURL} alt="Recipe Image"></img>
                    </div>
                    <div className="recipe-name-container">
                      {recipe.name?.length > 30 ? (
                        <Tooltip enterDelay={1000} arrow title={recipe.name}>
                          <div>
                            <span>{recipe.name}</span>
                            <span>{`(${recipe.recipeNumber})`}</span>
                          </div>
                        </Tooltip>
                      ) : (
                        <div>
                          <span>{recipe.name}</span>
                          <span className="id-span">{` (${recipe.recipeNumber})`}</span>
                        </div>
                      )}
                      <span className="date-container">
                        {dayjs(
                          dateCalculationHelper(
                            recipe?.createdAt,
                            profileDetail?.timeZoneOffSet
                          )
                        ).format('DD MMMM YYYY')}
                      </span>
                    </div>
                    <div className="recipe-detail-container">
                      {recipeDetailsHeader.map((item: any) => (
                        <div>
                          <div className="detail-label">{item.label}</div>
                          <div>
                            {recipe[item.name]?.toString().length > 15 ? (
                              <Tooltip
                                enterDelay={1000}
                                arrow
                                title={recipe[item.name]}
                              >
                                <span>{recipe[item.name] ?? '-'}</span>
                              </Tooltip>
                            ) : (
                              <span
                                className={`${
                                  item.type === 'tag' && 'status-tag '
                                } ${recipe[item.name]?.toLowerCase()}`}
                              >
                                {recipe[item.name] ?? '-'}
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="mobile-view-date-container">
                        <div className="detail-label">Date</div>
                        <span>
                          {dayjs(
                            dateCalculationHelper(
                              recipe?.createdAt,
                              profileDetail?.timeZoneOffSet
                            )
                          ).format('DD MMMM YYYY')}
                        </span>
                      </div>
                      <div>
                        <span className="action-cell">
                          <VisibilityRounded
                            onClick={() => onCalculateClick(`${recipe._id}`)}
                          ></VisibilityRounded>
                          <EditRounded
                            onClick={() => onEditRecipe(`${recipe._id}`)}
                          ></EditRounded>
                          <DeleteRounded
                            onClick={() => onDeleteRecipe(recipe._id)}
                          ></DeleteRounded>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {docs?.length && (
              <Pagination
                total={total}
                limit={limit}
                page={page}
                pages={pages}
                pageActionClick={onPageChange}
                onChangeLimit={onChangeLimit}
              />
            )}
          </div>
        )
      ) : (
        <div className="no-record-text">No record found</div>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Recipe"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="recipe"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getRecipeListAction}
          isDateFilters
          filterAction={[getRecipeProductDropdown, getRecipeCategoryDropdown]}
        />
      )}
    </div>
  );
};

export default RecipeCost;
