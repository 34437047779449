import { FC, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/button/Button';
import companyLogo from '../../../assets/images/company-logo.svg';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  confirmPlanAction,
  getPlans,
} from '../../../store/actions/planActions';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotification } from '../../../components/notifyHelper';
import BackButton from '../../../components/backButton/BackButton';
import Loader from '../../../components/loader/Loader';
import { getCurrencyRateAction } from '../../../store/actions/commonAction';
import { currencyConversion } from '../../../helpers/currencyConversionHelper';

const Plans: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [planType, setPlanType] = useState<string>('monthly');
  const [selectedPlan, setSelectedPlan] = useState<
    Record<string, string | boolean> | undefined
  >(undefined);
  const { userId } = useParams<Record<string, string>>();

  const { planList } = useSelector(
    ({ planReducer }: Record<string, Record<string, any>>) => planReducer ?? {}
  );

  const { getPlansLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { currencyRate } = useSelector(
    ({ commonReducer }: any) => commonReducer ?? {}
  );

  const { AUD } = useMemo(() => currencyRate ?? {}, [currencyRate]);
  interface PlanType {
    _id?: string;
    price?: string;
    name?: string;
    facilities?: string[];
    tag?: string;
    createdAt?: string;
    updatedAt?: string;
    _v?: number;
  }
  interface PlanListType {
    monthly: PlanType[];
    yearly: PlanType[];
  }
  // const { monthly, yearly }: PlanListType = planList;
  const onPlanSelect = (plan: Record<string, any>) => {
    setSelectedPlan({
      planId: plan._id,
      userId,
      isTrial: false,
    });
  };
  const onTrialPlanSelect = (plan: Record<string, any>) => {
    setSelectedPlan({
      planId: plan._id,
      userId,
      isTrial: true,
    });
  };

  // const plan: PlanType[] = planList;
  useEffect(() => {
    dispatch(getCurrencyRateAction());

    const params = { planType: planType === 'monthly' ? 'Monthly' : 'Yearly' };
    dispatch(getPlans(params));
  }, []);

  const onPlanConfirmClick = () => {
    if (selectedPlan?.planId) {
      dispatch(confirmPlanAction(selectedPlan, history));
    } else {
      errorNotification('Please choose plan');
    }
  };

  return (
    <div className="plans-container">
      <div className="auth-company-logo plan-header-container">
        <img src={companyLogo} alt="Foodygent" />
        <div className="plan-header">
          <div>
            <BackButton
              className="plan-back-button"
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)}
            />
            Choose Subscription Plan
          </div>
        </div>
      </div>
      <div className="fixed-plan-type-container">
        <div className="plan-type-menu">
          <span
            className={planType === 'monthly' ? 'selected-plan-type' : ''}
            onClick={() => {
              setPlanType('monthly');
              dispatch(getPlans({ planType: 'Monthly' }));
            }}
          >
            Monthly
          </span>
          <span
            className={planType === 'yearly' ? 'selected-plan-type' : ''}
            onClick={() => {
              setPlanType('yearly');
              dispatch(getPlans({ planType: 'Yearly' }));
            }}
          >
            Yearly
          </span>
          <div className="selected-plan" />
        </div>
      </div>

      {getPlansLoaderAction ? (
        <Loader />
      ) : (
        <>
          <div
            className={`${
              selectedPlan
                ? 'plans-grid-container plans-mobile-grid-container'
                : 'plans-grid-container'
            } ${planList?.length === 0 && 'message-container'}`}
          >
            {planList?.length > 0 ? (
              <>
                <div className="plans-grid">
                  {planList.length > 0 &&
                    planList?.map((plan: any) => (
                      <div
                        key={plan._id}
                        className={`plan ${
                          selectedPlan?.planId === plan?._id
                            ? 'selected-plan'
                            : ''
                        }`}
                      >
                        {plan?.tag && (
                          <div className="ribbon-corner cr-top cr-right cr-sticky cr-blue">
                            Popular
                          </div>
                        )}
                        <div>
                          <div className="plan-name">
                            <div className="plan-name-break">{plan.name}</div>
                          </div>
                          <div className="plan-price">
                            $
                            {AUD &&
                              currencyConversion(parseFloat(plan.price), AUD)}
                            {plan.price.toString().includes('GST')
                              ? ' + GST'
                              : ''}
                            <span>{`/${
                              planType === 'monthly' ? 'Month' : 'Year'
                            }`}</span>
                          </div>
                          <div className="plan-facilities">
                            {plan?.facilities?.length &&
                              plan.facilities.map(
                                (facility: string[], index: number) => (
                                  <div
                                    className="plan-facility"
                                    key={`facility-${index}`}
                                  >
                                    <span className="material-icons">
                                      check_circle
                                    </span>
                                    {facility}
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                        <div className="button-wrapper">
                          <Button
                            className={`${
                              selectedPlan?.planId === plan?._id &&
                              !selectedPlan?.isTrial
                                ? 'selected-button'
                                : ''
                            } choose-plan-button`}
                            onClick={() => onPlanSelect(plan)}
                          >
                            Choose Plan
                          </Button>
                          <Button
                            className={`${
                              selectedPlan?.planId === plan?._id &&
                              selectedPlan?.isTrial
                                ? 'selected-button'
                                : ''
                            } choose-plan-button`}
                            onClick={() => onTrialPlanSelect(plan)}
                          >
                            14 days trial
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>

                {planList.length > 0 && (
                  <div className="plan-confirm-button-container">
                    <Button
                      className="auth-button plan-confirm-button"
                      variant="contained"
                      color="primary"
                      onClick={onPlanConfirmClick}
                    >
                      Confirm
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div>
                No {planType.toLowerCase()} plans available at the moment
              </div>
            )}
          </div>

          {/* Mobile button starts here  */}

          {selectedPlan && (
            <div className="mobile-plan-confirmation-container">
              <Button
                className="auth-button plan-confirm-button"
                variant="contained"
                color="primary"
                onClick={onPlanConfirmClick}
              >
                Confirm
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Plans;
