import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../../assets/images/action-items/printer';
import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import FilterModal from '../../../components/filterModal/FilterModal';
import CustomMenu from '../../../components/menu/Menu';
import CostSalesReportsChart from './CostSalesReportsChart';
import ReportsChart from './ReportsChart';
import SalesReportsChart from './SalesReportsChart';
import {
  supplierListDropdownInReportsAction,
  categoryDropdownListInReportsAction,
  exportPurchasesExcelsheet,
  exportPurchasesPdf,
  supplierPurchaseChartAction,
  getPurchasefoodAndBeveragesDetailsAction,
  exportSalesExcelSheet,
  exportSalesPdf,
  getSalesFoodAndBeveragesDetailsAction,
  getSupplierSalesChartAction,
  getSupplierCostSalesChartAction,
  getCostSalesDetailsAction,
  exportCostSalesExcelSheet,
  exportCostSalesPdf,
} from '../../../store/actions/reportActions/reportAction';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import _, { has } from 'lodash';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';

export type ReportsRefType = {
  onPrintPurchaseChart?: () => void;
  onPrintSalesChart?: () => void;
  onPrintCostSalesChart?: () => void;
};

const Reports = () => {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');
  const dispatch = useDispatch();

  const childRef = useRef<ReportsRefType>(null);

  const onChangeActiveTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };
  const { supplierDropDown, categoryList } = useSelector(
    ({ reportReducer }: Record<string, any>) => reportReducer ?? {}
  );
  const { purchaseChart, salesChart, costSaleChart } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );
  const { accessModuleList } = useSelector(
    ({ authReducer }: Record<string, any>) => authReducer ?? {}
  );

  const { hasAccessOfCostSales, hasAccessOfPurchase, hasAccessOfSales } =
    useMemo(() => {
      let nameOfTabs = {
        hasAccessOfSales: false,
        hasAccessOfPurchase: false,
        hasAccessOfCostSales: false,
      };
      accessModuleList?.forEach((object: any) => {
        if (object.name === 'report') {
          nameOfTabs.hasAccessOfSales = object.subModules.includes('sales');
          nameOfTabs.hasAccessOfCostSales =
            object.subModules.includes('cost-sales');
          nameOfTabs.hasAccessOfPurchase =
            object.subModules.includes('purchase');
        }
      });
      return nameOfTabs;
    }, [accessModuleList]);

  useEffect(() => {
    if (!hasAccessOfPurchase && !hasAccessOfSales) {
      setActiveTab('2');
    } else if (!hasAccessOfPurchase) {
      setActiveTab('1');
    } else {
      setActiveTab('0');
    }
  }, [hasAccessOfPurchase, hasAccessOfSales, hasAccessOfCostSales]);
  const filters = useMemo(() => {
    let chart;
    if (activeTab === '0') chart = purchaseChart;
    if (activeTab === '1') chart = salesChart;
    if (activeTab === '2') chart = costSaleChart;

    return {
      supplierId: chart?.supplierId?._id,
      categoryId: chart?.categoryId?._id,
      category: chart?.category,
      duration: chart?.duration,
      startDate: chart?.startDate,
      endDate: chart?.endDate,
    };
  }, [salesChart, purchaseChart, costSaleChart, activeTab]);

  const exportToExcel = () => {
    if (activeTab === '0') {
      dispatch(
        exportPurchasesExcelsheet({
          ...filters,
        })
      );
    } else if (activeTab === '1') {
      dispatch(
        exportSalesExcelSheet({
          ...filters,
        })
      );
    } else if (activeTab == '2') {
      dispatch(exportCostSalesExcelSheet({ ...filters }));
    }
  };

  const exportToPdf = () => {
    if (activeTab === '0') {
      dispatch(
        exportPurchasesPdf({
          ...filters,
        })
      );
    } else if (activeTab === '1') {
      dispatch(
        exportSalesPdf({
          ...filters,
        })
      );
    } else if (activeTab === '2') {
      dispatch(exportCostSalesPdf({ ...filters }));
    }
  };
  useUrlParamsUpdate({
    supplierId: purchaseChart?.supplierId?._id,
    categoryId: purchaseChart?.categoryId?._id,
    duration: purchaseChart?.duration,
    chart: purchaseChart?.chart,
    startDate: purchaseChart?.startDate,
    endDate: purchaseChart?.endDate,
  });

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export PDF',
      onClick: exportToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        if (childRef.current?.onPrintPurchaseChart) {
          childRef.current.onPrintPurchaseChart();
        }
        if (childRef.current?.onPrintSalesChart) {
          childRef.current.onPrintSalesChart();
        }
        if (childRef.current?.onPrintCostSalesChart) {
          childRef.current.onPrintCostSalesChart();
        }
      },
    },
  ];

  const filterFieldsCommon = [
    {
      name: 'supplierId',
      label: 'Supplier',
      type: 'autocomplete',
      options: supplierDropDown,
      placeholder: 'Select Supplier',
    },
    {
      name: 'categoryId',
      label: 'Category',
      placeholder: 'Select Category',
      options: categoryList,
      type: 'autocomplete',
      className: 'filter-field-gap',
    },
  ];
  const filterFields = [
    {
      name: 'chart',
      label: 'Chart',
      type: 'autocomplete',
      placeholder: 'Select Chart',
      options: ['Line Chart', 'Bar Chart'],
      className: 'filter-field-gap',
    },
    {
      name: 'duration',
      label: 'Duration',
      type: 'autocomplete',
      placeholder: 'Select Duration',
      options: ['Weekly', 'Fortnightly', 'Monthly', 'Yearly', 'Custom'],
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      type: 'date',
      placeholder: 'From',
      className: 'filter-field-gap',
      disableFutureDate: true,
    },
    {
      name: 'endDate',
      label: 'To',
      type: 'date',
      placeholder: 'To',
      className: 'filter-field-gap',
      disableFutureDate: true,
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      activeTab === '0'
        ? supplierPurchaseChartAction
        : activeTab === '1'
        ? getSupplierSalesChartAction
        : activeTab === '2'
        ? getSupplierCostSalesChartAction
        : () => {},
      {},
      activeTab === '0'
        ? 'purchaseChart'
        : activeTab === '1'
        ? 'salesChart'
        : 'costSale',
      () => setOpenFilterModal(false)
    );
  };

  useEffect(() => {
    if (activeTab === '0') {
      dispatch(supplierListDropdownInReportsAction());
      dispatch(categoryDropdownListInReportsAction());
      dispatch(supplierPurchaseChartAction(filters));
      dispatch(getPurchasefoodAndBeveragesDetailsAction(filters));
    } else if (activeTab === '1') {
      dispatch(getSalesFoodAndBeveragesDetailsAction(filters));
      dispatch(getSupplierSalesChartAction(filters));
    } else if (activeTab === '2') {
      dispatch(getSupplierCostSalesChartAction());
      dispatch(getCostSalesDetailsAction(filters));
    }
  }, [activeTab]);

  return (
    <div className="report-container">
      <div className="page-header report-page-header">
        <div className="page-title">Reports</div>
        <div className="button-container">
          {(!_.isEmpty(purchaseChart) ||
            !_.isEmpty(salesChart) ||
            !_.isEmpty(costSaleChart)) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {(!_.isEmpty(purchaseChart) ||
            !_.isEmpty(salesChart) ||
            !_.isEmpty(costSaleChart)) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            isFilterApplied={
              activeTab === '0'
                ? !_.isEmpty(purchaseChart)
                : activeTab === '1'
                ? !_.isEmpty(salesChart)
                : activeTab === '2'
                ? !_.isEmpty(costSaleChart)
                : undefined
            }
            filterData={
              activeTab === '0'
                ? purchaseChart
                : activeTab === '1'
                ? salesChart
                : activeTab == '2'
                ? costSaleChart
                : undefined
            }
            onClick={() => setOpenFilterModal((e) => !e)}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="report-action"
          />
        </div>
      </div>

      <div className="reports-content-container">
        <TabContext value={activeTab}>
          <TabList
            onChange={onChangeActiveTab}
            aria-label="Reports chart"
            variant="scrollable"
            scrollButtons="auto"
          >
            {hasAccessOfPurchase && <Tab label="Purchase Chart" value="0" />}
            {hasAccessOfSales && <Tab label="Sales Chart" value="1" />}
            {hasAccessOfCostSales && <Tab label="Cost Sales Chart" value="2" />}
          </TabList>

          {hasAccessOfPurchase && (
            <TabPanel value="0" sx={{ padding: '24px 0' }}>
              <ReportsChart ref={childRef} />
            </TabPanel>
          )}
          {hasAccessOfSales && (
            <TabPanel value="1" sx={{ padding: '24px 0' }}>
              <SalesReportsChart ref={childRef} />
            </TabPanel>
          )}
          {hasAccessOfCostSales && (
            <TabPanel value="2" sx={{ padding: '24px 0' }}>
              <CostSalesReportsChart ref={childRef} />
            </TabPanel>
          )}
        </TabContext>
      </div>

      {openFilterModal && (
        <FilterModal
          requestFrom={
            activeTab === '0'
              ? 'purchaseChart'
              : activeTab === '1'
              ? 'salesChart'
              : activeTab === '2'
              ? 'costSaleChart'
              : ''
          }
          closeModal={() => setOpenFilterModal(false)}
          filterFields={
            activeTab === '0'
              ? [...filterFieldsCommon, ...filterFields]
              : [...filterFields]
          }
          updateListAction={
            activeTab === '0'
              ? supplierPurchaseChartAction
              : activeTab === '1'
              ? getSupplierSalesChartAction
              : activeTab === '2'
              ? getSupplierCostSalesChartAction
              : () => {}
          }
          isDateFilters
        />
      )}
    </div>
  );
};

export default Reports;
