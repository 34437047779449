export const ResendEmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        id="Resend_Email"
        data-name="Resend Email"
        transform="translate(-819.264 -1549.264)"
      >
        <g id="mark_email_read_black_24dp" transform="translate(819 1549)">
          <g id="Group_37738" data-name="Group 37738">
            <rect
              id="Rectangle_9089"
              data-name="Rectangle 9089"
              width="20"
              height="20"
              transform="translate(0.264 0.264)"
              fill="none"
            ></rect>
          </g>
          <g
            id="Group_37739"
            data-name="Group 37739"
            transform="translate(1.711 3.421)"
          >
            <path
              id="Path_162313"
              data-name="Path 162313"
              d="M10.622,17.685H3.711A1.716,1.716,0,0,1,2,15.975V5.711A1.716,1.716,0,0,1,3.711,4H17.4a1.716,1.716,0,0,1,1.711,1.711v5.714a5.981,5.981,0,0,0-8.553,5.406,5.674,5.674,0,0,0,.064.854Zm-.522-6.27a.865.865,0,0,0,.907,0l6.047-3.781a.726.726,0,0,0-.77-1.232L10.553,9.987,4.823,6.4a.726.726,0,1,0-.77,1.232Z"
              transform="translate(-2 -4)"
              fill="#f21d86"
            ></path>
          </g>
        </g>
        <path
          id="Path_162314"
          data-name="Path 162314"
          d="M9.968,5.024a3.493,3.493,0,1,0,.681,3.969.437.437,0,0,0-.393-.629.425.425,0,0,0-.384.231A2.617,2.617,0,1,1,7.5,4.872a2.582,2.582,0,0,1,1.842.777l-.659.659a.437.437,0,0,0,.306.747h1.567a.438.438,0,0,0,.437-.437V5.051a.438.438,0,0,0-.747-.31l-.279.284Z"
          transform="translate(826.897 1556.904)"
          fill="#f21d86"
        ></path>
      </g>
    </svg>
  );
};
