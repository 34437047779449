export const RECIPE_REDUX_CONSTANTS = {
  RECIPE_LIST: 'RECIPE_LIST',
  ON_CHANGE_RECIPE_DATA: 'ON_CHANGE_RECIPE_DATA',
  ON_CHANGE_RECIPE_PRODUCT_QUANTITY: 'ON_CHANGE_RECIPE_PRODUCT_QUANTITY',
  ON_CHANGE_TABLE_DATA: 'ON_CHANGE_TABLE_DATA',
  DELETE_PRODUCT_FOR_RECIPE: 'DELETE_PRODUCT_FOR_RECIPE',
  UPLOAD_RECIPE_PHOTO: 'UPLOAD_RECIPE_PHOTO',
  OPTIONAL_RECIPE_PHOTOS: 'OPTIONAL_RECIPE_PHOTOS',
  RESET_RECIPE_DATA: 'RESET_RECIPE_DATA',
  GET_SELECTED_RECIPE_DETAIL: 'GET_SELECTED_RECIPE_DETAIL',
  DELETE_RECIPE: 'DELETE_RECIPE',
  PRODUCT_DETAILS: 'PRODUCT_DETAILS',
  GET_RECIPE_CATEGORY_LIST: 'GET_RECIPE_CATEGORY_LIST',
  ON_ADD_RECIPE_CATEGORY: 'ON_ADD_RECIPE_CATEGORY',
  ON_DELETE_CATEGORY: 'ON_DELETE_CATEGORY',
  RECIPE_CATEGORY_DROPDOWN: 'RECIPE_CATEGORY_DROPDOWN',
  RECIPE_PRODUCT_LIST: 'RECIPE_PRODUCT_LIST',
  PREVIOUS_RECIPE_LIST: 'PREVIOUS_RECIPE_LIST',
  RECIPE_CALCULATION: 'RECIPE_CALCULATION',
  DELETE_PREVIOUS_RECIPE: 'DELETE_PREVIOUS_RECIPE',
};
