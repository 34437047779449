export const recipeCostIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3204 14.6347C15.2268 14.6035 15.1311 14.5708 15.0357 14.5368V13.1394C15.2931 13.1906 15.4466 13.3138 15.4595 13.3243C15.5817 13.4287 15.7653 13.4152 15.8709 13.2936C15.977 13.1715 15.9639 12.9865 15.8418 12.8804C15.8241 12.8651 15.5246 12.6113 15.0357 12.5464V12.4309C15.0357 12.2691 14.9046 12.1379 14.7428 12.1379C14.5811 12.1379 14.4499 12.2691 14.4499 12.4309V12.5676C14.4004 12.5775 14.35 12.5893 14.2985 12.604C13.8482 12.7317 13.5159 13.0993 13.4316 13.5634C13.3571 13.9729 13.4989 14.3663 13.8017 14.59C13.9653 14.7108 14.1695 14.8215 14.4499 14.9387V16.7055C14.19 16.6905 14.0177 16.6358 13.7288 16.4579C13.5911 16.373 13.4106 16.4159 13.3258 16.5536C13.2409 16.6914 13.2838 16.8718 13.4216 16.9567C13.8222 17.2035 14.0942 17.2744 14.4499 17.2915V17.4423C14.4499 17.6041 14.5811 17.7352 14.7429 17.7352C14.9046 17.7352 15.0358 17.604 15.0358 17.4423V17.2691C15.7234 17.1436 16.1575 16.5988 16.2428 16.0618C16.3454 15.4156 15.9834 14.8554 15.3204 14.6347ZM14.1497 14.1187C14.0043 14.0113 13.9811 13.8156 14.0079 13.6682C14.0421 13.4802 14.1763 13.252 14.4498 13.1704V14.2959C14.3361 14.2397 14.2336 14.1806 14.1497 14.1187ZM15.6641 15.9699C15.6187 16.2554 15.4065 16.5594 15.0357 16.6684V15.157C15.0683 15.1681 15.1012 15.1792 15.1353 15.1906C15.5234 15.3198 15.7211 15.6111 15.6641 15.9699Z"
      fill="#666666"
    />
    <path
      d="M14.4818 2.18893C14.4818 1.89713 14.2444 1.65979 13.9527 1.65979H2.28012C1.98836 1.65979 1.75098 1.89717 1.75098 2.18893V2.97846C1.75098 3.14026 1.88215 3.27139 2.04391 3.27139C2.20566 3.27139 2.33684 3.14022 2.33684 2.97846V2.24565H13.8959V5.07963H2.33687V4.34811C2.33687 4.18631 2.2057 4.05518 2.04395 4.05518C1.88219 4.05518 1.75102 4.18635 1.75102 4.34811V5.13639C1.75102 5.42815 1.9884 5.66553 2.28016 5.66553H13.9527C14.2445 5.66553 14.4818 5.42815 14.4818 5.13639V2.18893Z"
      fill="#666666"
    />
    <path
      d="M4.26633 7.55198C4.26633 7.28624 4.05012 7.07007 3.78441 7.07007H2.33641C2.07066 7.07007 1.85449 7.28628 1.85449 7.55198V8.99995C1.85449 9.26569 2.0707 9.4819 2.33641 9.4819H3.78441C4.05016 9.4819 4.26633 9.26569 4.26633 8.99995V7.55198ZM3.68043 8.89601H2.44035V7.65593H3.68043V8.89601Z"
      fill="#666666"
    />
    <path
      d="M7.63742 7.55198C7.63742 7.28624 7.42121 7.07007 7.15547 7.07007H5.7075C5.44176 7.07007 5.22559 7.28628 5.22559 7.55198V8.99995C5.22559 9.26569 5.4418 9.4819 5.7075 9.4819H7.15547C7.42121 9.4819 7.63742 9.26569 7.63742 8.99995V7.55198ZM7.05152 8.89601H5.81145V7.65593H7.05152V8.89601Z"
      fill="#666666"
    />
    <path
      d="M9.07863 7.07007C8.81289 7.07007 8.59668 7.28628 8.59668 7.55198V8.99995C8.59668 9.26569 8.81289 9.4819 9.07863 9.4819H10.5266C10.7923 9.4819 11.0085 9.26569 11.0085 8.99995V7.55198C11.0085 7.28624 10.7923 7.07007 10.5266 7.07007H9.07863ZM10.4227 8.89601H9.18258V7.65593H10.4227V8.89601Z"
      fill="#666666"
    />
    <path
      d="M13.8967 7.07007H12.4487C12.183 7.07007 11.9668 7.28628 11.9668 7.55198V8.99995C11.9668 9.26569 12.183 9.4819 12.4487 9.4819H13.8967C14.1625 9.4819 14.3786 9.26569 14.3786 8.99995V7.55198C14.3786 7.28624 14.1624 7.07007 13.8967 7.07007ZM13.7927 8.89601H12.5527V7.65593H13.7927V8.89601Z"
      fill="#666666"
    />
    <path
      d="M4.26633 11.1178C4.26633 10.852 4.05012 10.6359 3.78441 10.6359H2.33641C2.07066 10.6359 1.85449 10.8521 1.85449 11.1178V12.5657C1.85449 12.8315 2.0707 13.0477 2.33641 13.0477H3.78441C4.05016 13.0477 4.26633 12.8315 4.26633 12.5657V11.1178ZM3.68043 12.4618H2.44035V11.2217H3.68043V12.4618Z"
      fill="#666666"
    />
    <path
      d="M7.63742 11.1178C7.63742 10.852 7.42121 10.6359 7.15547 10.6359H5.7075C5.44176 10.6359 5.22559 10.8521 5.22559 11.1178V12.5657C5.22559 12.8315 5.4418 13.0477 5.7075 13.0477H7.15547C7.42121 13.0477 7.63742 12.8315 7.63742 12.5657V11.1178ZM7.05152 12.4618H5.81145V11.2217H7.05152V12.4618Z"
      fill="#666666"
    />
    <path
      d="M7.15547 14.2017H5.7075C5.44176 14.2017 5.22559 14.4179 5.22559 14.6836V16.1315C5.22559 16.3973 5.4418 16.6135 5.7075 16.6135H7.15547C7.42121 16.6135 7.63742 16.3973 7.63742 16.1315V14.6836C7.63742 14.4179 7.42121 14.2017 7.15547 14.2017ZM7.05152 16.0276H5.81145V14.7876H7.05152V16.0276Z"
      fill="#666666"
    />
    <path
      d="M14.7708 9.87329C13.279 9.87329 11.936 10.5219 11.0085 11.5518V11.1178C11.0085 10.8521 10.7923 10.6359 10.5266 10.6359H9.07863C8.81289 10.6359 8.59668 10.8521 8.59668 11.1178V12.5658C8.59668 12.8315 8.81289 13.0477 9.07863 13.0477H10.0732C9.92566 13.4133 9.81961 13.8 9.76094 14.2018H9.07863C8.81289 14.2018 8.59668 14.418 8.59668 14.6837V16.1317C8.59668 16.3974 8.81289 16.6136 9.07863 16.6136H9.9932C10.6868 18.5839 12.5664 20.0001 14.7708 20.0001C17.5628 20.0001 19.8343 17.7287 19.8343 14.9367C19.8343 12.1447 17.5628 9.87329 14.7708 9.87329ZM9.18254 12.4618V11.2218H10.4226V12.345C10.3995 12.3836 10.377 12.4226 10.3549 12.4618H9.18254ZM9.18254 16.0278V14.7877H9.70977C9.70832 14.8372 9.70742 14.8868 9.70742 14.9367C9.70742 15.3112 9.74867 15.6763 9.82617 16.0278H9.18254ZM14.7708 19.4142C12.3019 19.4142 10.2933 17.4056 10.2933 14.9367C10.2933 12.4678 12.3019 10.4592 14.7708 10.4592C17.2397 10.4592 19.2484 12.4678 19.2484 14.9367C19.2484 17.4056 17.2398 19.4142 14.7708 19.4142Z"
      fill="#666666"
    />
    <path
      d="M14.7709 11.0492C12.6275 11.0492 10.8838 12.7929 10.8838 14.9363C10.8838 17.0797 12.6275 18.8234 14.7709 18.8234C16.9143 18.8234 18.658 17.0797 18.658 14.9363C18.658 12.7929 16.9143 11.0492 14.7709 11.0492ZM14.7709 18.2376C12.9506 18.2376 11.4696 16.7566 11.4696 14.9363C11.4696 13.116 12.9506 11.6351 14.7709 11.6351C16.5912 11.6351 18.0721 13.116 18.0721 14.9363C18.0721 16.7566 16.5912 18.2376 14.7709 18.2376Z"
      fill="#666666"
    />
    <path
      d="M3.78441 14.2017H2.33641C2.07066 14.2017 1.85449 14.4179 1.85449 14.6836V16.1315C1.85449 16.3973 2.0707 16.6135 2.33641 16.6135H3.78441C4.05016 16.6135 4.26633 16.3973 4.26633 16.1315V14.6836C4.26633 14.4179 4.05012 14.2017 3.78441 14.2017ZM3.68043 16.0276H2.44035V14.7876H3.68043V16.0276Z"
      fill="#666666"
    />
    <path
      d="M10.5248 18.571H1.87484C1.25566 18.571 0.751914 18.0672 0.751914 17.448V1.70879C0.751914 1.08961 1.25566 0.585859 1.87484 0.585859H14.3588C14.978 0.585859 15.4817 1.08961 15.4817 1.70879V8.89836C15.4817 9.06016 15.6129 9.19129 15.7746 9.19129C15.9364 9.19129 16.0676 9.06012 16.0676 8.89836V1.70879C16.0676 0.766562 15.301 0 14.3588 0H1.87484C0.932578 0 0.166016 0.766562 0.166016 1.70879V17.448C0.166016 18.3903 0.932578 19.1569 1.8748 19.1569H10.5248C10.6866 19.1569 10.8177 19.0257 10.8177 18.8639C10.8177 18.7022 10.6866 18.571 10.5248 18.571Z"
      fill="#666666"
    />
  </svg>
);
