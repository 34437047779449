import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import Input from '../../../../components/input/Input';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import {
  categorySubcategoryDropdownAction,
  getCategories,
  updateCategoryList,
} from '../../../../store/actions/commonAction';
import { Add, Delete } from '@mui/icons-material';
import Loader from '../../../../components/loader/Loader';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const AddEditCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteSelectedCategory, setDeleteSelectedCategory] = useState('');
  const [deleteSelectedSubcategory, setDeleteSelectedSubcategory] =
    useState<{ key: string; index: number }>();
  const { module } = useParams<Record<string, string>>();

  const { categorySubcategoryDropdown } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const [updatedCategories, setUpdatedCategories] = useState<any[]>(
    categorySubcategoryDropdown
  );

  const { categoriesLoaderaction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onBackButtonClick = () => {
    switch (module) {
      case 'suppliers':
        history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
        break;
      case 'invoices':
        history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
        break;
    }
  };
  const [inputValue, setInputValue] = useState<
    Record<string, string | undefined>
  >({
    value: '',
    error: '',
  });

  const [category, setCategory] = useState<Record<string, any>>([]);
  const { addCategoryLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const [inputSubcategory, setInputSubcategory] = useState<Record<string, any>>(
    {
      name: { value: '', error: '' },
    }
  );

  const categoryButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setInputValue({ value: undefined, error: undefined });
      },
      color: 'secondary',
    },
    {
      title: addCategoryLoaderAction ? <ButtonLoader /> : 'Save',
      onClick: () => {
        dispatch(
          updateCategoryList({ categories: updatedCategories }, () => {
            dispatch(getCategories());
          })
        );
      },
      color: 'primary',
    },
  ];

  const onAddCategory = () => {
    if (inputValue.value && inputValue.value.toString().trim().length) {
      const duplicateRecord = updatedCategories.filter(
        (category) =>
          category.name.toString().trim().toLowerCase() ===
          inputValue?.value?.toString().trim().toLowerCase()
      );
      if (duplicateRecord?.length) {
        setInputValue({
          value: '',
          error: `${inputValue.value} category already exists, please add  another one`,
        });
      } else {
        setUpdatedCategories((prevValue) =>
          inputValue.value
            ? [
                ...prevValue,
                {
                  name: inputValue.value.toString().trim(),
                },
              ]
            : [...prevValue]
        );
        setInputValue({ value: '', error: '' });
      }
    } else {
      setInputValue({
        value: '',
        error: 'Please add valid category name',
      });
    }
  };

  const deleteCategory = (name: string) => {
    delete category[name];
    const categoryList = updatedCategories;
    const index = categoryList.map((item) => item.name).indexOf(name);
    categoryList.splice(index, 1);
    setInputValue({ value: '', error: '' });
    setUpdatedCategories([...categoryList]);
  };
  const deleteSubcategory = (key: string, index: number) => {
    const categoryList = category[key].subcategoryIds;
    categoryList.splice(index, 1);
    setInputSubcategory({
      [key]: {
        value: '',
        error: '',
      },
    });
    setCategory((prev) => ({
      ...prev,
      [key]: {
        ...category[key],
        ['subcategoryIds']: categoryList,
      },
    }));
  };

  const onChangeSubcategory = (e: any) => {
    const { name, value } = e.target;
    setInputSubcategory({ ...inputSubcategory, [name]: { value: value } });
  };

  const onAddSubcategory = (name: string) => {
    if (
      inputSubcategory[name]?.value &&
      inputSubcategory[name]?.value.toString().trim().length
    ) {
      const duplicateRecord = category[name]?.subcategoryIds?.filter(
        (item: Record<string, string>) =>
          item.name.toString().trim().toLowerCase() ===
          inputSubcategory[name]?.value?.toString().trim().toLowerCase()
      );
      if (duplicateRecord?.length) {
        setInputSubcategory({
          [name]: {
            value: '',
            error: `${inputValue.value} subcategory already exists, please add  another one`,
          },
        });
      } else {
        const abc: Record<string, string>[] =
          category[name]?.subcategoryIds || [];

        {
          inputSubcategory[name]?.value?.length &&
            abc?.push({ ['name']: inputSubcategory[name]?.value });
        }

        setCategory((prev) => ({
          ...prev,
          [name]: {
            ...category[name],
            ['subcategoryIds']: abc,
          },
        }));

        setInputSubcategory({
          [name]: {
            value: '',
            error: '',
          },
        });
      }
    } else {
      setInputSubcategory({
        [name]: {
          value: '',
          error: 'Please add valid subcategory name',
        },
      });
    }
  };

  const onclickSave = () => {
    const finalCategoriesValue = Object.values(category);
    let existingCategory: any = [];
    existingCategory = updatedCategories.filter(
      (item) => !finalCategoriesValue.some((value) => item.name === value.name)
    );
    const finalData = finalCategoriesValue.length
      ? [...finalCategoriesValue, ...existingCategory]
      : updatedCategories;

    dispatch(
      updateCategoryList({ categories: finalData }, () => {
        dispatch(getCategories());
      })
    );
    history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
  };

  const onClickCancel = () => {
    setInputValue({ value: undefined, error: undefined });
    setInputSubcategory({});
    history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
  };
  const onClickDeleteConfirmation = () => {
    deleteSelectedCategory && deleteCategory(deleteSelectedCategory);
    deleteSelectedSubcategory &&
      deleteSubcategory(
        deleteSelectedSubcategory?.key,
        deleteSelectedSubcategory?.index
      );
    setOpenDeleteModal(false);
    setDeleteSelectedCategory('');
    setDeleteSelectedSubcategory(undefined);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setDeleteSelectedCategory('');
        setDeleteSelectedSubcategory(undefined);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
    setDeleteSelectedCategory('');
    setDeleteSelectedSubcategory(undefined);
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(categorySubcategoryDropdownAction());
  }, []);

  useEffect(() => {
    setUpdatedCategories(categorySubcategoryDropdown);
  }, [categorySubcategoryDropdown]);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton onClick={() => onBackButtonClick()} />
        <span className="form-title">Add/Edit Categories</span>
      </div>
      {categoriesLoaderaction ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="form-field-name">Add Category</div>
          <div className="category-input-button-container">
            <Input
              variant="filled"
              placeholder="Enter A New Category"
              value={inputValue.value}
              onChange={(e) => setInputValue({ value: e.target.value })}
              onKeyUp={(event) => enterKeyHelper(event, onAddCategory)}
              helperText={inputValue?.error}
            />
            <Button
              color="primary"
              variant="contained"
              className="modal-button"
              onClick={onAddCategory}
            >
              Add
            </Button>
          </div>
          <div className="category-chips-container">
            {updatedCategories?.length > 0 &&
              updatedCategories?.map(
                (category: Record<string, string>, index: number) => (
                  <div className="category-chip">
                    <pre>{category.name}</pre>
                    <Add
                      onClick={() => {
                        setCategory((prev) => ({
                          ...prev,
                          [category.name]: category,
                        }));
                      }}
                    ></Add>
                    {category?.isDefault || (
                      <Delete
                        onClick={() => {
                          setDeleteSelectedCategory(category.name);
                          setOpenDeleteModal(true);
                        }}
                      />
                    )}
                  </div>
                )
              )}
          </div>
          {Object.entries(category)?.map(([key, value]) => (
            <div>
              <div className="subcatgory-title">{key}</div>
              <div className="form-field-name">Add Subcategory</div>
              <div className="category-input-button-container">
                <Input
                  name={key}
                  variant="filled"
                  value={inputSubcategory[key]?.value}
                  placeholder="Enter A New Subcategory"
                  onChange={(e) => onChangeSubcategory(e)}
                  helperText={inputSubcategory[key]?.error}
                />
                <Button
                  color="primary"
                  variant="contained"
                  className="modal-button"
                  onClick={() => onAddSubcategory(key)}
                >
                  Add
                </Button>
              </div>
              <div className="category-chips-container">
                {value?.subcategoryIds?.map(
                  (item: Record<string, string>, index: number) => (
                    <div className="category-chip">
                      <pre>{item.name}</pre>
                      <Delete
                        onClick={() => {
                          setDeleteSelectedSubcategory({ key, index });
                          setOpenDeleteModal(true);
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
          <div className="form-button-row">
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onclickSave}>
              Save
            </Button>
          </div>
        </div>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Sale"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default AddEditCategory;
