import { Dispatch } from 'react';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import billingHistoryApiServices from '../../../services/pageServices/accountSettingsServices/billingHistoryApiServices';
import { BILLING_HISTORY_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/billinghistoryReduxConstants';

export const getBillinghistoryList = (
  params: Record<string, string | number>
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await billingHistoryApiServices.billingHistoryList(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: BILLING_HISTORY_REDUX_CONSTANTS.GET_BILLING_HISTORY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportBillingOverviewExcelsheet = (
  data: Record<string, string>
) => {
  return async () => {
    try {
      const response =
        await billingHistoryApiServices.downloadBillingOverviewExcelSheet(data);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportBillingOverviewPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response =
        await billingHistoryApiServices.downloadBillingOverviewPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
