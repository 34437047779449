export const closeIcon = (
  <svg
    width="13.19"
    height="13.19"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5002 0.516679C20.8502 -0.133321 19.8002 -0.133321 19.1502 0.516679L11.0002 8.65001L2.8502 0.500012C2.2002 -0.149988 1.1502 -0.149988 0.500195 0.500012C-0.149805 1.15001 -0.149805 2.20001 0.500195 2.85001L8.6502 11L0.500195 19.15C-0.149805 19.8 -0.149805 20.85 0.500195 21.5C1.1502 22.15 2.2002 22.15 2.8502 21.5L11.0002 13.35L19.1502 21.5C19.8002 22.15 20.8502 22.15 21.5002 21.5C22.1502 20.85 22.1502 19.8 21.5002 19.15L13.3502 11L21.5002 2.85001C22.1335 2.21668 22.1335 1.15001 21.5002 0.516679Z"
      fill="black"
    />
  </svg>
);
