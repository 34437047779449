import { BILLING_OVERVIEW_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/billingOverviewReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

export type accountInfoType = {
  accountHolderName: string;
  address: string;
  email: string;
  errors?: Record<string, string>;
};

export type cardType = {
  cardName: string;
  cardNumber: string;
  cardType: string;
  expiryDate: { month: string; year: string };
  isSelected: boolean;
  _id: string;
};
interface billingOverviewInitialStateProps {
  accountInfo: accountInfoType;
  paymentInfo: cardType[];
  subscriptionInfo: Record<string, string | boolean | Record<string, string>>;
  planList: Record<string, Record<string, string | number | string[]>>[];
  cardList: Record<string, any>[];
  planUpgradeDetails: Record<string, string>;
  paymentDetail: Record<string, any>;
}

const initialReducer: billingOverviewInitialStateProps = {
  accountInfo: {
    accountHolderName: '',
    address: '',
    email: '',
    errors: {},
  },
  paymentInfo: [],
  subscriptionInfo: {},
  planList: [],
  cardList: [],
  planUpgradeDetails: {},
  paymentDetail: {},
};

export const billingOverviewReducer = (
  state = initialReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case BILLING_OVERVIEW_REDUX_CONSTANTS.GET_BILLING_OVERVIEW_DETAIL:
      const { accountInfo, paymentInfo, subscriptionInfo } = action.data;
      return {
        ...state,
        accountInfo,
        paymentInfo,
        subscriptionInfo,
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.ON_CHANGE_ACCOUNT_INFO:
      const { name, value } = action.data;
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          [name]: value,
        },
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.UPDATE_PAYMENT_INFO:
      return { ...state, paymentInfo: action.data };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.GET_PLAN_LIST:
      return {
        ...state,
        planList: action.data,
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.GET_CARD_LIST_UPGRADE_PALN:
      return {
        ...state,
        cardList: action.data,
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.PLAN_UPGRADE_DETAILS:
      const { varName, id } = action.data;
      return {
        ...state,
        planUpgradeDetails: { ...state.planUpgradeDetails, [varName]: id },
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.GET_PAYMENT_DETAIL_UPGRADE_PLAN:
      return {
        ...state,
        paymentDetail: action.data,
      };

    case BILLING_OVERVIEW_REDUX_CONSTANTS.RESET_PAYMENT_DETAILS:
      return { ...state, paymentDetail: {} };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialReducer,
      };

    default:
      return state;
  }
};
