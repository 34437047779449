import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CustomTable from '../../../../components/customTable/CustomTable';

const TopTenItemsOrdered = () => {
  const { purchaseTopTenOrders } = useSelector(
    ({ dashboardReducer }: Record<string, any>) => dashboardReducer ?? {}
  );

  const { headers, topTenOrdersList } = useMemo(
    () => purchaseTopTenOrders ?? {},
    [purchaseTopTenOrders]
  );

  const mobileHeaders = ['productName', 'price'];
  return (
    <CustomTable
      className="top-ten-items-ordered-table-container"
      data={topTenOrdersList}
      headers={headers}
      mobileHeaders={mobileHeaders}
      primaryTextField="productName"
      isPagination={false}
      listName="topTenOrders"
    />
  );
};

export default TopTenItemsOrdered;
