import { LIST_FILTERS_REDUX_CONSTANTS } from '../reduxConstants/listFiltersReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';

const initialListFilterReducer: Record<string, any> = {
  suppliers: {},
  products: {},
  users: {},
  recipe: {},
  subscriber: {},
  sales: {},
  orders: {},
  invoice: {},
  creditNotes: {},
  statement: {},
  billingHistory: {},
  expense: {},
  reconcile: {},
  productInventory: {},
  reconciledStatement: {},
  purchaseChart: {},
  salesChart: {},
  costSaleChart: {},
  plan: {},
};

export const listFilterReducer = (
  state = initialListFilterReducer,
  action: Record<string, any>
) => {
  switch (action.type) {
    case LIST_FILTERS_REDUX_CONSTANTS.SAVE_APPLIED_FILTERS:
      return {
        [action?.filterFor]: action?.filters,
      };

    case LIST_FILTERS_REDUX_CONSTANTS.RESET_ON_MODULE_CHANGE:
      const data = { ...state?.[action?.data] };
      return {
        ...initialListFilterReducer,
        [action?.data]: data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return initialListFilterReducer;

    default:
      return {
        ...state,
      };
  }
};
