import { Check } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { audConverter } from '../constants/regexConstants';
import { calculation } from './calculationHelper';
import { dateCalculationHelper } from './dateCalculationHelper';

interface TableDataProcessHelperProps {
  header: Record<string, any>;
  row: Record<string, any>;
  timeZoneOffSet?: number;
}

export const processTableDataByType = ({
  header,
  row,
  timeZoneOffSet,
}: TableDataProcessHelperProps) => {
  const { type } = header;
  const headerName =
    header.name === 'createdDate' || header.name === 'createdTime'
      ? 'orderDate'
      : header.name;

  const data = (headerName: string) => {
    switch (headerName) {
      case 'statementNumber':
        return row?.statementDetails?.statementNumber;

      case 'statementDate':
        return row?.statementDetails?.statementDate ?? row?.statementDate;

      case 'statementBalance':
        return row?.statementDetails?.statementBalance ?? row?.statementBalance;

      case 'orderId':
        return row?.orderDetails?.orderNumber;

      default:
        return row?.[headerName];
    }
  };
  const currentData = data(headerName);

  switch (type) {
    case 'priceChange':
      let arrow;
      switch (currentData?.fluctuation) {
        case 'Up':
          arrow = 'arrow_upward';
          break;
        case 'Down':
          arrow = 'arrow_downward';
          break;
        default:
          arrow = '';
          break;
      }

      const arrowClass =
        currentData?.fluctuation === 'Down' ? 'downward-arrow' : 'upward-arrow';

      return currentData && currentData.toString().trim().length > 0 ? (
        <span className={`priceChangeClass ${arrowClass}`}>
          {arrow.length > 0 && (
            <span className="material-icons-round">{arrow}</span>
          )}
          <span>{currentData.value ? `${currentData.value}%` : '0%'}</span>
        </span>
      ) : (
        '-'
      );

    case 'amount':
      return currentData && currentData.toString().trim().length > 0
        ? audConverter(currentData)
        : audConverter(0);

    case 'arrayOfString':
      return currentData?.length
        ? currentData.map((data: string) => data).join(', ')
        : '-';

    case 'date':
      const date =
        timeZoneOffSet && dateCalculationHelper(currentData, timeZoneOffSet);
      return currentData?.toString()?.trim()?.length
        ? dayjs(date).format('DD MMM YYYY')
        : '-';

    case 'time':
      return currentData?.toString()?.trim()?.length
        ? dayjs(dateCalculationHelper(currentData, timeZoneOffSet)).format(
            'hh:mm A'
          )
        : '-';

    case 'tag':
      const className = currentData?.toString()?.length
        ? currentData?.toLowerCase().split(' ').join('-')
        : '';
      return <span className={`status-tag ${className}`}>{currentData}</span>;

    case 'icon':
      return currentData ? (
        <div className={`icon-tag`}>
          <span className="right">
            <Check />
          </span>
        </div>
      ) : (
        <div className={`icon-tag`}>
          <span className="wrong">
            <Close />
          </span>
        </div>
      );

    case 'percentage':
      return currentData?.toString()?.trim()?.length
        ? `${calculation('decimal', currentData)} %`
        : '-';

    case 'code':
      return currentData && currentData.toString().trim().length > 0
        ? `#${currentData}`
        : '-';

    case 'object':
      return currentData?.name ? currentData?.name : '-';

    case 'number':
      return currentData?.toString().trim().length > 0
        ? new Intl.NumberFormat('en-IN').format(currentData)
        : '-';

    case 'gstAmount':
      return currentData?.toString()?.trim()?.length ? `$${currentData}` : '-';

    case 'productName':
      return (
        <div className="product-cell">
          {currentData?.toString()?.length > 15 ? (
            <Tooltip enterDelay={1000} arrow title={currentData}>
              <div className="ellipsis-class">{currentData ?? '-'}</div>
            </Tooltip>
          ) : (
            <div className="ellipsis-class">{currentData ?? '-'}</div>
          )}
          {row.productNote && row.productNote?.toString()?.length > 15 ? (
            <Tooltip enterDelay={1000} arrow title={row.productNote}>
              <div className="productNote-container">{row.productNote}</div>
            </Tooltip>
          ) : (
            <div className="productNote-container">{row.productNote}</div>
          )}
        </div>
      );

    case 'billAmount':
      const amount =
        currentData && currentData.toString().trim().length > 0
          ? audConverter(currentData)
          : audConverter(0);

      return row.discountAmount && row.discountAmount > 0 ? (
        <span>
          <span>{amount}</span>
          <span>{` (-${audConverter(row.discountAmount)})`}</span>
        </span>
      ) : (
        amount
      );

    case 'dateRange':
      const startDate =
        timeZoneOffSet &&
        dayjs.utc(currentData).utcOffset(timeZoneOffSet).format('DD MMM YYYY');

      const endDate =
        timeZoneOffSet &&
        dayjs.utc(row?.endDate).utcOffset(timeZoneOffSet).format('DD MMM YYYY');

      return currentData && row.endDate ? `${startDate} - ${endDate}` : '-';

    case 'input':
      return currentData?.toString().trim().length > 0 ? currentData : 0;

    default:
      return currentData?.toString().trim().length > 0 ? currentData : '-';
  }
};
