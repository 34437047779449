import { ACCOUNT_SETTINGS_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const softwareIntegrationApiServices = {
  integrateSoftware: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.SOFTWARE_INTEGRATION,
      data
    ),
  getCurrentsoftware: () =>
    ApiService.getData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.GET_CURRENT_SOFTWARE
    ),
  validateSoftwareIntegration: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.VALIDATE_INTEGRATION,
      data
    ),
  chooseOrganization: (data: Record<string, string | boolean>) =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.CHOOSE_ORGANIZATION,
      {
        ...data,
      }
    ),
  resetIntegration: () =>
    ApiService.putData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.RESET_INTEGRATION
    ),
  removeIntegration: () =>
    ApiService.deleteData(
      ACCOUNT_SETTINGS_URLS.SOFTWARE_INTEGRATION_URLS.REMOVE_INTEGRATION
    ),
};
