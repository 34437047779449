import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import rightIcon from '../../../../assets/images/right-icon.svg';
import { deleteIcon } from '../../../../assets/images/action-items/deleteIcon';
import ActiveSwitch from '../../../../components/activeSwitch/ActiveSwitch';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import Loader from '../../../../components/loader/Loader';
import { preparedCountries } from '../../../../constants/countryConstants';
import { FormField, OptionsType } from '../../../../constants/interfaces';
import {
  supplierConstants,
  supplierRequireFields,
} from '../../../../constants/supplierConstants/supplierConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import { categorySubcategoryDropdownAction } from '../../../../store/actions/commonAction';
import {
  deleteTermsAndConditionsAction,
  getDetailsByCompanyName,
  getDetailsByCompanyNumber,
  getPaymentMethodDropdownData,
  getSelectedSupplierDetails,
  onAddSupplierValueChange,
  resetCompanyDetailList,
  resetSupplierDetailsForm,
  uploadTermsAndCondition,
} from '../../../../store/actions/supplierActions/supplierActions';
import { SUPPLIER_REDUX_CONSTANTS } from '../../../../store/reduxConstants/supplierReduxConstants/supplierReduxConstants';
import { handleAddSupplierValidation } from './addSupplierValidations';
import BrowseFile from '../../../../components/browsFile/BrowseFile';
import { ArrowDropDownSharp, Search } from '@mui/icons-material';

import { errorNotification } from '../../../../components/notifyHelper';
import CustomTable from '../../../../components/customTable/CustomTable';
import { focusHelper } from '../../../../helpers/focusHelper';
import _ from 'lodash';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const AddSupplier = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Record<string, any>>>();
  const { action, supplierId } = useParams<Record<string, string>>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [venueTredingModal, setVenueTredingModal] = useState(false);

  const { paymentMethods, paymentTerms, supplier, companyDetailList } =
    useSelector(
      ({ supplierReducer }: Record<string, any>) => supplierReducer ?? {}
    );

  const { categorySubcategoryDropdown } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );
  const {
    getSelectedSupplierDetailsLoaderAction,
    supplierDetailsUpdateLoaderAction,
    companyDetailsListByCompanyNameLoaderAction,
    companyDetailsListByAbnLoaderAction,
    addSupplierLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const { errors } = useMemo(() => supplier ?? {}, [supplier]);

  const headers = [
    { name: 'entityName', label: 'Name' },
    { name: 'abn', label: 'ABN' },
    { name: 'code', label: 'State/Postal Code' },
  ];
  const mobileHeaders = ['entityName', 'abn'];

  const onSearchClick = (label: string) => {
    if (label === 'companyName') {
      if (supplier?.companyName.length > 0) {
        const searchByName = dispatch(
          getDetailsByCompanyName(supplier?.companyName)
        );

        if (searchByName !== null) {
          // window.scrollTo(0, 0);
          setVenueTredingModal(true);
        }
      } else errorNotification('Please enter Company Name');
    } else {
      supplier?.abn.length > 0
        ? dispatch(getDetailsByCompanyNumber(supplier.abn))
        : errorNotification('Please enter ABN / Company number');
    }
  };

  const onSelectRow = (row: Record<string, string>) => {
    dispatch(getDetailsByCompanyNumber(row.abn));
    setVenueTredingModal(false);
    dispatch(resetCompanyDetailList());
  };

  const onSupplierDetailChange = (
    _event: React.SyntheticEvent,
    value: any,
    input: Record<string, any>
  ) => {
    dispatch(onAddSupplierValueChange(input.name, value));
    if (input.name === 'paymentTerms' && value !== 'OTHER') {
      dispatch(onAddSupplierValueChange('otherPaymentTerm', undefined));
    } else if (input.name === 'country') {
      dispatch(onAddSupplierValueChange('state', ''));
    }
    if (isSubmitted && input?.type === 'autocomplete') {
      handleAddSupplierValidation(
        true,
        'onBlur',
        action,
        dispatch,
        { ...supplier, [input.name]: value },
        input?.name,
        errors,
        history,
        setIsSubmittedSuccessfully
      );
    }
  };

  const onAddSupplier = (action: string) => {
    setIsSubmitted(true);
    handleAddSupplierValidation(
      true,
      'onSubmit',
      action,
      dispatch,
      { ...supplier },
      undefined,
      errors,
      history,
      setIsSubmittedSuccessfully
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
      return;
    }
  };

  const onChangeSubcategory = (
    category: Record<string, Record<string, string>>,
    selectedSubcategory: Record<string, string>
  ) => {
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.ON_CHANGE_SUBCATEGORY,
      data: { category, selectedSubcategory },
    });
  };

  const onChangeCategory = (
    selectedCategory: Record<string, Record<string, string>>
  ) => {
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.ON_CHANGE_CATEGORY,
      data: { selectedCategory },
    });
  };

  const onDeleteCategory = (selectedCategory: Record<string, any>) => {
    const [categoryToDelete, _] = selectedCategory.split('/');

    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.ON_DELETE_CATEGORY,
      data: { categoryToDelete },
    });
  };

  const getComponentFromType = (input: FormField) => {
    let component = null;
    const check = () => {
      if (
        input.type === 'autocomplete' ||
        (input.type === 'state' &&
          (supplier?.country?.name === 'Australia' ||
            supplier?.country === 'Australia'))
      ) {
        return 'autocomplete';
      } else if (input.type === 'multiLevelDropdown') {
        return 'multiLevelDropdown';
      } else {
        return 'input';
      }
    };
    const onInputBlur = (name: string) => {
      handleAddSupplierValidation(
        isSubmitted,
        'onBlur',
        action,
        dispatch,
        supplier,
        name,
        errors,
        history,
        setIsSubmittedSuccessfully
      );
    };
    switch (check()) {
      case 'autocomplete':
        const getOption = (input: Record<string, any>) => {
          switch (input.name) {
            case 'paymentMethod':
              return paymentMethods;
            case 'paymentTerms':
              return paymentTerms;
            case 'category':
              return categorySubcategoryDropdown;
            case 'country':
              return preparedCountries;
            default:
              return input?.options;
          }
        };

        let dropdownValue = null;
        if (input?.isMulti && !supplier?.[input?.name]?.length) {
          dropdownValue = [];
        } else {
          dropdownValue = supplier?.[input.name];
        }

        const getLabel = (
          name: string,
          option: string | OptionsType<string, string>
        ) => {
          switch (name) {
            case 'country':
              return typeof option === 'object' ? (
                <>
                  <img
                    loading="lazy"
                    width="20"
                    className="select__country-flag"
                    src={`https://flagcdn.com/w20/${option._id.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option._id.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.name}
                </>
              ) : (
                '-'
              );

            case 'category':
              return typeof option === 'object' ? option.name : '-';

            default:
              return option;
          }
        };

        component = (
          <FormControl
            className={`input-field ${
              errors?.[input.name] ? 'input-with-error' : ''
            }`}
          >
            <Autocomplete
              key={input.name}
              className={`autocomplete ${
                errors?.[input.name] ? 'input-with-error' : ''
              } ${dropdownValue?.length ? 'multiple-autocomplete' : ''}`}
              options={getOption(input) ?? []}
              onChange={(event, value) =>
                onSupplierDetailChange(event, value, input)
              }
              onKeyUp={(event) =>
                enterKeyHelper(event, () => onAddSupplier(action))
              }
              multiple={input?.isMulti}
              value={dropdownValue}
              getOptionLabel={(option) => option?.name ?? option ?? ''}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.name} {...getTagProps({ index })} />
                ))
              }
              renderOption={(props, option) => (
                <li {...props}>{option.name ?? option}</li>
              )}
              renderInput={(params) => (
                <Input
                  variant="filled"
                  {...params}
                  placeholder={
                    !dropdownValue?.length ? `Select ${input.placeholder}` : ''
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormHelperText className="input-with-error">
              {errors?.[input.name]}
            </FormHelperText>
          </FormControl>
        );

        break;

      case 'multiLevelDropdown':
        const value = supplier.category?.map((category: any) => {
          const subcategories = category.subcategoryIds?.map(
            (subcategory: any) => subcategory.name
          );
          return `${category.name}${
            subcategories?.length > 0
              ? `/${subcategories.filter(
                  (value: string, index: number, self: string) =>
                    self.indexOf(value) === index
                )}. `
              : ''
          }`;
        });

        component = (
          <FormControl
            className={`input-field ${
              errors?.[input.name] ? 'input-with-error' : ''
            }`}
          >
            <Autocomplete
              key={Math.random()}
              onChange={(event, value) =>
                onSupplierDetailChange(event, value, input)
              }
              multiple
              options={categorySubcategoryDropdown ?? []}
              getOptionLabel={(option: Record<string, any>) =>
                option?.name ?? option ?? ''
              }
              value={value}
              onKeyUp={(event) =>
                enterKeyHelper(event, () => onAddSupplier(action))
              }
              className={`autocomplete ${
                errors?.[input.name] ? 'input-with-error' : ''
              }`}
              id={input.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={(v) => onDeleteCategory(option)}
                    deleteIcon={deleteIcon}
                  />
                ))
              }
              renderOption={(props, option) => (
                <div className="multi-level-dropdown">
                  {option?.subcategoryIds?.length > 0 ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownSharp />}
                        aria-controls="panel1a-content"
                      >
                        <Typography
                          onClick={() => onChangeCategory(option)}
                          sx={{ fontWeight: 'bold' }}
                        >
                          {option.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {option?.subcategoryIds?.map(
                          (subcategory: Record<string, string>) => (
                            <Typography
                              onClick={() => {
                                onChangeSubcategory(option, subcategory);
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              {subcategory.name}
                            </Typography>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Accordion>
                      <AccordionDetails>
                        <Typography
                          onClick={() => onChangeCategory(option)}
                          sx={{ cursor: 'pointer' }}
                        >
                          {option.name}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              )}
              renderInput={(params) => (
                <Input
                  variant="filled"
                  {...params}
                  placeholder={
                    supplier.category.length > 0 ? '' : 'Select Category'
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormHelperText className="input-with-error">
              {errors?.[input.name]}
            </FormHelperText>
          </FormControl>
        );
        break;

      default:
        const isVisible =
          (input.name === 'otherPaymentTerm' &&
            supplier?.['paymentTerms'] === 'Other') ||
          input.name !== 'otherPaymentTerm';
        component = isVisible ? (
          <Input
            variant="filled"
            key={input.name}
            multiline={input?.isMulti}
            className={`${errors?.[input.name] ? 'input-with-error' : ''} ${
              input?.isMulti ? 'delivery-notes' : ''
            }`}
            id={input.name}
            rows={4}
            placeholder={`Enter ${input.placeholder}`}
            onBlur={() => onInputBlur(input.name)}
            onChange={(event) =>
              onSupplierDetailChange(event, event.target.value, input)
            }
            onKeyUp={(event) =>
              enterKeyHelper(event, () => onAddSupplier(action))
            }
            value={supplier?.[input.name]}
            helperText={errors?.[input.name]}
            InputProps={{
              endAdornment:
                input?.suffix === 'search' ? (
                  <InputAdornment position="start">
                    {(companyDetailsListByCompanyNameLoaderAction &&
                      input.name === 'companyName') ||
                    (companyDetailsListByAbnLoaderAction &&
                      input.name === 'abn') ? (
                      <ButtonLoader />
                    ) : (
                      <Search
                        onClick={() => {
                          onSearchClick(input.name);
                        }}
                        className="search-icon"
                      />
                    )}
                  </InputAdornment>
                ) : (
                  ''
                ),
            }}
          />
        ) : null;
    }

    return (
      component && (
        <div
          key={input.name}
          className={input.isFull ? 'form-full-width-field' : ''}
        >
          <div className="form-field-name">
            {input?.label}
            {supplierRequireFields.includes(input.name) && (
              <div className="required-sign">*</div>
            )}
          </div>
          {component}
        </div>
      )
    );
  };

  const isSupplierDataLoaded = () => {
    if (getSelectedSupplierDetailsLoaderAction && action === 'edit') {
      return false;
    }
    return true;
  };

  const onClickBackButton = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
    dispatch({
      type: SUPPLIER_REDUX_CONSTANTS.RESET_SUPPLIER_DETAILS,
    });
  };

  const supplierAddedConfirmationButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
      },
      color: 'primary',
    },
  ];

  useEffect((): any => {
    dispatch(getPaymentMethodDropdownData());
    dispatch(categorySubcategoryDropdownAction());
    if (action === 'edit') {
      dispatch(getSelectedSupplierDetails(supplierId));
    }
    return () => {
      dispatch(resetSupplierDetailsForm());
      setVenueTredingModal(false);
      dispatch(resetCompanyDetailList());
    };
  }, []);

  return (
    <div className="form">
      <div className="form-title-switch-container">
        <div className="form-title-row">
          <BackButton onClick={onClickBackButton} />
          <div className="form-title">
            {action === 'add' ? 'Add' : 'Edit'} Supplier
          </div>
        </div>
      </div>
      {isSupplierDataLoaded() ? (
        <div className="form-container">
          <div className="form-fields-grid">
            {supplierConstants.map((input) => getComponentFromType(input))}
          </div>
          <div className="terms-and-condition-block">
            <div className="form-field-name">Attach Credit Application</div>
            <BrowseFile
              uploadAction={uploadTermsAndCondition}
              fileNameKey="supplier-terms-and-conditions"
              fileExtension={['pdf', 'doc', 'docx']}
              mimeType={[
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
              errorMessage="Only PDF or Word file allowed"
              fileName={supplier?.fileName}
              fileUrl={supplier?.termsandconditionsURL}
              removeFileAction={deleteTermsAndConditionsAction}
            />
          </div>
          <div className="form-button-row">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onClickBackButton()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              loader={
                addSupplierLoaderAction || supplierDetailsUpdateLoaderAction
              }
              onClick={() => onAddSupplier(action === 'add' ? 'add' : 'edit')}
            >
              {action === 'add' ? 'Add Supplier' : 'Save'}
            </Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Supplier Added Confirmation"
          buttons={supplierAddedConfirmationButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.SUPPLIER);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Supplier added successfully</span>
          </>
        </CustomModal>
      )}

      {venueTredingModal && companyDetailList.length > 0 && (
        <CustomModal
          header="Search Venue Trading"
          className="confirmation-modal supplier-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="company-details-table"
          hideModal={() => {
            setVenueTredingModal(false);
            dispatch(resetCompanyDetailList());
          }}
        >
          {/* {
            (window.onscroll = () => {
              window.scrollTo(0, 0);
            })
          } */}
          <CustomTable
            headers={headers}
            mobileHeaders={mobileHeaders}
            data={companyDetailList}
            isPagination
            onClickRow={(row) => onSelectRow(row)}
            className="modal-table"
          />
        </CustomModal>
      )}
    </div>
  );
};

export default AddSupplier;
