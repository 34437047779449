import { ACCOUNTING_SOFTWARE_REDUX_CONSTANTS } from '../reduxConstants/accountingSoftwareReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';

interface initialReducerProps {
  organizationList: Record<string, any>[];
  accountingSoftwareData: Record<string, string>;
}

const initialAccountingSoftware: initialReducerProps = {
  organizationList: [],
  accountingSoftwareData: {},
};

export const accountingSoftwareReducer = (
  state = initialAccountingSoftware,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case ACCOUNTING_SOFTWARE_REDUX_CONSTANTS.GET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: action.data,
      };

    case ACCOUNTING_SOFTWARE_REDUX_CONSTANTS.ACCOUNTING_SOFTWARE_DATA:
      return {
        ...state,
        accountingSoftwareData: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialAccountingSoftware,
      };

    default:
      return state;
  }
};
