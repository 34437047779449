import { FormControl, InputLabel } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { gstIcon } from '../../../assets/images/gstIcon';
import Button from '../../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import Input from '../../../components/input/Input';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { FLOAT_NUMBER_REGEX } from '../../../constants/regexConstants';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import {
  addDefaultGstAction,
  getDefaultGstAction,
  onChangeDefaultGstAction,
  getGstListAction,
  deleteGstAction,
} from '../../../store/actions/admin/gstActions';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const Gst = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState('');
  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const [openDefaultGstModal, setOpenDefaultGstModal] = useState(false);
  const [error, setError] = useState('');

  const { gstList, defaultGst } = useSelector(
    ({ gstReducer }: Record<string, any>) => gstReducer ?? {}
  );

  const { gstListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const mobileHeaders = ['country', 'gst'];

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => gstList ?? {},
    [gstList]
  );

  const onClickAddGst = () => {
    history.push('/gst/add');
  };

  const addMenuItems = [
    {
      icon: gstIcon,
      name: 'Add Default GST(%)',
      onClick: () => setOpenDefaultGstModal(true),
    },
    {
      icon: gstIcon,
      name: 'Add GST(%)',
      onClick: () => onClickAddGst(),
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getGstListAction({ page: page, limit }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getGstListAction({ page: 1, limit: value }));
  };

  const onClickSave = () => {
    if (parseInt(defaultGst?.gst) < 0 || parseInt(defaultGst?.gst) > 100) {
      setError('Please enter valid Default GST(%)');
    } else {
      dispatch(addDefaultGstAction({ gst: parseFloat(defaultGst?.gst) }));
      setOpenDefaultGstModal(false);
      setError('');
    }
  };

  const addDefaultGstButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        dispatch(getDefaultGstAction());
        setOpenDefaultGstModal(false);
        setError('');
      },
      color: 'secondary',
    },
    {
      title: 'Save',
      onClick: () => {
        onClickSave();
      },
      color: 'primary',
    },
  ];

  const deleteTableRow = (val: string) => {
    setOpenDeleteModal(true);
    setRowId(val);
  };

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
    };
    await dispatch(getGstListAction({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteGstAction(rowId, afterSuccessfulDeletion));
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeDefaultGstAction(name, value));
  };

  const onEditGst = (gst: Record<string, any>) => {
    history.push(`gst/edit/${gst._id}`);
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: gstList?.limit ?? limit ?? paramLimit ?? 15,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: gstList?.limit ?? limit ?? paramLimit ?? 15,
    };
    dispatch(getGstListAction(params));
    dispatch(getDefaultGstAction());
    dispatch(resetfilterOnModuleChange('gst'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">GST(%) List</div>
        <div className="button-container">
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={() => setOpenDefaultGstModal(true)}
          >
            Add Default GST(%)
          </Button>
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={onClickAddGst}
          >
            Add GST(%)
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="add"
              menuItems={addMenuItems}
              id="user-action"
            />
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>
      {gstListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isAction
          deleteTableRow={deleteTableRow}
          editTableRow={onEditGst}
        />
      )}
      {openDefaultGstModal && (
        <CustomModal
          header="Default GST(%)"
          buttons={addDefaultGstButtons}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="default-gst-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setOpenDefaultGstModal(false);
            setError('');
            dispatch(getDefaultGstAction());
          }}
        >
          <div className="default-gst-input">
            <InputLabel className="add-default-gst-label">
              Default GST(%)
            </InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Enter Default GST(%)"
                className={`input-field ${
                  error.length ? 'input-with-error' : ''
                }`}
                name="gst"
                value={defaultGst?.gst}
                type="number"
                helperText={error}
                onChange={onInputChange}
                onKeyUp={(event) => enterKeyHelper(event, () => onClickSave())}
              />
            </FormControl>
          </div>
        </CustomModal>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete GST"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default Gst;
