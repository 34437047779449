import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetfilterOnModuleChange } from '../../store/actions/listFiltersAction';
import PurchaseChartDashboard from './purchaseChartDashboard/PurchaseChartDashboard';
import SalesChartDashboard from './salesChartDashboard/SalesChartDashboard';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<string>('0');
  const onChangeActiveTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const { pendingInvoicesList } = useSelector(
    ({ dashboardReducer }: Record<string, any>) => dashboardReducer ?? {}
  );

  useEffect(() => {
    dispatch(resetfilterOnModuleChange('dashboard'));
  }, []);
  return (
    <div className="dashboard-container">
      <div className="page-header">
        <div className="page-title">Dashboard</div>
        <div className="welcome-text">
          <div>Welcome!</div>
          {pendingInvoicesList?.organizationName?.length > 10 ? (
            <Tooltip title={pendingInvoicesList.organizationName} arrow>
              <div className="user-business-name">
                {pendingInvoicesList.organizationName}
              </div>
            </Tooltip>
          ) : (
            <div className="user-business-name">
              {pendingInvoicesList.organizationName}
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-content-container">
        <TabContext value={activeTab}>
          <TabList
            onChange={onChangeActiveTab}
            aria-label="Dashboard-chart"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Purchase Chart" value="0" />
            <Tab label="Sales Chart" value="1" />
          </TabList>
          <TabPanel value="0" sx={{ padding: '24px 0' }}>
            <PurchaseChartDashboard />
          </TabPanel>
          <TabPanel value="1" sx={{ padding: '24px 0' }}>
            <SalesChartDashboard />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};
export default Dashboard;
