import { Delete } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { importIcon } from '../../../assets/images/action-items/importIcon';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { filterIcon } from '../../../assets/images/filter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import Button from '../../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import CustomTable from '../../../components/customTable/CustomTable';
import FilterModal from '../../../components/filterModal/FilterModal';
import ImportCsv from '../../../components/importCsv/ImportCsv';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { errorNotification } from '../../../components/notifyHelper';
import { SUPPLIERS_URLS } from '../../../constants/urlConstants';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  getCategories,
  getSuppliers,
} from '../../../store/actions/commonAction';
import {
  resetfilterOnModuleChange,
  saveAppliedFilters,
} from '../../../store/actions/listFiltersAction';
import {
  deleteSuppliers,
  exportSupplierExcelsheet,
  exportSupplierListPdf,
  getSupplierList,
} from '../../../store/actions/supplierActions/supplierActions';
import { SupplierType } from '../../../store/reducers/supplierReducer/supplierReducer';
import CategoryModal from './categoryModal/CategoryModal';

const Supplier = () => {
  const history = useHistory();
  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const dispatch = useDispatch();
  const { supplierList } = useSelector(
    ({ supplierReducer }: Record<string, any>) => supplierReducer ?? {}
  );
  const { categories } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );

  const { supplierListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const mobileHeaders = ['companyName', 'name'];
  const { docs, headers, limit, page, pages, total } = useMemo(
    () => supplierList ?? {},
    [supplierList]
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [categoryModal, setCategoryModal] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);

  const { suppliers } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const [rowId, setRowId] = useState<string>('');
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
    setRowId('');
  };

  const editTableRow = (supplier: Record<string, any>) => {
    history.push(`/suppliers/list/edit/${supplier._id}`);
  };

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const selectAllCheckBox = () => {
    const allIds = docs.map((data: SupplierType) => data._id);
    if (docs.length !== 0) {
      if (checkedIds.length === docs.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    await dispatch(getSupplierList({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteSuppliers(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds.length > 1 ? checkedIds.join(',') : checkedIds[0];
      if (checkedIds.length !== 0) {
        dispatch(deleteSuppliers(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const onDeleteAllClick = () => {
    setOpenDeleteModal(true);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const onAddSupplierClick = () => {
    history.push('/suppliers/list/add');
  };

  const onPageChange = (page: any) => {
    dispatch(getSupplierList({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getSupplierList({ page: 1, limit: value, ...filters }));
  };

  const addMenuItems = [
    {
      icon: <span className="material-icons">person_add</span>,
      name: 'Add Supplier',
      onClick: () => history.push('/suppliers/list/add'),
    },
    {
      icon: <span className="material-icons">category</span>,
      name: 'Add/ Edit Category',
      onClick: () => {
        history.push(`/suppliers/add-edit-category`);
      },
    },
  ];

  const exportToExcel = () => {
    const filters = {
      categoryId: suppliers?.categoryId?._id,
      searchString: suppliers?.searchString,
    };
    dispatch(exportSupplierExcelsheet({ ...filters }));
  };

  const exportToPdf = () => {
    const filters = {
      categoryId: suppliers?.categoryId?._id,
      searchString: suppliers?.searchString,
    };
    dispatch(exportSupplierListPdf({ ...filters, userDate: new Date() }));
  };

  const actionItems = [
    {
      icon: importIcon,
      name: 'Import Supplier',
      onClick: () => setImportModal(true),
    },
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];

  const filterFields = [
    {
      name: 'searchString',
      label: 'Supplier',
      placeholder: 'Search by Supplier',
    },
    {
      name: 'categoryId',
      label: 'Category',
      placeholder: 'Select Category',
      options: categories,
      type: 'autocomplete',
      className: 'filter-field-gap',
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getSupplierList,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'suppliers',
      () => setOpenFilterModal(false),
      [getCategories]
    );
  };

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: suppliers?.limit ?? limit ?? paramLimit ?? 15,
    categoryId: suppliers?.categoryId?._id,
    searchString: suppliers?.searchString,
  });

  const filters = {
    categoryId: suppliers?.categoryId?._id,
    searchString: suppliers?.searchString,
  };

  const afterFileUploadSuccess = () => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getSupplierList({ ...params }));
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: suppliers?.limit ?? paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getSupplierList({ ...params }));
    dispatch(getCategories());
    dispatch(resetfilterOnModuleChange('suppliers'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Suppliers</div>
        <div className="button-container">
          {!_.isEmpty(suppliers) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(suppliers) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={onAddSupplierClick}
          >
            Add Supplier
          </Button>
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/suppliers/add-edit-category`);
            }}
          >
            Add/Edit Category
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="add"
              menuItems={addMenuItems}
              id="user-action"
            />
          </div>
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(suppliers)}
            filterData={suppliers}
            isFor="Supplier"
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="supplier-action"
          />
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={onDeleteAllClick}
          >
            <Delete />
          </Button>
        </div>
      </div>

      {supplierListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isCheckbox
          onChangeCheckBox={onChangeCheckBox}
          checkedIds={checkedIds}
          selectAllCheckBox={selectAllCheckBox}
          isAction
          deleteTableRow={deleteTableRow}
          editTableRow={editTableRow}
          viewRecord={(supplier: Record<string, any>) => {
            history.push(`/suppliers/list/view/${supplier._id}`);
          }}
          primaryTextField="companyName"
        />
      )}

      {openFilterModal && (
        <FilterModal
          requestFrom="suppliers"
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getSupplierList}
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          filterAction={[getCategories]}
        />
      )}

      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Supplier"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}

      {importModal && (
        <ImportCsv
          setImportModal={setImportModal}
          className="import-users-modal"
          fileNameKey="supplier-csv-file"
          afterFileUploadSuccess={afterFileUploadSuccess}
          actionUrl={SUPPLIERS_URLS.SUPPLIERS_URLS.IMPORT_SUPPLIERS}
          csvFor="supplier"
        />
      )}

      {categoryModal && <CategoryModal setCategoryModal={setCategoryModal} />}
    </div>
  );
};
export default Supplier;
