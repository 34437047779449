import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  getModuleListAction,
  getUserDataById,
  resendEmailAction,
  resetUserDetails,
} from '../../../../store/actions/accountSettingsActions/accountSettingsUsersActions';
import Button from '../../../../components/button/Button';
import { Edit } from '@mui/icons-material';
import Loader from '../../../../components/loader/Loader';
import { ResendEmailIcon } from '../../../../assets/images/resendEmailIcon';
import { Switch } from '@mui/material';
import { levelOneRole } from '../../../../constants/levelOneRoleConstants';

const ViewUser = () => {
  const { userId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userDetail } = useSelector(
    ({ accountSettingsUsersReducer }: Record<string, any>) =>
      accountSettingsUsersReducer ?? {}
  );

  const { getUserDataByIdLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const userDetalConstants = [
    {
      name: 'firstName',
      label: 'First Name',
    },
    {
      name: 'lastName',
      label: 'Last Name',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'contactNumber',
      label: 'Contact Number',
    },
    {
      name: 'role',
      label: 'User Role',
    },
  ];

  useEffect((): any => {
    const getdata = async () => {
      await dispatch(getModuleListAction());
      await dispatch(getUserDataById(userId));
    };
    getdata();
    return () => dispatch(resetUserDetails());
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.USER)}
          />
          <div className="form-title">User Details</div>
          <div
            className={`status-tag ${
              userDetail?.isActive ? 'isActive' : 'inactive'
            }`}
          >
            {userDetail?.isActive ? 'Active' : 'Inactive'}
          </div>
        </div>
        <div className="button-container">
          {userDetail.resendInviteEmail && (
            <Button
              className="desktop-button"
              variant="contained"
              color="primary"
              onClick={() => dispatch(resendEmailAction(userId))}
            >
              Send Verification Email
            </Button>
          )}
          {levelOneRole.includes(profileDetail.role) && (
            <Button
              className="desktop-button"
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(`/account-settings/users/edit/${userId}`)
              }
            >
              Edit User
            </Button>
          )}
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            {userDetail.resendInviteEmail && (
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => dispatch(resendEmailAction(userId))}
              >
                <ResendEmailIcon />
              </Button>
            )}
          </div>
          <div className="mobile-button-container">
            {levelOneRole.includes(profileDetail.role) && (
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() =>
                  history.push(`/account-settings/users/edit/${userId}`)
                }
              >
                <Edit />
              </Button>
            )}
          </div>
          {/* Mobile Buttons end here */}
        </div>
      </div>

      {getUserDataByIdLoaderAction ? (
        <Loader />
      ) : (
        <div className="view-container">
          <div className="view-details-white-container details-grid details-grid-three-columns">
            {userDetalConstants.map((field) => (
              <div>
                <div className="detail-label">{field.label}</div>
                <div className="detail-value">
                  {userDetail?.[field?.name] ?? '-'}
                </div>
              </div>
            ))}
          </div>
          <div className="filter-field-gap">
            <div className="form-field-name">Module Access</div>
          </div>
          <div className="module-container bottom-gap">
            {userDetail?.moduleAccess?.map(
              (module: Record<string, any>, index: number) =>
                !module?.hasSubModule && (
                  <div className="module-card">
                    <div
                      className="module"
                      style={{
                        borderRadius: !module?.hasSubModule ? '8px' : '',
                      }}
                    >
                      <span>{module?.label}</span>
                      <Switch
                        size="small"
                        edge="start"
                        checked={module?.hasAccess}
                        disabled
                      />
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="module-container">
            {userDetail?.moduleAccess?.map(
              (module: Record<string, any>, index: number) =>
                module?.hasSubModule && (
                  <div className="module-card">
                    <div className="module">
                      <span>{module.label}</span>
                      <Switch
                        size="small"
                        edge="start"
                        checked={module?.hasAccess}
                        disabled
                      />
                    </div>
                    <div className={`${module.hasSubModule && 'submodule'}`}>
                      {module?.subModules?.map(
                        (submodule: Record<string, any>, i: number) => (
                          <div>
                            <span>{submodule.label}</span>
                            <Switch
                              size="small"
                              edge="start"
                              checked={submodule.hasAccess}
                              disabled
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUser;
