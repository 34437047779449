export const orderTableProductsHeaderConstants: Record<string, any>[] = [
  {
    name: 'productCode',
    label: 'Product Code',
    type: 'code',
  },
  {
    name: 'name',
    label: 'Product',
    type: 'productName',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'editQuantity',
  },
  {
    name: 'unitId',
    label: 'UOM',
    type: 'object',
  },
  {
    name: 'weight',
    label: 'Weight',
    type: 'string',
  },
];

export const orderTableProductsMobileHeader: string[] = ['productCode', 'name'];
