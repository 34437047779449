import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import subscribersApiServices from '../../../services/pageServices/admin/subscribersApiServices';
import { SUBSCRIBERS_REDUX_CONSTANTS } from '../../reduxConstants/admin/subscribersReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getSubscribersListAction = (params: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('subscribersListLoaderAction');
      const response = await subscribersApiServices.subscribersList(params);
      if (response.status === 200) {
        dispatch({
          type: SUBSCRIBERS_REDUX_CONSTANTS.SUBSCRIBERS_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('subscribersListLoaderAction');
    }
  };
};

export const getSubscribersFilterDropdown = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await subscribersApiServices.subscriberPlanDropdown();
      if (response.status === 200) {
        dispatch({
          type: SUBSCRIBERS_REDUX_CONSTANTS.PLAN_SUBSCRIBERS_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getSelectedSubscriberDetails = (params: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedAdminPlanDetailsLoaderAction');
      const response = await subscribersApiServices.getSubscriberDetailById(
        params
      );
      if (response?.status === 200) {
        dispatch({
          type: SUBSCRIBERS_REDUX_CONSTANTS.GET_SELECTED_SUBSCRIBER_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getSelectedAdminPlanDetailsLoaderAction'
      );
    }
  };
};

export const cancelPlan = (
  subscriberId: string,
  data: Record<string, boolean>,
  cb: () => void
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest(
        `cancelPlan${data.cancelNow ? 'Immediately' : 'PeriodEnd'}LoaderAction`
      );
      const response = await subscribersApiServices.cancelPlan(
        subscriberId,
        data
      );
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Plan cancelled successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        `cancelPlan${data.cancelNow ? 'Immediately' : 'PeriodEnd'}LoaderAction`
      );
    }
  };
};
