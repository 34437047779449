import { LOGIN_REDUX_CONSTANTS } from './../../reduxConstants/loginReduxConstants';
import { ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/accountSettingsUserReduxConstants';

export type UserType = {
  firstName: string;
  lastName: string;
  email?: string;
  contactNumber: string;
  role: string | null;
  _id?: string;
  isActive: boolean;
};

interface AccountSettingsUsersInitialStateProps {
  usersList: Record<string, any>;
  userDetail: Record<string, any>;
  list: Record<string, any>[];
}

const initialUsersReducer: AccountSettingsUsersInitialStateProps = {
  usersList: {},
  userDetail: {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    role: null,
    isActive: true,
    moduleAccess: [],
  },
  list: [],
};

export const accountSettingsUsersReducer = (
  state = initialUsersReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.USERS_LIST:
      return {
        ...state,
        usersList: action?.data,
      };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_USER_DETAIL:
      const { name, value } = action.data;
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          [name]: value,
        },
      };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.USER_DETAIL:
      const { moduleAccess } = action.data;
      const data = [...state.list];
      const accessModules = [...moduleAccess];

      const finalData = data.reduce((acc: any[], module: any) => {
        const foundModule = accessModules?.find(
          (accessModule: any) => module.name === accessModule.name
        );
        if (foundModule) {
          const module2: any = { ...module };
          module2.hasAccess = true;
          const subModules =
            module.subModules?.length > 0
              ? module.subModules.map((x: any) => {
                  const foundSubModule = foundModule?.subModules?.find(
                    (accesssubModule: any) => x.name === accesssubModule
                  );
                  const subModule2 = { ...x };
                  if (foundSubModule) {
                    subModule2.hasAccess = true;
                  } else {
                    subModule2.hasAccess = false;
                  }
                  return subModule2;
                })
              : [];
          module2.subModules = subModules;
          acc.push(module2);
        } else {
          const module2: any = { ...module };
          module2.hasAccess = false;
          acc.push(module2);
        }
        return acc;
      }, []);
      delete action.data.accessModule;
      return {
        ...state,
        userDetail: {
          ...action.data,
          moduleAccess: [...finalData],
        },
      };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.RESET_USER_DETAIL:
      return {
        ...state,
        userDetail: { ...initialUsersReducer.userDetail },
        list: [],
      };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_ROLE:
      action?.data?.map((accessModule: any) =>
        state?.userDetail?.moduleAccess?.filter(
          (modules: any, index: number) => {
            modules.name === accessModule.moduleName &&
              (state.userDetail.moduleAccess[index].hasAccess = true);

            modules.name === accessModule.moduleName &&
              modules?.hasSubModule &&
              accessModule?.subModules?.map((accessSubmodule: any) =>
                modules?.subModules?.map((subModule: any, i: number) => {
                  subModule.name === accessSubmodule &&
                    (state.userDetail.moduleAccess[index].subModules[
                      i
                    ].hasAccess = true);
                })
              );
            return;
          }
        )
      );
      return { ...state };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.ON_CHANGE_MODULE:
      const { moduleIndex, subModuleIndex } = action.data;
      let modules: [];

      if (subModuleIndex === undefined) {
        modules = state.userDetail.moduleAccess.map(
          (module: any, index: number) => {
            if (index === moduleIndex) {
              module.hasAccess = !module.hasAccess;
              const submodules = module.subModules?.map((submodule: any) => {
                if (module.hasAccess) submodule.hasAccess = true;
                else submodule.hasAccess = false;
                return submodule;
              });
              module.subModules = submodules;
            }
            return module;
          }
        );
      } else {
        modules = state.userDetail.moduleAccess.map(
          (module: any, index: number) => {
            if (index === moduleIndex) {
              const submodules = module.subModules.map(
                (submodule: any, subIndex: number) => {
                  if (subIndex === subModuleIndex) {
                    submodule.hasAccess = !submodule.hasAccess;
                  }
                  return submodule;
                }
              );
              const data = submodules.filter(
                (submodule: any) => submodule.hasAccess
              );
              data.length === 0
                ? (module.hasAccess = false)
                : (module.hasAccess = true);
            }
            return module;
          }
        );
      }

      return {
        ...state,
        userDetail: { ...state.userDetail, moduleAccess: modules },
      };

    case ACCOUNT_SETTINGS_USERS_REDUX_CONSTANTS.MODULE_LIST:
      return {
        ...state,
        userDetail: { ...state.userDetail, moduleAccess: action.data },
        list: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialUsersReducer,
      };

    default:
      return state;
  }
};
