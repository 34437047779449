import Button from '../../../../components/button/Button';
import companyLogo from '../../../../assets/images/company-logo.svg';
import { useHistory, useParams } from 'react-router-dom';
import accountSoftwareIntegration from '../../../../assets/images/account-software-integration..png';
import { useDispatch } from 'react-redux';
import { skipAccountingSoftwareIntegration } from '../../../../store/actions/accountingSoftwareActions';

const AccountSoftwareIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<Record<string, string>>();

  const onSkip = () => {
    dispatch(skipAccountingSoftwareIntegration(userId, history));
  };

  const onProceed = () => {
    history.push(`/account-software/${userId}`);
  };

  return (
    <div className="plans-container payment-container">
      <div className="auth-company-logo plan-header-container">
        <img src={companyLogo} alt="Foodygent" />
        <div className="plan-header">
          <div>Accounting Software</div>
        </div>
      </div>

      <div className="payment-details account-integration-page-container">
        <div className="payment-failed">
          <div className="payment-failed-image">
            <img src={accountSoftwareIntegration} alt="Payment Failed" />
          </div>
          <div className="payment-failed-text">
            Do you want to connect accounting software right now?
          </div>
        </div>
        <div className="pay-now-button-container">
          <div className="form-button-row">
            <Button variant="contained" color="secondary" onClick={onSkip}>
              Skip
            </Button>
            <Button variant="contained" color="primary" onClick={onProceed}>
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountSoftwareIntegration;
