export const GST_REDUX_CONSTANTS = {
  GST_LIST: 'GST_LIST',
  ADD_GST: 'ADD_GST',
  ON_CHANGE_GST_DATA: 'ON_CHANGE_GST_DATA',
  RESET_DATA: 'RESET_DATA',
  ADD_DEFAULT_GST: 'ADD_DEFAULT_GST',
  ON_CHANGE_DEFAULT_GST: 'ON_CHANGE_DEFAULT_GST',
  GET_DEFAULT_GST: 'GET_DEFAULT_GST',
  GET_SELECTED_GST_DETAIL: 'GET_SELECTED_GST_DETAIL',
};
