import { AxiosRequestConfig } from 'axios';
import { INVOICE_URLS, COMMON_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';
export const invoiceApiServices = {
  invoiceList: (params: Record<string, any>) =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.INVOICE_LIST, { params }),
  invoiceDetailsById: (invoiceId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.INVOICES_URLS.INVOICE_LIST}${invoiceId}`
    ),
  fetchAttachment: (config: any) => {
    return ApiService.getData(`${COMMON_URLS.FETCH_ATTACHMENTS}`, config);
  },

  invoiceDetailsByOrderId: (orderId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.INVOICES_URLS.GET_INVOICE_DETAILS_BY_ORDER_ID}${orderId}/invoice`
    ),
  orderListDropdown: (params: Record<string, string>) =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.GET_ORDER_LIST, { params }),
  acceptInvoice: (invoiceId: string, data: Record<string, string>) =>
    ApiService.putData(
      `${INVOICE_URLS.INVOICES_URLS.ACCEPT_INVOICE}${invoiceId}`,
      data
    ),
  pendingInvoice: (invoiceId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.INVOICES_URLS.PENDING_INVOICE}${invoiceId}`
    ),
  deleteInvoice: (params: Record<string, any>) =>
    ApiService.deleteData(`${INVOICE_URLS.INVOICES_URLS.DELETE_INVOICES}`, {
      params,
    }),
  supplierDropdownList: () =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.GET_SUPPLIER_DROPDOWN),
  userDropdownList: () =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.GET_USERS_DROPDOWN),
  modifyInvoice: (invoiceId: string, data: Record<string, string>) =>
    ApiService.postData(
      `${INVOICE_URLS.INVOICES_URLS.MODIFY_INVOICE}${invoiceId}/modify`,
      data
    ),
  getModyfiedData: (invoiceId: string, params: Record<string, string>) =>
    ApiService.putData(
      `${INVOICE_URLS.INVOICES_URLS.GET_MODIFIED_DATA}${invoiceId}/modified/document`,
      null,
      { params }
    ),
  downloadInvoicesExcelSheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.INVOICES_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadInvoicesPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.INVOICES_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...data,
        userDate: new Date(),
      },
    }),
  modifyUploadedInvoice: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.INVOICES_URLS.MODIFY_UPLOADED_INVOICE,
      null,
      { params }
    ),
  getModyfiedUploadedData: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.INVOICES_URLS.GET_MODIFY_UPLOADED_DATA,
      null,
      { params }
    ),
  syncInvoice: (invoiceId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.INVOICES_URLS.SYNC_INVOICE}${invoiceId}/manual-sync`
    ),
  supplierDropdown: () =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.SUPPLIER_DROPDOWN),
  productDropdown: (params: Record<string, string>) =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.PRODUCT_DROPDOWN, { params }),
  uploadCreateInvoice: (
    data: FormData,
    config: AxiosRequestConfig<any> | undefined
  ) =>
    ApiService.postData(
      INVOICE_URLS.INVOICES_URLS.UPLOAD_CREATE_INVOICE,
      data,
      config
    ),
  addInvoice: (data: Record<string, any>) =>
    ApiService.postData(INVOICE_URLS.INVOICES_URLS.ADD_INVOICE, data),
  getEditInvoiceData: (invoiceId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.INVOICES_URLS.GET_EDIT_INVOICE_DATA}${invoiceId}`
    ),
  updateInvoice: (invoiceId: string, data: Record<string, any>) =>
    ApiService.putData(
      `${INVOICE_URLS.INVOICES_URLS.UPDATE_INVOICE}${invoiceId}`,
      data
    ),
  autoSync: (data: Record<string, boolean>) =>
    ApiService.putData(INVOICE_URLS.INVOICES_URLS.AUTO_SYNC, data),
  getMapperData: (invoiceId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.INVOICES_URLS.GET_MAPPER_DATA}${invoiceId}`
    ),
  getInventoryProductList: () =>
    ApiService.getData(INVOICE_URLS.INVOICES_URLS.INVENTORY_PRODUCT_DROPDOWN),
  addImapConfig: (data: any, config: any) =>
    ApiService.postData(
      `${INVOICE_URLS.INVOICES_URLS.ADD_CONFIG}`,
      data,
      config
    ),
};
