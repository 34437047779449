import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { PROFILE_REDUX_CONSTANTS } from '../reduxConstants/profileReduxConstant';

const initialProfile: Record<string, any> = {
  profileDetail: {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    accountCreatedAt: '',
    profileURL: undefined,
    tempProfileURL: undefined,
    contactNumber: '',
    errors: {},
  },
};

export const profileReducer = (
  state = initialProfile,
  action: { type: string; data: Record<string, any> }
) => {
  switch (action.type) {
    case PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL:
      return {
        ...state,
        profileDetail: {
          ...action?.data,
          tempProfileURL: action?.data?.profileURL ?? null,
        },
        profileMenuDetail: {
          name: `${action?.data?.firstName}${
            action?.data?.lastName ? ` ${action?.data?.lastName}` : ''
          }`,
          role: action?.data?.role ?? '',
        },
      };

    case PROFILE_REDUX_CONSTANTS.ON_CHANGE_PROFILE_DETAIL:
      const { name, value } = action.data;
      return {
        ...state,
        profileDetail: {
          ...state.profileDetail,
          [name]: value,
        },
      };

    case PROFILE_REDUX_CONSTANTS.PROFILE_PICTURE:
      return {
        ...state,
        profileDetail: { ...state?.profileDetail, profileURL: action.data },
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialProfile,
      };

    default:
      return state;
  }
};
