import { useHistory } from 'react-router';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import error from '../../../assets/images/error.png';
import { Button } from '@mui/material';

const LinkExpired = () => {
  const history = useHistory();
  return (
    <div className="outer-container">
      <div className="white-container">
        <div className="title">The link you followed has expired</div>
        <div>
          <img src={error} />
        </div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
          }}
        >
          Back to Login
        </Button>
      </div>
    </div>
  );
};

export default LinkExpired;
