import { store } from '../redux/store';
import { GENERAL_LOADER_REDUX_CONSTANTS } from './../reduxConstants/generalLoaderReduxConstants';

export const startGeneralLoaderOnRequest = (loaderFor: string) => {
  store.dispatch({
    type: GENERAL_LOADER_REDUX_CONSTANTS.START_GENERAL_LOADER_ON_REQUEST,
    loaderFor,
  });
};
export const stopGeneralLoaderOnSuccessOrFail = (loaderFor: string) => {
  store.dispatch({
    type: GENERAL_LOADER_REDUX_CONSTANTS.STOP_GENERAL_LOADER_ON_SUCCESS_OR_FAIL,
    loaderFor,
  });
};
