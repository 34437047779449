import { Add } from '@mui/icons-material';
import { Radio } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import { color_palette } from '../../../../constants/colorPalette';
import { cardTypeIconHelper } from '../../../../helpers/cardTypeIconHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  getCardListAction,
  getPaymentDetailActionInBilling,
  palnUpgradDetails,
} from '../../../../store/actions/accountSettingsActions/billingOverviewActions';
import { useDispatch, useSelector } from 'react-redux';
import { cardDetailReducer } from '../../../../store/reducers/cardDetailReducer';

type cardListType = {
  cardName: string;
  cardNumber: string;
  cardType: string;
  expiryDate: { month: string; year: string };
  isDefault: boolean;
  _id: string;
};

const CardList = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const [selectedCard, setSelectedCard] = useState('');
  const { cardList, planUpgradeDetails } = useSelector(
    ({ billingOverviewReducer }: Record<string, any>) =>
      billingOverviewReducer ?? {}
  );

  const { flow } = useSelector(({ authReducer }: any) => authReducer);

  const { planId, cardId } = useMemo(
    () => planUpgradeDetails ?? {},
    [planUpgradeDetails]
  );

  const onProceed = async () => {
    const defaultCard = await cardList?.filter((card: any) => card.isDefault);
    if (selectedCard === '') {
      await dispatch(palnUpgradDetails('cardId', defaultCard?.[0]._id));
    } else {
      await dispatch(palnUpgradDetails('cardId', selectedCard));
    }
    const success = await dispatch(
      getPaymentDetailActionInBilling({
        cardId: cardId ?? defaultCard?.[0]._id,
        planId,
      })
    );

    success && history.push(ROUTE_CONSTANTS_VARIABLE.PLAN_PAYMENT_DETAILS);
  };

  useEffect(() => {
    dispatch(getCardListAction());
  }, []);

  return (
    <div className="change-plan-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          {flow !== 2 && (
            <BackButton
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN)}
            />
          )}
          <div className="form-title">Card Details</div>
        </div>
        <Button
          className="desktop-button"
          variant="contained"
          color="primary"
          onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.ADD_NEW_CARD)}
        >
          Add Card
        </Button>
        {/* Mobile Buttons start here */}
        <div className="mobile-button-container">
          <Button
            className="form-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.ADD_NEW_CARD)}
          >
            <Add />
          </Button>
        </div>
        {/* mobile button end here */}
      </div>
      {cardList?.length ? (
        <>
          <div className="payment-row-container margin-top">
            {cardList?.map((card: cardListType, i: number) => {
              return (
                <div className="card-detail-row" key={i}>
                  <div>
                    <Radio
                      checked={
                        selectedCard !== ''
                          ? selectedCard === card?._id
                          : card.isDefault
                      }
                      onChange={() => setSelectedCard(card?._id)}
                      value="value"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: color_palette.primary,
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                        '&.Mui-checked': {
                          color: color_palette.primary,
                        },
                      }}
                    />
                    {cardTypeIconHelper(card?.cardType)}
                  </div>
                  <span>{card.cardName}</span>
                  <span>{`**** **** **** ${card.cardNumber}`}</span>
                  <span>{`Validity : ${card.expiryDate.month}/${card.expiryDate.year}`}</span>
                </div>
              );
            })}
          </div>

          <div className="form-button-row margin-bottom">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                flow === 2
                  ? dispatch(getCardListAction())
                  : history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onProceed()}
            >
              Proceed
            </Button>
          </div>
        </>
      ) : (
        <div className="no-record-text">No card found</div>
      )}
    </div>
  );
};

export default CardList;
