import {
  ABN_REGEX,
  ALPHA_NUMERIC_REGEX,
  ALPHA_REGEX,
  EMAIL_ADDRESS_REGEX,
  MOBILE_NUMBER_REGEX,
} from '../../../../constants/regexConstants';
import { supplierRequireFields } from '../../../../constants/supplierConstants/supplierConstants';
import {
  editSupplier,
  onAddSupplier,
  onAddSupplierValueChange,
  validateSupplierAbnAndVenue,
} from '../../../../store/actions/supplierActions/supplierActions';

export const handleAddSupplierValidation = async (
  isSubmittedOnce: boolean,
  requestFrom: string,
  action: string,
  dispatch: any,
  supplier: Record<string, any>,
  fieldName: string | undefined,
  existingErrors: Record<string, string>,
  history: any,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    abn: 'enter valid abn / company number',
    companyName: 'enter valid company name',
    ordersEmail: 'enter valid orders email Id',
    name: 'enter Rep name',
    tradingName: 'enter trading name',
    category: 'select category / subcategory',
    contactNumber: 'enter valid account contact number',
    instructions: 'enter delivery instruction',
  };

  //checking require fields
  if (isSubmittedOnce) {
    for (const key in supplier) {
      if (supplierRequireFields.includes(key)) {
        if (!supplier[key]?.toString()?.trim()?.length) {
          validated = false;
          errors[key] = `Please ${errorMessages[key]}`;
        } else {
          delete errors[key];
        }
      }
    }
    dispatch(onAddSupplierValueChange('errors', errors));
  }

  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'abn':
        if (!ABN_REGEX.test(value)) {
          validated = false;
          errors.abn = 'Please enter valid abn / company number';
        } else {
          delete errors.abn;
        }
        break;

      case 'name':
        if (!ALPHA_REGEX.test(value)) {
          validated = false;
          errors.name =
            'Rep name should not empty or contain number & special character';
        }
        break;

      case 'contactNumber':
        if (!MOBILE_NUMBER_REGEX.test(value)) {
          validated = false;
          errors.contactNumber = 'Please enter valid account contact number';
        } else {
          delete errors.contactNumber;
        }
        break;

      case 'ordersEmail':
        if (!EMAIL_ADDRESS_REGEX.test(value)) {
          validated = false;
          errors.ordersEmail = 'Please enter valid orders email Id';
        } else {
          delete errors.ordersEmail;
        }
        break;

      case 'contactEmail':
        if (
          value.toString().trim().length &&
          !EMAIL_ADDRESS_REGEX.test(value)
        ) {
          validated = false;
          errors.contactEmail = 'Please enter valid contacts email Id';
        } else {
          delete errors.contactEmail;
        }
        break;

      case 'postalCode':
        if (
          value?.toString()?.trim()?.length &&
          !ALPHA_NUMERIC_REGEX.test(value)
        ) {
          validated = false;
          errors.postalCode = 'Please enter valid postal code';
        } else {
          delete errors.postalCode;
        }
        break;
    }
  };

  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in supplier) {
        const value = supplier[key];
        checkValidations(key, value);
      }
      if (supplier.abn !== '' && supplier.companyName !== '') {
        const companyDetails = await dispatch(
          validateSupplierAbnAndVenue(supplier.abn)
        );
        if (
          (companyDetails?.entityName || companyDetails?.tradingName) !==
          supplier.companyName
        ) {
          validated = false;
          errors.companyName = `Please enter valid company name`;
        }
        dispatch(onAddSupplierValueChange('errors', errors));
      }
    } else if (fieldName) {
      checkValidations(fieldName, supplier[fieldName]);
    }
    dispatch(onAddSupplierValueChange('errors', errors));
  }

  if (validated && requestFrom === 'onSubmit') {
    let finalData = supplier;

    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'abn':
            finalData['abn'] = finalData.abn.replace(/ +/g, '');
            break;
          case 'category':
            const category = finalData.category;
            finalData['categoryIds'] = [];
            finalData['subcategoryIds'] = [];
            category?.map((category: any) => {
              finalData.categoryIds.push(category._id);
              category.subcategoryIds?.map((subcategory: any) =>
                finalData.subcategoryIds.push(subcategory._id)
              );
            });
            break;
          case 'country':
            finalData['country'] =
              finalData['country']?.name ?? finalData['country'];
            break;
          case 'state':
            finalData['state'] = finalData['state'].name ?? finalData['state'];
            break;
          case 'contactEmail':
          case 'ordersEmail':
            finalData[key] = finalData[key].toLowerCase();

            break;
          default:
            finalData[key] = finalData[key].toString().trim();
            break;
        }
      }
    }
    delete supplier?.errors;

    if (action === 'add') {
      const isAddedSuccessFully = await dispatch(onAddSupplier(finalData));
      if (isAddedSuccessFully) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const isUpdatedSuccessfully = await dispatch(
        editSupplier(supplier._id, finalData, history)
      );
      if (isUpdatedSuccessfully) {
        setIsSubmittedSuccessfully(true);
      }
    }
  }
};
