import { INVOICE_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const statementsApiServices = {
  statementList: (params: Record<string, any>) =>
    ApiService.getData(INVOICE_URLS.STATEMENTS_URLS.STATEMENTS_LIST, {
      params,
    }),
  getSupplierList: () =>
    ApiService.getData(
      INVOICE_URLS.STATEMENTS_URLS.GET_SUPPLIER_LIST_IN_STATEMENT
    ),
  statementDetailsById: (statementId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.STATEMENTS_URLS.GET_DETAILS_BY_ID}${statementId}`
    ),
  updateStatusToPending: (statementId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.STATEMENTS_URLS.UPDATE_STATUE_TO_PENDING}${statementId}`
    ),
  updateStatusToReconciled: (statementId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.STATEMENTS_URLS.UPDATE_STATUS_TO_RECONCILED}${statementId}`
    ),
  deleteStatement: (params: Record<string, string>) =>
    ApiService.deleteData(INVOICE_URLS.STATEMENTS_URLS.DELETE_STATEMENT, {
      params,
    }),
  modifyStatement: (statementId: string, data: Record<string, string>) =>
    ApiService.postData(
      `${INVOICE_URLS.STATEMENTS_URLS.MODIFY_STATEMENT}${statementId}/modify`,
      data
    ),
  getModifiedData: (statementId: string, params: Record<string, string>) =>
    ApiService.putData(
      `${INVOICE_URLS.STATEMENTS_URLS.GET_MODIFIED_DATA}${statementId}/modified/document/`,
      null,
      { params }
    ),
  downloadStatementEscelsheet: (data: Record<string, string>) =>
    ApiService.request({
      method: 'get',
      url: INVOICE_URLS.STATEMENTS_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
  downloadStatementPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'get',
      url: INVOICE_URLS.STATEMENTS_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: { ...data, userDate: new Date() },
    }),
  syncStatement: (statementId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.STATEMENTS_URLS.SYNC_STATEMENT}${statementId}/manual-sync`
    ),
  statementAutoSync: (data: Record<string, boolean>) =>
    ApiService.putData(INVOICE_URLS.STATEMENTS_URLS.AUTO_SYNC, data),
  getModyfiedUploadedStatementData: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.STATEMENTS_URLS.GET_MODIFIED_UPLOADED_DATA,
      null,
      { params }
    ),
  modifyUploadedstatement: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.STATEMENTS_URLS.MODIFY_UPLOADED_EXPENSE,
      null,
      { params }
    ),
};
