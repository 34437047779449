import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';

const BackToLogin = () => {
  const history = useHistory();
  const backButtonClick = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
  };
  return (
    <div className="back-to-login-text">
      <span onClick={backButtonClick}>Back to Login</span>
    </div>
  );
};

export default BackToLogin;
