import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { AUTH_REDUX_CONSTANTS } from './../reduxConstants/authReduxConstants';
import { AccessModuleProps } from '../../helpers/urlManagementHelper';

export interface initialReducerProps {
  authStatus: boolean;
  accessModuleList: AccessModuleProps[];
  userPlanDetails: Record<string, any>[];
}

const initialAuthReducer: initialReducerProps = {
  authStatus: false,
  accessModuleList: [],
  userPlanDetails: [],
};

export const authReducer = (
  state = initialAuthReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS:
      return {
        ...state,
        authStatus: action?.status,
        accessModuleList: action.data,
      };

    case AUTH_REDUX_CONSTANTS.ADD_DATA:
      return { ...state, ...action.data, authStatus: action?.status };

    case AUTH_REDUX_CONSTANTS.PLAN_DETAILS_OF_USER:
      return {
        ...state,
        userPlanDetails: action.data,
      };

    case AUTH_REDUX_CONSTANTS.ADD_ON_DATA:
      const { name, value } = action.data;
      return { ...state, [name]: value };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialAuthReducer,
        authStatus: false,
      };

    default:
      return state;
  }
};
