import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import CustomTable from '../../../../components/customTable/CustomTable';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import {
  cancelPlan,
  getSelectedSubscriberDetails,
} from '../../../../store/actions/admin/subscribersAction';

const ViewSubscriber = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { subscriberId } = useParams<Record<string, string>>();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [cancelId, setCancelId] = useState<string>('');
  const [disableAtPeriodEnd, setDisableAtPeriodEnd] = useState(false);

  const { planList } = useSelector(
    ({ subscribersReducer }: Record<string, any>) => subscribersReducer ?? {}
  );
  const { cancelPlanImmediatelyLoaderAction, cancelPlanPeriodEndLoaderAction } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? {}
    );

  const {
    docsActive,
    docsCancelled,
    headerActive,
    headerCancelled,
    limitPlan,
    pagePlan,
    pagesPlan,
    totalPlan,
  } = useMemo(() => planList ?? {}, [planList]);

  const onPageChangePlan = (page: any) => {
    dispatch(
      getSelectedSubscriberDetails({
        page: page,
        limit: limitPlan,
        subscriberId,
      })
    );
  };

  const onChangeLimitPlan = (value: any) => {
    dispatch(
      getSelectedSubscriberDetails({ page: 1, limit: value, subscriberId })
    );
  };

  const afterSuccessfulCancellation = async () => {
    setOpenCancelModal(false);
    setCancelId('');
    const paramsPlan = {
      activePagePlan: 1,
      activeLimitPlan: 15,
      cancelPagePlan: 1,
      cancelLimitPlan: 15,
      subscriberId: subscriberId,
    };
    await dispatch(getSelectedSubscriberDetails({ ...paramsPlan }));
  };

  const onClickCancelConfirmation = (cancelNow: boolean) => {
    if (subscriberId) {
      dispatch(
        cancelPlan(subscriberId, { cancelNow }, afterSuccessfulCancellation)
      );
    }
  };

  const cancelModalButton: ButtonType[] = disableAtPeriodEnd
    ? [
        {
          title: 'Cancel immediately',
          onClick: () => onClickCancelConfirmation(true),
          color: 'primary',
          loader: cancelPlanImmediatelyLoaderAction,
        },
      ]
    : [
        {
          title: 'Cancel immediately',
          onClick: () => onClickCancelConfirmation(true),
          color: 'primary',
          loader: cancelPlanImmediatelyLoaderAction,
        },
        {
          title: 'Cancel at period end',
          onClick: () => onClickCancelConfirmation(false),
          color: 'primary',
          loader: cancelPlanPeriodEndLoaderAction,
        },
      ];

  const handleClose = () => {
    setOpenCancelModal(false);
    setCancelId('');
  };

  const cancelTableRow = (row: Record<string, any>) => {
    setCancelId(row.id);
    setDisableAtPeriodEnd(row.cancelAtPeriodEnd);
    setOpenCancelModal(true);
  };

  const mobileHeadersPlan = ['packageType', 'planName'];

  useEffect((): any => {
    const paramsPlan = {
      activePagePlan: 1,
      activeLimitPlan: 15,
      cancelPagePlan: 1,
      cancelLimitPlan: 15,
      subscriberId: subscriberId,
    };

    dispatch(getSelectedSubscriberDetails({ ...paramsPlan }));
  }, []);

  //to here

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => {
              history.push(ADMIN_ROUTE_CONST.SUBSCRIBERS);
            }}
          />
          {/* <div className="form-title">{organization}</div> */}
          <div className="form-title">Subscriber Details</div>
        </div>
        <div className="button-container">
          <div className="subscriber-detail-container">
            <div className="restro-name">{planList?.organizationName}</div>
            <div className="subscriber-email">
              {planList?.organizationEmail}
            </div>
          </div>
        </div>
      </div>
      <div className="subscriber-view-body">
        <div className="main-details-container">
          <div className="details-block-title">
            Payment Subscription History
          </div>
          <CustomTable
            headers={headerActive}
            mobileHeaders={mobileHeadersPlan}
            data={docsActive}
            isAction
            isCancelable={true}
            isEditable={false}
            isDeleteAction={false}
            cancelTableRow={cancelTableRow}
            isPagination
            listName="subscirberActivePlans"
          />
        </div>
        <div className="main-details-container">
          <div className="details-block-title">Cancellation History</div>
          <CustomTable
            headers={headerCancelled}
            mobileHeaders={mobileHeadersPlan}
            data={docsCancelled}
            limit={limitPlan}
            page={pagePlan}
            pages={pagesPlan}
            total={totalPlan}
            onPageChange={onPageChangePlan}
            onChangeLimit={onChangeLimitPlan}
            listName="subscirberCancelledPlans"
            isPagination
          />
        </div>
      </div>
      {openCancelModal && (
        <CustomModal
          className="delete-modal"
          header="Cancel Plan"
          buttons={cancelModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Please choose the option to cancel the plan
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default ViewSubscriber;
