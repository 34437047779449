import { ROUTE_CONSTANTS_VARIABLE } from '../routes/RouteConstants';

export const upgradePlanRedirectionHelper = (
  data: Record<string, any>,
  history: any
) => {
  switch (data.flow) {
    case 1:
      history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN);
      break;

    case 2:
      history.push(ROUTE_CONSTANTS_VARIABLE.CARD_LIST);
      break;

    default:
      let key = data.moduleAccess[0]?.hasSubModule
        ? data.moduleAccess[0]?.subModules[0]
            .kebabToSnakeCaseConvertor()
            .toUpperCase()
        : data.moduleAccess[0]?.name.kebabToSnakeCaseConvertor().toUpperCase();
      history.push(ROUTE_CONSTANTS_VARIABLE[key]);
  }
};
