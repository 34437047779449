import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { preparedCountries } from '../../../../constants/countryConstants';
import { ADMIN_ROUTE_CONST } from '../../../../routes/RouteConstants';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button/Button';
import { addGstValidation } from './addGstValidations';
import { useEffect, useState } from 'react';
import {
  getSelectedGstDetailAction,
  onChangeGstDataAction,
  resetGstData,
} from '../../../../store/actions/admin/gstActions';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import rightIcon from '../../../../assets/images/right-icon.svg';
import Loader from '../../../../components/loader/Loader';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const AddGst = () => {
  const history = useHistory();
  const { action, gstId } = useParams<Record<string, string>>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const { gstDetail } = useSelector(
    ({ gstReducer }: Record<string, any>) => gstReducer ?? {}
  );

  const {
    getSelectedGstDetailLoaderAction,
    updateGstDetailsLoaderAction,
    addGstLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { errors } = gstDetail || {};

  const onClickAddGst = async (action: string) => {
    setIsSubmitted(true);
    addGstValidation(
      dispatch,
      gstDetail,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const onBlur = (name: string) => {
    addGstValidation(
      dispatch,
      gstDetail,
      errors,
      isSubmitted,
      'onBlur',
      name,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const onSelectChange = (name: string, _e: any, value: any) => {
    dispatch(onChangeGstDataAction(name, value));
    if (isSubmitted) {
      addGstValidation(
        dispatch,
        { ...gstDetail, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeGstDataAction(name, value));
  };

  const GstAddedConfirmationButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ADMIN_ROUTE_CONST.GST);
      },
      color: 'primary',
    },
  ];

  useEffect((): any => {
    if (gstId) {
      dispatch(getSelectedGstDetailAction(gstId));
    }
    return () => dispatch(resetGstData());
  }, []);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton onClick={() => history.push(ADMIN_ROUTE_CONST.GST)} />
        <span className="form-title">
          {`${action === 'add' ? 'Add' : 'Edit'} GST(%)`}
        </span>
      </div>
      {action === 'edit' && getSelectedGstDetailLoaderAction ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="add-gst-form">
            <div className="input-field-margin-bottom">
              <InputLabel className="add-gst-label">Country</InputLabel>
              <FormControl
                className={`input-field ${
                  gstDetail?.errors?.country ? 'input-with-error' : ''
                }`}
              >
                <Autocomplete
                  key="country"
                  options={preparedCountries}
                  className={`autocomplete ${
                    gstDetail?.errors?.country ? 'input-with-error' : ''
                  }`}
                  getOptionLabel={(option) => option.name ?? option ?? ''}
                  onChange={(event, value) =>
                    onSelectChange('country', event, value)
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        className="select__country-flag"
                        src={`https://flagcdn.com/w20/${option?._id?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?._id?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option?.name}
                    </Box>
                  )}
                  value={gstDetail?.country}
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder="Select Country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {gstDetail?.errors?.country}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="input-field-margin-bottom">
              <InputLabel className="add-gst-label">GST(%)</InputLabel>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter GST(%)"
                  className={`input-field ${
                    gstDetail?.errors?.gst ? 'input-with-error' : ''
                  }`}
                  name="gst"
                  value={gstDetail?.gst}
                  type="number"
                  helperText={gstDetail?.errors?.gst}
                  onChange={onHandleChangeInput}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddGst(action))
                  }
                  onBlur={() => onBlur('gst')}
                />
              </FormControl>
            </div>
            <div className="form-button-row">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(ADMIN_ROUTE_CONST.GST);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={updateGstDetailsLoaderAction || addGstLoaderAction}
                onClick={() => {
                  onClickAddGst(action === 'add' ? 'add' : 'edit');
                }}
              >
                {action === 'add' ? 'Add GST(%)' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isSubmittedSuccessfully && (
        <CustomModal
          header="GST Added Confirmation"
          buttons={GstAddedConfirmationButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ADMIN_ROUTE_CONST.GST);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>GST added successfully</span>
          </>
        </CustomModal>
      )}
    </div>
  );
};

export default AddGst;
