import { Delete } from '@mui/icons-material';
import _ from 'lodash';
import { stringify } from 'querystring';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { filterIcon } from '../../../assets/images/filter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import FilterModal from '../../../components/filterModal/FilterModal';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import {
  getReconciledStatementsList,
  supplierListDropdownAction,
  ReconciledStatementExportToPdf,
  ReconciledStatementExportToExcel,
} from '../../../store/actions/reportActions/reconciledStatementsAction';

const ReconciledStatement = () => {
  const dispatch = useDispatch();
  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const { reconciledStatementsList, supplierList } = useSelector(
    ({ reconciledStatementsReducer }: Record<string, any>) =>
      reconciledStatementsReducer ?? {}
  );

  const { docs, headers, page, limit, pages, total } = useMemo(
    () => reconciledStatementsList ?? {},
    [reconciledStatementsList]
  );

  const { reconciledStatementsListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );
  const exportToPdf = () => {
    dispatch(ReconciledStatementExportToPdf(filters));
  };
  const exportToExcel = () => {
    dispatch(ReconciledStatementExportToExcel(filters));
  };

  const { reconciledStatement } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const filters = {
    supplierId: reconciledStatement?.supplierId?._id,
    startDate: reconciledStatement?.startDate,
    endDate: reconciledStatement?.endDate,
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getReconciledStatementsList({ page: page, limit }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getReconciledStatementsList({ page: 1, limit: value }));
  };

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplierList,
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getReconciledStatementsList,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'reconciledStatement',
      () => setOpenFilterModal(false),
      [supplierListDropdownAction]
    );
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: limit ?? paramLimit ?? 15,
    supplierId: reconciledStatement?.supplierId?._id,
    startDate: reconciledStatement?.startDate
      ? new Date(reconciledStatement?.startDate)?.toISOString()
      : undefined,
    endDate: reconciledStatement?.endDate
      ? new Date(reconciledStatement?.endDate)?.toISOString()
      : undefined,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    dispatch(getReconciledStatementsList(params));
    dispatch(supplierListDropdownAction());
    dispatch(resetfilterOnModuleChange('reconciledStatement'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Reconciled Statements</div>
        <div className="button-container">
          {!_.isEmpty(reconciledStatement) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(reconciledStatement) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(reconciledStatement)}
            filterData={reconciledStatement}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="product-action"
          />
        </div>
      </div>
      {reconciledStatementsListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={['supplierName', 'statementDate']}
          data={docs}
          page={page}
          pages={pages}
          total={total}
          limit={limit}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
        />
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="reconciledStatement"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getReconciledStatementsList}
          isDateFilters
          filterAction={[supplierListDropdownAction]}
        />
      )}
    </div>
  );
};

export default ReconciledStatement;
