import { IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import uploaded from '../../assets/images/uploaded-icon.svg';
import { downloadAll } from '../../helpers/downloadHelper';
import { getAuthTokenFromLocalStorage } from '../../helpers/localstoragehelper';
import { downloadSampleCsvFile } from '../../store/actions/commonAction';
import Button from '../button/Button';
import Modal from '../customModal/CustomModal';
import { errorNotification } from '../notifyHelper';
import { desktopModificationIcon } from '../../assets/images/desktopModificationIcon';
import ButtonLoader from '../buttonLoader/ButtonLoader';

interface ImportUsersProps {
  setImportModal: (val: boolean) => void;
  className?: string;
  fileNameKey: string;
  afterFileUploadSuccess: () => void;
  actionUrl: string;
  modifyDoc?: any;
  getModifiedDoc?: any;
  saveUploadResponse?: any;
  csvFor?: string;
  modalTitle?: String;
  fileNameTitle?: String;
  buttonTitle?: string;
  fileExtension?: string[];
  mimeType?: string[];
  errorMessage?: string;
}

let cancelTokenSource = axios.CancelToken.source();

const ImportCsv: FC<ImportUsersProps> = (props) => {
  const {
    setImportModal,
    className,
    actionUrl,
    modifyDoc,
    getModifiedDoc,
    saveUploadResponse,
    afterFileUploadSuccess,
    fileNameKey,
    csvFor,
    modalTitle,
    fileNameTitle,
    buttonTitle,
    fileExtension,
    mimeType,
    errorMessage,
  } = props;
  const dispatch = useDispatch<any>();
  const [errorsList, setErrorsList] = useState<string[] | null>(null);
  const [progressPer, setProgressPer] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [validFile, setValidFile] = useState(false);
  const [getModifiedData, setModifiedData] = useState(false);
  const [modifyButton, setModifyButton] = useState(false);
  const [uploadedResponseData, setUploadedResponseData] = useState<
    Record<string, any>
  >({});
  const [closeModalButton, setCloseModalButton] = useState(true);
  let checkMimeTypes;
  let checkExtension;
  let checkFileSize;

  const {
    getUploadedModifiedDataLoaderAction,
    getUploadedExpenseModifiedDataLoaderAction,
    getUploadedCreditNoteModifiedDataLoaderAction,
    getUploadedStatementModifiedDataLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onDrop = (accepted: any, rejected: any) => {
    setCloseModalButton(false);
    setProcessing(false);
    setUploadComplete(false);
    setErrorsList(null);
    cancelTokenSource = axios.CancelToken.source();

    if (accepted && accepted.length > 0) {
      const defaultFileExtension = ['csv'];
      const defaultMimeType = ['text,csv'];

      checkExtension = fileExtension
        ? fileExtension.indexOf(accepted[0].name.split('.').splice(-1)[0]) !==
          -1
        : defaultFileExtension.indexOf(
            accepted[0].name.split('.').splice(-1)[0]
          ) !== -1;
      checkMimeTypes = mimeType
        ? mimeType.indexOf(accepted[0].type) !== -1
        : defaultMimeType.indexOf(accepted[0].type) !== -1;
      checkFileSize = accepted[0].size > 4194304;
      if (!(checkExtension || checkMimeTypes)) {
        errorNotification(`${errorMessage || 'Only csv file allowed'}`);
        setProcessing(false);
        setValidFile(false);
      } else if (checkFileSize) {
        errorNotification('File size should be upto 4 MB');
        setProcessing(false);
        setValidFile(false);
      } else {
        setValidFile(true);
        if (accepted?.[0]) {
          const selectedFile = accepted?.[0];
          const formData = new FormData();
          formData.append(fileNameKey, selectedFile);
          const token = getAuthTokenFromLocalStorage();
          const config: AxiosRequestConfig<any> | undefined = {
            headers: {
              'Content-Type': 'text/csv',
              Authorization: `${token}`,
            },
            // timeout: 120000,
            onUploadProgress: (ProgressEvent: any) => {
              const { loaded, total } = ProgressEvent;
              let percentage = Math.floor((loaded * 100) / total);
              setProgressPer(percentage);
            },
            cancelToken: cancelTokenSource.token,
          };
          axios
            .post(`${actionUrl}`, formData, config)
            .then((res) => {
              setCloseModalButton(true);
              if (res?.status === 201) {
                setErrorsList(null);
                setUploadedResponseData({
                  ...res?.data?.data,
                  message: res.data.message,
                });
                if (saveUploadResponse)
                  dispatch(
                    saveUploadResponse({
                      ...res?.data?.data,
                      message: res.data.message,
                    })
                  );
                res?.data?.data?.modifyDocument && setModifyButton(true);
              }
              setProcessing(false);
              setUploadComplete(true);
            })
            .catch((e) => {
              setCloseModalButton(true);
              setProcessing(false);
              setUploadComplete(false);
              if (
                e?.response?.headers?.['content-type'] ===
                'text/csv; charset=utf-8'
              ) {
                downloadAll(e?.response);
                setErrorsList([
                  'The uploaded file is inappropriate, please reflect all the changes given in the downloaded CSV file',
                ]);
              } else if (
                e?.response?.headers?.['content-type'] ===
                'application/json; charset=utf-8'
              ) {
                if (Array.isArray(e?.response?.data?.message)) {
                  setErrorsList(e?.response?.data?.message);
                } else {
                  setErrorsList([e?.response?.data?.message]);
                }
              }
            });
        }
      }
    }
  };

  const disableDropzone = () => {
    if (progressPer === 0) {
      return false;
    } else if (progressPer === 100 && errorsList?.length) {
      return false;
    } else {
      return true;
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, isFileDialogActive } =
    useDropzone({
      onDrop,
      disabled: disableDropzone(),
    });

  const onCancelUpload = () => {
    setProgressPer(0);
    setProcessing(false);
    setUploadComplete(false);
    cancelTokenSource.cancel();
  };

  const downloadSampleFile = () => {
    {
      csvFor && dispatch(downloadSampleCsvFile(csvFor));
    }
  };

  const modifyData = async () => {
    setUploadedResponseData((prev) => ({
      ...prev,
      message: 'Get your modified document from Xtracta',
    }));
    const data = {
      documentId: uploadedResponseData.documentId,
    };
    const success = await dispatch(modifyDoc(data));
    if (success) {
      setModifiedData(true);
    }
    setModifyButton(false);
  };

  const getUploadedModifiedData = async () => {
    const data = {
      documentId: uploadedResponseData.documentId,
      fileName: uploadedResponseData.fileName.toString(),
    };

    try {
      const response = await dispatch(getModifiedDoc(data));

      if (response) {
        response?.modifyDocument && setModifyButton(true);
        setModifiedData(false);
      } else {
        setUploadComplete(false);
        setImportModal(false);
        afterFileUploadSuccess();
      }
    } catch (e) {
      setUploadComplete(false);
      setImportModal(false);
      afterFileUploadSuccess();
    }
  };

  const showButton = () => {
    if (uploadedResponseData.modifyDocument && modifyButton) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => modifyData()}
        >
          Modify
        </Button>
      );
    } else if (getModifiedData) {
      return (
        <Button
          className="list-icon-button desktop-button"
          variant="contained"
          color="primary"
          onClick={() => getUploadedModifiedData()}
        >
          {getUploadedModifiedDataLoaderAction ||
          getUploadedExpenseModifiedDataLoaderAction ||
          getUploadedCreditNoteModifiedDataLoaderAction ||
          getUploadedStatementModifiedDataLoaderAction ? (
            <ButtonLoader />
          ) : (
            desktopModificationIcon
          )}
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setImportModal(false);
            afterFileUploadSuccess();
          }}
        >
          Okay
        </Button>
      );
    }
  };

  useEffect(() => {
    if (progressPer !== 100 && acceptedFiles?.length) {
      setProcessing(true);
    }
  }, [progressPer, acceptedFiles]);

  return (
    <Modal
      hideModal={() => {
        setImportModal(false);
        if (uploadComplete) {
          afterFileUploadSuccess();
        }
      }}
      className={className}
      bodyClassName="import-csv-body"
      headerClassName="import-csv-header"
      isHideModal={closeModalButton}
    >
      {modalTitle ? (
        <div className="choose-csv-title">{modalTitle}</div>
      ) : (
        <div className="choose-csv-title">
          Here is a sample CSV file{' '}
          <Tooltip title="Download sample file" arrow enterTouchDelay={0}>
            <span onClick={downloadSampleFile}>Download</span>
          </Tooltip>
          it.
        </div>
      )}

      <div
        {...getRootProps({
          className: 'dropzone browse-file-button-container',
        })}
      >
        <input {...getInputProps()} />
        <Button
          color="primary"
          variant="contained"
          component="span"
          className="browse-file-button"
        >
          {buttonTitle ? buttonTitle : 'Browse CSV File'}
        </Button>
      </div>

      {acceptedFiles?.[0] && validFile && (
        <div className="uploaded-csv-file-container">
          <div className="uploaded-csv-file-title">
            {fileNameTitle ? fileNameTitle : 'Uploaded CSV File'}
          </div>
          <div className="progress-bar-title">
            <span onClick={() => {}}>{acceptedFiles[0]?.name}</span>
            <span className="percentage-count">{`${progressPer}%`}</span>
          </div>
          <div className="progressbar-container">
            <LinearProgress
              variant="determinate"
              value={progressPer}
              className="progress-bar"
            />
            {progressPer !== 100 ? (
              <IconButton
                aria-label="upload picture"
                component="span"
                className="progress-btn"
                onClick={onCancelUpload}
                size="large"
              >
                <CloseIcon className="progress-close-icon" />
              </IconButton>
            ) : (
              <img className="progress-green-tick-mark" src={uploaded} />
            )}
          </div>
          {errorsList?.length && (
            <ul className="csv-error-list">
              {errorsList.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          )}
          {(processing || (uploadComplete && !errorsList?.length)) && (
            <div className="progress-bar-action">
              {processing && <div>Please wait extracting your data...</div>}
              {uploadComplete && !errorsList?.length && (
                <div className="csv-upload-process-success-text">
                  {uploadedResponseData.message
                    ? uploadedResponseData.message
                    : 'File uploaded successfully!!'}
                </div>
              )}
              {uploadComplete && showButton()}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ImportCsv;
