import dayjs from 'dayjs';
import { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router';
import { errorNotification } from '../../../components/notifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  confirmAndSendOrderDetails,
  createOrder,
  onChangeOrderDetail,
  updateOrder,
} from '../../../store/actions/orderAction';

export const checkOrderValidation = (
  requestType: string,
  data: Record<string, any>,
  dispatch: Dispatch<any>,
  history: RouteComponentProps['history'],
  requestFrom: string,
  action?: string
) => {
  let isValidated = true;
  let errors: Record<string, string> = {};
  const fieldLabel: Record<string, string> = {
    deliveryDate: 'enter delivery date',
    deliveryTime: 'enter delivery time',
    supplierId: 'select supplier',
    products: 'select product/s',
  };

  for (const key in data) {
    switch (key) {
      case 'deliveryDate':
      case 'deliveryTime':
        if (
          !data?.[key]?.toString()?.trim()?.length ||
          data?.[key] === undefined ||
          data?.[key] === null
        ) {
          isValidated = false;
          if (errors) {
            errors[key] = `Please ${fieldLabel[key]}`;
            data?.status === 'Draft' &&
              action !== 'edit' &&
              errorNotification(
                'Please select delivery date and delivery time by edit order'
              );
          }
        } else {
          delete errors[key];
        }
        break;
      default:
        if (
          !data?.[key]?.toString()?.trim()?.length &&
          key !== 'notes' &&
          key !== 'errors' &&
          key !== 'search'
        ) {
          isValidated = false;
          errors[key] = `Please ${fieldLabel[key]}`;
        } else {
          delete errors?.[key];
        }
    }
  }

  data?.products?.forEach((product: Record<string, string | number>) => {
    if (!product?.quantity) {
      isValidated = false;
      errorNotification(`Please increase quantity for ${product.name}`);
    }
  });

  if (!isValidated) {
    dispatch(onChangeOrderDetail({ name: 'errors', value: errors }));
  }
  if (!Object.keys(errors).length) {
    isValidated = true;
    dispatch(onChangeOrderDetail({ name: 'errors', value: undefined }));
  }

  if (isValidated && requestFrom === 'onSubmit') {
    const finalData = data;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'notes':
          case 'status':
          case '_id':
            finalData[key] = finalData[key].toString().trim();
            break;

          case 'deliveryTime':
            finalData[key] = finalData[key];
            break;

          case 'deliveryDate':
            finalData[key] = dayjs(finalData[key]).format('YYYY-MM-DD');
            break;

          case 'supplierId':
            finalData[key] = finalData[key]._id;
            break;

          case 'products':
            const product = finalData['products'].map(
              (product: Record<string, Record<string, number | string>>) => ({
                _id: product._id,
                quantity: product.quantity,
                weight: product.weight,
                unitId: product.unitId._id,
                productNote: product.productNote,
              })
            );
            finalData[key] = product;
            break;
          default:
            delete finalData[key];
        }
      }
    }

    delete finalData?.errors;

    if (action === 'create') {
      dispatch(createOrder(finalData, history));
    } else if (action === 'edit') {
      if (finalData?.status === 'Draft' || data?.status === 'Draft') {
        dispatch(
          updateOrder(data._id, { isForAddOn: false }, finalData, () =>
            history.push(`/orders/draft/${data._id}`)
          )
        );
      } else if (finalData?.status === 'Placed' || data?.status === 'Placed') {
        dispatch(
          updateOrder(data._id, { isForAddOn: false }, finalData, () =>
            history.push(`/orders/placed/${data._id}`)
          )
        );
      }
    } else if (action === 'addOn') {
      dispatch(
        updateOrder(data._id, { isForAddOn: true }, finalData, () =>
          history.push(`/orders/placed/${data._id}`)
        )
      );
    }
    if (requestType === 'confirm') {
      const loadOrderList = () => history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
      dispatch(confirmAndSendOrderDetails(data._id, finalData, loadOrderList));
    }
  }
};
