import { RECONCILE_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/reconcileReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';
import { statementDetailType } from './statementReducer';

interface reconcileprops {
  reconcileStatementList: Record<string, any>;
  supplierList: Record<string, string>[];
  statement: Record<string, string>[] | null;
  invoiceId: Record<string, string>[] | null;
  invoices: Record<string, string | number | boolean>[];
  adjustmentNoteDetails: Record<string, any>;
}

const initialReconcileReducer: reconcileprops = {
  reconcileStatementList: {},
  supplierList: [],
  statement: null,
  invoiceId: null,
  invoices: [],
  adjustmentNoteDetails: {
    statementId: null,
    invoiceId: null,
    amount: '',
    note: '',
  },
};

export const reconcileReducer = (
  state = initialReconcileReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action.type) {
    case RECONCILE_REDUX_CONSTANTS.GET_RECONCILE_STATEMENT_LIST:
      return {
        ...state,
        reconcileStatementList: action.data,
      };

    case RECONCILE_REDUX_CONSTANTS.GET_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: action.data,
      };

    case RECONCILE_REDUX_CONSTANTS.GET_STATEMENT_LIST:
      return { ...state, statement: action.data };

    case RECONCILE_REDUX_CONSTANTS.GET_INVOICE_ID_LIST:
      return { ...state, invoiceId: action.data };

    case RECONCILE_REDUX_CONSTANTS.ON_CHANGE_INVOICE_CHECKBOX:
      const finalInvoices =
        state.reconcileStatementList.docs?.[0].invoices.reduce(
          (acc: any, item: any) => {
            const foundData = action.data?.find((id: any) => item._id === id);
            foundData && acc.push(item);
            return acc;
          },
          []
        );

      return { ...state, invoices: finalInvoices };

    case RECONCILE_REDUX_CONSTANTS.ON_CHANGE_ADJUSTMENT_NOTE:
      const { name, value } = action.data;
      return {
        ...state,
        adjustmentNoteDetails: {
          ...state.adjustmentNoteDetails,
          [name]: value,
        },
      };

    case RECONCILE_REDUX_CONSTANTS.RESET_ADJUSTMENTNOTE_DETAILS:
      return {
        ...state,
        adjustmentNoteDetails: initialReconcileReducer.adjustmentNoteDetails,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialReconcileReducer,
      };

    default:
      return state;
  }
};
