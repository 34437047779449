import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import dummy from '../../../src/assets/images/user-profile.svg';
import CustomModal, { ButtonType } from '../customModal/CustomModal';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import Button from '../button/Button';
import { canvasPreview } from './ImageCroppeedCanvas';
import { atob } from 'buffer';
import { escape } from 'querystring';

type FileUploadProps = {
  fileName?: string;
  isProfile?: boolean;
  handleChange?: (e: any) => void;
  handleUpdate: (file: any) => void;
  profilePictureUrl?: string;
  className?: string;
  file?: Blob | MediaSource;
  isDeleteIcon?: boolean;
  onDeleteClick?: () => void;
  removeFile: () => void;
};

const FileUpload = (props: FileUploadProps) => {
  const {
    isProfile = true,
    handleChange,
    handleUpdate,
    profilePictureUrl,
    fileName,
    className,
    file,
    removeFile,
  } = props;

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [url, setUrl] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const aspect = 1;

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleUpdation = () => {
    previewCanvasRef?.current?.toBlob(
      (blob: any) => {
        const image: any = file;
        const fileName = image?.name;
        if (file) {
          const croppedFile = new File([blob], fileName);
          handleUpdate(croppedFile);
        }
      },
      'image/jpeg',
      0.5
    );
  };

  const editImageButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setUrl('');
        removeFile();
      },
      color: 'secondary',
    },
    {
      title: 'Update',
      onClick: handleUpdation,
      color: 'primary',
    },
  ];

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  useEffect(() => {
    if (file) {
      setUrl(URL.createObjectURL(file));
    } else {
      setUrl('');
    }
  }, [file]);

  const clearFileData = () => {
    setUrl('');
    removeFile();
  };

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
    }
  }, [completedCrop, scale, rotate]);

  return (
    <div className={className ?? 'user-dp-upload'}>
      {isProfile ? (
        <div className="profile-dp-upload">
          {profilePictureUrl ? (
            <div className="profile-dp-container">
              <img
                alt="profile-image"
                className="user-dp"
                src={profilePictureUrl || dummy}
              />
              {profilePictureUrl && (
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  className="profile-close-btn"
                  onClick={clearFileData}
                  size="large"
                >
                  <CloseIcon className="close-icon" />
                </IconButton>
              )}
            </div>
          ) : (
            <img className="user-dp" src={dummy} />
          )}
        </div>
      ) : (
        ''
      )}
      <div className="file-upload-container">
        <Button
          color="primary"
          variant="outlined"
          className="outlined-primary-button"
          onClick={handleClick}
        >
          Upload Photo
        </Button>
        <input
          type="file"
          className="input-file-type"
          ref={hiddenFileInput}
          onChange={handleChange}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            e.currentTarget.value = '';
          }}
        />
        <p onClick={handleClick}>{fileName}</p>
      </div>
      {Boolean(url) && (
        <CustomModal
          className="image-edit-modal"
          header="Edit Photo"
          hideModal={() => setUrl('')}
          buttons={editImageButtons}
        >
          <div className="image-container">
            <ReactCrop
              crop={crop}
              ruleOfThirds
              onChange={(_, percentCrop: any) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={url}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <canvas ref={previewCanvasRef} />
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default FileUpload;
