import { PROFILE_URLS } from '../../../constants/urlConstants';
import { profileDetailType } from '../../../pages/profile-module/editProfile/EditProfile';
import ApiService from '../apiService';

const ProfileApiServices = {
  getProfileDetail: () => ApiService.getData(PROFILE_URLS.GET_PROFILE_DETAIL),
  updateProfileDetail: (data: Record<string, any>) =>
    ApiService.putData(PROFILE_URLS.GET_PROFILE_DETAIL, data),
  uploadProfilePicture: (data: FormData, config: any) =>
    ApiService.putData(PROFILE_URLS.UPLOAD_PROFILE_IMAGE, data, config),
  changePassword: (data: object) =>
    ApiService.postData(PROFILE_URLS.CHANGE_PASSWORD_URL, data),
  changeFetchOption: (data: object, config: any) =>
    ApiService.postData(
      `${PROFILE_URLS.CHANGE_EMAILFETCHOPTION}`,
      data,
      config
    ),
};
export default ProfileApiServices;
