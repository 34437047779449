import { FormControl, MenuItem, Select } from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { FC } from 'react';
import { CUSTOM_SELECT_TEST_CONSTANT } from './SelectConst';

interface Options {
  label: string | number;
  value: string | number;
}

interface CustomSelectProps {
  placeholder?: string;
  options?: Options[];
  className?: string;
}
type Props = CustomSelectProps & SelectProps;

const CustomSelect: FC<Props> = (props) => {
  const { options, className, ...restProps } = props;

  return (
    <FormControl className={`select ${className}`} variant="filled">
      <Select
        displayEmpty
        inputProps={{
          'aria-label': 'Without label',
          'data-testid': CUSTOM_SELECT_TEST_CONSTANT.customSelect,
        }}
        renderValue={(selected: any) => {
          if (typeof selected === 'object' && selected?.length > 0) {
            return selected?.join(', ');
          }
          return selected;
        }}
        {...restProps}
      >
        {options?.length &&
          options?.map((option) => (
            <MenuItem key={option?.label} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
