import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { exportExcelIcon } from '../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../assets/images/action-items/exportPdfIcon';
import { desktopResetFilter } from '../../assets/images/desktopResetFilter';
import { filterIcon } from '../../assets/images/filter';
import { mobileResetFilter } from '../../assets/images/mobileResetFilter';
import Button from '../../components/button/Button';
import CustomTable from '../../components/customTable/CustomTable';
import FilterModal from '../../components/filterModal/FilterModal';
import Loader from '../../components/loader/Loader';
import CustomMenu from '../../components/menu/Menu';
import {
  priceFluctuationDropdown,
  UOMOptionsConstant,
} from '../../constants/productInventoryConstants';
import { resetFilterHelper } from '../../helpers/resetFilterHelper';
import { useQueryParams } from '../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../hooks/useUrlParamsUpdate';
import { resetfilterOnModuleChange } from '../../store/actions/listFiltersAction';
import {
  exportProductInventoryExcelsheet,
  exportProductInventoryPDF,
  getInventoryFilterProduct,
  getInventoryFilterSupplier,
  getProductInventoryListaction,
} from '../../store/actions/productInventoryActions';

const ProductInventory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page: paramPage, limit: paramLimit } = useQueryParams();
  const [openfilteModal, setOpenFilterModal] = useState(false);

  const {
    productInventoryList,
    filterSupplierDropdown,
    filterProductDropdown,
  } = useSelector(
    ({ productInventoryReducer }: Record<string, any>) =>
      productInventoryReducer ?? {}
  );

  const { productInventoryListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { productInventory } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { docs, headers, page, pages, total, limit } = useMemo(
    () => productInventoryList ?? {},
    [productInventoryList]
  );

  const exportToExcel = () => {
    dispatch(exportProductInventoryExcelsheet(filters));
  };

  const exportToPDF = () => {
    dispatch(exportProductInventoryPDF(filters));
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPDF,
    },
  ];

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: filterSupplierDropdown,
    },
    {
      name: 'productNames',
      label: 'Product',
      placeholder: 'Select Products',
      type: 'autocomplete',
      options: filterProductDropdown,
      isMulti: true,
      className: 'filter-field-gap',
    },
    {
      name: 'unit',
      label: 'UOM',
      placeholder: 'Select UOM',
      type: 'autocomplete',
      options: UOMOptionsConstant,
      className: 'filter-field-gap',
    },
    {
      name: 'priceFluctuation',
      label: 'Price Fluctuation',
      placeholder: 'Select Price Fluctuation',
      type: 'autocomplete',
      options: priceFluctuationDropdown,
      className: 'filter-field-gap',
    },
  ];

  const filters = {
    supplierId: productInventory?.supplierId?._id,
    productNames:
      productInventory?.productNames?.length &&
      productInventory.productNames
        ?.map((product: any) => product.name)
        .join(', '),
    unit: productInventory?.unit,
    priceFluctuation: productInventory?.priceFluctuation,
  };

  const onPageChange = (page: any) => {
    dispatch(getProductInventoryListaction({ page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(
      getProductInventoryListaction({ page: 1, limit: value, ...filters })
    );
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getProductInventoryListaction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'productInventory',
      () => setOpenFilterModal(false),
      [getInventoryFilterProduct, getInventoryFilterSupplier]
    );
  };

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: paramLimit ?? limit ?? 15,
    ...filters,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getInventoryFilterProduct());
    dispatch(getInventoryFilterSupplier());
    dispatch(getProductInventoryListaction(params));
    dispatch(resetfilterOnModuleChange('productInventory'));
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Product Inventory</div>
        <div className="button-container">
          {/* Mobile Buttons start here */}
          {!_.isEmpty(productInventory) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          {!_.isEmpty(productInventory) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal(true)}
            isFilterApplied={!_.isEmpty(productInventory)}
            filterData={productInventory}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="product-inventory-action"
          />
        </div>
      </div>
      {productInventoryListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={['name', 'weight']}
          data={docs}
          page={page}
          pages={pages}
          limit={limit}
          total={total}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          isAction
          isDeleteAction={false}
          isEditable={false}
          viewRecord={(product) =>
            history.push(`/product-inventory/view/${product._id}`)
          }
        />
      )}
      {openfilteModal && (
        <FilterModal
          requestFrom="productInventory"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getProductInventoryListaction}
          filterAction={[getInventoryFilterProduct, getInventoryFilterSupplier]}
        />
      )}
    </div>
  );
};

export default ProductInventory;
