import signUpProcessHelper from '../../helpers/signUpProcessHelper';
import { displayErrors } from './../../helpers/errorNotifyHelper';
import { registerApiServices } from './../../services/pageServices/registerApiServices';
import { REGISTER_REDUX_CONSTANTS } from './../reduxConstants/registerReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const getPlans = (params?: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getPlansLoaderAction');
      const response = await registerApiServices.getPlans(params);
      if (response?.status === 200) {
        dispatch({
          type: REGISTER_REDUX_CONSTANTS.PLAN.GET_PLANS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getPlansLoaderAction');
    }
  };
};

export const confirmPlanAction = (
  data: Record<string, string | boolean>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await registerApiServices.confirmPlan(data);
      if (response?.status === 200) {
        const route = signUpProcessHelper(response.data.data);
        history.push(route);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
