import { AUTH_URLS, HEADER_URLS } from '../../../constants/urlConstants';
import ApiService from '../apiService';

const AuthApiServices = {
  loginUser: (data: { email: string; password: string }) =>
    ApiService.postData(AUTH_URLS.LOGIN_URL, data),
  forgotPassword: (data: { email: string }) =>
    ApiService.postData(AUTH_URLS.FORGOT_PASSWORD_URL, data),
  resetPassword: (data: { token: string; password: string }) =>
    ApiService.postData(AUTH_URLS.RESET_PASSWORD_URL, data),
  setPassword: (data: { token: string; password: string }) =>
    ApiService.postData(AUTH_URLS.SET_PASSWORD_URL, data),
  logoutUser: () => ApiService.deleteData(HEADER_URLS.LOGOUT_URL),
  getQRCode: (params: Record<string, any>) =>
    ApiService.postData(AUTH_URLS.GET_QR_CODE, { ...params }),
  verifyOtp: (params: Record<string, any>) =>
    ApiService.putData(AUTH_URLS.VERIFY_OTP, { ...params }),
  resetTwoFactor: (data: { email: string }) =>
    ApiService.postData(AUTH_URLS.RESET_TWO_FACTOR, data),
};
export default AuthApiServices;
