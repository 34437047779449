import { successNotification } from '../../components/notifyHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import { saveAuthTokenToLocalStorage } from '../../helpers/localstoragehelper';
import signUpProcessHelper from '../../helpers/signUpProcessHelper';
import { upgradePlanRedirectionHelper } from '../../helpers/upgradePlanredirectionHelper';
import {
  ADMIN_ROUTE_CONST,
  ROUTE_CONSTANTS_VARIABLE,
} from '../../routes/RouteConstants';
import AuthApiServices from '../../services/apiService/authApiServices/AuthApiservices';
import { AUTH_REDUX_CONSTANTS } from '../reduxConstants/authReduxConstants';
import { PROFILE_REDUX_CONSTANTS } from '../reduxConstants/profileReduxConstant';
import { SET_PASSWORD_REDUX_CONSTANTS } from '../reduxConstants/setPasswordReduxConstant';

type SetPasswordProps = {
  token: string;
  password: string;
};

export const setPassWord = ({ token, password }: any, history: any) => {
  return async (dispatch: any) => {
    try {
      const data = { token, password };
      const response = await AuthApiServices.setPassword(data);
      if (response?.status === 200) {
        successNotification(response?.data?.message);
        dispatch({
          type: SET_PASSWORD_REDUX_CONSTANTS.SET_PASSWORD,
          data: response.data.data,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getQRCodeAction = (
  params: Record<string, number | string>,
  callBack: () => void
) => {
  return async (dispatch: any) => {
    try {
      const response = await AuthApiServices.getQRCode(params);
      if (response.status === 200) {
        dispatch({
          type: SET_PASSWORD_REDUX_CONSTANTS.GET_QR_CODE,
          data: response.data.data,
        });
      }
    } catch (e) {
      callBack();
    }
  };
};

export const verifyOptAction = (
  params: Record<string, number | string | boolean>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await AuthApiServices.verifyOtp(params);
      if (response?.status === 200) {
        const route = signUpProcessHelper(response.data.data);
        history.push(`${route}`);

        if (response.data.data.token) {
          saveAuthTokenToLocalStorage(response.data.data.token);
          successNotification(response?.data?.message ?? 'Login successfully.');
          dispatch({
            type: AUTH_REDUX_CONSTANTS.ADD_ON_DATA,
            data: { name: 'flow', value: response.data.data.flow },
          });
          dispatch({
            type: PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL,
            data: { ...response?.data.data },
          });
          dispatch({
            type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
            data: response.data.data.moduleAccess,
            status: true,
          });
          dispatch({
            type: AUTH_REDUX_CONSTANTS.PLAN_DETAILS_OF_USER,
            data: response.data.data.planDetails,
          });

          upgradePlanRedirectionHelper(response.data.data, history);
        }
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetTwoFactorAction = (
  data: { email: string },
  callBack: () => void
) => {
  return async () => {
    try {
      const response = await AuthApiServices.resetTwoFactor(data);
      if (response?.status === 200) {
        successNotification(response?.data?.message);
        callBack();
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
