export const UOMOptionsConstant = ['Kilogram', 'Litre'];

export const priceFluctuationDropdown = ['Up', 'Down', 'Both'];

export const productInventoryDetailsConstants = [
  {
    name: 'name',
    label: 'Product Name',
    type: 'string',
  },
  {
    name: 'weight',
    label: 'Weight',
    type: 'string',
  },
  {
    name: 'unit',
    label: 'UOM',
    type: 'string',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'string',
  },
  {
    name: 'priceFluctuation',
    label: 'Price Changes',
    type: 'priceChange',
  },
  {
    name: 'price',
    label: 'Price',
    type: 'amount',
  },
];
