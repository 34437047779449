import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import myjob from '../../../assets/images/myjob.png';
import Button from '../../../components/button/Button';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import {
  adminSoftwareIntegrationValidation,
  chooseOrganizationForAdminAction,
  connectSoftwareAdminAction,
  getCurrentAdminSoftwareaction,
  removeIntegrationAdminAction,
  resetInegrationAdminAction,
} from '../../../store/actions/admin/adminSoftwareIntegrationAction';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';

const SoftwareIntegration = () => {
  const dispatch = useDispatch<any>();
  const [selectedSoftware, setSelectedSoftware] = useState<string | undefined>(
    undefined
  );
  const [openModal, setOpenModal] = useState(false);
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<
    Record<string, string>
  >({});

  const { softwareDetails, accountDetail, organizations } = useSelector(
    ({ adminSoftwareIntegrationReducer }: Record<string, any>) =>
      adminSoftwareIntegrationReducer ?? {}
  );

  const {
    validateSoftwareIntegrationLoaderAction,
    chooseOrganizationAdminLoader,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const onConnect = (software: string) => {
    setSelectedSoftware(software);
    const data = {
      softwareName: software,
    };
    const cb = () => {
      setSelectedSoftware(undefined);
    };
    dispatch(connectSoftwareAdminAction(data, cb));
  };

  const onRemove = () => {
    setOpenModal(true);
  };

  const onRemoveSoftware = async () => {
    const success = await dispatch(removeIntegrationAdminAction());
    if (success) {
      setOpenModal(false);
      setSelectedSoftware(undefined);
      !organizations?.length && dispatch(getCurrentAdminSoftwareaction());
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const confirmationModalButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onRemoveSoftware,
      color: 'primary',
    },
  ];

  const onProceed = async () => {
    const data = {
      softwareName: selectedSoftware,
      accountingSoftwareId: accountDetail.accountingSoftwareId,
    };
    const reset = () => {
      setSelectedSoftware(undefined);
    };
    const success = await dispatch(
      adminSoftwareIntegrationValidation(data, reset)
    );
    if (success) {
      setOpenOrganizationModal(true);
      !organizations?.length && dispatch(getCurrentAdminSoftwareaction());
      selectedSoftware !== 'Myob' && setSelectedSoftware(undefined);
    }
  };

  const onClickApply = async () => {
    if (!chooseOrganizationAdminLoader) {
      const data = selectedOrganization && {
        ...selectedOrganization,
        softwareName: selectedSoftware,
        accountingSoftwareId: accountDetail?.accountingSoftwareId,
      };
      const success = await dispatch(chooseOrganizationForAdminAction(data));
      if (success) {
        setOpenOrganizationModal(false);
        setSelectedOrganization({});
        setSelectedSoftware(undefined);
        dispatch(getCurrentAdminSoftwareaction());
      }
    }
  };

  const organizationSelectionButton: ButtonType[] = [
    {
      title: 'Reset',
      onClick: () => {
        setSelectedOrganization({});
      },
      color: 'secondary',
    },
    {
      title: 'Apply',
      onClick: () => {
        onClickApply();
      },
      color: 'primary',
      loader: chooseOrganizationAdminLoader,
    },
  ];

  const onCancel = () => {
    setSelectedSoftware(undefined);
    const success = dispatch(resetInegrationAdminAction());
    if (success) {
      dispatch(getCurrentAdminSoftwareaction());
    }
  };

  useEffect(() => {
    dispatch(getCurrentAdminSoftwareaction());
    dispatch(resetfilterOnModuleChange(''));
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="page-title account-page-title">
          Software Integration
        </div>
      </div>
      <div className="main-details-container">
        <div className="view-details-white-container accounting-detail-block">
          <div className="software-image">
            <img src={myjob} alt="Foodygent" />
          </div>
          <div>
            <Button
              color="primary"
              variant={
                selectedSoftware === 'Myob' ||
                softwareDetails?.connectedAccountingSoftwareName === 'Myob'
                  ? 'outlined'
                  : 'contained'
              }
              onClick={() =>
                selectedSoftware === 'Myob' ||
                softwareDetails?.connectedAccountingSoftwareName === 'Myob'
                  ? onRemove()
                  : onConnect('Myob')
              }
            >
              {selectedSoftware === 'Myob' ||
              softwareDetails?.connectedAccountingSoftwareName === 'Myob'
                ? 'Remove'
                : 'Connect'}
            </Button>
          </div>
        </div>
      </div>
      <div className="form-button-row">
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Skip
        </Button>
        {selectedSoftware && (
          <Button
            variant="contained"
            loader={validateSoftwareIntegrationLoaderAction}
            color="primary"
            onClick={onProceed}
          >
            Save Changes
          </Button>
        )}
      </div>

      {openModal && (
        <CustomModal
          header="Remove Accounting Software"
          buttons={confirmationModalButtons}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to Remove Accounting software?
          </span>
        </CustomModal>
      )}

      {organizations?.length > 0 && openOrganizationModal && (
        <CustomModal
          header="Select Organization"
          className="confirmation-modal organization-modal"
          bodyClassName="credential-modal-body"
          buttons={organizationSelectionButton}
          hideModal={() => {
            setOpenOrganizationModal(false);
          }}
        >
          {organizations?.map((organization: Record<string, string>) => {
            return (
              <div className="organization-container">
                <span>{organization?.name}</span>
                <Button
                  color="primary"
                  variant={
                    selectedOrganization.id === organization?.id
                      ? 'outlined'
                      : 'contained'
                  }
                  onClick={() => {
                    setSelectedOrganization(organization);
                  }}
                >
                  {selectedOrganization.id === organization?.id
                    ? 'Remove'
                    : 'Connect'}
                </Button>
              </div>
            );
          })}
        </CustomModal>
      )}
    </div>
  );
};

export default SoftwareIntegration;
