import { UPLOAD_TERMS_AND_POLICY_URL } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';
import { AxiosRequestConfig } from 'axios';

const uploadTermsAndPolicyApiServices = {
  gstTermsList: () =>
    ApiService.getData(UPLOAD_TERMS_AND_POLICY_URL.GET_TERMS_LIST),
  gstPolicyList: () =>
    ApiService.getData(UPLOAD_TERMS_AND_POLICY_URL.GET_POLICT_LIST),
  uploadOrganizationTermsAndCondition: (
    data: any,
    config: AxiosRequestConfig<any> | undefined
  ) =>
    ApiService.postData(
      UPLOAD_TERMS_AND_POLICY_URL.UPLOAD_ORGANIZATION_TERMS_AND_CONDITION,
      data
    ),
  uploadOrganizationPrivacyPolicy: (
    data: any,
    config: AxiosRequestConfig<any> | undefined
  ) =>
    ApiService.postData(
      UPLOAD_TERMS_AND_POLICY_URL.UPLOAD_ORGANIZATION_PRIVACY_POLICY,
      data,
      config
    ),
  saveDocuments: (data: any) =>
    ApiService.putData(
      UPLOAD_TERMS_AND_POLICY_URL.SAVE_ORGANIZATION_DOCUMENTS,
      data
    ),
};
export default uploadTermsAndPolicyApiServices;
