import { PRODUCT_INVENTORY_URLS } from '../../constants/urlConstants';
import { PRODUCT_INVENTORY_REDUX_CONSTANTS } from '../../store/reduxConstants/productInventoryReduxConstants';
import ApiService from '../apiService/apiService';

const productInventoryApiServices = {
  productInventoryList: (params: Record<string, any>) =>
    ApiService.getData(PRODUCT_INVENTORY_URLS.GET_PRODUCT_INVENROTY_LIST, {
      params,
    }),
  filterSupplierList: () =>
    ApiService.getData(
      PRODUCT_INVENTORY_URLS.GET_INVENTORY_FILTER_SUPPLIER_LIST
    ),
  filterProductList: () =>
    ApiService.getData(PRODUCT_INVENTORY_URLS.GET_INVENTORY_PRODUCT_LIST),
  getProductInventoryDetailsById: (productId: string) =>
    ApiService.getData(
      `${PRODUCT_INVENTORY_URLS.GET_PRODUCT_INVENTORY_DETAIL_BY_ID}${productId}`
    ),
  productInventoryExportToPdf: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: PRODUCT_INVENTORY_URLS.PRODUCT_INVENTORY_EXPORT_TO_PDF,
      responseType: 'blob',
      params,
    }),
  productInventoryExportToExcel: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: PRODUCT_INVENTORY_URLS.PRODUCT_INVENTORY_EXPORT_TO_EXCEL,
      responseType: 'blob',
      params,
    }),
};

export default productInventoryApiServices;
