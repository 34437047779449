import { REPORTS_REDUX_CONSTANT } from '../../reduxConstants/reportsReduxConstant';

interface reportsProps {
  supplierDropDown: Record<string, any> | null;
  categoryList: Record<string, any> | null;
  supplierPurchaseChart: Record<string, any> | null;
  supplierSalesChart: Record<string, any> | null;
  purchaseFoodAndBevarages: Record<string, any> | null;
  salesFoodAndBeverages: Record<string, any> | null;
  supplierCostSaleChart: Record<string, any> | null;
  supplierCostSaleDetails: Record<string, any> | null;
}

const initialReportReducer: reportsProps = {
  supplierDropDown: null,
  categoryList: null,
  supplierPurchaseChart: null,
  supplierSalesChart: null,
  purchaseFoodAndBevarages: null,
  salesFoodAndBeverages: null,
  supplierCostSaleChart: null,
  supplierCostSaleDetails: null,
};

export const reportReducer = (
  state = initialReportReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action?.type) {
    case REPORTS_REDUX_CONSTANT.GET_SUPPLIER_LIST_DROPDOWN:
      return {
        ...state,
        supplierDropDown: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_CATEGORY_DROPDOWN:
      return {
        ...state,
        categoryList: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_SUPPLIER_PURCHASE_CHART:
      return {
        ...state,
        supplierPurchaseChart: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_PURCHASE_FOOD_AND_BEVERAGES_DETAIL:
      return {
        ...state,
        purchaseFoodAndBevarages: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_SUPPLIER_SALES_CHART:
      return {
        ...state,
        supplierSalesChart: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_SALES_FOOD_AND_BEVERAGES_DETAIL:
      return {
        ...state,
        salesFoodAndBeverages: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_SUPPLIER_COST_SALE_CHART:
      return {
        ...state,
        supplierCostSaleChart: action.data,
      };
    case REPORTS_REDUX_CONSTANT.GET_COST_SALE_DETAILS:
      return {
        ...state,
        supplierCostSaleDetails: action.data,
      };
    default:
      return state;
  }
};
