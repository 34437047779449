import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {
  clearAuthTokenFromLocalStorage,
  clearFCMTokenFromLocalStorage,
} from '../../helpers/localstoragehelper';
import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { registerReducer } from '../reducers/registerReducer';
import { profileReducer } from '../reducers/profileReducer';
import { authReducer } from '../reducers/authReducer';
import sessionStorage from 'redux-persist/lib/storage/session';
import { planReducer } from '../reducers/planReducer';
import { accountSettingsUsersReducer } from '../reducers/accountSettingsReducer/accountSettingsUsersReducer';
import { supplierReducer } from '../reducers/supplierReducer/supplierReducer';
import { listFilterReducer } from '../reducers/listFiltersReducer';
import { generalLoaderReducer } from '../reducers/generalLoaderReducer';
import { commonReducer } from '../reducers/commonReducer';
import { productReducer } from '../reducers/supplierReducer/productReducer';
import { orderReducer } from '../reducers/orderReducer';
import { recipeReducer } from '../reducers/recipeReducer';
import { subscribersReducer } from '../reducers/admin/subscribersReducer';
import { salesReducer } from '../reducers/salesReducer';
import { gstReducer } from '../reducers/admin/gstReducer';
import { billingOverviewReducer } from '../reducers/accountSettingsReducer/billingOverviewReducer';
import { invoiceReducer } from '../reducers/invoiceReducer/invoiceReducer';
import { statementReducer } from '../reducers/invoiceReducer/statementReducer';
import { supportReducer } from '../reducers/supportReducer';
import { uploadTermsAndPolicyReducer } from '../reducers/admin/uploadTermsAndPolicyReducer';
import { setPasswordReducer } from '../reducers/setPasswordReducer';
import { adminPlanReducer } from '../reducers/admin/adminPlanReducer';
import { creditNotesReducer } from '../reducers/invoiceReducer/creditNotesReducer';
import { cardDetailReducer } from '../reducers/cardDetailReducer';
import { paymentReducer } from '../reducers/paymentReducer';
import { expenseReducer } from '../reducers/invoiceReducer/expenseReducer';
import { accountingSoftwareReducer } from '../reducers/accountingSoftwareReducer';
import { billingHistoryReducer } from '../reducers/accountSettingsReducer/billingHistoryReducer';
import { softwareIntegrationReducer } from '../reducers/accountSettingsReducer/softwareIntegrationReducer';
import { reconcileReducer } from '../reducers/invoiceReducer/reconcileReducer';
import { reconciledStatementsReducer } from '../reducers/reportReducer/reconciledStatementsReducer';
import { productInventoryReducer } from '../reducers/productInventoryReducer';
import { notificationReducer } from '../reducers/notificationReducer';
import { createFilter } from 'redux-persist-transform-filter';
import { reportReducer } from '../reducers/reportReducer/reportReducer';
import { dashboardReducer } from '../reducers/dashboardReducer';
import { adminSoftwareIntegrationReducer } from '../reducers/admin/adminSoftwareIntegrationReducer';

const persitingReducers = createFilter('billingOverviewReducer', [
  `planUpgradeDetails`,
]);

const persitingProfileReducer = createFilter('profileReducer', [
  `profileDetail`,
  `profileMenuDetail`,
]);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authReducer', 'billingOverviewReducer', 'profileReducer'],
  transforms: [persitingReducers, persitingProfileReducer],
};

const filterPersistConfig = {
  key: 'allFilters',
  storage: sessionStorage,
};

const appReducer = combineReducers({
  generalLoaderReducer,
  registerReducer,
  authReducer,
  profileReducer,
  planReducer,
  accountSettingsUsersReducer,
  supplierReducer,
  commonReducer,
  productReducer,
  orderReducer,
  recipeReducer,
  subscribersReducer,
  salesReducer,
  gstReducer,
  setPasswordReducer,
  invoiceReducer,
  billingOverviewReducer,
  supportReducer,
  statementReducer,
  adminPlanReducer,
  uploadTermsAndPolicyReducer,
  creditNotesReducer,
  cardDetailReducer,
  paymentReducer,
  expenseReducer,
  accountingSoftwareReducer,
  billingHistoryReducer,
  softwareIntegrationReducer,
  reconcileReducer,
  reconciledStatementsReducer,
  productInventoryReducer,
  notificationReducer,
  reportReducer,
  dashboardReducer,
  adminSoftwareIntegrationReducer,
  listFilterReducer: persistReducer(filterPersistConfig, listFilterReducer),
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION) {
    clearAuthTokenFromLocalStorage();
    clearFCMTokenFromLocalStorage();

    sessionStorage.removeItem('persist:allFilters');
    localStorage.clear();

    return appReducer(state, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
