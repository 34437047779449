import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button/Button';
import { registerConstants } from '../../../constants/registerConstants';
import { handleRegisterValidation } from './registerValidations';

interface RegisterButtonProps {
  currentStep: number;
  setCurrentStep: () => void;
  data: Record<string, string | number>;
}
export const onRegisterButtonClick = async (
  props: RegisterButtonProps,
  dispatch: any,
  history: any
) => {
  const { currentStep, setCurrentStep, data } = props;

  let stepData = {};

  registerConstants[currentStep].fields.forEach((field) => {
    if (data.hasOwnProperty(field.name)) {
      stepData = {
        ...stepData,
        [field.name]: data[field.name],
      };
      window.scrollTo(0, 0);
    }

    if (currentStep === 0) {
      stepData = {
        ...stepData,
        [field.name]: data[field.name],
        isReadPolicyAndTerms: data.isReadPolicyAndTerms,
      };
      window.scrollTo(0, 0);
    }
  });

  switch (currentStep) {
    case 2:
      handleRegisterValidation(
        stepData,
        currentStep,
        dispatch,
        data,
        history //remove
      );
      break;

    default: {
      const checkValidation = await handleRegisterValidation(
        stepData,
        currentStep,
        dispatch,
        data,
        history //remove
      );

      if (checkValidation) {
        setCurrentStep();
      }
    }
  }
};

const RegisterButton: FC<RegisterButtonProps> = (props) => {
  const { currentStep } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Button
      className="register-button"
      variant="contained"
      color="primary"
      onClick={() => {
        onRegisterButtonClick(props, dispatch, history);
      }}
    >
      {registerConstants[currentStep]?.buttonText}
    </Button>
  );
};

export default RegisterButton;
