import { ADMIN_PLAN_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

const adminPlansApiServices = {
  planList: (params: Record<string, any>) =>
    ApiService.getData(ADMIN_PLAN_URLS.GET_PLANS_LIST, { params }),
  addPlan: (data: Record<string, any>) =>
    ApiService.postData(ADMIN_PLAN_URLS.ADD_PLAN, data),
  deletePlan: (params: any) =>
    ApiService.deleteData(`${ADMIN_PLAN_URLS.DELETE_PLAN}`, { params }),
  getPlanDetailById: (planId: string) =>
    ApiService.getData(`${ADMIN_PLAN_URLS.VIEW_PLAN}${planId}`),
  planFacilitiesList: () =>
    ApiService.getData(`${ADMIN_PLAN_URLS.GET_PLANS_FACILITIES_LIST}`),
  updatePlanDetailsById: (
    data: Record<string, string | number | undefined | null>,
    planId: string
  ) => ApiService.putData(`${ADMIN_PLAN_URLS.EDIT_PLAN}${planId}`, data),
};

export default adminPlansApiServices;
