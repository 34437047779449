import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../components/button/Button';
import { errorNotification } from '../../../components/notifyHelper';
import {
  uploadOrganizationPrivacyPolicy,
  uploadOrganizationTermsAndCondition,
} from '../../../store/actions/admin/uploadTermsAndPolicyAction';

const UploadFile = (props: { id: string }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const BrowseButtonClick = () => {
    hiddenFileInput?.current?.click();
  };
  const { id } = props;

  const uploadFileChange = async (event: any) => {
    event.persist();
    if (event.target.files) {
      const fileExtension = ['pdf'];
      const mimeType = ['application/pdf'];
      const checkExtension =
        fileExtension.indexOf(
          event.target.files[0].name.split('.').splice(-1)[0]
        ) !== -1;
      const checkMimeTypes =
        mimeType.indexOf(event.target.files[0].type) !== -1;
      const checkFileSize = event.target.files[0].size > 6291456;
      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('Only PDF file allowed');
      } else if (checkFileSize) {
        errorNotification('File size should be upto 6 MB');
      } else {
        if (event?.target?.files?.[0]) {
          if (id === 'uploadTermsAndCondition') {
            const formData = new FormData();
            formData.append('terms-and-conditions', event.target.files[0]);
            const config: Record<string, Record<string, string>> = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            await dispatch(
              uploadOrganizationTermsAndCondition(formData, config)
            );
          } else if (id === 'uploadPrivacyPolicy') {
            const formData = new FormData();
            formData.append('privacy-and-policy', event.target.files[0]);
            const config: Record<string, Record<string, string>> = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            await dispatch(uploadOrganizationPrivacyPolicy(formData, config));
          }
        }
      }
    }
  };
  return (
    <div className="file-upload-container">
      <input
        type="file"
        className="input-file-type"
        ref={hiddenFileInput}
        onChange={uploadFileChange}
      />
      <Button
        color="primary"
        variant="outlined"
        component="span"
        className="browse-file-button outlined-primary-button"
        onClick={BrowseButtonClick}
      >
        Upload File
      </Button>
    </div>
  );
};
export default UploadFile;
