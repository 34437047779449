import { Dispatch } from '@reduxjs/toolkit';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import adminSoftwareIntegrationApiServices from '../../../services/pageServices/admin/adminSoftwareIntegration';
import { ADMIN_SOFTWARE_INTEGRATION_REDUX_CONSTANTS } from '../../reduxConstants/admin/adminSoftwareIntegrationReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getCurrentAdminSoftwareaction = () => {
  return async (dispatch: any) => {
    try {
      const response =
        await adminSoftwareIntegrationApiServices.getCurrentsoftware();
      if (response.status === 200) {
        dispatch({
          type: ADMIN_SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_CURRENT_SOFTWARE,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const connectSoftwareAdminAction = (
  data: Record<string, string | boolean>,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response =
        await adminSoftwareIntegrationApiServices.integrateSoftware(data);
      if (response.status === 200) {
        dispatch({
          type: ADMIN_SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ACCOUNT_DETAILS,
          data: response.data.data,
        });
        if (response.data?.data?.consentURL) {
          window.open(
            response.data.data.consentURL,
            '_blank',
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
          );
        }
        return true;
      }
    } catch (e) {
      cb();
      displayErrors(e);
    }
  };
};

export const adminSoftwareIntegrationValidation = (
  data: Record<string, any>,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('validateSoftwareIntegrationLoaderAction');
      const response =
        await adminSoftwareIntegrationApiServices.validateSoftwareIntegration(
          data
        );
      if (response.status === 200) {
        if (response.data.data) {
          dispatch({
            type: ADMIN_SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ORGANIZATION_DETAIL,
            data: response.data.data,
          });
        } else {
          successNotification(response?.data?.message);
        }
        return true;
      }
    } catch (e) {
      if (cb) {
        cb();
      }
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'validateSoftwareIntegrationLoaderAction'
      );
    }
  };
};

export const chooseOrganizationForAdminAction = (data: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('chooseOrganizationAdminLoader');
      const response =
        await adminSoftwareIntegrationApiServices.chooseOrganization(data);
      if (response.status === 200) {
        successNotification(response?.data?.message);
        dispatch({
          type: ADMIN_SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ORGANIZATION_DETAIL,
          data: {},
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('chooseOrganizationAdminLoader');
    }
  };
};

export const removeIntegrationAdminAction = () => {
  return async () => {
    try {
      const response =
        await adminSoftwareIntegrationApiServices.removeIntegration();
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetInegrationAdminAction = () => {
  return async () => {
    try {
      const response =
        await adminSoftwareIntegrationApiServices.resetIntegration();
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
