export const COMMON_REDUX_CONSTANTS = {
  GET_USER_ROLE: 'GET_USER_ROLE',
  SUPPLIER_DROPDOWN_DATA: 'SUPPLIER_DROPDOWN_DATA',

  UNIT: {
    GET_UNIT_DROPDOWN_DATA: 'GET_UNIT_DROPDOWN_DATA',
    ON_CHANGE_UNIT: 'ON_CHANGE_UNIT',
  },

  CATEGORY: {
    GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
    ON_CHANGE_CATEGORY_LIST: 'ON_CHANGE_CATEGORY_LIST',
    CATEGORY_SUBCATEGORY_DROPDOWN: 'CATEGORY_SUBCATEGORY_DROPDOWN',
  },

  PRODUCT_DROPDOWN_DATA: 'PRODUCT_DROPDOWN_DATA',
  PRODUCT_DROPDOWN_LIST: 'PRODUCT_DROPDOWN_LIST',

  GET_CURRENCY_RATE: 'GET_CURRENCY_RATE',
};
