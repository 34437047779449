import { SET_PASSWORD_REDUX_CONSTANTS } from '../reduxConstants/setPasswordReduxConstant';

const initialState = {
  userDetail: {},
  authDetail: {},
};

export const setPasswordReducer = (
  state = initialState,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case SET_PASSWORD_REDUX_CONSTANTS.SET_PASSWORD:
      return { ...state, userDetail: action.data };

    case SET_PASSWORD_REDUX_CONSTANTS.GET_QR_CODE:
      return { ...state, authDetail: action.data };

    case SET_PASSWORD_REDUX_CONSTANTS.RESET_USER_DETAIL:
      return { ...initialState };

    default:
      return { ...state };
  }
};
