export const REGISTER_REDUX_CONSTANTS: Record<string, any> = {
  ON_CHANGE_VALUE: 'ON_CHANGE_VALUE',
  RESET_REGISTER_DATA: 'RESET_REGISTER_DATA',
  GET_COMPANY_DETAILS_BY_NAME: 'GET_COMPANY_DETAILS_BY_NAME',
  GET_COMPANY_DETAILS_BY_ABN: 'GET_COMPANY_DETAILS_BY_ABN',
  UPDATE_DETAILS_BY_SEARCH: 'UPDATE_DETAILS_BY_SEARCH',
  RESET_COMPANY_DETAILS: 'RESET_COMPANY_DETAILS',
  PLAN: {
    GET_PLANS: 'GET_PLANS',
  },
};
