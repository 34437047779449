import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { color_palette } from './constants/colorPalette';
import './index.scss';
import reportWebVitals from './reportWebVitals';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#F21D86',
      contrastText: '#fff',
    },
    secondary: {
      main: '#979797',
      contrastText: '#fff',
    },
    success: {
      main: '#4CD964', // custom button color (seafoam green)
      contrastText: '#fff', // custom button text (white)
    },
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: color_palette.primary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          marginRight: 0,
          padding: '10px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: '7px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#ff516c',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '6px',
          borderRadius: '100px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: color_palette.white,
          borderRadius: '4px',
          boxShadow: '0 0 4px rgba(0, 0, 0, 0.16)',
          '&:after': {
            content: '""',
            display: 'table',
            clear: 'both',
          },
        },
        input: {
          height: '1.358em',
          padding: '10.5px 14px',
          '&::placeholder': {
            color: color_palette.placeholder,
            opacity: 1,
          },
        },
        underline: {
          '&::before': {
            content: '""',
            borderBottom: 'none',
          },
          '&:hover': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          padding: '0 12px',
          fontSize: '1.125rem',
          fontWeight: 400,
          color: color_palette.drakGrey,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          bottom: '6px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: color_palette.white,
          borderRadius: '4px',
          boxShadow: '0 0 4px rgba(0, 0, 0, 0.16)',
          border: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '10px 14px',
          '& .notchedOutline': {
            borderColor: 'blue',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 500,
            transition: 'none',
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
