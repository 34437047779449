import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import Input from '../../../../components/input/Input';
import { useState } from 'react';
import { ALPHA_REGEX } from '../../../../constants/regexConstants';
import { useDispatch, useSelector } from 'react-redux';
import { addCardActionInBilling } from '../../../../store/actions/accountSettingsActions/billingOverviewActions';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../../../../store/actions/generalLoaderAction';

const AddNewCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const stripe = useStripe();
  const elements: any = useElements();
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const { addNewCardLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (
      !value?.toString()?.trim()?.length ||
      value === undefined ||
      value === null ||
      !ALPHA_REGEX.test(value)
    ) {
      setErrors((prev) => ({ ...prev, name: 'Please enter valid full name' }));
      setName(value);
    } else {
      setErrors((prev) => ({ ...prev, name: undefined }));
      setName(value);
    }
  };

  const onClickAddCard = async () => {
    startGeneralLoaderOnRequest('addNewCardLoaderAction');
    if (
      !name?.toString()?.trim()?.length ||
      name === undefined ||
      name === null ||
      !ALPHA_REGEX.test(name)
    ) {
      setErrors((prev) => ({ ...prev, name: 'Please enter valid full name' }));
    } else {
      setErrors((prev) => ({ ...prev, name: undefined }));
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const res = await stripe?.createToken(cardNumberElement);

    if (res?.token) {
      const card = res?.token?.card;
      const finalData = {
        cardType: card?.brand,
        cardName: name,
        cardNumber: card?.last4,
        expiryDate: {
          month: card?.exp_month.toString(),
          year: card?.exp_year.toString(),
        },
        stripeToken: res?.token?.id,
        isSelected: false,
      };

      dispatch(addCardActionInBilling(finalData, () => history.goBack()));
    } else if (res?.error) {
      stopGeneralLoaderOnSuccessOrFail('addNewCardLoaderAction');
    }
  };
  const expirationError = "Your card's expiration year is in the past.";
  const expirationAbove = "Your card's expiration year is invalid.";

  const onInputChange = (
    name: string,
    error: Record<string, string> | undefined
  ) => {
    if (error) {
      let message = error.message;
      if (name === 'cardExpiry') {
        if (message === expirationError)
          message = 'Card expiration in the past';
        else if (message === expirationAbove)
          message = "Your card's expiration year is invalid.";
      }
      setErrors((prev) => ({
        ...prev,
        [name]: message,
      }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
        <span className="form-title">Add New Card</span>
      </div>
      <div className="form-container">
        <InputLabel className="form-label">Name On Card</InputLabel>
        <FormControl className="name-input">
          <Input
            variant="filled"
            placeholder="Enter Card Name"
            className={`input-field ${errors?.name ? 'input-with-error' : ''}`}
            name="weight"
            value={name}
            type="text"
            helperText={errors?.name}
            onChange={onChangeInput}
          />
        </FormControl>
        <InputLabel className="form-label">Credit Card Number</InputLabel>
        <div className="element-container">
          <CardNumberElement
            onChange={({ error }) => onInputChange('cardNumber', error)}
            options={{
              placeholder: '.... .... .... ....',
              style: {
                base: {
                  color: '#666',
                },
              },
            }}
          />
          {
            <FormHelperText className="input-with-error">
              {errors?.cardNumber}
            </FormHelperText>
          }
        </div>
        <InputLabel className="form-label">Expiry Date (MM/YY)</InputLabel>
        <div className="element-container">
          <CardExpiryElement
            onChange={({ error }) => onInputChange('cardExpiry', error)}
            options={{
              placeholder: 'Enter MM / YY',
              style: {
                base: {
                  color: '#666',
                },
              },
            }}
          />
          {
            <FormHelperText className="input-with-error">
              {errors?.cardExpiry}
            </FormHelperText>
          }
        </div>
        <InputLabel className="form-label">Security Code</InputLabel>
        <div className="element-container">
          <CardCvcElement
            onChange={({ error }) => onInputChange('cardCvv', error)}
            options={{
              placeholder: 'Enter CVV',
              style: {
                base: {
                  color: '#666',
                },
              },
            }}
          />
          {
            <FormHelperText className="input-with-error">
              {errors?.cardCvv}
            </FormHelperText>
          }
        </div>

        <div className="form-button-row">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            loader={addNewCardLoaderAction}
            onClick={onClickAddCard}
          >
            Add Card
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNewCard;
