import { useHistory, useParams } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import companyLogo from '../../../assets/images/company-logo.svg';
import Button from '../../../components/button/Button';
import { Radio } from '@mui/material';
import { color_palette } from '../../../constants/colorPalette';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCardListAction,
  selectCardAction,
} from '../../../store/actions/cardDetailActions';
import { Add } from '@mui/icons-material';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import { cardTypeIconHelper } from '../../../helpers/cardTypeIconHelper';

type cardListType = {
  cardName: string;
  cardNumber: string;
  cardType: string;
  expiryDate: { month: string; year: string };
  isDefault: boolean;
  _id: string;
};
const CardDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedCard, setSelectedCard] = useState('');
  const { userId } = useParams<Record<string, string>>();

  const { cardList } = useSelector(
    ({ cardDetailReducer }: Record<string, any>) => cardDetailReducer ?? {}
  );
  const { selectCardButtonLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onProceed = async () => {
    if (selectedCard === '') {
      const defaultCard = await cardList?.cardDetails?.filter(
        (card: any) => card.isDefault
      );
      const params = { userId, cardId: defaultCard[0]._id };
      await dispatch(selectCardAction(params, history));
    } else {
      const params = { userId, cardId: selectedCard };
      dispatch(selectCardAction(params, history));
    }
  };

  useEffect(() => {
    const params = { userId };
    dispatch(getCardListAction(params));
  }, [userId]);

  return (
    <div className="plans-container">
      <div className="auth-company-logo">
        <img src={companyLogo} alt="companyLogo" />
      </div>
      <div className="card-screen-container">
        <div className="card-detail-header">
          <BackButton onClick={() => history.push(`/plans/${userId}`)} />
          <div>Card Details</div>
          <Button
            className="desktop-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(`/card-details/${userId}/add-card/`)}
          >
            Add Card
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/card-details/${userId}/add-card/`)}
            >
              <Add />
            </Button>
          </div>
          {/* mobile button end here */}
        </div>
        {cardList?.cardDetails?.length ? (
          <>
            <div className="payment-row-container">
              {cardList?.cardDetails?.map((card: cardListType, i: number) => {
                return (
                  <div className="card-detail-row" key={i}>
                    <div key={card._id}>
                      <Radio
                        checked={
                          selectedCard !== ''
                            ? selectedCard === card._id
                            : card.isDefault
                        }
                        onChange={() => setSelectedCard(card._id)}
                        value="value"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                        sx={{
                          color: color_palette.primary,
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                          '&.Mui-checked': {
                            color: color_palette.primary,
                          },
                        }}
                      />
                      {cardTypeIconHelper(card?.cardType)}
                    </div>
                    <span>{card.cardName}</span>
                    <span>{`**** **** **** ${card.cardNumber}`}</span>
                    <span>{`Validity : ${card.expiryDate.month}/${card.expiryDate.year}`}</span>
                  </div>
                );
              })}
            </div>

            <div className="form-button-row">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(`/plans/${userId}`);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={selectCardButtonLoaderAction}
                onClick={() => onProceed()}
              >
                Proceed
              </Button>
            </div>
          </>
        ) : (
          <div className="no-record-text">
            No card found, Please add card by clicking add card button.
          </div>
        )}
      </div>
    </div>
  );
};
export default CardDetail;
