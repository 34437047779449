export const addProductIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2 10.05L9.21947 14.4464L11.095 11.9434L3.49312 7.67407L2 10.05Z"
      fill="#666666"
    />
    <path
      d="M12.8848 11.934L14.7673 14.4464L22 10.0419L20.4866 7.50067L12.8848 11.934Z"
      fill="#666666"
    />
    <path
      d="M4.58936 6.88073L11.9888 11.0363L19.3988 6.71482L12.0049 3L4.58936 6.88073Z"
      fill="#666666"
    />
    <path
      d="M3.88525 16.7751L11.4081 21V13.5239L9.82208 15.6406C9.64208 15.8808 9.32434 15.9455 9.07366 15.7928L3.88525 12.6333V16.7751Z"
      fill="#666666"
    />
    <path
      d="M12.5781 13.5239V20.9807L20.101 16.5873V12.6333L14.9126 15.7928C14.662 15.9454 14.3442 15.8808 14.1642 15.6406L12.5781 13.5239Z"
      fill="#666666"
    />
  </svg>
);
