import { Dispatch } from 'react';
import { successNotification } from '../../components/notifyHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import { accountingSoftwareApiServices } from '../../services/pageServices/accountingSoftwareApiServices';
import { ACCOUNTING_SOFTWARE_REDUX_CONSTANTS } from '../reduxConstants/accountingSoftwareReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const softwareintegration = (
  data: Record<string, string | boolean>,
  history: any
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await accountingSoftwareApiServices.integrateSoftware(
        data
      );
      if (response.status === 200) {
        dispatch({
          type: ACCOUNTING_SOFTWARE_REDUX_CONSTANTS.ACCOUNTING_SOFTWARE_DATA,
          data: response.data.data,
        });
        if (response.data?.data?.consentURL) {
          window.open(
            response.data.data.consentURL,
            '_blank',
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
          );
        } else {
          history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const validateSoftwareIntegration = (
  params: Record<string, string | boolean>,
  history: any,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    startGeneralLoaderOnRequest('getAccountingSoftwareValidationLoaderAction');
    try {
      const response = await accountingSoftwareApiServices.validateIntegration(
        params
      );
      if (response.status === 200) {
        if (response.data.data) {
          dispatch({
            type: ACCOUNTING_SOFTWARE_REDUX_CONSTANTS.GET_ORGANIZATION_LIST,
            data: response.data.data,
          });
          return true;
        } else {
          successNotification(response?.data?.message);
          history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
        }
      }
    } catch (e) {
      if (cb) {
        cb();
      }
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getAccountingSoftwareValidationLoaderAction'
      );
    }
  };
};

export const chooseOrganizationForAccountingSoftware = (
  data: Record<string, string | boolean>,
  history: any
) => {
  return async (dispatch: Dispatch<any>) => {
    startGeneralLoaderOnRequest(
      'chooseOrganizationForAccountingSoftwareLoaderAction'
    );
    try {
      const response = await accountingSoftwareApiServices.chooseOrganization(
        data
      );
      if (response.status === 200) {
        successNotification(response?.data?.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'chooseOrganizationForAccountingSoftwareLoaderAction'
      );
    }
  };
};

export const skipAccountingSoftwareIntegration = (
  userId: string,
  history: any
) => {
  return async () => {
    try {
      const response =
        await accountingSoftwareApiServices.skipAccountingSoftwareIntegration({
          userId,
        });
      if (response.status === 200) {
        history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const removeAccountingSoftwareAction = (userId: string) => {
  return async () => {
    try {
      const response =
        await accountingSoftwareApiServices.removeAccountingSoftware({
          userId,
        });
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
