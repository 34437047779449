import { RECONCILE_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/reconcileReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';
import { RECONCILED_STATEMENTS_REDUX_CONSTANTS } from '../../reduxConstants/reportReduxConstants/reconciledStatementsreduxConstants';

interface reconciledStatementsProps {
  reconciledStatementsList: Record<string, any>;
  supplierList: Record<string, any> | null;
}

const initialReconciledStatementsReducer: reconciledStatementsProps = {
  reconciledStatementsList: {},
  supplierList: null,
};

export const reconciledStatementsReducer = (
  state = initialReconciledStatementsReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action.type) {
    case RECONCILED_STATEMENTS_REDUX_CONSTANTS.GET_RECONCILED_STATEMENTS_LIST:
      return { ...state, reconciledStatementsList: action.data };

    case RECONCILED_STATEMENTS_REDUX_CONSTANTS.GET_SUPPLIER_LIST_DROPDOWN:
      return { ...state, supplierList: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialReconciledStatementsReducer,
      };

    default:
      return state;
  }
};
