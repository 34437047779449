import ApiService from '../apiService/apiService';
import { AUTH_URLS } from './../../constants/urlConstants';

export const registerApiServices = {
  onRegister: (data: Record<string, any> | undefined) =>
    ApiService.postData(AUTH_URLS.ON_REGISTER_URL, data),
  getPlans: (params?: Record<string, string>) =>
    ApiService.getData(AUTH_URLS.PLAN.GET_PLAN_URL, { params }),
  getDetailbyName: (params: Record<string, any>) =>
    ApiService.getData(AUTH_URLS.GET_DETAIL_BY_NAME, { params }),
  getDetailbyAbn: (params: Record<string, any>) =>
    ApiService.getData(AUTH_URLS.GET_DETAIL_BY_ABN, { params }),
  confirmPlan: (data: Record<string, string | boolean>) =>
    ApiService.putData(AUTH_URLS.PLAN.CONFIRM_PLAN, { ...data }),
};
