import dayjs from 'dayjs';
import {
  FLOAT_NUMBER_REGEX,
  NUMBER_REGEX,
} from '../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  addSaleAction,
  onChangeSaleData,
  updateSelectedSaleDetails,
} from '../../../store/actions/salesAction';

export const addSaleValidations = async (
  dispatch: any,
  sale: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
) => {
  let validated = true;
  let errors: Record<string, string> = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    date: 'Please select date',
    covers: 'Please enter covers',
    foodSales: 'Please enter food sales',
    beveragesSales: 'Please enter beverages sales',
  };
  const checkValidations = (field: string, value: string) => {
    switch (field) {
      case 'date':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = errorMessages[field];
          }
        } else {
          delete errors[field];
        }
        break;
      case 'covers':
        if (!NUMBER_REGEX.test(value)) {
          validated = false;
          if (errors) {
            errors[field] = errorMessages[field];
          }
        } else {
          delete errors[field];
        }
        break;
      case 'errors':
      case '_id':
        break;
      default:
        if (!FLOAT_NUMBER_REGEX.test(value)) {
          validated = false;
          if (errors) {
            errors[field] = errorMessages[field];
          }
        } else {
          delete errors[field];
        }
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in sale) {
        const value = sale[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, sale[fieldName]);
    }
    {
      errors && dispatch(onChangeSaleData('errors', errors));
    }
  }
  if (validated && requestFrom === 'onSubmit') {
    delete sale?.errors;
    const finalData = sale;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case '_id':
            break;

          case 'date':
            const date = finalData[key];
            finalData[key] = dayjs(date).format('YYYY-MM-DD');
            break;

          case 'covers':
            finalData[key] = parseInt(finalData[key]);
            break;

          default:
            finalData[key] = parseFloat(finalData[key]);
            break;
        }
      }
    }

    if (action === 'add') {
      const isAddedSuccessFully = await dispatch(addSaleAction(finalData));

      if (isAddedSuccessFully) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const updateUrl = () => history.push(ROUTE_CONSTANTS_VARIABLE.SALE);
      dispatch(updateSelectedSaleDetails(finalData, sale._id, updateUrl));
    }
  }
};
