import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import { useHistory } from 'react-router-dom';
import paymentFailed from '../../../../assets/images/payment-failed.png';

const PaymentFailed = () => {
  const history = useHistory();
  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container plan-payment-failed">
          <div className="form-title">Payment Failed</div>
        </div>
      </div>
      <div className="payment-details">
        <div className="payment-failed view-photos-container">
          <div className="payment-failed-image">
            <img src={paymentFailed} alt="Payment Failed" />
          </div>
          <div className="payment-failed-text plan-payment-failed-text">
            Your transaction has failed due to some technical error. Please try
            again.
          </div>
        </div>
        <div className="plan-failed-button-contain">
          <Button
            className="auth-button plan-confirm-button plan-auth-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.CARD_LIST)}
          >
            Try Again
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PaymentFailed;
