import { UserType } from '../../../store/reducers/accountSettingsReducer/accountSettingsUsersReducer';
import ApiService from '../../apiService/apiService';
import { ACCOUNT_SETTINGS_URLS } from './../../../constants/urlConstants';

type UserIdsType = {
  userIds: string;
};
const AccountSettingsUsersApiServices = {
  usersList: (params: Record<string, any>) =>
    ApiService.getData(ACCOUNT_SETTINGS_URLS.USERS_URLS.GET_USERS_LIST, {
      params,
    }),
  addUser: (data: Record<string, any>) =>
    ApiService.postData(ACCOUNT_SETTINGS_URLS.USERS_URLS.ADD_USER, data),
  getUserDataById: (id: string) =>
    ApiService.getData(
      `${ACCOUNT_SETTINGS_URLS.USERS_URLS.GET_USER_BY_ID}${id}`
    ),
  editUserData: (data: UserType, id: string) =>
    ApiService.putData(
      `${ACCOUNT_SETTINGS_URLS.USERS_URLS.EDIT_USER}${id}`,
      data
    ),
  deleteUsers: (params: UserIdsType) =>
    ApiService.deleteData(`${ACCOUNT_SETTINGS_URLS.USERS_URLS.DELETE_USERS}`, {
      params,
    }),
  resendEmail: (userId: string) =>
    ApiService.getData(
      `${ACCOUNT_SETTINGS_URLS.USERS_URLS.RESEND_EMAIL}${userId}`
    ),
  moduleList: () =>
    ApiService.getData(ACCOUNT_SETTINGS_URLS.USERS_URLS.GET_MODULE_LIST),
  updateStatus: (data: Record<string, boolean>, userId: string) =>
    ApiService.putData(
      `${ACCOUNT_SETTINGS_URLS.USERS_URLS.UPDATE_STATUS}${userId}`,
      data
    ),
};

export default AccountSettingsUsersApiServices;
