const signUpProcessHelper = (data: Record<string, string>) => {
  const { stage, userId, _id } = data;
  let route: any = '';

  switch (stage) {
    case 'Plan Selection':
      route = `/plans/${userId ?? _id}`;
      break;

    case 'Card Selection':
      route = `/card-details/${userId ?? _id}`;
      break;

    case 'Google Authentication':
      route = `/google-authentication/${userId ?? _id}`;
      break;

    case 'Verify Google Authentication':
      route = `/authenticateCode/${userId ?? _id}`;
      break;

    case 'Software Integration':
      route = `/account-software-integration/${userId ?? _id}`;
      break;

    case 'Payment Details':
      route = `/payment-details/${userId ?? _id}`;
      break;

    case 'Team Member Google Authentication':
      route = `/google-authentication/team-member-set-2FA/${userId ?? _id}`;
      break;

    default:
      route = `/login`;
      break;
  }
  return route;
};

export default signUpProcessHelper;
