import { COMMON_URLS } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

export const commonApiServices = {
  getCategoryList: (params: Record<string, any>) =>
    ApiService.getData(COMMON_URLS.GET_CATEGORY, { params }),
  updateCategoryList: (data: Record<string, Record<string, string>[]>) =>
    ApiService.putData(COMMON_URLS.UPDATE_CATEGORY_LIST, data),
  getSuppliers: (params?: Record<string, string>) =>
    ApiService.getData(COMMON_URLS.GET_SUPPLIER_DROPDOWN_DATA, { params }),
  getUserRoles: () => ApiService.getData(COMMON_URLS.GET_USER_ROLE),
  getUnits: () => ApiService.getData(COMMON_URLS.GET_UNITS),
  updateUnits: (data: Record<string, Record<string, string>[]>) =>
    ApiService.putData(COMMON_URLS.UPDATE_UNITS, data),
  downloadSampleCsv: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: COMMON_URLS.DOWNLOAD_SAMPLE_CSV,
      responseType: 'blob',
      params,
    }),
  getProducts: (params?: Record<string, string>) =>
    ApiService.getData(COMMON_URLS.GET_PRODUCT_DROPDOWN_DATA, { params }),
  getCategorySubcategoryDropdown: () =>
    ApiService.getData(COMMON_URLS.CATEGORY_SUNCATEGORY_DROPDOWN),
  productDropdownList: () =>
    ApiService.getData(COMMON_URLS.PRODUCT_DROPDOWN_LIST),
  getCurrencyRate: () => ApiService.getData(COMMON_URLS.CURRENCY_CONVERSION),
};
