import { UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS } from '../../reduxConstants/admin/uploadTermsAndPolicyReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface uploadProps {
  termsList: Record<string, any>;
  policyList: Record<string, any>;
}

const initialUploadTermsReducer: uploadProps = {
  termsList: {},
  policyList: {},
};

export const uploadTermsAndPolicyReducer = (
  state = initialUploadTermsReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.TERMS_AND_CONDITIONS_LIST:
      return { ...state, termsList: action.data };

    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.PRIVACY_POLICY_LIST:
      return { ...state, policyList: action.data };

    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.UPLOAD_ORGANIZATION_TERMS_AND_CONDITION:
      const termList = [...state.termsList.docs];
      const isRepeatTerm = termList
        .map((term) => term._id)
        .includes(`unsaved${action.data?.name}`);

      return isRepeatTerm
        ? { ...state }
        : {
            ...state,
            termsList: {
              ...state.termsList,
              docs: [
                ...state.termsList.docs,
                {
                  URL: action.data?.URL,
                  _id: `unsaved${action.data?.name}`,
                  name: `Terms And Conditions (unsaved - ${action.data?.name})`,
                  isActive: false,
                },
              ],
            },
          };

    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.UPLOAD_ORGANIZATION_PRIVACY_POLICY:
      let { URL, name } = action.data;

      const policyList = [...state.policyList.docs];
      const isRepeatPolicy = policyList
        .map((term) => term._id)
        .includes(`unsaved${name}`);

      return isRepeatPolicy
        ? { ...state }
        : {
            ...state,
            policyList: {
              ...state.policyList,
              docs: [
                ...state.policyList.docs,
                {
                  URL: URL,
                  _id: `unsaved${name}`,
                  name: `Privacy Policy (unsaved - ${name})`,
                  isActive: false,
                },
              ],
            },
          };

    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.ON_CHANGE_ACTIVE:
      const { type, rowId } = action.data;
      const allTerms = [...state?.termsList.docs];
      const allPolicy = [...state?.policyList.docs];
      let data: Record<string, string | boolean>[] = [];

      if (type === 'termsAndCondition') {
        data = allTerms?.map((term: Record<string, string | boolean>) => {
          term._id === rowId ? (term.isActive = true) : (term.isActive = false);
          return term;
        });
      } else {
        data = allPolicy?.map((term: Record<string, string | boolean>) => {
          term._id === rowId ? (term.isActive = true) : (term.isActive = false);
          return term;
        });
      }

      return type === 'termsAndCondition'
        ? {
            ...state,
            termsList: {
              ...state.termsList,
              docs: data,
            },
          }
        : {
            ...state,
            policyList: {
              ...state.policyList,
              docs: data,
            },
          };

    case UPLOAD_TERMS_AND_POLICY_REDUX_CONSTANTS.ON_REMOVE_DOCUMENT:
      const { isFor, id } = action.data;
      const allTermsList = [...state?.termsList.docs];
      const allPolicyList = [...state?.policyList.docs];
      let finalData: Record<string, string | boolean>[] = [];

      if (isFor === 'termsAndCondition') {
        finalData = allTermsList?.filter(
          (term: Record<string, string | boolean>) => term._id !== id
        );
      } else {
        finalData = allPolicyList?.filter(
          (term: Record<string, string | boolean>) => term._id !== id
        );
      }

      return isFor === 'termsAndCondition'
        ? {
            ...state,
            termsList: {
              ...state.termsList,
              docs: finalData,
            },
          }
        : {
            ...state,
            policyList: {
              ...state.policyList,
              docs: finalData,
            },
          };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialUploadTermsReducer,
      };
    default:
      return { ...state };
  }
};
