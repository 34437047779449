import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { softwareIntegrationApiServices } from '../../../services/pageServices/accountSettingsServices/softwareIntegrationApiServices';
import { SOFTWARE_INTEGRATION_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/softwareIntegrationReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getCurrentSoftwareaction = () => {
  return async (dispatch: any) => {
    try {
      const response =
        await softwareIntegrationApiServices.getCurrentsoftware();
      if (response.status === 200) {
        dispatch({
          type: SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_CURRENT_SOFTWARE,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const accountingSoftwareintegration = (
  data: Record<string, string | boolean>,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await softwareIntegrationApiServices.integrateSoftware(
        data
      );
      if (response.status === 200) {
        dispatch({
          type: SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ACCOUNT_DETAILS,
          data: response.data.data,
        });
        if (response.data?.data?.consentURL) {
          window.open(
            response.data.data.consentURL,
            '_blank',
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
          );
        }
        return true;
      }
    } catch (e) {
      cb();
      displayErrors(e);
    }
  };
};

export const validateSoftwareIntegrationAction = (
  data: Record<string, any>,
  cb: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('updateDetailsLoaderAction');
      const response =
        await softwareIntegrationApiServices.validateSoftwareIntegration(data);
      if (response.status === 200) {
        if (response.data.data) {
          dispatch({
            type: SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ORGANIZATION_DETAIL,
            data: response.data.data,
          });
        } else {
          successNotification(response?.data?.message);
        }
        return true;
      }
    } catch (e) {
      if (cb) {
        cb();
      }
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateDetailsLoaderAction');
    }
  };
};

export const chooseOrganizationAction = (data: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('loginOrganizationValidationLoader');
      const response = await softwareIntegrationApiServices.chooseOrganization(
        data
      );
      if (response.status === 200) {
        successNotification(response?.data?.message);
        dispatch({
          type: SOFTWARE_INTEGRATION_REDUX_CONSTANTS.GET_ORGANIZATION_DETAIL,
          data: {},
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('loginOrganizationValidationLoader');
    }
  };
};

export const resetInegrationAction = () => {
  return async () => {
    try {
      const response = await softwareIntegrationApiServices.resetIntegration();
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const removeIntegrationAction = () => {
  return async () => {
    try {
      const response = await softwareIntegrationApiServices.removeIntegration();
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
